import axios from "axios";
import { API } from "../../../../backend";

const orgId = localStorage.getItem('orgID');

export const getOrgDetails = async () => {
    const url = `${API}/org/getData/${orgId}`;
    const config = {
        method: 'GET',
        url: url,
        headers: {
            "Content-Type": "application/json",
        },
    }
    const response = await axios(config);
    return response;
}

export const getOrgDept = async () => {
    const url = `${API}/org/getOrgDeprt/${orgId}`;
    const config = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': "application/json",
        },
    };
    const response = await axios(config);
    return response;
}

export const createOrg = async (form) => {
    const url = `${API}/org/addOrg`;
    const config = {
        method: 'POST',
        url: url,
        data: form,
        headers: {
            'Content-Type': "application/json",
        },
    }
    const response = await axios(config);
    return response;
}

export const updateOrg = async (form) => {
    const url = `${API}/org/update/${orgId}`;
    const config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            'Content-Type': "application/json",
        },
    }
    const response = await axios(config);
    return response;
}

export const updateLogo = async (form) => {
    const url = `${API}/org/logo/${orgId}`;
    const config = {
        method: 'PATCH',
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await axios(config);
    return response;
}

export const getOrgById = async (id) => {
    const url = `${API}/org/getData/${id}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios(config);
    return response;
}