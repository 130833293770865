import React, { useState, useRef } from "react";
import topHeader from "../../../../assets/images/dashboard/top-header.png";
import { Row, Col, Container, Toast } from "react-bootstrap";
// import Calender from "../../special-pages/calender";
import Calendar from "react-calendar";
import "./payroll.css";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { toast } from "react-hot-toast";
import Leave from "./leave";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { HiOutlinePencilAlt } from "react-icons/hi";
// import 'react-calendar/dist/Calendar.css';
import {
  SevenColGrid,
  Wrapper,
  HeadDays,
  DateControls,
  StyledEvent,
  SeeMore,
  PortalWrapper,
} from "./Calender.styled";
import { DAYS, MOCKAPPS } from "./conts";
import {
  datesAreOnSameDay,
  getDarkColor,
  getDaysInMonth,
  getMonthYear,
  getSortedDays,
  nextMonth,
  prevMonth,
  range,
  sortDays,
} from "./utils";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { useEffect } from "react";
import { MdStreetview } from "react-icons/md";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import EditAttendance from "./editAttendance";
import Activity from "../../../../components/partials/dashboard/MainStyle/Activity";
import LeavesTaken from "./leavesTaken";
import {
  checkOut,
  getAllLeaves,
  markAttendancce,
  markAttendance,
} from "../Hook/attendanceHooks";
import { ErrorToast, SuccessToast } from "../../components/toast";

const Attendance = () => {
  const [value, onChange] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState(MOCKAPPS);
  const dragDateRef = useRef();
  const dragindexRef = useRef();
  const [showPortal, setShowPortal] = useState(false);
  const [portalData, setPortalData] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [leaves, setLeaves] = useState([]);

  const [checkIn, setCheckIn] = useState(false);
  const [checkedIn, setCheckedIn] = useState(false);
  const [checkedOut, setCheckedOut] = useState(false);

  const [show, setShow] = useState(false);
  const [showAttend, setShowAttend] = useState(false);
  const [showCalendar, setShowCalendar] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [showLeaves, setShowLeaves] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showActivity, setShowActivity] = useState(false);

  const today = new Date();
  const date = today.getDate();
  // const day = today.getDay();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const hour = today.getHours();
  const minute = today.getMinutes();
  const second = today.getSeconds();

  const [checkInTime, setCheckInTime] = useState();
  const [checkOutTime, setCheckOutTime] = useState();

  const markAttend = () => {
    markAttendance(setCheckedIn);
  };

  const markExit = () => {
    checkOut(setCheckedOut);
  };

  console.log("checked in", checkIn);

  const addEvent = (date, event) => {
    if (!event.target.classList.contains("StyledEvent")) {
      const text = window.prompt("name");
      if (text) {
        date.setHours(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        setEvents((prev) => [
          ...prev,
          { date, title: text, color: getDarkColor() },
        ]);
      }
    }
  };

  const drag = (index, e) => {
    dragindexRef.current = { index, target: e.target };
  };

  const onDragEnter = (date, e) => {
    e.preventDefault();
    dragDateRef.current = { date, target: e.target.id };
  };

  const drop = (ev) => {
    ev.preventDefault();

    setEvents((prev) =>
      prev.map((ev, index) => {
        if (index === dragindexRef.current.index) {
          ev.date = dragDateRef.current.date;
        }
        return ev;
      })
    );
  };

  const handleOnClickEvent = (event) => {
    setShowPortal(true);
    setPortalData(event);
  };

  const handlePotalClose = () => setShowPortal(false);

  const handleDelete = () => {
    setEvents((prevEvents) =>
      prevEvents.filter((ev) => ev.title !== portalData.title)
    );
    handlePotalClose();
  };

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleCheckIn = () => {
    checkedIn === false && setCheckIn(true);
    checkedIn === false && setCheckedIn(true);

    checkedIn === false && markAttend();

    checkedIn === false && SuccessToast("Checked In");
    checkedOut === true && ErrorToast("Already checked out");
    checkedIn === true && setCheckInTime(`${hour}:${minute}:${second}`);
  };

  const handleCheckOut = () => {
    checkIn === true && setCheckIn(false);
    checkedIn === true && setCheckedOut(true);
    checkedIn === true && checkedOut === false && SuccessToast("Checked Out");
    setCheckOutTime(`${hour}:${minute}:${second}`);
  };

  const handleCalendar = () => {
    setShowTable(false);
    setShowCalendar(true);
  };

  const handleTable = () => {
    setShowCalendar(false);
    setShowTable(true);
  };

  const dummyData = [
    {
      id: 1,
      date: "20/1/2022",
      status: "N/A",
      checkIn: "09:30 AM",
      checkOut: "06:00 PM",
      duration: "08:30 hrs",
      remark: "N/A",
    },
    {
      id: 2,
      date: "20/1/2022",
      status: "N/A",
      checkIn: "09:30 AM",
      checkOut: "06:00 PM",
      duration: "08:30 hrs",
      remark: "N/A",
    },
    {
      id: 3,
      date: "20/1/2022",
      status: "N/A",
      checkIn: "09:30 AM",
      checkOut: "06:00 PM",
      duration: "08:30 hrs",
      remark: "N/A",
    },
    {
      id: 4,
      date: "20/1/2022",
      status: "N/A",
      checkIn: "09:30 AM",
      checkOut: "06:00 PM",
      duration: "08:30 hrs",
      remark: "N/A",
    },
    {
      id: 5,
      date: "20/1/2022",
      status: "N/A",
      checkIn: "09:30 AM",
      checkOut: "06:00 PM",
      duration: "08:30 hrs",
      remark: "N/A",
    },
    {
      id: 6,
      date: "20/1/2022",
      status: "N/A",
      checkIn: "09:30 AM",
      checkOut: "06:00 PM",
      duration: "08:30 hrs",
      remark: "N/A",
    },
    {
      id: 7,
      date: "20/1/2022",
      status: "N/A",
      checkIn: "09:30 AM",
      checkOut: "06:00 PM",
      duration: "08:30 hrs",
      remark: "N/A",
    },
    {
      id: 8,
      date: "20/1/2022",
      status: "N/A",
      checkIn: "09:30 AM",
      checkOut: "06:00 PM",
      duration: "08:30 hrs",
      remark: "N/A",
    },
    {
      id: 9,
      date: "20/1/2022",
      status: "N/A",
      checkIn: "09:30 AM",
      checkOut: "06:00 PM",
      duration: "08:30 hrs",
      remark: "N/A",
    },
    {
      id: 10,
      date: "20/1/2022",
      status: "N/A",
      checkIn: "09:30 AM",
      checkOut: "06:00 PM",
      duration: "08:30 hrs",
      remark: "N/A",
    },
  ];

  return (
    <>
      <SubHeader title="Attendance" />
      <Row
        className="mx-1"
        style={{
          width: screenWidth >= 1200 ? "95vw" : "90vw",
          marginTop: screenWidth >= 1200 ? "-1%" : "-3%",
          position: "absolute",
          // minWidth: "90vw",
        }}
      >
        <Col sm={9}>
          {/* <Col sm={9} className="m-0" style={{maxHeight: "100vh"}}> */}
          <Col className="bg-white rounded mb-3" sm={12}>
            <div className="row py-2 px-3">
              <Col sm={8}>
                <h2>
                  Mark Your attendance for today{" "}
                  <span className="m-0 text-primary">
                    {`(${date} / ${month} / ${year})`}
                  </span>
                </h2>
                <p className="fs-5 mt-2 text-secondary">
                  You can mark your attendance for today. For any other day,
                  please use the edit option below.
                </p>
              </Col>
              <Col sm={4} className="mt-3">
                <span
                  className={`${
                    checkedIn === true
                      ? "bg-primary border border-primary text-white"
                      : "bg-white border border-primary"
                  } py-2 px-2 rounded mb-2`}
                  onClick={markAttend}
                  style={{
                    cursor: checkedOut === true ? "not-allowed" : "pointer",
                  }}
                >
                  Check In
                </span>
                <span
                  className={`${
                    checkedIn
                      ? "bg-white text-primary border-primary"
                      : checkedOut
                      ? "bg-primary text-white"
                      : "bg-soft-secondary text-dark"
                  } p-2 rounded border`}
                  style={{
                    cursor: checkedIn ? "not-allowed" : "pointer",
                  }}
                  // className={`${
                  //   checkedIn === false && checkedOut === false
                  //     ? "bg-soft-secondary border border-secondary"
                  //     : checkedIn === true && checkedOut === false
                  //     ? "bg-white border border-primary"
                  //     : checkedIn === true && checkedOut === true
                  //     ? "bg-primary border border-primary text-white"
                  //     : ""
                  // }  py-2 px-2 rounded mb-2`}
                  onClick={markExit}
                  // style={{
                  //   cursor:
                  //     checkedIn === true
                  //       ? "pointer"
                  //       : checkedOut === true
                  //       ? "not-allowed"
                  //       : "not-allowed",
                  // }}
                >
                  Check Out
                </span>
              </Col>
            </div>
          </Col>

          <Col className="bg-white rounded p-2 mb-3">
            <Row>
              <Col sm={11}>
                <Row className="text-center">
                  <Col>
                    <IoIosArrowRoundBack
                      className="fs-1 fw-bold"
                      onClick={() => prevMonth(currentDate, setCurrentDate)}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                  <Col>
                    <span
                      className="ms-5 ps-4 fs-4"
                      onClick={() => setCurrentDate(new Date())}
                      style={{ cursor: "pointer" }}
                    >
                      Today
                    </span>
                  </Col>
                  <Col>
                    <IoIosArrowRoundForward
                      className="fs-1 fw-bold"
                      onClick={() => nextMonth(currentDate, setCurrentDate)}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={1} className="text-center">
                {!showCalendar ? (
                  <BsChevronDown
                    className="fs-3"
                    onClick={() => handleCalendar()}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <BsChevronUp
                    className="fs-3"
                    onClick={() => setShowCalendar(false)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Col>
            </Row>
            {showCalendar === true && (
              <Row className="mt-2">
                <Col className="text-center fw-bold fs-3 text-primary">
                  {getMonthYear(currentDate)}
                </Col>
              </Row>
            )}

            {showCalendar === true && (
              <Row className="px-4">
                <div
                  className="row bg-primary"
                  style={{
                    borderRadius: "10px 10px 0 0",
                    width: "97%",
                    marginLeft: "15px",
                  }}
                >
                  {DAYS.map((day) => (
                    <Col className=" text-center text-white fs-5 fw-bold py-2">
                      {day}
                    </Col>
                  ))}
                </div>
                <SevenColGrid
                  className="pb-3 px-3"
                  fullheight={false}
                  is28Days={getDaysInMonth(currentDate) === 28}
                >
                  {getSortedDays(currentDate).map((day) => (
                    <Col
                      className=" text-primary"
                      // style={{minWidth: "300px"}}
                      id={`${currentDate.getFullYear()}/${currentDate.getMonth()}/${day}`}
                      onDragEnter={(e) =>
                        onDragEnter(
                          new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            day
                          ),
                          e
                        )
                      }
                      onDragOver={(e) => e.preventDefault()}
                      onDragEnd={drop}
                    >
                      <span
                        className={` ${
                          datesAreOnSameDay(
                            new Date(),
                            new Date(
                              currentDate.getFullYear(),
                              currentDate.getMonth(),
                              day
                            )
                          )
                            ? "active border border-2 border-primary pt-3"
                            : "pt-3"
                        }`}
                        onClick={() => console.log(day)}
                        style={{
                          color: day === date && checkedIn === true && "green",
                        }}
                      >
                        {day}
                      </span>
                      {/* <EventWrapper>
                    {events.map(
                      (ev, index) =>
                        datesAreOnSameDay(
                          ev.date,
                          new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            day
                          )
                        ) && (
                          <StyledEvent
                            onDragStart={(e) => drag(index, e)}
                            onClick={() => handleOnClickEvent(ev)}
                            draggable
                            className="StyledEvent"
                            id={`${ev.color} ${ev.title}`}
                            key={ev.title}
                            bgColor={ev.color}
                          >
                            {ev.title}
                          </StyledEvent>
                        )
                    )}
                  </EventWrapper> */}
                    </Col>
                  ))}
                </SevenColGrid>
              </Row>
            )}
          </Col>

          <Col className="bg-white rounded mb-3 py-2" sm={12}>
            <Row className="mx-2">
              <Col sm={11}>
                <h2>Attendance</h2>
                <p className="fs-5 mt-2 text-dark">
                  To apply for leaves, or to update your attendance data, please
                  click on the edit button next to a date. To apply for many
                  leaves together, please click{" "}
                  <span
                    className="m-0 d-inline text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShow(true)}
                  >
                    here
                  </span>{" "}
                  .
                </p>
              </Col>
              <Col sm={1} className="text-end">
                {!showTable ? (
                  <BsChevronDown
                    className="fs-3"
                    onClick={() => handleTable()}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <BsChevronUp
                    className="fs-3"
                    onClick={() => setShowTable(false)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Col>
            </Row>
            {showTable && (
              <Row
                className="table-responsive mx-4 mb-2"
                style={{
                  height: "60vh",
                  maxHeight: "60vh",
                  overflowY: "scroll",
                }}
              >
                <table>
                  <thead
                    className="bg-primary w-100 top-0 text-white fs-5 py-2 m-0"
                    style={{ position: "sticky", borderRadius: "5px 5px 0 0" }}
                  >
                    <th className="ps-2" style={{ borderRadius: "5px 0 0 0" }}>
                      Date
                    </th>
                    <th>Status</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    <th>Duration</th>
                    <th>Remark</th>
                    <th style={{ borderRadius: "0 5px 0 0" }}>Edit</th>
                  </thead>
                  <tbody>
                    {getSortedDays(currentDate).map((day) => (
                      <tr
                        style={{
                          borderBottom: "1px solid #000",
                          display: day === undefined && "none",
                        }}
                      >
                        <td className="py-2 ps-2">{`${day}/${month}/${year}`}</td>
                        <td>Leave</td>
                        <td>09:30:00</td>
                        <td>06:00:00</td>
                        <td>07:30:00</td>
                        <td>N/A</td>
                        <td>
                          <Tooltip title="Edit">
                            <Link to="#">
                              <HiOutlinePencilAlt
                                className="text-success fs-4"
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowAttend(true)}
                              />
                            </Link>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Row>
            )}
          </Col>
          {/* </Col> */}
        </Col>
        <Col sm={3} className="mb-5">
          <div className="bg-white py-2 rounded m-0 mb-2">
            <Row className="mx-2" onClick={() => setShowOptions(!showOptions)}>
              <Col sm={9}>
                <h4>Options</h4>
              </Col>
              <Col sm={1}>
                {showOptions === false ? (
                  <BsChevronDown
                    className="fs-4"
                    onClick={() => setShowOptions(true)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <BsChevronUp
                    className="fs-4"
                    onClick={() => setShowOptions(false)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Col>
            </Row>
            {showOptions === true && (
              <h4
                className="text-white mx-4 mt-2 p-2 m-0 bg-primary text-white rounded"
                style={{ cursor: "pointer" }}
                onClick={() => setShowLeaves(true)}
              >
                View taken leaves
              </h4>
            )}
          </div>
          {/* {showActivity === true && ( */}
          <div
            style={{
              // height: "100%",
              maxHeight: "100vh",
              overflowX: "hidden",
              overflowY: "scroll",
              scrollBehavior: "smooth",
            }}
          >
            <Activity
              title="Recent Activities"
              showActivity={showActivity}
              setShowActivity={setShowActivity}
            />
          </div>
          {/* )} */}
        </Col>
      </Row>
      <Leave show={show} setShow={setShow} />
      <EditAttendance show={showAttend} setShow={setShowAttend} />
      <LeavesTaken show={showLeaves} setShow={setShowLeaves} />
    </>
  );
};

export default Attendance;
