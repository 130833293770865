import { responsiveFontSizes } from "@material-ui/core";
import axios from "axios";
import { API } from "../../../../backend"
import {toast} from 'react-hot-toast';
import { ErrorToast, SuccessToast } from "../../components/toast";

const eid = "F0.7764395207983226";

export const markAttendance = async (setCheckedIn) => {
    let url=`${API}/attendence/markAttendence/F0.7764395207983226`;
    const config = {
        method: "POST",
        url: url,
        headers: {
            "Content-Type": "application/json"
        }
    };
    try{
        const response = await axios(config);
        if(response.status === 201){
            console.log("attendance", response.data);
            SuccessToast("Checked In");
            setCheckedIn(true);
            return response.data;
        }
        else{
            console.log("Something went wrong");
            ErrorToast("Already checked In ! ! !");
        }
    }
    catch(err) {
        console.log(err.message);
        ErrorToast("Already checked In ! ! !");
    }
}

export const checkOut = async (setCheckedOut) => {
    let url=`${API}/attendence/markExit/F0.7764395207983226`;
    const config = {
        method: "PATCH",
        url: url,
        headers: {
            "Content-Type": "application/json"
        }
    };
    try{
        const response = await axios(config);
        if(response.status === 200){
            console.log("attendance", response.data);
            SuccessToast("Checked Out");
            setCheckedOut(true);
            return response.data;
        }
        else{
            console.log("Something went wrong");
            ErrorToast("Already Checked Out");
        }
    }
    catch(err) {
        console.log(err.message);
        ErrorToast("Already Checked Out");
    }
}

export const editAttendance = async (data) => {
    let url = `${API}/`;
    let config = {
        method: "PATCH",
        url: url,
        data: data,
        headers: {
            "Content-Type": "application/json",
        },
    };
    try{
        const res = await axios(config);
        if(res.status === 201){
            return res.data;
            console.log("request sent");
        }
        else{
            console.log("Something went wrong");
        }
    }
    catch(err){
        return err.message
    }
}

export const requestleave = async (data) => {
    let url = `${API}/attendence/requestLeave/F0.7764395207983226`;
    let config = {
        method: "PATCH",
        url: url,
        body: data,
        headers: {
            "Content-Type": "applicaion/json",
        }
    };
    try{
        const response = await axios(config);
        if(response.status === 201){
           console.log("request sent");
           SuccessToast("Request sent")
        }
        else{
            console.log("Something went wrong");
            ErrorToast("Something went wrong");
        }
    }
    catch(err) {
        console.log(err.message)
        ErrorToast("Something went wrong")
    }
}

export const getAllLeaves =  async (setLeaves) => {
    let url = `${API}/attendence/getLeaves/${eid}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
        }
    };
    try{
        const response = await axios(config);
        if(response.status === 201){
            setLeaves(response.data.data);
            console.log("All leaves", response.data)
        }
        else{
            console.log("something went wrong")
        }
    }
    catch(err){
        console.log(err.message)
    }
}