import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { MdClear } from "react-icons/md";
import { API } from "../../../../backend";
import { toast } from "react-hot-toast";
import { requestleave } from "../Hook/attendanceHooks";
import axios from "axios";

const Leave = ({ show, setShow }) => {
  const [form, setForm] = useState({});
  const formData = new FormData();

  const sendRequest = async (e) => {
    e.preventDefault();
    await fetch(`${API}/attendence/requestLeave/F0.7764395207983226`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      body: JSON.stringify(form),
    })
    .then((res) => res.json())
    .then((response) => {
      if(response.status === 201){
        setForm({
          ...form,
          [e.target.name]: e.target.value
        });
        console.log("request sent");
      }
      else{
        console.log("Something went wrong");
      }
    })
    .catch((err) => {
      console.log(err.message);
    })
  }

  // const sendRequest = (e) => {
  //   e.preventDefault();
  //   requestleave(form).then((res) => {
  //     console.log(res.data);
  //   })
  // }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  console.log("Form data", form);
  return (
    <div>
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-primary">
          <Col sm={11}>
            <h4 className="text-white">Request for a leave</h4>
          </Col>
          <Col className="text-end" sm={1} onClick={() => setShow(false)}>
            <MdClear
              className="fs-4 text-white"
              style={{ cursor: "pointer" }}
            />
          </Col>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={sendRequest}>
            <Form.Group className="mb-2">
              <Form.Label className="fs-5 mb-0">Status</Form.Label>
              <Form.Select
                className="border border-primary"
                name="status"
                onChange={handleChange}
                required
              >
                <option>Select Status</option>
                <option>Leave</option>
                <option>Half Day</option>
                <option>Unpaid Leave</option>
                <option>Unpaid Half Day</option>
                <option>Work From Home</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="fs-5 mb-0">From Date</Form.Label>
              <Form.Control
                type="date"
                className="border border-primary"
                name="from"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="fs-5 mb-0">To Date</Form.Label>
              <Form.Control
                type="date"
                className="border border-primary"
                name="to"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="fs-5 mb-0">Reason</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows="6"
                className="border border-primary"
                name="reason"
                onChange={handleChange}
                placeholder="Reason"
                required
              />
            </Form.Group>
            <Row className="mt-2">
              <Col sm={4}>
                <Button type="submit" className="bg-primary">
                  Request
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        {/* <Modal.Footer> */}
        {/* <Button className="bg-primary">Cancel</Button> */}
        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Leave;
