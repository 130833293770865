import React, { useEffect, useState } from 'react'
import { Form, Col, Modal, Row } from 'react-bootstrap';
import { MdClear } from 'react-icons/md';
import { getProductDetails, updateProduct } from '../Hook/productHooks';
import { useParams } from 'react-router-dom';
import { getGlobalTaxes } from '../Hook/taxHooks';
import { toast } from 'react-hot-toast';
import { ErrorToast, SuccessToast } from '../../components/toast';

const ProductDetails = ({ getProducts, showProductDetails, setShowProductDetails,
    showUpdateProduct, setShowUpdateProduct, firmTaxes, productId, firmId
}) => {

    const [productDetails, setProductDetails] = useState([]);

    const [form, setForm] = useState([]);
    const [allGlobalTaxes, setAllGlobalTaxes] = useState([]);
    const [validate, setValidate] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [showCustomize, setShowCustomize] = useState(false);
    const [showTaxField, setShowTaxField] = useState(false);
    const [showHSNField, setShowHSNField] = useState(false);
    const [showSACField, setShowSACField] = useState(false);
    const [showDiscontField, setShowDiscountField] = useState(false);
    const allTaxes = [...firmTaxes, ...allGlobalTaxes];
    // const allTaxes = [{'taxname': 5}];

    let productHSN = (productDetails === undefined || productDetails.hsn === undefined) ? '' : productDetails.hsn.length;
    let productSAC = (productDetails === undefined || productDetails.sac === undefined) ? '' : productDetails.sac.length;
    const orgId = localStorage.getItem('orgID');

    useEffect(() => {
        getProductDetails(productId).then((response) => {
            if (response.status === 200) {
                setProductDetails(response.data.data);
            }
        })
    }, [productId]);

    useEffect(() => {
        productHSN > 0 ? setShowHSNField(true) : setShowHSNField(false);
        productSAC > 0 ? setShowSACField(true) : setShowSACField(false);
    }, [productDetails]);

    useEffect(() => {
        getGlobalTaxes(setAllGlobalTaxes)
    }, []);

    const handleSelectHSNorSAC = (e) => {
        if (e.target.value === 'hsn') {
            setShowHSNField(true);
            setShowSACField(false);
        } else if (e.target.value === 'sac') {
            setShowSACField(true);
            setShowHSNField(false);
        }
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
            delete: false,
            firmId: firmId,
            orgId: orgId
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateProduct(form, productId).then((response) => {
            if (response.status === 201) {
                SuccessToast("Product updated successfully");
                getProducts();
                setShowUpdateProduct(false);
            } else ErrorToast("Unable to update product")
        })
    };

    // console.log("product details", productDetails);

    return (
        <>
            <Modal centered show={showProductDetails} onHide={() => setShowProductDetails(false)} style={{ boxShadow: "0px 0px 10px #c8c9ca" }}>
                <Modal.Header className="bg-primary">
                    <Col sm={11} className="text-white">
                        <h4 className="text-white">Product Details</h4>
                    </Col>
                    <Col sm={1} className="text-end text-white">
                        <MdClear className="fs-4" onClick={() => setShowProductDetails(false)} style={{ cursor: "pointer" }} />
                    </Col>
                </Modal.Header>
                <Modal.Body className="my-2" style={{ fontSize: "0.8rem" }}>
                    <Row>
                        <Row className='mb-2 mx-3 pe-5'>
                            <Col sm={3} className='m-0 p-0 fw-semibold'>
                                <Row className='m-0 p-0'>
                                    <Col sm={10} className='m-0 p-0 text-start'>Product Name</Col>
                                    <Col sm={2} className='m-0 p-0 pe-3 text-end'>:</Col>
                                </Row>
                            </Col>
                            <Col sm={9} className='m-0 p-0'>{productDetails.name === undefined ? '' : productDetails.name}</Col>
                        </Row>
                        <Row className='mb-2 mx-3 pe-5'>
                            <Col sm={3} className='m-0 p-0 fw-semibold'>
                                <Row className='m-0 p-0'>
                                    <Col sm={10} className='m-0 p-0 text-start'>Price</Col>
                                    <Col sm={2} className='m-0 p-0 pe-3 text-end'>:</Col>
                                </Row>
                            </Col>
                            <Col sm={9} className='m-0 p-0'>{productDetails.price === undefined ? '' : productDetails.price}</Col>
                        </Row>
                    </Row>

                    {
                        productDetails.desc && (
                            <Row>
                                <Row className='mx-3 mb-2 pe-5'>
                                    <Col sm={3} className='m-0 p-0 fw-semibold'>
                                        <Row className='m-0 p-0'>
                                            <Col sm={10} className='m-0 p-0 text-start'>Description</Col>
                                            <Col sm={2} className='m-0 p-0 pe-3 text-end'>:</Col>
                                        </Row>
                                    </Col>
                                    <Col sm={9} className='m-0 p-0'>{productDetails.desc === undefined ? '' : productDetails.desc}</Col>
                                </Row>
                            </Row>
                        )
                    }

                    {
                        productDetails.tax && (
                            <Row>
                                <Row className='mb-2 mx-3 pe-5'>
                                    <Col sm={3} className='m-0 p-0 fw-semibold'>
                                        <Row className='m-0 p-0'>
                                            <Col sm={10} className='m-0 p-0 text-start'>Tax Rate</Col>
                                            <Col sm={2} className='m-0 p-0 pe-3 text-end'>:</Col>
                                        </Row>
                                    </Col>
                                    <Col sm={9} className='m-0 p-0'>{productDetails.tax === undefined ? '' : productDetails.tax}</Col>
                                </Row>
                            </Row>
                        )
                    }

                    {
                        productDetails.hsn && (
                            <Row>
                                <Row className='mb-2 mx-3 pe-5'>
                                    <Col sm={3} className='m-0 p-0 fw-semibold'>
                                        <Row className='m-0 p-0'>
                                            <Col sm={10} className='m-0 p-0 text-start'>HSN</Col>
                                            <Col sm={2} className='m-0 p-0 pe-3 text-end'>:</Col>
                                        </Row>
                                    </Col>
                                    <Col sm={9} className='m-0 p-0'>{productDetails.hsn === undefined ? '' : productDetails.hsn}</Col>
                                </Row>
                            </Row>
                        )
                    }
                    {
                        productDetails.sac && (
                            <Row>
                                <Row className='mb-2 mx-3 pe-5'>
                                    <Col sm={3} className='m-0 p-0 fw-semibold'>
                                        <Row className='m-0 p-0'>
                                            <Col sm={10} className='m-0 p-0 text-start'>SAC</Col>
                                            <Col sm={2} className='m-0 p-0 pe-3 text-end'>:</Col>
                                        </Row>
                                    </Col>
                                    <Col sm={9} className='m-0 p-0'>{productDetails.sac === undefined ? '' : productDetails.sac}</Col>
                                </Row>
                            </Row>
                        )
                    }
                    {
                        productDetails.type && (
                            <Row>
                                <Row className='mb-2 mx-3 pe-5'>
                                    <Col sm={3} className='m-0 p-0 fw-semibold'>
                                        <Row className='m-0 p-0'>
                                            <Col sm={10} className='m-0 p-0 text-start'>Product Type</Col>
                                            <Col sm={2} className='m-0 p-0 pe-3 text-end'>:</Col>
                                        </Row>
                                    </Col>
                                    <Col sm={9} className='m-0 p-0'>{productDetails.type === undefined ? '' : productDetails.type}</Col>
                                </Row>
                            </Row>
                        )
                    }

                    {
                        productDetails.cat && (
                            <Row>
                                <Row className='mb-2 mx-3 pe-5'>
                                    <Col sm={3} className='m-0 p-0 fw-semibold'>
                                        <Row className='m-0 p-0'>
                                            <Col sm={10} className='m-0 p-0 text-start'>Category</Col>
                                            <Col sm={2} className='m-0 p-0 pe-3 text-end'>:</Col>
                                        </Row>
                                    </Col>
                                    <Col sm={9} className='m-0 p-0'>{productDetails.cat === undefined ? '' : productDetails.cat}</Col>
                                </Row>
                            </Row>
                        )
                    }
                </Modal.Body>
            </Modal>

            <Modal centered size='lg' show={showUpdateProduct} onHide={() => setShowUpdateProduct(false)}>
                <Modal.Header className="bg-primary">
                    <Col sm={11} className="text-white">
                        <h4 className="text-white">Create Products</h4>
                    </Col>
                    <Col sm={1} className="text-end text-white">
                        <MdClear className="fs-4" onClick={() => setShowUpdateProduct(false)} style={{ cursor: "pointer" }} />
                    </Col>
                </Modal.Header>
                <Modal.Body className="my-2">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group className='col-md-6 col-sm-6'>
                                <Form.Label className={(validate && !form.name) && 'text-danger'}>Product Name*</Form.Label>
                                <Form.Control type="text" name="name" defaultValue={productDetails.name}
                                    onChange={handleChange} className={`${!validate && 'border border-primary'} rounded`} placeholder='Product Name'
                                />
                            </Form.Group>
                            <Form.Group className='col-md-6'>
                                <Form.Label className={(validate && !form.price) && 'text-danger'}>Price*</Form.Label>
                                <Form.Control type="number" name="price" defaultValue={productDetails.price}
                                    onChange={handleChange} className={`${!validate && 'border border-primary'} rounded`} placeholder='0'
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-6 mt-2'>
                                <Form.Label>Product Type</Form.Label>
                                <input type="text" name="type" onChange={handleChange} defaultValue={productDetails.type}
                                    className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='Product Type' />
                            </Form.Group>
                            <Form.Group className='col-sm-6 mt-2'>
                                <Form.Label>Category</Form.Label>
                                <input type="text" name="cat" onChange={handleChange} defaultValue={productDetails.cat}
                                    className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='Category' />
                            </Form.Group>
                            <Form.Group className='col-md-4 mt-2'>
                                <Form.Label>Tax Rate</Form.Label>
                                {
                                    showSelect === false ? (
                                        <select name="tax"
                                            onChange={() => setShowSelect(true)}
                                            onInput={handleChange} defaultValue={productDetails.tax}
                                            className='w-100 p-2 border border-primary bg-white rounded' style={{ borderRadius: "5px" }}
                                        >
                                            <option value={0}>Select Tax</option>
                                            <option value={0}>Remove</option>
                                            {
                                                allTaxes.map((data, index) => (
                                                    <option value={Object.values(data)}>
                                                        {Object.keys(data)} {Object.values(data)}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    ) : (
                                        <input type="number" name="taxRate" defaultValue={form.tax} onClick={() => setShowSelect(false)}
                                            className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='0'
                                        />
                                    )
                                }
                            </Form.Group>

                            {
                                <Form.Group className='col-md-4 mt-2'>
                                    <Form.Label>HSN / SAC</Form.Label>
                                    <Form.Select className='border border-primary' onChange={handleSelectHSNorSAC}>
                                        <option>Select
                                            {/* {
                                                productHSN > 0 ? 'HSN' : productSAC > 0 ? 'SAC' : 'Select'
                                            } */}
                                        </option>
                                        <option value="hsn">HSN</option>
                                        <option value="sac">SAC</option>
                                    </Form.Select>
                                </Form.Group>
                            }

                            {
                                showHSNField ? (
                                    <Form.Group className='col-md-4 mt-2'>
                                        <Form.Label>HSN</Form.Label>
                                        <input type="text" name="hsn" onChange={handleChange} defaultValue={productDetails.hsn} maxLength={8}
                                            className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='HSN'
                                        />
                                    </Form.Group>
                                ) : null
                            }
                            {
                                showSACField ? (
                                    <Form.Group className='col-md-4 mt-2'>
                                        <Form.Label>SAC</Form.Label>
                                        <input type="text" name="sac" onChange={handleChange} defaultValue={productDetails.sac} maxLength={8}
                                            className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='SAC'
                                        />
                                    </Form.Group>
                                ) : null
                            }
                            <Form.Group className='col-sm-12 mt-2'>
                                <Form.Label>Product Description</Form.Label>
                                <input type="text" name="desc" onChange={handleChange} defaultValue={productDetails.desc}
                                    className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='Item Description' />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col sm={2} className='mt-4 mx-0 px-0'>
                                <button type='submit' className='bg-white m-0 p-0 border border-white' onMouseOver={handleChange}>
                                    <span className='w-25 mt-3 bg-primary text-white fs-5 py-2 px-4 rounded' style={{ cursro: "pointer" }}>Update</span>
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ProductDetails
