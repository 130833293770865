import React, { useState } from "react";
import { Row, Button, Col, Form, Modal } from "react-bootstrap";
import { MdClear } from "react-icons/md";
import { API } from "../../../../backend";
import { toast } from "react-hot-toast";
import axios from "axios";
import { editAttendance } from "../Hook/attendanceHooks";
import { ErrorToast, SuccessToast } from "../../components/toast";

const EditAttendance = ({ show, setShow }) => {
  const [form, setForm] = useState({});
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const requestLeave = async (e) => {
    e.preventDefault();
    await fetch(`${API}/`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(form),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setForm({
            ...form,
            [e.target.name]: e.target.reset(),
          });
          SuccessToast("Request sent");
        } else {
          ErrorToast("Something went wrong");
        }
      })
      .catch((err) => ErrorToast(err.message));
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    editAttendance(form).then((data) => {
      SuccessToast("Request sent", data.message);
    })
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  console.log("form data", form)

  return (
    <div>
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-primary">
          <Col sm={11}>
            <h4 className="text-white">
              Change your attendance for {`${date}/${month}/${year}`}
            </h4>
          </Col>
          <Col sm={1} onClick={() => setShow(false)} className="text-end">
            <MdClear
              className="fs-4 text-white"
              style={{ cursor: "pointer" }}
            />
          </Col>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEdit}>
            <Form.Group className="mb-2">
              <Form.Label className="fs-5 mb-0">Status</Form.Label>
              <Form.Select
                className="border border-primary"
                name="status"
                onChange={handleChange}
                required
              >
                <option>Select Status</option>
                <option>Present</option>
                <option>Leave</option>
                <option>Half Day</option>
                <option>Unpaid Leave</option>
                <option>Unpaid Half Day</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="fs-5 mb-0">Check In</Form.Label>
              <Form.Control
                type="time"
                placeholder="hh:mm"
                className="border border-primary"
                name="chekIn"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="fs-5 mb-0">Check Out</Form.Label>
              <Form.Control
                type="time"
                placeholder="hh:mm"
                className="border border-primary"
                name="checkOut"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="fs-5 mb-0">Remark</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows="6"
                className="border border-primary"
                name="remark"
                onChange={handleChange}
                placeholder="(Optional)"
                required
              />
            </Form.Group>
            <Row className="mt-2">
              <Col sm={4}>
                <Button type="submit" className="bg-primary">
                  Request
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        {/* <Modal.Footer> */}
        {/* <Button className="bg-primary">Cancel</Button> */}
        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default EditAttendance;
