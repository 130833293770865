import { Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { BsPrinterFill } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import ActivityBar from '../../../../components/partials/components/activitybar';
import { getInvoiceById, getSharedInvoiceById, updateInvoice } from '../Hook/invoiceHooks';
import Preview from './preview';
import RequestEdit from './request-edit';
import axios from 'axios';
import { API } from '../../../../backend';
import ReactToPrint from 'react-to-print';
import { MdClear } from 'react-icons/md';
import './styles/invoice.css';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import useFullPageLoader from '../Hook/useFullPageLoader';
import { IoAlertCircle } from 'react-icons/io5';
import { ErrorToast, SuccessToast, WarningToast } from '../../components/toast';
import { ToastContainer } from 'react-toastify';
import { cubicle2, cubicleFullLogo } from '../../../../assets/images';

const ShareInvoice = () => {

  const { id } = useParams();
  const { orgId } = useParams();
  const [form, setForm] = useState({});
  const [data, setData] = useState([]);
  const [firmDetails, setFirmDetails] = useState({});
  const [firmAdd, setFirmAdd] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [clientAdd, setClientAdd] = useState({});
  const [items, setItems] = useState([]);
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [taxObj, setTaxObj] = useState({});
  const [taxName, setTaxName] = useState("");
  const [terms, setTerms] = useState([]);
  const [amountpaid, setamountpaid] = useState(0);
  const [hideStatus, setHideStatus] = useState(false);
  const [selectedFirmId, setSelectedFirmId] = useState('')
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [showRaiseDispute, setShowRaiseDispute] = useState(false);
  const [disputeDesc, setDisputeDesc] = useState('');
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const init = [];
  const [addedtax, setAddedTax] = useState(init);

  const path = `http://localhost:3000/${id}`;
  const componentRef = useRef();
  // const orgId = localStorage.getItem('orgID');
  const editId = id;

  console.log("orgId", orgId);
  console.log("id", id);


  useEffect(() => {
    getSharedInvoiceById(orgId, id).then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setLoad(false);
          setData(response?.data?.data[0]);
          setamountpaid(response?.data?.data[0]?.amountPaid);
          setFirmDetails(response?.data?.data[0]?.firm);
          setFirmAdd(response?.data?.data[0]?.firm.address);
          setClientDetails(response?.data?.data[0]?.client);
          setClientAdd(response?.data?.data[0]?.client.address);
          setAddedTax(response?.data?.data[0]?.tax);
          setItems(response?.data?.data[0]?.items);
          setTerms(response?.data?.data[0]?.termsNcondition);
        }, 2000);
      } else {
        setData({});
      }
    }).catch(() => {
      setData({});
    })
  }, []);

  useEffect(() => {
    setTaxObj(data.tax);
  });

  // useEffect(() => {
  //   setTaxName(Object.values(taxObj));
  // });

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTaxName(Object.keys(taxObj));
  //   }, 1000)
  // }, [taxName]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 1200;

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleDisputeChange = (e) => {
    setDisputeDesc(e.target.value);
    setForm({
      ...form,
      dispute: e.target.value,
      orgId: orgId
    })
  };

  const handleRaiseDispute = (e) => {
    e.preventDefault();
    setDisabledBtn(true);
    showLoader();

    updateInvoice(editId, form).then((response) => {
      if (response.status === 200) {
        SuccessToast('Dispute Raised');
        setDisputeDesc('');
        hideLoader();
        setDisabledBtn(false);
        setShowRaiseDispute(false);
      } else {
        ErrorToast('Unable to raise dispute');
        hideLoader();
        setShowRaiseDispute(false);
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
      hideLoader();
      setShowRaiseDispute(false);
    })
  };

  const invoiceOptions = () => {
    return (
      <>
        {/* <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{ cursor: "pointer" }}>
          <Col sm={9}>Pay Now</Col>
          <Col sm={1} className="text-end">
            <Tooltip title="Pay">
              <Link className="py-1 px-3 text-white rounded me-2">
                <MdPayment style={{ fontSize: "1rem", cursor: "pointer" }} />
              </Link>
            </Tooltip>
          </Col>
        </Row> */}
        {/* <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{ cursor: "pointer" }}> */}
        <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" onClick={() => setHideStatus(!hideStatus)} style={{ cursor: "pointer" }}>
          <Col sm={9}>{hideStatus ? 'Show Status' : 'Hide Status'}</Col>
          <Col sm={1} className="text-end">
            <Tooltip title="Convert Invoice">
              <Link to="#" className="py-1 px-3 rounded text-white me-2">
                {
                  hideStatus ? (
                    <AiFillEye style={{ fontSize: "1rem", cursor: "pointer" }} />
                  ) : (
                    <AiFillEyeInvisible style={{ fontSize: "1rem", cursor: "pointer" }} />
                  )
                }
              </Link>
            </Tooltip>
          </Col>
        </Row>
        <ReactToPrint
          trigger={() => (
            <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{ cursor: "pointer" }}>
              <Col sm={9}>Print Invoice</Col>
              <Col sm={1} className="text-end">
                <Tooltip title="Print">
                  <Link to="#" className="py-1 px-3 text-white rounded me-2">
                    <BsPrinterFill style={{ fontSize: "1rem", cursor: "pointer" }} />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )}
          content={() => componentRef.current}
        />
        {
          (data.status === "Pending") && (
            <Link to={`#`} onClick={() => setShowRaiseDispute(true)}>
              <Row className="bg-warning rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{ cursor: "pointer" }}>
                <Col sm={9}>Raise Dispute</Col>
                <Col sm={1} className="text-end">
                  <Tooltip title='Raise Dispute'>
                    <Link to={'#'} className="py-1 px-3 rounded text-white me-2">
                      <IoAlertCircle className="text-white" style={{ fontSize: "1rem", cursor: "pointer" }} />
                    </Link>
                  </Tooltip>
                </Col>
              </Row>
            </Link>
          )
        }
      </>
    );
  };

  console.log("data", data);

  return (
    <>
      <ToastContainer />
      <div className='m-0 p-0 desktop-view'>
        <Row className='m-0 p-2 w-100 bg-white' style={{ boxShadow: "0 0 10px #c8c9ca", position: "sticky", top: 0 }}>
          <Col sm={3}>
            <Row className='m-0 p-0'>
              <img src={cubicleFullLogo} alt='' style={{ width: "200px", height: "50px" }} />
            </Row>
          </Col>
        </Row>
        <div className='d-flex mt-3'>
          <Row className=" mx-3 mb-5" style={{ width: screenWidth >= breakPoint ? "70%" : "90%" }}>
            <div className="rounded px-5 bg-white" style={{ boxShadow: "0 0 10px #c8c9ca" }}>
              <Card.Body>
                <div className="p-3 bg-white" style={{ boxShadow: "0px 0px 10px 2px #E8E8E8" }}>
                  <div className="m-0 p-0 px-5" ref={componentRef}>
                    <Preview hideStatus={hideStatus} terms={terms} addedtax={addedtax} form={data} firmDetails={firmDetails} firmAdd={firmAdd} clientDetails={clientDetails} clientAdd={clientAdd} items={items} currency="$" />
                  </div>
                </div>
              </Card.Body>
            </div>
          </Row>
          <ActivityBar options={invoiceOptions} hide={true} path={path} />

          <Modal centered size="lg" show={showRaiseDispute} onHide={() => setShowRaiseDispute(false)}>
            <Modal.Header className="bg-primary">
              <Col sm={11} className="text-white">
                <h4 className="text-white">Raise Dispute</h4>
              </Col>
              <Col sm={1} className="text-end text-white">
                <MdClear className="fs-4" onClick={() => setShowRaiseDispute(false)} style={{ cursor: "pointer" }} />
              </Col>
            </Modal.Header>
            <Modal.Body className="my-1">
              <Form onSubmit={handleRaiseDispute}>
                <Row>
                  <Col sm={12}>
                    <Form.Control as="textarea" name="dispute" value={disputeDesc} onChange={handleDisputeChange} rows={6} placeholder="Dispute description" className="border border-primary" required />
                  </Col>
                </Row>
                <div className="d-flex justify-content-end align-items-end w-100">
                  <Button type='submit' variant={disabledBtn ? 'secondary' : 'primary'} className="mt-3 fs-5 w-5"
                    style={{ cursor: disabledBtn ? 'not-allowed' : 'pointer' }}
                  >Submit</Button>
                </div>
              </Form>
            </Modal.Body>
            {loader}
          </Modal>
          <RequestEdit show={show} setShow={setShow} items={items} />
        </div>
      </div>
    </>
  )
}

export default ShareInvoice