import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClear } from "react-icons/md";
import { getAllLeaves } from "../Hook/attendanceHooks";

const LeavesTaken = ({ show, setShow }) => {
  const [leaves, setLeaves] = useState([]);

  const allLeaves = () => {
    getAllLeaves(setLeaves);
  };

  useEffect(() => {
    allLeaves();
  }, []);

  return (
    <div>
      <Modal centered size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-primary">
          <Col sm={11}>
            <h4 className="text-white">Leaves Taken</h4>
          </Col>
          <Col sm={1} onClick={() => setShow(false)} className="text-end">
            <MdClear
              className="fs-4 text-white"
              style={{ cursor: "pointer" }}
            />
          </Col>
        </Modal.Header>
        <Modal.Body>
          <div
            className="table-responsive m-0"
            style={{
              // height: "90vh",
              maxHeight: "60vh",
              overflowY: "scroll",
              scrollBehavior: "smooth",
              minHeight: "300px",
            }}
          >
            <table className="w-100">
              <thead className="bg-primary text-white fs-5">
                <th className="ps-2" style={{ borderRadius: "5px 0 0 0" }}>
                  From
                </th>
                <th className="px-3">To</th>
                <th className="px-3">Type</th>
                <th className="px-3" style={{ borderRadius: "0 5px 0 0" }}>
                  Reason
                </th>
              </thead>
              <tbody>
                {leaves.length === 0 ? (
                  <>
                    <tr className="w-100 mt-3 border-secondary" style={{borderBottom: "1px solid"}}>
                      <td></td>
                      <td></td>
                      <td className="text-secondary text-center py-2" style={{fontSize: "0.8rem"}}>No data found</td>
                      <td></td>
                    </tr>
                  </>
                ) : (
                  leaves.map((data) => (
                    <tr style={{ borderBottom: "1px solid #000" }}>
                      <td className="ps-2 py-2">{data.from}</td>
                      <td className="px-3">{data.to}</td>
                      <td className="px-3">{data.status}</td>
                      <td
                        className="px-3"
                        style={{ maxWidth: "200px", overflowX: "scroll" }}
                      >
                        {data.reason}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LeavesTaken;
