import React, { useEffect, useState } from 'react'
import SubHeader from '../../../../components/partials/dashboard/HeaderStyle/sub-header'
import { Link, useParams } from 'react-router-dom';
import { getVendorById } from '../Hook/vendorHooks';
import { Col, Row } from 'react-bootstrap';
import { CgProfile } from 'react-icons/cg';
import ActivityBar from '../../../../components/partials/components/activitybar';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { Tooltip } from '@material-ui/core';
// import useFullPageLoader from '../Hook/useFullPageLoader';
import Pagination from '../pagination/pagination';
import { FiEye } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { getPurchaseByVendor } from '../Hook/purchaseHooks';

const AboutVendor = () => {

    const [vendorDetails, setVendorDetails] = useState({});
    const [vendorAddress, setVendorAddress] = useState({});
    const [contactPerson, setContactPerson] = useState({});

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    // const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [allPurchases, setAllPurchases] = useState([]);
    const { id } = useParams();
    const orgName = localStorage.getItem('orgName');

    const showPerPage = 10;
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    useEffect(() => {
        // showLoader();
        getVendorById(id).then((response) => {
            if (response.status === 200) {
                setVendorDetails(response.data.data);
                setVendorAddress(response.data.data.add);
                setContactPerson(response.data.data.contectPerson);
                // hideLoader();
            } else {
                setVendorDetails({});
                setVendorAddress({});
                setContactPerson({});
                // hideLoader();
            }
        }).catch(() => {
            setVendorDetails({});
            setVendorAddress({});
            setContactPerson({});
            // hideLoader();
        })
    }, []);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        getPurchaseByVendor(id).then((response) => {
            if (response.status === 200) {
                setAllPurchases(response.data);
            } else {
                setAllPurchases([]);
            }
        }).catch(() => {
            setAllPurchases([]);
        })
    }, []);

    const vendorOptions = () => {
        return (
            <>
                <Link to={`/${orgName}/dashboard/vendor/update-vendor/${id}`}>
                    <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{ cursor: "pointer" }}>
                        <Col sm={9}>Update Vendor</Col>
                        <Col sm={1} className="text-end">
                            <Tooltip title='Update Vendor'>
                                <Link to={'#'} className="py-1 px-3 rounded text-white me-2">
                                    <HiOutlinePencilAlt style={{ fontSize: "1rem", cursor: "pointer" }} />
                                </Link>
                            </Tooltip>
                        </Col>
                    </Row>
                </Link>
            </>
        )
    };

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };

    return (
        <div>
            <SubHeader title="First Vendor" />
            <div className="d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-1%" : "-3%", flexDirection: "row" }}>
                <Row className="w-25 rounded mx-2" style={{ marginRight: "10%", fontSize: "0.8rem" }}>
                    <Col sm={12} className="bg-white rounded">
                        <Row className="py-2">
                            <h5 className="text-primary" style={{ fontWeight: "550" }}> About </h5>
                        </Row>
                        <Row>
                            <Col sm={12} style={{ textAlign: "center", height: "100%" }}>
                                <CgProfile className="text-secondary" style={{ height: "25%", width: "25%" }} />
                            </Col>
                            <Col className="my-2 text-dark text-center" style={{ fontWeight: "550", fontSize: "1rem", textTransform: "uppercase" }}>
                                {vendorDetails === undefined || vendorDetails.name === undefined ? '' : vendorDetails.name}
                            </Col>
                        </Row>
                        <Row className="py-1">
                            <Col md={3} sm={12}>
                                <div style={{ fontWeight: "550" }}>TaxId: </div>
                            </Col>
                            <Col>{vendorDetails === undefined || vendorDetails.taxId === undefined ? '' : vendorDetails.taxId}</Col>
                        </Row>
                        <Row className="py-1">
                            <Col md={3} sm={12}>
                                <div style={{ fontWeight: "550" }}>Phone: </div>
                            </Col>
                            <Col>{vendorDetails === undefined || vendorDetails.phone === undefined ? '' : vendorDetails.phone}</Col>
                        </Row>
                        <Row className="py-1">
                            <Col md={3} sm={12}>
                                <div style={{ fontWeight: "550" }}>Email: </div>
                            </Col>
                            <Col>{vendorDetails === undefined || vendorDetails.email === undefined ? '' : vendorDetails.email}</Col>
                        </Row>
                        <Row className="py-1">
                            <Col md={3} sm={12}>
                                <div style={{ fontWeight: "550" }}>Address: </div>
                            </Col>
                            <Col md={8} sm={12}>
                                <div>{vendorAddress === undefined || vendorAddress.address1 === undefined ? '' : vendorAddress.address1}</div>
                                <div>{vendorAddress === undefined || vendorAddress.address2 === undefined ? '' : vendorAddress.address2}</div>
                                <span className='m-0 p-0'>{vendorAddress === undefined || vendorAddress.country === undefined ? '' : `${vendorAddress.country}, `}</span>
                                <span className='m-0 p-0'>{vendorAddress === undefined || vendorAddress.state === undefined ? '' : `${vendorAddress.state}, `}</span>
                                <span className='m-0 p-0'>{vendorAddress === undefined || vendorAddress.city === undefined ? '' : `${vendorAddress.city}, `}</span>
                                <span className='m-0 p-0'>{vendorAddress === undefined || vendorAddress.pinCode === undefined ? '' : `${vendorAddress.pinCode} `}</span>
                            </Col>
                        </Row>
                    </Col>
                    {
                        contactPerson && (
                            <Col sm={12} className="bg-white rounded my-2">
                                <Row className="py-2">
                                    <h5 className="text-primary" style={{ fontWeight: "550" }}> Contact Person </h5>
                                </Row>
                                <Row className="py-1">
                                    <Col md={5} sm={12}>
                                        <div style={{ fontWeight: "550" }}>Name: </div>
                                    </Col>
                                    <Col className='p-0'>{contactPerson === undefined || contactPerson.name === undefined ? '' : contactPerson.name}</Col>
                                </Row>
                                <Row className="py-1">
                                    <Col md={5} sm={12}>
                                        <div style={{ fontWeight: "550" }}>Phone:</div>
                                    </Col>
                                    <Col className='p-0'>{contactPerson === undefined || contactPerson.phone === undefined ? '' : contactPerson.phone}</Col>
                                </Row>
                                <Row className="py-1">
                                    <Col md={5} sm={12}>
                                        <div style={{ fontWeight: "550" }}>Alternative Phone: </div>
                                    </Col>
                                    <Col className='p-0'>{contactPerson === undefined || contactPerson.altPhone === undefined ? '' : contactPerson.altPhone}</Col>
                                </Row>
                                <Row className="py-1">
                                    <Col md={5} sm={12}>
                                        <div style={{ fontWeight: "550" }}>Mobile: </div>
                                    </Col>
                                    <Col className='p-0'>{contactPerson === undefined || contactPerson.mobile === undefined ? '' : contactPerson.mobile}</Col>
                                </Row>
                                <Row className="py-1">
                                    <Col md={5} sm={12}>
                                        <div style={{ fontWeight: "550" }}>Alternative Mobile: </div>
                                    </Col>
                                    <Col className='p-0'>{contactPerson === undefined || contactPerson.altMobile === undefined ? '' : contactPerson.altMobile}</Col>
                                </Row>
                                <Row className="py-1">
                                    <Col md={5} sm={12}>
                                        <div style={{ fontWeight: "550" }}>Address: </div>
                                    </Col>
                                    {/* <Col className='p-0'>123, Shipra path, masarovar, <br /> Jaipur, Rajasthan, India, <br /> 303030</Col> */}
                                    <Col className='p-0'>
                                        <p className="m-0 p-0">{contactPerson === undefined || contactPerson.address1 === undefined ? '' : contactPerson.address1}</p>
                                        <p className="m-0 p-0">{contactPerson === undefined || contactPerson.address2 === undefined ? '' : contactPerson.address2}</p>
                                        <p className="m-0 p-0">
                                            {
                                                `${contactPerson === undefined || contactPerson.city === undefined ? '' : `${contactPerson.city}, `} 
                                            ${contactPerson === undefined || contactPerson.state === undefined ? '' : `${contactPerson.state}, `} 
                                            ${contactPerson === undefined || contactPerson.country === undefined ? '' : `${contactPerson.country}, `} 
                                            ${contactPerson === undefined || contactPerson.pinCode === undefined ? '' : `${contactPerson.pinCode} `}`
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        )
                    }
                </Row>
                <Row className='w-75 px-4'>
                    <div className='rounded bg-white'>
                        <h5 className=" text-primary my-2">All Purchases</h5>
                        <hr />
                        <div>
                            <div className="table-responsive" style={{ minHeight: "50vh" }}>
                                <table className="" style={{ width: "100%" }}>
                                    <thead>
                                        <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                                            {/* <th className="fw-bold" style={{ fontSize: "0.9rem" }}></th> */}
                                            <th className="fw-bold w-5" style={{ fontSize: "0.9rem" }}>Purchase No.</th>
                                            <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Firm Name</th>
                                            <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Item Name</th>
                                            {/* <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Status</th> */}
                                            <th className="fw-bold w-20 text-end" style={{ fontSize: "0.9rem" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            allPurchases.slice(pagination.start, pagination.end).map((data, index) => (
                                                <tr key={index}>
                                                    <td className="w-5 py-3" style={{ width: "12%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                        <span className="ms-0 text-dark">{data.purchaseNumber}</span>
                                                    </td>
                                                    <td className="w-25 py-3" style={{ width: "12%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                        <span className="ms-0 text-dark">{data.firm.name}</span>
                                                    </td>
                                                    <td className="w-50 py-3" style={{ width: "12%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                        <span className="ms-0 text-dark">Test</span>
                                                    </td>
                                                    <td className="w-5 py-3 text-end" style={{ width: "12%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                        <div className="flex align-items-end list-user-action">
                                                            <Tooltip title="View" key="view">
                                                                <Link to={`/${orgName}/dashboard/purchase-details/1234`}>
                                                                    <FiEye id="viewLead" className="text-primary mx-2" style={{ fontSize: "0.9rem", cursor: "pointer", }} />
                                                                </Link>
                                                            </Tooltip>
                                                            <Tooltip title="Delete" key="view">
                                                                <Link to='#'>
                                                                    <MdDeleteOutline id="delete" className="text-danger" style={{ fontSize: "0.9rem", cursor: "pointer", }} />
                                                                </Link>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                {
                                    allPurchases.length === 0 && (
                                        <div className='mt-5 fs-4 text-center text-secondary'>
                                            No Purchase To Show
                                        </div>
                                    )
                                }
                            </div>
                            <div className="my-5">
                                <Pagination showPerPage={showPerPage} onPaginationChange={onPaginationChange} total={allPurchases.length} />
                            </div>
                        </div>
                    </div>
                </Row>
                <ActivityBar options={vendorOptions} />
            </div>
            {/* <ModalContainer show={show} setShow={setShow}>
                <p>
                    lorem50
                </p>
            </ModalContainer> */}
            {/* {loader} */}
        </div>
    )
}

export default AboutVendor
