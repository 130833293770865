import axios from "axios";
import { API } from "../../../../backend"

const base = `${API}/vendor`;
const orgId = localStorage.getItem('orgID');

export const getAllVendors = async () => {
    const url = `${base}/getAllVendor/${orgId}`;
    const config = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios(config);
    return response;
}

export const createVendor = async (form) => {
    const url = `${base}/createVendor`;
    const config = {
        method: 'POST',
        url: url,
        data: form,
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const response = await axios(config);
    return response;
};

export const updateVendor = async (id, form) => {
    const url = `${base}/updateVendor/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            'Content-Type': "application/json"
        }
    }
    const response = await axios(config);
    return response;
}

export const getVendorById = async (id) => {
    const url = `${base}/getVendorById/${id}`;
    const config = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': "application/json"
        }
    }
    const response = await axios(config);
    return response;
}


export const deleteVendorParmanently = async (deletedId) => {
    const url = `${base}/deleteVendor/${deletedId}`;
    const config = {
        method: "DELETE",
        url: url,
        headers: {
            'Content-Type': "application/json",
        }
    }
    const response = await axios(config);
    return response;
}
