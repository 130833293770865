import React, { useEffect, useState } from 'react'
import SubHeader from '../../../../components/partials/dashboard/HeaderStyle/sub-header'
import { FiEye, FiFilter } from 'react-icons/fi'
import { Col, Row } from 'react-bootstrap';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { MdDeleteOutline, MdOutlineRefresh } from 'react-icons/md'
import ActivityBar from '../../../../components/partials/components/activitybar'
import Pagination from '../pagination/pagination'
import { IoRadioButtonOn } from 'react-icons/io5'
import { SuccessToast, ErrorToast } from '../../components/toast';
import { getAllVendors, getVendorById, updateVendor } from '../Hook/vendorHooks';
import DeleteVendorModal from './delete-vendor-modal';
// import useFullPageLoader from '../Hook/useFullPageLoader';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { CgProfile } from 'react-icons/cg';

const AllVendors = () => {

  const [vendorsList, setVendorsList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selected, setSelected] = useState('All');
  const [showFilter, setShowFilter] = useState(false);

  const [vendorDetails, setVendorDetails] = useState({});
  const [vendorAddress, setVendorAddress] = useState({});
  const [vendorId, setVendorId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [deleted, setDeleted] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  // const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const showPerPage = 10;
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const filters = ['All', 'Deleted'];

  const breakPoint = 970;
  const orgName = localStorage.getItem('orgName');

  const getVendorList = () => {
    // showLoader();
    setSelected('All');
    getAllVendors().then((response) => {
      if (response.status === 200) {
        const initialList = response.data.data;
        const updatedlist = initialList.filter((data) => !data.delete);

        setVendorsList(response.data.data);
        setFilteredList(updatedlist);
        setVendorId(updatedlist[0]._id);
        handleSelectVendor(updatedlist[0]._id);
        // hideLoader();
      } else {
        setFilteredList([]);
        // hideLoader();
      }
    }).catch(() => {
      setFilteredList([]);
      // hideLoader();
    })
  }

  useEffect(() => {
    getVendorList();
  }, []);

  const handleSelectVendor = (id) => {
    getVendorById(id).then((response) => {
      if (response.status === 200) {
        setVendorId(response.data.data._id);
        setVendorDetails(response.data.data);
        setVendorAddress(response.data.data.add);
      } else {
        setVendorDetails({})
      }
    }).catch(() => {
      setVendorDetails({});
    })
  }

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleFilter = (name) => {
    setSelected(name);
    const allVendors = vendorsList.filter((data) => data.delete === false);
    const deletedVendors = vendorsList.filter((data) => data.delete === true);

    name === 'All' ? setFilteredList(allVendors) : setFilteredList(deletedVendors);
    name === 'All' ? setVendorId(allVendors.length === 0 ? '' : allVendors[0]._id) : setVendorId(deletedVendors.length === 0 ? '' : deletedVendors[0]._id);
    name === 'All' ? handleSelectVendor(allVendors[0]._id) : handleSelectVendor(deletedVendors[0]._id)
  };

  const handleDeleteVendor = (data) => {
    setDeleteId(data._id);
    setDeleted(data.delete);
    setShowDelete(true);
  }

  const handleRestoreDeletedVendor = (id) => {
    const form = { delete: false }
    updateVendor(id, form).then((response) => {
      if (response.status === 201) {
        SuccessToast('Vendor restored successfully');
        getVendorList();
        setSelected('All');
      } else {
        ErrorToast('Unable to restore');
      }
    }).catch(() => {
      ErrorToast('Unable to restore');
    })
  }

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const vendorOptions = () => {
    return (
      <div style={{ position: "relative", zIndex: 1 }}>
        <div className="mx-3 text-dark fs-5">Filters</div>
        <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setShowFilter(!showFilter)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm>{selected === "All" ? 'All' : `${selected}`}</Col>
          <Col sm={1}>
            <Tooltip title="filter">
              <Link to="#">
                <FiFilter className="text-dark fs-4" />
              </Link>
            </Tooltip>
          </Col>
        </Row>
        {
          showFilter && (
            <ul className="m-0 ms-3 p-0 bg-white rounded" style={{ width: "91%", zIndex: 9, listStyle: "none", position: "absolute", boxShadow: "0px 0px 10px #c8c9ca" }}>
              {filters.map((data, index) => (
                <li
                  onClick={() => handleFilter(data)}
                  className={`${data === selected
                    ? " bg-primary border-primary text-white"
                    : "border-white text-dark"
                    } py-2 px-3 rounded`}
                  style={{ cursor: "pointer", borderBottom: "3px solid" }}
                  key={index}
                >
                  {data}
                </li>
              ))}
            </ul>
          )
        }
      </div>
    )
  }

  return (
    <div className='m-0 p-0' onClick={() => showFilter && setShowFilter(false)}>
      <SubHeader title="Vendors" btnLink={`/${orgName}/dashboard/vendor/create-vendor`} btnTitle="Create Vendor" />
      <div className="">
        <div className="d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-2%" : "-3%", flexDirection: "row", }}>
          <Row className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
            <Col sm={12} className="bg-white rounded">
              <Row className="py-2">
                <h5 className="text-primary" style={{ fontWeight: "550" }}> About </h5>
              </Row>
              <Row>
                <Col sm={12} style={{ textAlign: "center", height: "100%" }}>
                  <CgProfile className="text-secondary" style={{ height: "25%", width: "25%" }} />
                </Col>
                <Col className="my-2 text-dark text-center" style={{ fontWeight: "550", fontSize: "1rem", textTransform: "uppercase" }}>
                  {vendorDetails === undefined || vendorDetails.name === undefined ? '' : vendorDetails.name}
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="mt-2 rounded bg-white">
              <Row className="py-2">
                <h5 className="text-primary" style={{ fontWeight: "550" }}>Contact</h5>
              </Row>
              <Row className="py-1">
                <Col md={3} sm={12}>
                  <div style={{ fontWeight: "550" }}>Phone: </div>
                </Col>
                <Col>{vendorDetails === undefined || vendorDetails.phone === undefined ? '' : vendorDetails.phone}</Col>
              </Row>
              <Row className="py-1">
                <Col md={3} sm={12}>
                  <div style={{ fontWeight: "550" }}>Email: </div>
                </Col>
                <Col>{vendorDetails === undefined || vendorDetails.email === undefined ? '' : vendorDetails.email}</Col>
              </Row>
              <Row className="py-1">
                <Col md={3} sm={12}>
                  <div style={{ fontWeight: "550" }}>Address: </div>
                </Col>
                <Col sm>
                  <p className="m-0 p-0">{vendorAddress === undefined || vendorAddress.address1 === undefined ? '' : `${vendorAddress.address1}, `}</p>
                  <p className="m-0 p-0">{vendorAddress === undefined || vendorAddress.address2 === undefined ? '' : `${vendorAddress.address2}, `}</p>
                  <p className="m-0 p-0">
                    {
                      `${vendorAddress === undefined || vendorAddress.city === undefined ? '' : `${vendorAddress.city}, `} 
                      ${vendorAddress === undefined || vendorAddress.state === undefined ? '' : `${vendorAddress.state}, `} 
                      ${vendorAddress === undefined || vendorAddress.country === undefined ? '' : `${vendorAddress.country}, `} 
                      ${vendorAddress === undefined || vendorAddress.pinCode === undefined ? '' : `${vendorAddress.pinCode}, `}`
                    }
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row sm={7} className={`h-100 bg-white rounded mx-2 mb-5`} style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%", }}>
            <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
              <table className="" style={{ width: "100%" }}>
                <thead>
                  <tr className="h-20 text-primary fw-bold" style={{ borderBottom: "1px solid #a09fa3" }}>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}>
                      <IoRadioButtonOn style={{ color: "blue" }} />
                    </th>
                    <th className="fw-bold ps-2" style={{ fontSize: "0.9rem" }}>Name</th>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Firm</th>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Email</th>
                    <th className="fw-bold ps-5 text-start" style={{ fontSize: "0.9rem" }}>Phone</th>
                    <th className="fw-bold text-end" style={{ fontSize: "0.9rem" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredList
                      .slice(pagination.start, pagination.end)
                      .map((data, index) => (
                        <tr style={{ borderBottom: "1px solid rgb(227 227 227)" }} key={index}>
                          <td className="py-3" style={{ height: "30px", borderBottom: "1px solid rgb(227 227 227)", }}>
                            <input type="radio" name="radiobtn" className="" value={data._id}
                              onChange={(e) => handleSelectVendor(e.target.value)}
                              checked={vendorId === data._id}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                          <td className="py-3 ps-2" style={{ width: "27%", height: "30px" }}>
                            <span className="ms-0 text-dark">{data === undefined || data.name === undefined ? '' : data.name}</span>
                          </td>
                          <td className="py-3" style={{ width: "27%", height: "30px" }}>
                            <span className="ms-0 text-dark">{data === undefined || data.firmName === undefined ? '' : data.firmName}</span>
                          </td>
                          <td className="w-5 py-3" style={{ paddingRight: "10px", width: "10%", }}>{data === undefined || data.email === undefined ? '' : data.email}</td>
                          <td className="w-5 py-3 ps-5 text-start">{data === undefined || data.phone === undefined ? '' : data.phone}</td>
                          <td className="py-3">
                            <div className="d-flex justify-content-end align-items-end">
                              {
                                selected === 'All' ? (
                                  <Tooltip title="Edit" key="edit">
                                    <Link to={`/${orgName}/dashboard/vendor/update-vendor/${data._id}`}>
                                      <HiOutlinePencilAlt className="text-success me-2" style={{ fontSize: "1rem", cursor: "pointer", }} />
                                    </Link>
                                  </Tooltip>
                                ) : null
                              }
                              <Tooltip title="View" key="view">
                                <Link to={`/${orgName}/dashboard/vendor/about-vendor/${data._id}`}>
                                  <FiEye id="viewLead" className="text-primary me-2" style={{ fontSize: "0.9rem", cursor: "pointer", }} />
                                </Link>
                              </Tooltip>
                              {
                                selected !== 'All' ? (
                                  <Tooltip title="Restore Vendor" key="restore">
                                    <Link to={`#`}>
                                      <MdOutlineRefresh onClick={() => handleRestoreDeletedVendor(data._id)} className="text-success me-2" style={{ fontSize: "1rem", cursor: "pointer", }} />
                                    </Link>
                                  </Tooltip>
                                ) : null
                              }
                              <Tooltip title="Delete" key="delete">
                                <Link to='#' onClick={() => handleDeleteVendor(data)}>
                                  <MdDeleteOutline id="delete" className="text-danger" style={{ fontSize: "0.9rem", cursor: "pointer", }} />
                                </Link>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))
                  }
                </tbody>
              </table>
              {
                filteredList.length === 0 && (
                  <div className='mt-5 fs-4 text-center text-secondary'>
                    No Vendors To Show
                  </div>
                )
              }
            </div>
            <div className="my-5">
              <Pagination showPerPage={showPerPage} onPaginationChange={onPaginationChange} total={10} />
            </div>
          </Row>
          <ActivityBar options={vendorOptions} />
        </div>
        <DeleteVendorModal showDelete={showDelete} setShowDelete={setShowDelete} id={deleteId} deleted={deleted} getVendorList={getVendorList} />
      </div>
      {/* {loader} */}
    </div>
  )
}

export default AllVendors
