import React, { useEffect } from "react";
import Index from "../views/dashboard/index";
import { Switch, Route } from "react-router-dom";
// user
// import UserProfile from '../views/dashboard/app/user-profile';
// import UserAdd from '../views/dashboard/app/user-add';
import UserList from "../views/dashboard/app/user-list";
import userProfileEdit from "../views/dashboard/app/user-privacy-setting";

// invoice
import InvoiceAdd from "../views/dashboard/app/invoice/add-invoice";
import InvoiceList from "../views/dashboard/app/invoice/invoice-list";
import InvoiceDetails from "../views/dashboard/app/invoice/invoice-details";

// firm
import AddFirm from "../views/dashboard/app/firm/add-firm";
import FirmList from "../views/dashboard/app/firm/firm-list";

//client
import AddClient from "../views/dashboard/app/client/add-client";
import AboutClient from "../views/dashboard/app/client/about-client";

// Lead
import AddLead from "../views/dashboard/app/lead/add-lead";
import LeadList from "../views/dashboard/app/lead/lead-list";
import LeadDetails from "../views/dashboard/app/lead/lead-details";
import Newleadlist from "../views/dashboard/app/lead/newleadlist";

//User
import AddUser from "../views/dashboard/app/settings/user/add-user";
import AllUsers from "../views/dashboard/app/settings/user/all-users";
import UserProfile from "../views/dashboard/app/settings/user/user-profile";

// organization
import AddOrg from "../views/dashboard/app/settings/organization/add-org";
import UpdateOrg from "../views/dashboard/app/settings/organization/updateOrg";
import OrganizationProfile from "../views/dashboard/app/settings/organization/organization-profile";
//
import AddPipeline from "../views/dashboard/app/settings/pipeline/add-pipeline";
import PipelineList from "../views/dashboard/app/settings/pipeline/pipeline-list";

//Roles
import Roles from "../views/dashboard/app/settings/roles/roles";

//Tax
import Addtax from "../views/dashboard/app/tax/add-tax";

// Payroll
import Payslips from "../views/dashboard/app/payroll/payslips";
import Attendance from "../views/dashboard/app/payroll/attendance";
import Form16 from "../views/dashboard/app/payroll/form16";
import SalaryAdavance from "../views/dashboard/app/payroll/salary-adavance";
import Reimbursment from "../views/dashboard/app/payroll/reimbursment/reimbursment";
import PastReimburs from "../views/dashboard/app/payroll/reimbursment/pastReimburs";

// widget
import Widgetbasic from "../views/dashboard/widget/widgetbasic";
import Widgetcard from "../views/dashboard/widget/widgetcard";
import Widgetchart from "../views/dashboard/widget/widgetchart";
// icon
import Solid from "../views/dashboard/icons/solid";
import Outline from "../views/dashboard/icons/outline";
import DualTone from "../views/dashboard/icons/dual-tone";
// Form
import FormElement from "../views/dashboard/from/form-element";
import FormValidation from "../views/dashboard/from/form-validation";
import FormWizard from "../views/dashboard/from/form-wizard";
// table
import BootstrapTable from "../views/dashboard/table/bootstrap-table";
import TableData from "../views/dashboard/table/table-data";

// map
import Vector from "../views/dashboard/maps/vector";
import Google from "../views/dashboard/maps/google";

//extra
import PrivacyPolicy from "../views/dashboard/extra/privacy-policy";
import TermsofService from "../views/dashboard/extra/terms-of-service";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";
//Special Pages
import Billing from "../views/dashboard/special-pages/billing";
import Kanban from "../views/dashboard/special-pages/kanban";
import Pricing from "../views/dashboard/special-pages/pricing";
import Timeline from "../views/dashboard/special-pages/timeline";
import Calender from "../views/dashboard/special-pages/calender";
//admin
import Admin from "../views/dashboard/admin/admin";
import ClientList from "../views/dashboard/app/client/client-list";
import UpdateLead from "../views/dashboard/app/lead/updateLead";
import { NewReleases, PartyMode } from "@mui/icons-material";
import Addroles from "../views/dashboard/app/settings/roles/addroles";
import AllEpmloyees from "../views/dashboard/app/payroll/employees/allEpmloyees";
import Editemployee from "../views/dashboard/app/payroll/employees/edit-employee";
import EditFirm from "../views/dashboard/app/firm/edit-firm";
import AboutFirm from "../views/dashboard/app/firm/about-firm";
import EditInvoice from "../views/dashboard/app/invoice/edit-invoice";
import FirmInvoice from "../views/dashboard/app/firm/firm-invoice";
import EditClient from "../views/dashboard/app/client/edit-client";
import EditCopiedInvoice from "../views/dashboard/app/invoice/edit-copied-invoice";
import TaxDetails from "../views/dashboard/app/tax/tax-details";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import AllVendors from "../views/dashboard/app/vendor/all-vendors";
import CreateVendor from "../views/dashboard/app/vendor/create-vendor";
import UpdateVendor from "../views/dashboard/app/vendor/update-vendor";
import AboutVendor from "../views/dashboard/app/vendor/about-vendor";

import AllPurchase from "../views/dashboard/app/purchase/all-purchase";
import CreatePurchase from "../views/dashboard/app/purchase/create-purchase";
import PurchaseDetails from "../views/dashboard/app/purchase/purchase-details";
import EditPurchase from "../views/dashboard/app/purchase/update-purchase";

const DefaultRouter = () => {

  // const orgName = localStorage.getItem("orgName");

  const orgName = localStorage.getItem("orgName");
  // const location = useHistory();

  // console.log("out", orgName);

  // useEffect(() => {
  //   console.log("use effect", orgName);
  //   (!orgName || orgName === undefined || orgName.length === 0) && location.push('/');
  // });

  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          <Route path={`/${orgName}/dashboard`} exact component={Index} />

          <Route path={`/${orgName}/dashboard/invoice/add-invoice`} exact component={InvoiceAdd} />
          <Route path={`/${orgName}/dashboard/Invoice/invoice-list`} exact component={InvoiceList} />
          <Route path={`/${orgName}/dashboard/invoice/invoice-details/:id`} exact component={InvoiceDetails} />
          <Route path={`/${orgName}/dashboard/invoice/add-invoice/:id`} exact component={InvoiceAdd} />
          <Route path={`/${orgName}/dashboard/invoice/edit/:editId`} exact component={EditInvoice} />
          <Route path={`/${orgName}/dashboard/invoice/copiedInvoice`} exact component={EditCopiedInvoice} />

          {/* Firm */}
          <Route path={`/${orgName}/dashboard/firm/add-firm`} exact component={AddFirm} />
          <Route path={`/${orgName}/dashboard/firm/firm-list`} exact component={FirmList} />
          <Route path={`/${orgName}/dashboard/firm/add-firm`} exact component={AddFirm} />
          <Route path={`/${orgName}/dashboard/firm/edit-firm/:id`} exact component={EditFirm} />
          <Route path={`/${orgName}/dashboard/firm/about-firm/:id`} exact component={AboutFirm} />
          <Route path={`/${orgName}/dashboard/firm/add-invoice/:id`} exact component={FirmInvoice} />

          {/* Client */}
          <Route path={`/${orgName}/dashboard/client/add-client`} exact component={AddClient} />
          <Route path={`/${orgName}/dashboard/client/client-list`} exact component={ClientList} />
          <Route path={`/${orgName}/dashboard/client/add-client`} exact component={AddClient} />
          <Route path={`/${orgName}/dashboard/client/update-client/:id`} exact component={EditClient} />
          <Route path={`/${orgName}/dashboard/client/about-client/:id`} exact component={AboutClient} />
          <Route path={`/${orgName}/dashboard/client/add-invoice/:id`} exact component={InvoiceAdd} />

          {/* Tax */}
          <Route path={`/${orgName}/dashboard/tax/add-tax`} exact component={Addtax} />
          <Route path={`/${orgName}/dashboard/tax/tax-details/:id`} exact component={TaxDetails} />


          {/* Vendor */}
          <Route path={`/${orgName}/dashboard/vendor/all-vendors`} exact component={AllVendors} />
          <Route path={`/${orgName}/dashboard/vendor/create-vendor`} exact component={CreateVendor} />
          <Route path={`/${orgName}/dashboard/vendor/update-vendor/:id`} exact component={UpdateVendor} />
          <Route path={`/${orgName}/dashboard/vendor/about-vendor/:id`} exact component={AboutVendor} />


          {/* purchase  */}
          <Route path={`/${orgName}/dashboard/purchase/all-purchase`} exact component={AllPurchase} />
          <Route path={`/${orgName}/dashboard/purchase/create-purchase`} exact component={CreatePurchase} />
          <Route path={`/${orgName}/dashboard/purchase/update-purchase/:editId`} exact component={EditPurchase} />
          <Route path={`/${orgName}/dashboard/purchase/purchase-details/:id`} exact component={PurchaseDetails} />
          <Route path={`/${orgName}/dashboard/purchase/copiedPurchase/`} exact component={PurchaseDetails} />

          {/* Lead */}
          <Route path={`/${orgName}/dashboard/lead/add-lead`} exact component={AddLead} />
          <Route path={`/${orgName}/dashboard/lead/lead-details/:id`} exact component={LeadDetails} />
          <Route path={`/${orgName}/dashboard/lead/update-lead/:id`} exact component={UpdateLead} />
          <Route path={`/${orgName}/dashboard/lead/lead-list`} exact component={Newleadlist} />
          {/* <Route path={`/${orgName}/dashboard/lead/lead-list`} exact component={LeadList}/> */}

          {/* User */}
          <Route path={`/${orgName}/dashboard/user/add-user`} exact component={AddUser} />
          <Route path={`/${orgName}/dashboard/user/all-users`} exact component={AllUsers} />
          {/* <Route path={`/${orgName}/dashboard/user/user-profile`} exact component={UserProfile} /> */}
          <Route path={`/${orgName}/dashboard/user/profile/:id`} exact component={UserProfile} />

          {/* Pipeline */}
          <Route path={`/${orgName}/dashboard/settings/pipeline/add-pipeline`} exact component={AddPipeline} />
          <Route path={`/${orgName}/dashboard/settings/pipeline/pipeline-list`} exact component={PipelineList} />

          {/* Settings */}
          <Route path={`/${orgName}/dashboard/settings/organization/add-org/`} exact component={AddOrg} />
          <Route path={`/${orgName}/dashboard/settings/organization/update-org/:id`} exact component={UpdateOrg} />
          <Route path={`/${orgName}/dashboard/settings/organization/organization-profile/:id`} exact component={OrganizationProfile} />
          <Route path={`/${orgName}/dashboard/settings/roles/roles`} excat component={Addroles} />

          {/* Payroll */}
          <Route path={`/${orgName}/dashboard/payroll/payslips`} exact component={Payslips} />
          <Route path={`/${orgName}/dashboard/payroll/attendance`} exact component={Attendance} />
          <Route path={`/${orgName}/dashboard/payroll/payslips/form-16`} exact component={Form16} />
          <Route path={`/${orgName}/dashboard/payroll/payslips/salary-advance`} exact component={SalaryAdavance} />
          <Route path={`/${orgName}/dashboard/payroll/reimbursment`} exact component={Reimbursment} />
          <Route path={`/${orgName}/dashboard/payroll/reimbursment/all-reimbursments`} exact component={PastReimburs} />

          {/* HR */}
          <Route path={`/${orgName}/dashboard/employees/all-employees`} exact component={AllEpmloyees} />
          <Route path={`/${orgName}/dashboard/employees/edit-employee/:id`} exact component={Editemployee} />

          {/* widget */}
          {/* <Route path={`/${orgName}/dashboard/widget/widgetbasic`} exact component={Widgetbasic}/>
          <Route path={`/${orgName}/dashboard/widget/widgetcard`} exact component={Widgetcard}/>
          <Route path={`/${orgName}/dashboard/widget/widgetchart`} exact component={Widgetchart}/> */}

          {/* icon */}
          {/* <Route  path={`/${orgName}/dashboard/icon/solid"`} exact  component={Solid} />
          <Route  path={`/${orgName}/dashboard/icon/outline"`} exact  component={Outline} />
          <Route  path={`/${orgName}/dashboard/icon/dual-tone"`} exact  component={DualTone} /> */}
          {/* From */}
          {/* <Route path={`/${orgName}/dashboard/form/form-element`} exact component={FormElement}/>
          <Route path={`/${orgName}/dashboard/form/form-validation`} exact component={FormValidation}/>
          <Route path={`/${orgName}/dashboard/form/form-wizard`} exact component={FormWizard}/> */}
          {/* table */}
          {/* <Route path={`/${orgName}/dashboard/table/bootstrap-table`} exact component={BootstrapTable}/>
          <Route path={`/${orgName}/dashboard/table/table-data`} exact component={TableData}/> */}
          {/*special pages */}
          {/* <Route path={`/${orgName}/dashboard/special-pages/billing`} exact component={Billing}/>
          <Route path={`/${orgName}/dashboard/special-pages/kanban`} exact component={Kanban}/>
          <Route path={`/${orgName}/dashboard/special-pages/pricing`} exact component={Pricing}/>
          <Route path={`/${orgName}/dashboard/special-pages/timeline`} exact component={Timeline}/>
          <Route path={`/${orgName}/dashboard/special-pages/calender`} exact component={Calender}/> */}
          {/* map */}
          {/* <Route  path={`/${orgName}/dashboard/map/vector"`} exact  component={Vector} />
          <Route  path={`/${orgName}/dashboard/map/google"`} exact  component={Google} /> */}
          {/* extra */}
          {/* <Route path={`/${orgName}/dashboard/extra/privacy-policy`} exact component={PrivacyPolicy}/>
          <Route path={`/${orgName}/dashboard/extra/terms-of-service`} exact component={TermsofService}/> */}
          {/*admin*/}
          {/* <Route  path={`/${orgName}/dashboard/admin/admin"`} exact  component={Admin} /> */}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DefaultRouter;
