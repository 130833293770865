import axios from "axios";
import { API } from "../../../../backend";
import { toast } from "react-hot-toast";
import { ErrorToast } from "../../components/toast";

const orgName = localStorage.getItem('orgName');
const orgId = localStorage.getItem('orgID');

export const createProduct = async (form) => {
    let url = `${API}/product/createProduct`;
    let config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "content-type": "application/json",
        },
    }
    try{
        const response = await axios(config);
        return response;
    } catch (err) {
        ErrorToast("Something went wrong");
    }
}

export const getAllProducts = async (firmId) => {
    let url = `${API}/product/getAllProducts/${firmId}`;
    let config = {
        method: "GET",
        url: url,
        headers: {
            'content-type': 'application/json',
        },
    }
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        console.log("Unable to get products");
    }
}

export const getProductDetails = async (productId) => {
    let url = `${API}/product/getProductDetails/${productId}`;
    let config = {
        method: "GET",
        url: url,
        headers: {
            "content-type": "application/json",
        },
    } 
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        console.log("Unable to get product details");
    }
};

export const updateProduct = async (form, productId) => {
    let url = `${API}/product/updateProduct/${productId}`;
    let config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            'content-type': "application/json",
        },
    }
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        console.log(err.message);
    }
};

export const softDeleteProduct = async (productId) => {
    let url = `${API}/product/softDeleteProduct/${productId}`;
    let config = {
        method: "PATCH",
        url: url,
        headers: {
            'content-type': "application/json",
        },
    }
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        ErrorToast("Unable to delete product")
    }
}

export const deleteProduct = async (productId) => {
    let url= `${API}/product/deleteProduct/${productId}`;
    let config = {
        method: "DELETE",
        url: url,
        headers: {
            'content-type': 'application/json',
        },
    }
    try{
        const response = await axios(config);
        return response;
    } catch (err) {
        ErrorToast("Unable to delete prorduct");
    }
}