import React, { useState, useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { BiCommentDetail } from "react-icons/bi";
import { VscAdd } from "react-icons/vsc";
import Activitymodal from "./activity-modal";
import { Link, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import { addLeadActivityComment, deleteLeadActivity, getActivityById, getAllActivities, getLeadActivityComments } from "../Hook/leadHooks";
import UpdateActivity from "./lead-main/update-activity";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";

const LeadActivities = ({ allActivities, setAllActivities, leadActivitiesByType, type }) => {
  const [selected, setSelected] = useState([]);
  const [activityDetails, setActivityDetails] = useState([]);
  const [currentActivityId, setCurrentActivityId] = useState('');
  const [comment, setComment] = useState('');
  const [allComments, setAllComments] = useState([]);
  const [form, setForm] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [showDeleteActivity, setShowDeleteActivity] = useState(false);
  const [activityId, setActivityId] = useState('');
  const [totalLetters, setTotalLetters] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showUpdateActivity, setShowUpdateActivity] = useState(false);

  const { id } = useParams();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    getAllActivities(id).then((response) => {
      if (response.status === 200) {
        setAllActivities(response.data.data);
      }
    }).catch((err) => err?.response?.data?.message && WarningToast(err?.response?.data?.message))
  }, [id, setAllActivities])

  const getComments = (currentId) => {
    getLeadActivityComments(currentId).then((response) => {
      if (response.status === 200) setAllComments(response.data.data[0].comment);
    }).catch((err) => err?.response?.data?.message && WarningToast(err?.response?.data?.message));
  }

  const getSingleActivityDetials = (currentId) => {
    getActivityById(currentId).then((response) => {
      if (response.status === 200) {
        setActivityDetails(response.data.data);
      }
    }).catch((err) => err?.response?.data?.message && WarningToast(err?.response?.data?.message));
  }

  const getAllData = (activityId) => {
    setShowDetails(true);
    getSingleActivityDetials(activityId);
    getComments(activityId);
  };

  const showDeleteActivityModal = (currentId) => {
    setShowDeleteActivity(true);
    setActivityId(currentId);
  };

  const deleteActivity = () => {
    deleteLeadActivity(activityId).then((response) => {
      if (response.status === 202) {
        SuccessToast('Activity Deleted Successfully');
        setShowDeleteActivity(false);
        leadActivitiesByType(type);
      } else {
        ErrorToast('Unable to delete activity');
      }
    }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'));
  };

  const handleCommentChange = (e, dataId) => {
    setCurrentActivityId(dataId);
    setComment(e.target.value);
    setForm({
      ...form,
      id: dataId,
      comment: [{
        comment: e.target.value,
        userID: userId
      }]
    });
    setTotalLetters(e.target.value.slice(0, 100));
  };

  const handleComments = async (e) => {
    e.preventDefault();
    if (comment.length > 0) {
      addLeadActivityComment(form).then((response) => {
        if (response.status === 200) {
          SuccessToast('Comment added');
          setComment('');
          leadActivitiesByType(type);
        } else {
          ErrorToast('Unable to add comment');
        }
      }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'));
    } else {
      ErrorToast('Add a comment');
    }
  };

  const handleRemove = (index) => {
    const newList = [...selected];
    newList.splice(index, 1);
    setSelected(newList);
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const format = (dateString) => {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleShowUpdateActivity = (currentId) => {
    setShowUpdateActivity(true);
    getSingleActivityDetials(currentId);
    setActivityId(currentId);
  }

  return (
    <>
      <Row className="mb-1 mt-4 ms-1 ">
        <div>
          {selected.map((data, index) => (
            <div className="m-0 p-0" key={index}>
              <span className="bg-white px-3 py-1 rounded-pill border-primary">{data}</span>
              <span className="ms-1 p-0" style={{ cursor: "pointer" }} onClick={() => handleRemove(index)}></span>
            </div>
          ))}
        </div>
        <Col sm={3}>
          <Form.Select
            className={`border-primary rounded py-2 ms-2 ${type === "All" || type === "Call" || type === "Meeting" || type === "Task"
              ? "bg-primary text-white" : "bg-white text-dark"}`}
            style={{ cursor: "pointer" }}
            onChange={(e) => leadActivitiesByType(e.target.value)}
          >
            <option className="bg-white text-dark">All</option>
            <option className="bg-white text-dark">Call</option>
            <option className="bg-white text-dark">Meeting</option>
            <option className="bg-white text-dark">Task</option>
          </Form.Select>
        </Col>
        <Col
          sm={2}
          className={`border-primary text-center rounded py-2 px-2 ms-2 ${type === "Note" ? "bg-primary text-white" : "bg-white text-dark"}`}
          onClick={(e) => leadActivitiesByType("Note")}
          style={{ cursor: "pointer", border: "1px solid" }}
        >
          Note
        </Col>
        <Col
          sm={2}
          className={`border-primary text-center rounded py-2 px-2 ms-2 ${type === "Email" ? "bg-primary text-white" : "bg-white text-dark"}`}
          onClick={() => leadActivitiesByType("Email")}
          style={{ cursor: "pointer", border: "1px solid" }}
        >
          Email
        </Col>
        <Col
          sm={2}
          className={`border-primary text-center rounded py-2 px-2 ms-2 ${type === "Attachment" ? "bg-primary text-white" : "bg-white text-dark"}`}
          onClick={() => leadActivitiesByType("Attachment")}
          style={{ cursor: "pointer", border: "1px solid" }}
        >
          Attachment
        </Col>
        <Col
          sm={2}
          className={`border-primary text-center rounded py-2 px-2 ms-2 ${type === "Reminder" ? "bg-primary text-white" : "bg-white text-dark"}`}
          onClick={() => leadActivitiesByType("Reminder")}
          style={{ cursor: "pointer", border: "1px solid" }}
        >
          Reminder
        </Col>
      </Row>
      <div
        className="mt-3"
        style={{ overflowX: "hidden", maxHeight: "500px", minHeight: "500px", overflowY: "scroll", }}
      >
        {allActivities.length === 0 && (
          <div sm={12} className="bg-white w-100 m-0 border-primary rounded w-100 py-2 px-3 text-center fs-5 text-primary">
            No activity found
          </div>
        )}
        {allActivities.map((data, index) => (
          <div className="m-0 p-0" key={index}>
            <div
              className="row bg-white w-100 m-0 rounded py-2"
              style={{ fontSize: "0.8rem", borderRadius: "10px" }}
              key={index}
            >
              <Col sm={2} className="me-1 pt-3 ps-2 text-left">
                <div className="m-0 text-primary border-primary w-5e" style={{ height: "50px", borderRight: "1px solid" }}>
                  {data.createdAt === undefined ? '' : format(data.createdAt)}
                </div>
              </Col>
              <Col sm={9}>
                <div className="w-100">
                  <Row className="mt-2">
                    <Col sm={3} className="text-primary m-0 p-0" style={{ fontWeight: "550" }}>Activity Type:</Col>
                    <Col sm>{data.type === undefined ? '' : data.type}</Col>
                    <Col sm={2} className="text-right">
                      <Row>
                        <Col sm={4}></Col>
                        <Col sm={1}>
                          <Tooltip title="Edit Activity">
                            <Link to="#">
                              <HiOutlinePencilAlt className="fs-4 text-success" onClick={() => handleShowUpdateActivity(data._id)} style={{ cursor: "pointer" }} />
                            </Link>
                          </Tooltip>
                        </Col>
                        <Col sm={1} className="text-start">
                          <Tooltip title="Delete Activity">
                            <Link to="#">
                              <MdDeleteOutline onClick={() => showDeleteActivityModal(data._id)} className="fs-4 text-danger" style={{ cursor: "pointer" }} />
                            </Link>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {data.title && (
                    <Row className="mt-2">
                      <Col sm={3} className="m-0 p-0" style={{ fontWeight: "550" }}>Activity Title:</Col>
                      <Col sm>{data.title === undefined ? '' : data.title}</Col>
                    </Row>
                  )}
                  <Row className="mt-2">
                    <Col sm={3} className="m-0 p-0" style={{ fontWeight: "550" }}>Description:</Col>
                    <Col>{data.desc === undefined || data.desc === 'undefined' ? '' : data.desc}</Col>
                  </Row>
                </div>
                <hr />
                <div className="mb-2 w-100">
                  <Col sm={12}>
                    <form onSubmit={handleComments} style={{ position: "relative" }}>
                      <div className="m-0 p-0 d-inline" style={{ position: "relative", cursor: "pointer" }} onClick={() => getAllData(data._id)}>
                        <BiCommentDetail className="text-primary mt-1 text-center" style={{ fontSize: "1.2rem", }} />
                        {
                          <span
                            className="text-white text-center bg-primary rounded-circle"
                            style={{
                              marginLeft: "-10px", marginTop: "-2px", paddingTop: "1px", width: "15px",
                              height: "15px", fontSize: "0.6rem", position: "absolute", display: data.comment.length < 1 && 'none'
                            }}
                          >
                            {data === undefined || data.comment === undefined || data.comment.length === 0 ? null : data.comment.length}
                          </span>
                        }
                      </div>
                      <div className="d-inline m-0 p-0 w-100" style={{ position: "relative" }}>
                        <input type="text" className="border-primary w-75 ms-3 pe-5 w-50" name="comment" placeholder="Add a comment "
                          value={data._id === currentActivityId ? comment : ''}
                          onChange={(e) => handleCommentChange(e, data._id)}
                          style={{ border: "none", borderBottom: "1px solid", background: "none", }}
                          maxLength={100} required autoComplete="off"
                        />
                        {
                          data._id === currentActivityId ? (
                            <span
                              className="text-primary bg-white p-0 me-5"
                              style={{ position: "absolute", marginLeft: screenWidth > 1600 ? "-25px" : "-40px", }}
                            >
                              {`${totalLetters.length}/100`}
                            </span>
                          ) : (
                            <span
                              className="text-primary bg-white p-0 me-5"
                              style={{ position: "absolute", marginLeft: screenWidth > 1600 ? "-25px" : "-40px", }}
                            >
                              {`0/100`}
                            </span>
                          )
                        }
                      </div>
                      <button type="submit" className="border-0 p-0 mx-2 text-primary" style={{ background: "none" }}>
                        <VscAdd className="text-primary" style={{ cursor: "pointer", fontSize: "1rem" }} />
                      </button>
                    </form>
                  </Col>
                </div>
              </Col>
            </div>
            {allActivities.length > 1 && index < allActivities.length - 1 && (
              <div
                className="row w-5" style={{ height: "30px", width: "10px", borderLeft: "2px solid red", marginLeft: "50%", }}></div>
            )}
          </div>
        ))}
      </div>
      <Activitymodal show={showDetails} setShow={setShowDetails} allComments={allComments} details={activityDetails} desc={activityDetails.desc} title={activityDetails.title} type={activityDetails.type} image={activityDetails.image} />
      <Modal centered show={showDeleteActivity} onHide={() => setShowDeleteActivity(false)}>
        <Modal.Body className="my-2">
          <Row className="mb-2 ms-1">
            <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Activity</Col>
            <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">You want to delete the activity ?</Col>
          </Row>
          <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
            <div className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger" onClick={() => deleteActivity()} style={{ borderRadius: "4px", cursor: "pointer" }}>
              Yes
            </div>
            <div className="bg-white text-primary py-1 px-5 border border-primary text-center" onClick={() => setShowDeleteActivity(false)} style={{ borderRadius: "5px", cursor: "pointer" }}>
              No
            </div>
          </div>
        </Modal.Body>
      </Modal >
      <UpdateActivity show={showUpdateActivity} setShow={setShowUpdateActivity} activityId={activityId} activityDetails={activityDetails} getAllActivities={leadActivitiesByType} />
    </>
  );
};

export default LeadActivities;
