import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import Card from "../../../../../components/Card";
import { Row, Col, Form, ToastContainer, Button } from "react-bootstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { RiAddCircleFill } from "react-icons/ri";
import { MdOutlineCancel } from "react-icons/md";
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import "./orgStyle.css";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
// import { getAllUsers } from "../../Hook/userHooks";
import { getOrgDetails, updateLogo, updateOrg } from "../../Hook/orgHooks";
import { ErrorToast, SuccessToast } from "../../../components/toast";

const UpdateOrg = () => {
  const [form, setForm] = useState([]);
  const [singleOrg, setSingleOrg] = useState({});
  const [logo, setLogo] = useState('');
  const [img, setImg] = useState('');
  const [showBasic, setShowBasic] = useState(true);
  const [showContact, setShowContact] = useState(false);
  const [showPipeline, setShowPipeline] = useState(false);
  const [showStages, setShowStages] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [departments, setDepartments] = useState([]);
  const [oldDepts, setOldDepts] = useState([]);


  const [orgPipeline, setOrgPipeline] = useState('');
  const [orgStage, setOrgStage] = useState('');
  const [orgStatus, setOrgStatus] = useState('');

  const [oldStages, setOldStages] = useState([]);

  const [oldStatus, setOldStatus] = useState([]);

  const [allUsers, setAllUsers] = useState([]);
  const [user, setUser] = useState('');
  const [isOpenUserList, setIsOpenUsersList] = useState(false);

  const [pipeline, setPipeline] = useState('');
  const [isOpenPipelineList, setIsOpenPipelinesList] = useState(false);

  const orgName = localStorage.getItem('orgName');

  const formData = new FormData();

  const orgDetails = () => {
    getOrgDetails().then((response) => {
      if (response.status === 200) {
        setOldStatus(response.data.data.orgLeadStatus);

        setSingleOrg(response.data.data);
        setLogo(response.data.data.orgLogo === undefined ? '' : response.data.data.orgLogo);
        setImg(response.data.data.orgLogo === undefined ? '' : response.data.data.orgLogo);

        setDepartments(response.data.data.orgDept);
        setOldDepts(response.data.data.orgDept);

        setOldStages(response.data.data.orgLeadStages);
      }
    })
  }

  useEffect(() => {
    orgDetails();
  }, []);

  // useEffect(() => {
  //   getAllUsers().then((response) => {
  //     if (response.status === 200) {
  //       setAllUsers(response.data.data);
  //     }
  //   });
  // }, []);

  const handleLogo = (e) => {
    setLogo(e.target.files[0]);
    setImg(URL.createObjectURL(e.target.files[0]));
  };

  const handleUpdateLogo = () => {
    formData.append('orgLogo', logo);
    updateLogo(formData).then((response) => {
      if (response.status === 200) {
        SuccessToast('Organization updated successfully');
        orgDetails();
      } else {
        ErrorToast('Unable to update logo');
      }
    }).catch(() => {
      ErrorToast('Something went wrong');
    })
  };

  const handleUpdateOrg = (e) => {
    e.preventDefault();
    updateOrg(form).then((response) => {
      if (response.status === 200) {
        handleUpdateLogo();
        SuccessToast('Organization updated successfully');
        orgDetails();
      } else {
        ErrorToast('Unable to update organization');
      }
    }).catch(() => {
      ErrorToast('Something went wrong');
    })
  };

  const handleInputChangePipeline = (e) => {
    setUser(e.target.value);

    setIsOpenPipelinesList(true);
    setForm({
      ...form,
      pipeline: e.target.value
    });
  };

  const handleOptionClickPipeline = (i) => {
    setPipeline(i)
    setForm({
      ...form,
      pipeline: i
    });
    setIsOpenPipelinesList(false);
  };

  const filteredPipelinesOptions = departments.filter((option) =>
    (option).toLowerCase().includes(user.toLowerCase())
  );



  const handleInputChangeUser = (e) => {
    setUser(e.target.value);

    setIsOpenPipelinesList(true);
    setForm({
      ...form,
      user: e.target.value
    });
  };

  const handleOptionClickUser = (i) => {
    setUser(i)
    setForm({
      ...form,
      user: i
    });
    setIsOpenUsersList(false);
  };

  const filteredUsersOptions = allUsers.filter((option) =>
    (option.firstName).toLowerCase().includes(user.toLowerCase())
  );

  const handleChange = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      orgDept: oldDepts,
      orgLeadStages: oldStages,
      orgLeadStatus: oldStatus
    });
  };

  // pipeline
  const addOrgPipeline = () => {
    if (orgPipeline === "") {
      ErrorToast("Pipeline can not be empty");
    } else {
      setOldDepts([...oldDepts, orgPipeline]);
    }
    setOrgPipeline('');
  };

  const handleRemoveDept = (index) => {
    const newState = [...oldDepts];
    newState.splice(index, 1);
    setOldDepts(newState);
  };

  // status
  const handleRemoveStatus = (index) => {
    const newState = [...oldStatus];
    newState.splice(index, 1);
    setOldStatus(newState);
  };


  const addOrgStatus = () => {
    setOldStatus([...oldStatus, orgStatus]);
    setOrgStatus('');
  };

  // stage
  const addOrgStage = () => {
    if (orgStage === "") {
      ErrorToast("Lead stage can not be empty")
    } else {
      oldStages.length < 6 ? setOldStages([...oldStages, orgStage]) : ErrorToast("Can not add more than 6 Lead Stages");
    }

    setOrgStage('');
  };

  const handleRemoveStage = (index) => {
    const newState = [...oldStages];
    newState.splice(index, 1);
    setOldStages(newState);
  };

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const url =
    "https://www.meme-arsenal.com/memes/b6a18f0ffd345b22cd219ef0e73ea5fe.jpg";


  console.log("oldStatus", oldStatus);
  return (
    <div onClick={() => {
      isOpenUserList && setIsOpenUsersList(false)
    }}>
      <Toaster />
      <SubHeader title="Update Organization" />
      <Row
        className="text-dark pb-5"
        style={{ width: "70%", marginLeft: "15%", height: "100%", minHeight: "70vh", marginTop: screenWidth >= 1200 ? "-1%" : "-3%", }}
      >
        <Col>
          <form onSubmit={handleUpdateOrg}>
            <Col className="w-100">
              <div className="bg-white mb-2 px-3 rounded text-dark w-100" style={{ minHeight: "3rem" }}>
                <Card.Header style={{ width: "100%" }}>
                  <div sm={6} className="w-100" onClick={() => setShowBasic(!showBasic)} style={{ cursor: "pointer" }}>
                    <h4 className=" text-primary fw-bold">About</h4>
                  </div>
                  <div sm={1}>
                    {showBasic === false ? (
                      <BsChevronDown className="fs-4 fw-bold" onClick={() => setShowBasic(true)} style={{ cursor: "pointer" }} />
                    ) : (
                      <BsChevronUp className="fs-4 fw-bold" onClick={() => setShowBasic(false)} style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </Card.Header>
                <div>
                  <Row className="pb-3 px-4" style={{ display: showBasic === false && "none", }}>
                    <Col className="mt-3" sm={12}>
                      <Form.Label htmlFor="closureDate">
                        Organization Name
                      </Form.Label>
                      <Form.Control type="text" className="border border-primary" defaultValue={singleOrg.orgName} placeholder={singleOrg.orgName} name="orgName" onChange={handleChange} />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Organization Logo</Form.Label>
                      <Form.Control type="file" className="border border-primary" name="orgLogo" onChange={handleLogo} />
                    </Col>
                    <Col sm={6}>
                      <img src={logo.length > 0 ? logo : url} alt="OrgLogo" width="80px" height="80px" className="rounded-circle mt-2" />
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="bg-white mb-2 px-3 rounded text-dark w-100" style={{ minHeight: "3rem" }}>
                <Card.Header style={{ width: "100%" }}>
                  <div sm={6} className="w-100" onClick={() => setShowContact(!showContact)} style={{ cursor: "pointer" }}>
                    <h4 className=" text-primary fw-bold">Contact</h4>
                  </div>
                  <div sm={1}>
                    {showContact === false ? (
                      <BsChevronDown className="fs-4 fw-bold" onClick={() => setShowContact(true)} style={{ cursor: "pointer" }} />
                    ) : (
                      <BsChevronUp className="fs-4 fw-bold" onClick={() => setShowContact(false)} style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </Card.Header>
                <div>
                  <Row className="pb-3 px-4" style={{ display: showContact === false && "none", }}>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Email</Form.Label>
                      <Form.Control type="email" className="border border-primary" name="orgEmail" placeholder={singleOrg.orgEmail} onChange={handleChange} />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Alternative Email</Form.Label>
                      <Form.Control type="email" className="border border-primary" name="orgAlterEmail" placeholder="Alternative Email" onChange={handleChange} />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Phone</Form.Label>
                      <Form.Control type="text" className="border border-primary" pattern="\d{10}" name="orgPhone" placeholder={singleOrg.orgPhone} onChange={handleChange} />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Alternative Phone</Form.Label>
                      <Form.Control type="text" className="border border-primary" pattern="\d{10}" name="orgAlterPhone" placeholder="Alternative Phone" onChange={handleChange} />
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="bg-white mb-2 px-3 rounded w-100" style={{ minHeight: "3rem" }}>
                <Card.Header style={{ width: "100%" }}>
                  <div sm={6} className="w-100" onClick={() => setShowPipeline(!showPipeline)} style={{ cursor: "pointer" }}>
                    <h4 className=" text-primary fw-bold">Pipeline</h4>
                  </div>
                  <div sm={1}>
                    {showPipeline === false ? (
                      <BsChevronDown className="fs-4 fw-bold" onClick={() => setShowPipeline(true)} style={{ cursor: "pointer" }} />
                    ) : (
                      <BsChevronUp className="fs-4 fw-bold" onClick={() => setShowPipeline(false)} style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </Card.Header>
                <div>
                  <Row className="pb-3 px-4" style={{ display: showPipeline === false && "none", }}>
                    <>
                      <div className="d-flex justify-content-start center align-items-center">
                        <Form.Group className="col-sm-6 form-group">
                          <Form.Label>Pipeline</Form.Label>
                          <Form.Control type="text" className="border border-primary" value={orgPipeline} placeholder="Pipeline" name="orgDept" onChange={(e) => setOrgPipeline(e.target.value)} />
                        </Form.Group>
                        <RiAddCircleFill onClick={addOrgPipeline} className="text-primary mx-1 mt-1 fs-1" style={{ cursor: "pointer" }} />
                      </div>
                    </>
                    <div className="row ms-3 p-0 d-flex justify-content-start align-items-start">
                      {
                        oldDepts.map((data, index) => (
                          <div className='col-sm-8 col-md-6 col-lg-3 mb-2 pe-2' key={index}>
                            <div className='w-100 d-flex justify-content-between align-items-between fs-6 border border-primary text-primary py-1 px-2 rounded-pill me-2'>
                              <span style={{ minWidth: "80%", width: "100%" }}>{data}</span>
                              <span className='m-0 p-0 text-start pe-5' onClick={() => handleRemoveDept(index)} style={{ cursor: "pointer" }}>X</span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </Row>
                </div>
              </div>

              <div className="bg-white mb-2 px-3 rounded w-100" style={{ minHeight: "3rem" }}>
                <Card.Header style={{ width: "100%" }}>
                  <div sm={6} className="w-100" onClick={() => setShowStages(!showStages)} style={{ cursor: "pointer" }}>
                    <h4 className=" text-primary fw-bold">Lead Stages</h4>
                  </div>
                  <div sm={1}>
                    {showStages === false ? (
                      <BsChevronDown className="fs-4 fw-bold" onClick={() => setShowStages(true)} style={{ cursor: "pointer" }} />
                    ) : (
                      <BsChevronUp className="fs-4 fw-bold" onClick={() => setShowStages(false)} style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </Card.Header>
                <div>
                  <Row className="pb-3 px-4" style={{ display: showStages === false && "nokf", }}>
                    {/* <Col sm={6}>
                      <Form.Group style={{ position: "relative" }}>
                        <Form.Label htmlFor="assignTo">Select Pipeline</Form.Label>
                        <Form.Control type="text" name="pipeline" className="w-100 border border-primary" value={pipeline} placeholder="Select Pipeline"
                          onChange={handleInputChangePipeline} onClick={() => setIsOpenPipelinesList(true)} autoComplete='off'
                        />
                        {isOpenPipelineList && (
                          <>
                            <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                              {
                                oldDepts.length > 0 ? (
                                  filteredPipelinesOptions.length > 0 ? (
                                    filteredPipelinesOptions.map((option, i) => (
                                      <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickPipeline(option)}>
                                        {option}
                                      </div>
                                    ))
                                  ) : (
                                    <div className="mt-2">No match found</div>
                                  )
                                ) : (
                                  <div className="mt-2">No pipeline found </div>
                                )
                              }
                            </div>
                          </>
                        )}
                      </Form.Group>
                    </Col> */}
                    {/* <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Select Pipeline</Form.Label>
                        <div className="w-100">
                          <Form.Control type="text" placeholder="Pipeline" name="pipeline" className="border border-primary" />
                        </div>
                      </Form.Group>
                    </Col> */}
                    <Col sm={6}>
                      <div className="w-100 d-flex justify-content-start center align-items-center">
                        <Form.Group className="form-group w-75">
                          <Form.Label>Lead Stage</Form.Label>
                          <Form.Control type="text" className="border border-primary" value={orgStage} placeholder="Lead Stage" name="orgLeadStages" onChange={(e) => setOrgStage(e.target.value)} />
                        </Form.Group>
                        <RiAddCircleFill onClick={addOrgStage} className="ms-3 text-primary mx-1 mt-1 fs-1" style={{ cursor: "pointer" }} />
                      </div>
                    </Col>
                    <div className="row w-100 ms-1 mt-2 p-0">
                      {
                        oldStages.map((data, index) => (
                          <div className='col-sm-8 col-md-6 col-lg-3 mb-2 pe-2' key={index}>
                            <div className='w-100 d-flex justify-content-between align-items-between fs-6 border border-primary text-primary py-1 px-2 rounded-pill me-2'>
                              <span style={{ minWidth: "80%", width: "100%" }}>{data}</span>
                              <span className='m-0 p-0 text-start pe-5' onClick={() => handleRemoveStage(index)} style={{ cursor: "pointer" }}>X</span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </Row>
                  {showStages === true ? <hr /> : null}
                  <Card.Footer>
                    <Button type="submit" onMouseOver={handleChange}>Update</Button>
                    {/* <Link to={`/${orgName}/dashboard/lead/lead-list`} className="ms-5">
                      <Button type="button" variant="danger">Cancel</Button>
                    </Link> */}
                  </Card.Footer>
                </div>
              </div>

              {/* <div className="bg-white mb-2 px-3 rounded w-100" style={{ minHeight: "3rem" }}>
                <Card.Header style={{ width: "100%" }}>
                  <div sm={6} className="w-100" onClick={() => setShowStatus(!showStatus)} style={{ cursor: "pointer" }}>
                    <h4 className=" text-primary fw-bold">Lead Status</h4>
                  </div>
                  <div sm={1}>
                    {showStatus === false ? (
                      <BsChevronDown className="fs-4 fw-bold" onClick={() => setShowStatus(true)} style={{ cursor: "pointer" }} />
                    ) : (
                      <BsChevronUp className="fs-4 fw-bold" onClick={() => setShowStatus(false)} style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </Card.Header>
                <div>
                  <Row className="pb-3 px-4" style={{ display: showStatus === false && "none", }}>
                    <div className="d-flex justify-content-start center align-items-center">
                      <Form.Group className="col-sm-6 form-group">
                        <Form.Label>Lead Status</Form.Label>
                        <Form.Control type="text" placeholder="Lead Status" value={orgStatus} name="orgLeadStatus" className="border border-primary" onChange={(e) => setOrgStatus(e.target.value)} />
                      </Form.Group>
                      <RiAddCircleFill onClick={addOrgStatus} className="text-primary mx-1 mt-1 fs-1" style={{ cursor: "pointer" }} />
                    </div>
                    <div className="row ms-3 p-0 d-flex justify-content-start align-items-start">
                      {
                        oldStatus.map((data, index) => (
                          <div className="col-lg-2 col-md-2 col-sm-3 p-2 me-3 mb-2 rounded rounded-pill border border-primary align-items-end text-center " key={index}>
                            <span className="text-primary pe-3">{data}</span>
                            <MdOutlineCancel className="text-primary fs-5" onClick={() => handleRemoveStatus(index)} style={{ cursor: "pointer" }} sm={1} md={1} lg={1} />
                          </div>
                        ))
                      }
                    </div>
                  </Row>
                </div>
              </div> */}

              {/* <div className="bg-white mb-2 px-3 rounded w-100" style={{ minHeight: "3rem" }}>
                <Card.Header style={{ width: "100%" }}>
                  <div sm={6} className="w-100" onClick={() => setShowAddUser(!showAddUser)} style={{ cursor: "pointer" }}>
                    <h4 className=" text-primary fw-bold">Add User</h4>
                  </div>
                  <div sm={1}>
                    {showAddUser === false ? (
                      <BsChevronDown className="fs-4 fw-bold" onClick={() => setShowAddUser(true)} style={{ cursor: "pointer" }} />
                    ) : (
                      <BsChevronUp className="fs-4 fw-bold" onClick={() => setShowAddUser(false)} style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </Card.Header>
                <div>
                  <Row className="pb-3 px-4" style={{ display: showAddUser === false && "none" }}>
                    <Col sm={6}>
                      <Form.Group style={{ position: "relative" }}>
                        <Form.Label htmlFor="assignTo">User</Form.Label>
                        <Form.Control type="text" name="user" className="w-100 border border-primary" value={user} placeholder="Select user"
                          onChange={handleInputChangeUser} onClick={() => setIsOpenUsersList(true)} autoComplete='off'
                        />
                        {isOpenUserList && (
                          <>
                            <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                              {
                                allUsers.length > 0 ? (
                                  filteredUsersOptions.length > 0 ? (
                                    filteredUsersOptions.map((option, i) => (
                                      <>
                                        <div className='mt-2' style={{ cursor: 'pointer' }} key={option} onClick={() => handleOptionClickUser(option.firstName)}>
                                          {option.firstName}
                                        </div>
                                      </>
                                    ))
                                  ) : (
                                    <div className="mt-2">No match found</div>
                                  )
                                ) : (
                                  <div className="mt-2">No user found </div>
                                )
                              }
                            </div>
                          </>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={2} style={{ paddingTop: "2rem", }}>
                      <span className="px-3 py-2 bg-primary text-white" style={{ cursor: "pointer", borderRadius: "5px" }}>Send Invitation</span>
                    </Col>
                  </Row>
                </div>
                {showStatus === true ? <hr /> : null}
                <Card.Footer>
                  <Button type="submit" onMouseOver={handleChange}>Update</Button>
                </Card.Footer>
              </div> */}
            </Col>
          </form>
        </Col>
        <ToastContainer />
      </Row >
    </div >
  );
};

export default UpdateOrg;
