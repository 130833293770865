import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { addLeadActivity } from "../../../Hook/leadHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../../../components/toast";

const AttachForm = ({ leadId, leadActivitiesByType }) => {
  const [form, setForm] = useState({});
  const [radio, setRadio] = useState("Link");
  const [image, setImage] = useState("");
  const [img, setImg] = useState('');
  const [url, setUrl] = useState('');
  const [attachmentDesc, setAttachmentDesc] = useState('');
  const [title, setTitle] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);

  const orgId = localStorage.getItem('orgID');   
  const formData = new FormData();
  const type = "Attachment";

  const addActivity = async (e) => {
    e.preventDefault();
    setDisabledBtn(true);

    formData.append("leadId", leadId);
    formData.append('orgId', orgId);
    formData.append("type", type);
    form.title !== undefined && formData.append("title", form.title);
    formData.append("desc", form.desc);
    image !== undefined && formData.append("image", image);

    addLeadActivity(formData).then((response) => {
      if (response.status === 201) {
        SuccessToast('Activity Added');
        leadActivitiesByType('Attachment');
        setUrl('');
        setAttachmentDesc('');
        setTitle('');
        setDisabledBtn(false);
      } else {
        ErrorToast("Unable to add activity");
        setDisabledBtn(false);
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong')
      setDisabledBtn(false);
    })
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImg(URL.createObjectURL(e.target.files[0]));
  }

  const handleRadio = (e) => {
    setRadio(e);
  };

  const handleChange = (e) => {
    if (e.target.name === 'desc') {
      setUrl(e.target.value);
      setAttachmentDesc(e.target.value);
    }
    else if (e.target.name === 'title') {
      setTitle(e.target.value);
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  console.log('img', img);

  return (
    <Row className="text-dark my-3" style={{ fontSize: "0.8rem" }}>
      <form>
        <p className="text-primary m-0 p-0">Select attachment type</p>
        <div className="m-0 p-0">
          <input type="radio" name="attachmentType" className="border border-primary" value="link" onClick={() => handleRadio("Link")} style={{ cursor: "pointer" }}
            defaultChecked checked={radio === 'Link'}
          />
          <label className="ms-1" onClick={() => handleRadio("Link")} style={{ cursor: 'pointer', fontSize: "0.7rem", fontWeight: "500" }}> Link</label>

          <input type="radio" name="attachmentType" value="Image" onClick={() => handleRadio("Image")} style={{ cursor: "pointer", marginLeft: "1rem" }}
            checked={radio === 'Image'}
          />
          <label className="ms-1" onClick={() => handleRadio("Image")} style={{ cursor: 'pointer', fontWeight: "500", fontSize: "0.7rem", }}> Upload Image</label>
        </div>
      </form>
      {radio === "Link" ? (
        <form className="my-3" onSubmit={addActivity} style={{ width: "98%" }}>
          <Col sm={12} className="mt-2">
            <textarea rows="5" placeholder="Paste Link" className="w-100 py-1 border border-primary rounded" id="leadFormInput" name="desc"
              value={url} onChange={handleChange} required
            ></textarea>
          </Col>
          <Col sm={12} className="d-flex justify-content-end align-items-end">
            <div className="m-0 p-0" style={{ cursor: disabledBtn ? 'not-allowed' : 'pointer' }}>
              <Button type="submit" variant={disabledBtn ? 'secondary' : 'primary'} className="ms-0 text-white fs-5 px-4" disabled={disabledBtn ? true : false} style={{ padding: '3px' }}>Save</Button>
            </div>
          </Col>
          <Col sm={3}>
          </Col>
        </form>
      ) : (
        <form className="my-3" onSubmit={addActivity} style={{ width: "98%" }}>
          <Col sm={12} className="mt-2">
            <Form.Control type="text" placeholder="File Title" className="py-1 rounded border-primary" name="title" value={title} onChange={handleChange} required />
          </Col>
          <Col sm={12} className="mt-2">
            <Form.Control type="text" as="textarea" rows="5" placeholder="File Description" className="w-100 py-1 rounded border-primary" name="desc"
              value={attachmentDesc} onChange={handleChange} required
            />
          </Col>
          <Row>
            <Col sm={4} className="mt-2">
              <Form.Control type="file" className="py-1 rounded border-primary" name="image" onChange={handleImage} />
            </Col>
            <Col sm={3} className="ms-5 mt-3">
              <img src={img} alt="attachment" style={{ width: '80px', height: '80px' }} />
            </Col>
          </Row>
          <Col sm={12} className="mt-2 d-flex justify-content-end align-items-end">
            <div className="m-0 p-0" style={{ cursor: disabledBtn ? 'not-allowed' : 'pointer' }}>
              <Button type="submit" variant={disabledBtn ? 'secondary' : 'primary'} className="ms-0 text-white fs-5 px-4" disabled={disabledBtn ? true : false} style={{ padding: '3px' }}>Upload Image</Button>
            </div>
          </Col>
        </form>
      )}
    </Row>
  );
};

export default AttachForm;
