import React, { useEffect, useState } from 'react';
import SubHeader from '../../../../components/partials/dashboard/HeaderStyle/sub-header';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { FiEye } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { Col, Row } from 'react-bootstrap';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IoRadioButtonOn } from 'react-icons/io5';
import ActivityBar from '../../../../components/partials/components/activitybar';
import Pagination from '../pagination/pagination';
import { getAllPurchase, getPurchaseById } from '../Hook/purchaseHooks';
import { getVendorById } from '../Hook/vendorHooks';
import DeletePurchase from './delete-purchase';
// import useFullPageLoader from '../Hook/useFullPageLoader';

const AllPurchase = () => {

  const initialList = Object.freeze({
    _id: 'lkjhg9876ghjo85987',
    puchaseNo: 987654,
    vendor: "Test vendor",
    firmName: 'Test firm',
  })
  const [purchaseList, setPurchaseList] = useState([]);
  const [details, setDetails] = useState([]);

  const [arr, setArr] = useState([]);
  const [vendorId, setvendorId] = useState('');
  const [selected, setSelected] = useState('All');
  const [deleteId, setDeleteId] = useState('');
  const [showDelete, setShowDelete] = useState(false);

  // const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [vendorDetails, setvendorDetails] = useState({});
  const [vendorAddress, setVendorAddress] = useState({});
  const vendorName = 'Name';
  const vendorEmail = 'vendor@gmail.com';
  // const vendorAddress = {
  //   address1: 'address1',
  //   address2: 'address2',
  //   country: 'country',
  //   state: 'state',
  //   city: 'city',
  //   pinCode: 'pinCode',
  // }

  const getPurchaseList = () => {
    // showLoader();
    getAllPurchase().then((response) => {
      if (response.status === 200) {
        console.log("if block");
        setPurchaseList(response.data.data);
        const resp = response.data.data;
        console.log("res", resp);
        setvendorId(resp[0]._id)
        // setArr(resp[0]);
        // setVendorAddress(resp[0].vendor.address);
        // setvendorDetails(resp[0].vendor);
        // hideLoader();
      } else {
        console.log("else block");
        setPurchaseList([]);
        // hideLoader();
      }
    }).catch(() => {
      console.log("catch block");
      setPurchaseList([]);
      // hideLoader();
    })
  }

  useEffect(() => {
    getPurchaseList();
  }, []);

  const getSingleVendor = () => {
    getPurchaseById(vendorId).then((response) => {
      if (response.status === 200) {
        setArr(response.data.data[0]);
        setvendorDetails(response.data.data[0].vendor);
        setVendorAddress(response.data.data[0].vendor.address)
      }
    })
  }

  useEffect(() => {
    getSingleVendor()
  }, [vendorId]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const showPerPage = 10;
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const breakPoint = 970;
  const orgName = localStorage.getItem('orgName');

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const format = (dateString) => {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };


  const handleDeletePurchase = (purId) => {
    setDeleteId(purId);
    setShowDelete(true);
  }


  const styleHandle = (data) => {
    if (data.status === "Pending") {
      return {
        backgroundColor: "#FFB802",
        textAlign: "center",
        fontSize: "90%",
        width: "90%"
      };
    } else if (data.status === "Paid") {
      return {
        backgroundColor: "green",
        textAlign: "center",
        fontSize: "90%",
        width: "90%"
      };
    } else if (data.status === "Partial Paid") {
      return {
        backgroundColor: "blue",
        textAlign: "center",
        fontSize: "90%",
        width: "90%"
      };
    } else if (data.status === "Overdue") {
      return {
        backgroundColor: "#ff0000",
        textAlign: "center",
        fontSize: "90%",
        width: "90%"
      };
    } else if (data.status === "Canceled" || data.status === "Cancel") {
      return {
        backgroundColor: "#a9083d",
        textAlign: "center",
        fontSize: "90%",
        width: "90%"
      };
    } else if (data.status === "Draft") {
      return {
        backgroundColor: "#374550",
        textAlign: "center",
        fontSize: "90%",
        width: "90%"
      };
    }
  };

  const styleHandleText = (data) => {
    if (data.status === "Pending") {
      return {
        color: "#FFB802",
        textAlign: "right",
      };
    } else if (data.status === "Paid") {
      return {
        color: "green",
        textAlign: "right",
      };
    } else if (data.status === "Partial Paid") {
      return {
        color: "blue",
        textAlign: "right",
      };
    } else if (data.status === "Overdue") {
      return {
        color: "#ff0000",
        textAlign: "right",
      };
    } else if (data.status === "Draft") {
      return {
        color: "black",
        textAlign: "right",
      };
    }
  };

  const purchaseOptions = () => {
    return (
      <>
      </>
    )
  }



  return (
    <div className='m-0 p-0'>
      <SubHeader title="Purchase" btnLink={`/${orgName}/dashboard/purchase/create-purchase`} btnTitle="Create Purchase" />
      <div>
        <div className="d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-2%" : "-3%", flexDirection: "row" }}>
          <Row sm={3} className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
            <Col sm={12} className="bg-white rounded">
              <div>
                <Row className="py-2" style={{ borderBottom: "1px solid rgb(125 125 125)" }}>
                  <Col sm={8}>
                    <h5 className="text-primary" style={{ fontWeight: "550" }}>Preview</h5>
                  </Col>
                  <Col sm={4} className="text-end">
                    <Link to={`/${orgName}/dashboard/purchase/purchase-details/1234`}>
                      <FiEye className="text-success" style={{ fontSize: "1rem" }} />
                    </Link>
                  </Col>
                </Row>
              </div>
              <div className="my-3">
                <Row className="mt-2">
                  <h5 className="text-primary" style={{ textAlign: "right" }}>Purchase</h5>
                  <span className="m-0 p-0 pe-3 w-100 text-end text-primary">{arr?.purchaseNumber}</span>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Row>
                      <Col sm={12} className="text-dark" style={{ fontWeight: "550" }}>Order Date:</Col>
                      <Col sm={12} className="text-dark">{format(arr.orderDate)}</Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col sm={12} className="text-dark text-end" style={{ fontWeight: "550" }}>Due Date:</Col>
                      <Col sm={12} className="text-dark text-end">{format(arr.deliveryDate)}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-3 text-dark">
                  <Col>Purchase From:</Col>
                  <Col className="w-100 mb-1" sm={12}>
                    <Row>
                      <Col style={{ fontWeight: 550 }} sm={3}>Name:</Col>
                      <Col>{`${vendorDetails === undefined || vendorDetails.name === undefined ? "" : vendorDetails.name}`}</Col>
                    </Row>
                  </Col>
                  <Col sm={12} className="mb-1">
                    <Row>
                      <Col style={{ fontWeight: 550 }} sm={3}>Email:</Col>
                      <Col>{vendorDetails === undefined || vendorDetails.email === undefined ? "" : vendorDetails.email}</Col>
                    </Row>
                  </Col>
                  <Col sm={12}>
                    <Row>
                      <Col style={{ fontWeight: 550 }} sm={3}>Address:</Col>
                      <Col sm={8}>
                        <div>
                          {`${(vendorAddress === undefined || vendorAddress.address1 === undefined) ? " " : `${vendorAddress.address1},`}`}
                        </div>
                        <div>
                          {`${(vendorAddress === undefined || vendorAddress.address2 === undefined) ? " " : `${vendorAddress.address2},`}`}
                        </div>
                        <div>
                          {`${(vendorAddress === undefined || vendorAddress.country === undefined) ? " " : `${vendorAddress.country},`}`}
                          {` ${(vendorAddress === undefined || vendorAddress.state === undefined) ? " " : `${vendorAddress.state},`}`}
                          {` ${(vendorAddress === undefined || vendorAddress.city === undefined) ? " " : `${vendorAddress.city},`}`}
                        </div>
                        <div>
                          {`${(vendorAddress === undefined || vendorAddress.pinCode === undefined) ? " " : `${vendorAddress.pinCode}`}`}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ textAlign: "right" }}>
                  <div className="text-dark" style={{ fontWeight: "550" }}>Total:</div>
                  <div className="text-dark">
                    {arr.currency === undefined ? "" : arr.currency} {arr.total === undefined ? " " : (arr.total).toFixed(2)}
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
          <Row sm={7} className={`h-100 mx-2 ps-3 mb-5`} style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%", }}>
            {/* <Row sm={12} className="bg-white rounded pt-2 mb-3 text-center fs-4">
              {mainfilters.map((data, index) => (
                <Col onClick={() => handleFilter(data)} className={`${data === selected ? "border-primary text-primary" : "border-white text-dark"}`}
                  style={{ cursor: "pointer", borderBottom: "3px solid" }} key={index}
                >
                  {data}
                </Col>
              ))}
            </Row> */}
            <Row className="bg-white rounded">
              <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
                <table className="" style={{ width: "100%" }}>
                  <thead>
                    <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}></th>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Purchase No.</th>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Firm Name</th>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Vendor Name</th>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Status</th>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseList.slice(pagination.start, pagination.end).map((data, index) => (
                      <tr key={index}>
                        <td className="py-3" style={{ width: "5%", height: "30px", borderBottom: "1px solid rgb(227 227 227)", }} key={index}>
                          <input type="radio" name="radiobtn" className="" value={data._id} onChange={() => setvendorId(data._id)}
                            // defaultChecked={vendorId === data[0]._id}
                            checked={vendorId === data._id} style={{ cursor: "pointer" }}
                          />
                        </td>
                        <td className="w-5 py-3" style={{ width: "15%", borderBottom: "1px solid rgb(227 227 227)" }}>
                          <span className="ms-0 text-dark">{data.purchaseNumber === undefined ? '' : data.purchaseNumber}</span>
                        </td>
                        <td className="py-3" style={{ width: "30%", borderBottom: "1px solid rgb(227 227 227)" }}>
                          {`${data.firm.name === undefined ? "" : data.firm.name}`}
                        </td>
                        <td className="py-3" style={{ width: "30%", borderBottom: "1px solid rgb(227 227 227)" }}>
                          {`${((data.vendor === undefined) || (data.vendor.name === undefined)) ? "" : data.vendor.name} `}
                        </td>
                        <td className="pt-3 w-5" style={{ borderBottom: "1px solid rgb(227 227 227)", }}>
                          <p className="text-white rounded-pill text-center px-2" style={styleHandle(data)}>{data.status}</p>
                        </td>
                        <td style={{ borderBottom: "1px solid rgb(227 227 227)" }}>
                          <div className="flex text-start list-user-action">
                            <Tooltip title="View" key="view">
                              <Link to={`/${orgName}/dashboard/purchase/purchase-details/${data._id}`}>
                                <FiEye className="text-primary" style={{ fontSize: "1.2rem", cursor: "pointer" }} />
                              </Link>
                            </Tooltip>

                            <Tooltip title={`${selected === "Deleted" ? 'Delete Permanently' : 'Delete'}`} key="view">
                              <Link to={`#`}>
                                <MdDeleteOutline onClick={() => handleDeletePurchase(data._id)} className="text-danger mx-2" style={{ fontSize: "1.2rem", cursor: "pointer", }} />
                              </Link>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {
                  purchaseList.length === 0 && (
                    <div className='mt-5 fs-4 text-center text-secondary'>
                      No Purchases To Show
                    </div>
                  )
                }
              </div>
              <div className="my-5">
                <Pagination
                  showPerPage={showPerPage}
                  onPaginationChange={onPaginationChange}
                  total={details.length}
                />
              </div>
            </Row>
          </Row>
          <ActivityBar options={purchaseOptions} />
          <DeletePurchase showDelete={showDelete} setShowDelete={setShowDelete} id={deleteId} selected={selected} getAllInvoices={getAllPurchase} />
        </div>
      </div>
      {/* {loader} */}
    </div>
  )
}

export default AllPurchase
