import { Tooltip } from '@material-ui/core'
import { CancelOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { MdDeleteOutline } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { hardDeletePurchase, softDeletePurchase } from '../Hook/purchaseHooks'
// import { getInvoiceById, hardDeleteInvoice, softDeleteInvoice } from '../Hook/invoiceHooks'

const DeletePurchase = ({ showDelete, setShowDelete, id, selected, getAllInvoices }) => {
    const deleteInvoice = () => {
        selected === "Deleted" ? hardDeletePurchase(id, getAllInvoices) : softDeletePurchase(id, getAllInvoices);
        setShowDelete(false);
    }

    return (
        <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
            <Modal.Body className="my-2">
                <Row className="mb-2 ms-1">
                    <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Invoice</Col>
                    <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">
                        {
                            selected === "Deleted"
                                ? 'You want to delete the purchase permanently ?'
                                : 'You want to delete the purchase ?'
                        }
                    </Col>
                </Row>
                <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                    <div
                        className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                        onClick={() => deleteInvoice()}
                        style={{ borderRadius: "4px", cursor: "pointer" }}
                    >
                        Yes
                    </div>
                    <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                        onClick={() => setShowDelete(false)}
                        style={{ borderRadius: "5px", cursor: "pointer" }}
                    >
                        No
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default DeletePurchase
