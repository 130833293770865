import React, { useEffect, useState } from 'react'
import SubHeader from '../../../../components/partials/dashboard/HeaderStyle/sub-header';
import { FiEye, FiFilter } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Col, Modal, Row } from 'react-bootstrap';
import { HiOutlinePencilAlt, HiOutlineReceiptTax, HiReceiptTax } from 'react-icons/hi'
import ActivityBar from "../../../../components/partials/components/activitybar"; import Card from '../../../../components/Card';
import { MdAccountTree, MdDeleteOutline, MdOutlineDescription, MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { Tooltip } from '@material-ui/core';
import axios from 'axios';
import { API } from '../../../../backend';
import { Description } from '@mui/icons-material';
import { deleteFirm, getAllFirms, getSingleFirm } from '../Hook/firmHooks';
import { getAllTaxes } from '../Hook/taxHooks';
import Pagination from '../pagination/pagination';
import { CgProfile } from 'react-icons/cg';
import { BsChevronDown, BsChevronUp, BsFillShareFill } from 'react-icons/bs';
import { getInvoicesByFirm, restoreDeletedInvoice } from '../Hook/invoiceHooks';
import DeleteInvoice from '../invoice/delete-invoice';
import { RWebShare } from 'react-web-share';
import { FaRegClock } from 'react-icons/fa';
import AddNewTaxes from './addnewtaxes';
import { toast } from 'react-hot-toast';
import { IoReloadOutline } from 'react-icons/io5';
// import CreateProducts from './create-products';
import CreateProducts from '../products/create-products';
import { deleteProduct, getAllProducts } from '../Hook/productHooks';
import ProductDetails from '../products/product-details';
import { ErrorToast, SuccessToast, WarningToast } from '../../components/toast';
import useFullPageLoader from '../Hook/useFullPageLoader';
import { addRecentActivity, getEntityRecentActivities } from '../Hook/recentActivityHooks';

const AboutFirm = () => {
    const { id } = useParams();
    const [singleFirm, setSingleFirm] = useState([]);
    const [allRecentActivities, setAllRecetActivities] = useState([]);
    const [firmId, setFirmId] = useState(id);
    const [firmAdd, setFirmAdd] = useState([]);
    const [firmDetails, setFirmDetails] = useState([]);
    const [contactPersonDetails, setContactPersonDetails] = useState([]);
    const [allFirms, setAllFirms] = useState([]);
    const [firmTaxes, setFirmTaxes] = useState([]);
    const [allProducts, setAllProducts] = useState([]);

    const [currency, setCurrency] = useState('');
    const [collectedTax, setCollectedTax] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [dueAmount, setDueAmount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [dueCount, setDueCount] = useState(0);
    const [showFilter, setShowFilter] = useState(false);

    const [invoiceId, setInvoiceId] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const initalData = Object.freeze({
        amountPaid: 0,
        client: {
            address: {
                address1: "",
                address2: "",
                city: "",
                country: "",
                state: "",
            },
        },
        currency: "",
        delete: false,
        draft: false,
        dueAmount: 0,
        dueDate: "",
        firm: {
            address: {
                address1: "",
                address2: "",
                city: "",
                country: "",
                state: "",
            }
        },
        invoiceDate: "",
        items: [
            {
                amount: 0,
                desc: "",
                itemName: "",
                quantity: 0,
                unitPrice: 0,
            }
        ],
        notes: "",
        orgId: "",
        payment: [
            {
                amountPaidpayment: 0,
                datePaid: "",
                notes: "",
                paymentMethod: "",
            }
        ],
        remark: "",
        status: "",
        subTotal: "",
        tax: [{}],
        termsNcondition: [""],
        total: 0
    });

    const [details, setDetails] = useState([initalData]);
    const [selected, setSelected] = useState("All");
    const [firmInvoices, setFirmInvoices] = useState([]);
    const [showNewTax, setShowNewTax] = useState(false);
    const [showCreateProducts, setShowCreateProducts] = useState(false);
    const [showUpdateProduct, setShowUpdateProduct] = useState(false);
    const [showProductDetails, setShowProductDetails] = useState(false);
    const [productId, setProductId] = useState('');
    const [itemToBeDeleted, setItemToBeDeleted] = useState('');

    const [allInvoices, setAllInvoices] = useState([]);

    const [showDeleteInvoice, setShowDeleteInvoice] = useState(false);
    const [showTaxes, setShowTaxes] = useState(false);
    const [showInvoices, setShowInvoices] = useState(true);
    const [showProducts, setShowProducts] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showDeleteProduct, setShowDeleteProduct] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [showPerPage, setShowPerPage] = useState(10);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const breakPoint = 970;

    const orgId = localStorage.getItem("orgID");
    const orgName = localStorage.getItem("orgName");
    const userToken = localStorage.getItem("user_token");

    const datetimeString = new Date();
    const dateObject = new Date(datetimeString);

    const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
    const formattedTime = dateObject.toLocaleTimeString();

    // console.log("user token", userToken);
    const history = useHistory();

    const firmObj = {
        firmID: id,
        orgId: orgId,
    }

    const [newArray, setNewArray] = useState([]);

    const invoices = async () => {
        getInvoicesByFirm(id).then((response) => {
            if (response.status === 200) {
                setNewArray(response?.data?.data);
            } else {
                setNewArray([]);
            }
        }).catch(() => {
            setNewArray([]);
        });
    }

    useEffect(() => {
        invoices();
    }, [firmDetails]);

    const getAllRecentActivities = () => {
        getEntityRecentActivities("firm", id).then((response) => {
            response.status === 200 ? setAllRecetActivities(response?.data?.data) : setAllRecetActivities([]);
        }).catch(() => setAllRecetActivities([]));
    };

    useEffect(() => {
        getAllRecentActivities();
    }, []);

    const getProducts = () => {
        getAllProducts(firmId).then((response) => {
            if (response.status === 200) {
                setAllProducts(response.data.data);
            } else {
                setAllProducts([]);
            }
        }).catch(() => {
            setAllProducts([]);
        })
    }

    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        getAllFirms(setAllFirms);
    }, []);


    const getFirmInvoices = async () => {
        getInvoicesByFirm(id).then((response) => {
            if (response.status === 200) {
                const mainArray = response.data.data;
                setAllInvoices(response.data.data);
                const allInvoices = mainArray.filter((data, i) => data.delete != true);
                setFirmInvoices(mainArray.filter((data, i) => data.delete != true));
                if (selected === "All") {
                    const deletedInvoices = response.data.data;
                    setFirmInvoices(deletedInvoices.filter((data, i) => data.delete != true));
                } else if (selected === "Pending") {
                    setFirmInvoices(allInvoices.filter((data, i) => data.status === "Pending"));
                } else if (selected === "Paid") {
                    setFirmInvoices(allInvoices.filter((data, i) => data.status === "Paid"));
                } else if (selected === "Partial Paid") {
                    setFirmInvoices(allInvoices.filter((data, i) => data.status === "Partial Paid"));
                } else if (selected === "Overdue") {
                    setFirmInvoices(allInvoices.filter((data, i) => data.status === "Overdue"));
                } else if (selected === "Disputed") {
                    showLoader();
                    setFirmInvoices(allInvoices.filter((data, i) => data.dispute !== undefined));
                    hideLoader();
                } else if (selected === "Deleted") {
                    const deleted = response.data.data;
                    setFirmInvoices(deleted.filter((data, i) => data.delete === true));
                } else if (selected === "Canceled") {
                    const canceled = response.data.data;
                    setFirmInvoices(canceled.filter((data, i) => data.cancel === true));
                } else if (selected === "Draft") {
                    const drafts = response.data.data;
                    setFirmInvoices(drafts.filter((data, i) => data.draft === true));
                }
            } else {
                setAllInvoices([]);
            }
        }).catch(() => {
            setAllInvoices([]);
        });
        const url = `${API}/invoice/getInvoiceByFirm`;
        const config = {
            method: "POST",
            url: url,
            data: firmObj,
            headers: {
                "Content-Type": "application/json",
                'authorization': `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
            },
        }
        try {
            const response = await axios(config);
            if (response.status === 200) {
                const mainArray = response.data.data;
                setAllInvoices(response.data.data);
                const allInvoices = mainArray.filter((data, i) => data.delete != true);
                setFirmInvoices(mainArray.filter((data, i) => data.delete != true));
                if (selected === "All") {
                    const deletedInvoices = response.data.data;
                    setFirmInvoices(deletedInvoices.filter((data, i) => data.delete != true));
                } else if (selected === "Pending") {
                    const pending = response.data.data;
                    setFirmInvoices(allInvoices.filter((data, i) => data.status === "Pending"));
                } else if (selected === "Paid") {
                    const paid = response.data.data;
                    setFirmInvoices(allInvoices.filter((data, i) => data.status === "Paid"));
                } else if (selected === "Partial Paid") {
                    const partial = response.data.data;
                    setFirmInvoices(allInvoices.filter((data, i) => data.status === "Partial Paid"));
                } else if (selected === "Overdue") {
                    const overdue = response.data.data;
                    setFirmInvoices(allInvoices.filter((data, i) => data.status === "Overdue"));
                } else if (selected === "Disputed") {
                    showLoader();
                    setFirmInvoices(allInvoices.filter((data, i) => data.dispute !== undefined));
                    hideLoader();
                } else if (selected === "Deleted") {
                    const deleted = response.data.data;
                    setFirmInvoices(deleted.filter((data, i) => data.delete === true));
                } else if (selected === "Canceled") {
                    const canceled = response.data.data;
                    setFirmInvoices(canceled.filter((data, i) => data.cancel === true));
                } else if (selected === "Draft") {
                    const drafts = response.data.data;
                    setFirmInvoices(drafts.filter((data, i) => data.draft === true));
                }
            }
        }
        catch (err) {
            setAllInvoices([]);
        }
    }

    useEffect(() => {
        getFirmInvoices();
    }, [selected]);

    const firmDetailById = async () => {
        getSingleFirm(firmId).then((response) => {
            if (response.status === 200) {
                if (response.data.data.length > 0) {
                    setSingleFirm(response.data.data[0]);
                    setFirmAdd(response.data.data[0].add);
                    setFirmDetails({
                        name: response.data.data[0].name,
                        phone: response.data.data[0].phone,
                        email: response.data.data[0].email,
                        address: response.data.data[0].add,
                        logo: response.data.data[0].logo,
                    });
                    setContactPersonDetails(response.data.data[0].contectPerson);
                    getFirmInvoices();
                }
                else {
                    setSingleFirm({});
                    setFirmAdd({});
                    setFirmDetails({
                        name: "",
                        phone: "",
                        email: "",
                        address: "",
                        logo: "",
                    })
                    setContactPersonDetails({})
                }
            } else {
                setSingleFirm({});
                setFirmAdd({});
                setFirmDetails({
                    name: "",
                    phone: "",
                    email: "",
                    address: "",
                    logo: "",
                })
                setContactPersonDetails("")
            }
        }).catch(() => {
            setSingleFirm([]);
        });
    }

    useEffect(() => {
        firmDetailById();
    }, []);

    const handleFilter = (name) => {
        setSelected(name);
    };

    const getSummary = () => {
        setCurrency(firmInvoices[0] ? firmInvoices[0].currency : '');

        const paid = firmInvoices.filter((data) => data.status === "Paid");
        const partialArray = firmInvoices.filter((data) => data.status === "Partial Paid");

        const paidSubtotal = paid.reduce((prev, curr) => prev + (curr.subTotal * ((!curr.curConvert || parseFloat(curr.curConvert) === 0) ? 1 : curr.curConvert)), 0);
        const partialSubtotal = partialArray.reduce((prev, curr) => prev + (curr.subTotal * ((!curr.curConvert || parseFloat(curr.curConvert) === 0) ? 1 : curr.curConvert)), 0);

        const paidTotal = paid.reduce((prev, curr) => prev + (curr.total * ((!curr.curConvert || parseFloat(curr.curConvert) === 0) ? 1 : curr.curConvert)), 0);
        const partialTotal = partialArray.reduce((prev, curr) => prev + (curr.total * ((!curr.curConvert || parseFloat(curr.curConvert) === 0) ? 1 : curr.curConvert)), 0);

        setCollectedTax((paidTotal + partialTotal) - (paidSubtotal + partialSubtotal));

        setTotalAmount(firmInvoices.reduce((prev, curr) => prev + (curr.total * (parseFloat(curr.curConvert > 0) ? parseFloat(curr.curConvert) : 1)), 0));
        setTotalCount(firmInvoices.length);

        const pendingArray = firmInvoices.filter((data) => data.status === "Pending" || data.status === 'Overdue');

        const totalPartial = partialArray.reduce((prev, curr) => prev + (curr.dueAmount * (((parseFloat(curr.curConvert) > 0)) ? parseFloat(curr.curConvert) : 1)), 0)
        const totalPending = pendingArray.reduce((prev, curr) => prev + (curr.total * (((parseFloat(curr.curConvert) > 0)) ? parseFloat(curr.curConvert) : 1)), 0);
        setDueAmount(totalPending + totalPartial);
        setDueCount(pendingArray.length);
    }

    useEffect(() => {
        getSummary();
    }, [newArray]);

    const handleDelete = (singleFirmId) => {
        deleteFirm(singleFirmId).then((response) => {
            if (response.status === 200) {

                const activityForm = {
                    module: ["firm"],
                    activity: "Firm Deleted",
                    activityDesc: `Firm ${singleFirm?.registeredFirmName ? singleFirm?.registeredFirmName : singleFirm?.name} Deleted Successfully`,
                    createdDate: formattedDate,
                    createdTime: formattedTime
                };

                SuccessToast("Firm deleted");
                addRecentActivity(activityForm);
                setShowDelete(false);
                setTimeout(() => {
                    history.push(`/${orgName}/dashboard/firm/firm-list`)
                }, 1000);
            }
            else {
                ErrorToast("Unable to delete the firm");
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
        })
    }

    const allTaxes = () => {
        getAllTaxes(firmId).then((response) => {
            if (response.status === 200) {
                response.data.data.length > 0 ? setFirmTaxes(response.data.data[0].taxRates) : setFirmTaxes([]);
            }
            else {
                setFirmTaxes([]);
            }
        }).catch(() => {
            setFirmTaxes([]);
        });
    }
    useEffect(() => {
        allTaxes();
    }, []);

    const handleShowTaxes = () => {
        setShowInvoices(false);
        setShowProducts(false);
        setShowTaxes(!showTaxes);
    };

    const handleShowInvoices = () => {
        setShowTaxes(false);
        setShowProducts(false);
        setShowInvoices(!showInvoices);
    };

    const handleShowProducts = () => {
        setShowInvoices(false);
        setShowTaxes(false);
        setShowProducts(!showProducts);
    };

    const handleDeleteInvoice = (invoId) => {
        setDeleteId(invoId);
        setShowDeleteInvoice(true);
    };

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const handleRestoreInvoice = (invoiceId) => {
        restoreDeletedInvoice(invoiceId).then((response) => {
            if (response.status === 200) {
                invoices();
                SuccessToast("Invoice Restored SuccessFully");
            }
            else {
                ErrorToast("Unable to restore the invoice");
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Somethign went wrong');
        });
    };

    const handleShowProduct = (pid) => {
        setShowProductDetails(true);
        setProductId(pid);
    };

    const handleShowUpdateProduct = (pid) => {
        setShowUpdateProduct(true)
        setProductId(pid);
    }

    const handleShowDelete = (pid) => {
        setShowDeleteProduct(true);
        setItemToBeDeleted(pid);
    }

    const handleDeleteProduct = () => {
        deleteProduct(itemToBeDeleted).then((response) => {
            if (response.status === 201) {
                SuccessToast("Product deleted successfully");
                getProducts();
                setShowDeleteProduct(false);
            } else ErrorToast("Unable to delete product");
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
            setTimeout(() => {
                setShowDeleteProduct(false);
            }, 2000)
        })
    }

    const filters = ['All', 'Pending', 'Partial Paid', 'Paid', 'Overdue', 'Draft', 'Deleted', 'Canceled', 'Disputed'];
    const mainfilters = ['All', 'Pending', 'Paid', 'Overdue']

    const firmOptions = () => {
        return (
            <div style={{ position: "relative", zIndex: 1 }}>
                <div className="mx-3 text-dark fs-5">Filters</div>
                <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setShowFilter(!showFilter)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm>
                        {
                            selected === "All" ? 'All' : `${selected}`
                        }
                    </Col>
                    <Col sm={1}>
                        <Tooltip title="filter">
                            <Link to="#">
                                <FiFilter className="text-dark fs-4" />
                            </Link>
                        </Tooltip>
                    </Col>
                </Row>
                {
                    showFilter && (
                        <ul className="m-0 ms-3 p-0 bg-white rounded" style={{ width: "91%", zIndex: 9, listStyle: "none", position: "absolute", boxShadow: "0px 0px 10px #c8c9ca" }}>
                            {filters.map((data, index) => (
                                <li
                                    onClick={() => handleFilter(data)}
                                    className={`${data === selected
                                        ? " bg-primary border-primary text-white"
                                        : "border-white text-dark"
                                        } py-2 px-3 rounded`}
                                    style={{ cursor: "pointer", borderBottom: "3px solid" }}
                                    key={index}
                                >
                                    {data}
                                </li>
                            ))}
                        </ul>
                    )
                }
                <Link to={`/${orgName}/dashboard/firm/add-invoice/${id}`}>
                    <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                        <Col sm={9}>Create Invoice</Col>
                        <Col sm={1} className="text-end">
                            <Tooltip title="Create invoice">
                                <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                                    <Description style={{ fontSize: "1rem", cursor: "pointer" }} />
                                </Link>
                            </Tooltip>
                        </Col>
                    </Row>
                </Link>

                <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" onClick={() => setShowNewTax(true)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={9}>Create Taxes</Col>
                    <Col sm={1} className="text-end">
                        <Tooltip title="Create Taxes">
                            <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                                <HiReceiptTax style={{ fontSize: "1rem", cursor: "pointer" }} />
                            </Link>
                        </Tooltip>
                    </Col>
                </Row>

                <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" onClick={() => setShowCreateProducts(true)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={9}>Create Product</Col>
                    <Col sm={1} className="text-end">
                        <Tooltip title="Create Product">
                            <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                                <MdOutlineProductionQuantityLimits style={{ fontSize: "1rem", cursor: "pointer" }} />
                            </Link>
                        </Tooltip>
                    </Col>
                </Row>

                <Link to={`/${orgName}/dashboard/firm/edit-firm/${id}`}>
                    <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                        <Col sm={9}>Edit Firm</Col>
                        <Col sm={1} className="text-end">
                            <Tooltip title="Edit Firm">
                                <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                                    <HiOutlinePencilAlt style={{ fontSize: "1rem", cursor: "pointer" }} />
                                </Link>
                            </Tooltip>
                        </Col>
                    </Row>
                </Link>

                <Row className="bg-soft-secondary text-secondary mx-3 py-2 ps-2 pe-3 my-2" style={{ cursor: "not-allowed", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={9}>Create Project</Col>
                    <Col sm={1} className="text-end">
                        {/* <Tooltip title="Create invoice"> */}
                        <Link to="#" className="py-1 px-3 rounded  text-secondary me-2" style={{ cursor: "not-allowed" }}>
                            <MdAccountTree style={{ fontSize: "1rem", cursor: "not-allowed" }} />
                        </Link>
                        {/* </Tooltip> */}
                    </Col>
                </Row>

                {/* <Row className="bg-soft-secondary text-secondary mx-3 py-2 ps-2 pe-3 my-2" style={{ cursor: "not-allowed", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={9}>Send Reminder</Col>
                    <Col sm={1} className="text-end">
                        <Tooltip title="Create Project">
                        <Link className="py-1 px-3 rounded  text-secondary me-2" style={{ cursor: "not-allowed" }}>
                            <MdMessage style={{ fontSize: "1rem", cursor: "not-allowed" }} />
                        </Link>
                        </Tooltip>
                    </Col>
                </Row> */}

                <Row className="text-white bg-danger mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setShowDelete(true)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={12}>Delete Firm</Col>
                </Row>
            </div>
        )
    }

    const styleHandle = (data) => {
        if (data.status === "Pending") {
            return {
                backgroundColor: "#FFB802",
                textAlign: "center",
                fontSize: "90%",
            };
        } else if (data.status === "Paid") {
            return {
                backgroundColor: "green",
                textAlign: "center",
                fontSize: "90%",
            };
        } else if (data.status === "Partial Paid") {
            return {
                backgroundColor: "blue",
                textAlign: "center",
                fontSize: "90%",
            };
        } else if (data.status === "Overdue") {
            return {
                backgroundColor: "#ff0000",
                textAlign: "center",
                fontSize: "90%",
            };
        } else if (data.status === "Canceled" || data.status === "Cancel") {
            return {
                backgroundColor: "#a9083d",
                textAlign: "center",
                fontSize: "90%",
            };
        } else if (data.status === "Draft") {
            return {
                backgroundColor: "#374550",
                textAlign: "center",
                fontSize: "90%",
            };
        }
    };

    const summary = [
        {
            id: 1,
            amount: collectedTax,
            title: "Tax Collected",
            logo: <HiOutlineReceiptTax />,
            count: '',
            desc: ""
        },
        {
            id: 2,
            amount: totalAmount,
            title: "Invoices",
            logo: <MdOutlineDescription />,
            count: totalCount,
            desc: "Invoices"
        },
        {
            id: 3,
            amount: dueAmount,
            title: "Due",
            logo: <FaRegClock />,
            count: dueCount,
            desc: "Pending Invoices"
        },
    ];

    const path = `${window.location.protocol}//${window.location.hostname}/invoice/${orgId}/${invoiceId}`;

    return (
        <div onClick={() => showFilter && setShowFilter(false)}>
            <SubHeader title={firmDetails.name} />
            <div>
                <div className="d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-1%" : "-3%", flexDirection: "row" }}>
                    <Row className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
                        <Col sm={12} className="bg-white rounded">
                            <Row className="py-2">
                                <h5 className="text-primary" style={{ fontWeight: "550" }}> About </h5>
                            </Row>
                            <Row>
                                <Col sm={12} style={{ textAlign: "center", height: "100%" }}>
                                    {
                                        singleFirm.logo ? (
                                            <img
                                                src={singleFirm.logo} alt="firm" height="70rem" width="70rem" style={{ borderRadius: "50%" }} />
                                        ) : (
                                            <CgProfile className="text-secondary" style={{ height: "25%", width: "25%" }} />
                                        )
                                    }
                                </Col>
                                <Col className="my-2 text-dark text-center" style={{ fontWeight: "550", fontSize: "1rem", textTransform: "uppercase" }}>
                                    {singleFirm === undefined || singleFirm.name === undefined ? ' ' : singleFirm.name}
                                </Col>
                            </Row>
                            <Row className="py-1">
                                <Col md={3} sm={12}>
                                    <div style={{ fontWeight: "550" }}>Website: </div>
                                </Col>
                                <Col>{singleFirm === undefined || singleFirm.website === undefined ? ' ' : singleFirm.website}</Col>
                            </Row>
                            <Row className="py-1">
                                <Col md={3} sm={12}>
                                    <div style={{ fontWeight: "550" }}>GST: </div>
                                </Col>
                                <Col style={{ textTransform: "uppercase" }}>{singleFirm === undefined || singleFirm.gst_no === undefined ? ' ' : singleFirm.gst_no}</Col>
                            </Row>
                            <Row className="py-1">
                                <Col md={3} sm={12}>
                                    <div style={{ fontWeight: "550" }}>Phone: </div>
                                </Col>
                                <Col>{singleFirm === undefined || singleFirm.phone === undefined ? ' ' : singleFirm.phone}</Col>
                            </Row>
                            <Row className="py-1">
                                <Col md={3} sm={12}>
                                    <div style={{ fontWeight: "550" }}>Email: </div>
                                </Col>
                                <Col>{singleFirm === undefined || singleFirm.email === undefined ? ' ' : singleFirm.email}</Col>
                            </Row>
                            <Row className="py-1">
                                <Col md={3} sm={12}>
                                    <div style={{ fontWeight: "550" }}>Address: </div>
                                </Col>
                                <Col md={8} sm={12}>
                                    <div>{firmAdd === undefined || firmAdd.address1 === undefined ? ' ' : firmAdd.address1}</div>
                                    <div>{firmAdd === undefined || firmAdd.address2 === undefined ? ' ' : firmAdd.address2}</div>
                                    <span className='m-0 p-0'>{firmAdd === undefined || firmAdd.country === undefined ? ' ' : `${firmAdd.country}, `}</span>
                                    <span className='m-0 p-0'>{firmAdd === undefined || firmAdd.state === undefined ? ' ' : `${firmAdd.state}, `}</span>
                                    <span className='m-0 p-0'>{firmAdd === undefined || firmAdd.city === undefined ? ' ' : `${firmAdd.city}, `}</span>
                                    <span className='m-0 p-0'>{firmAdd === undefined || firmAdd.pinCode === undefined ? ' ' : `${firmAdd.pinCode} `}</span>
                                </Col>
                            </Row>
                        </Col>
                        {
                            contactPersonDetails && (
                                <Col sm={12} className="bg-white rounded my-2">
                                    <Row className="py-2">
                                        <h5 className="text-primary" style={{ fontWeight: "550" }}> Contact Person </h5>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Name: </div>
                                        </Col>
                                        <Col className='p-0'>{contactPersonDetails === undefined || contactPersonDetails.name === undefined ? ' ' : contactPersonDetails.name}</Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Phone:</div>
                                        </Col>
                                        <Col className='p-0'>{contactPersonDetails === undefined || contactPersonDetails.phone === undefined ? ' ' : contactPersonDetails.phone}</Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Alternative Phone: </div>
                                        </Col>
                                        <Col className='p-0'>{contactPersonDetails === undefined || contactPersonDetails.altPhone === undefined ? ' ' : contactPersonDetails.altPhone}</Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Mobile: </div>
                                        </Col>
                                        <Col className='p-0'>{contactPersonDetails === undefined || contactPersonDetails.mobile === undefined ? ' ' : contactPersonDetails.mobile}</Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Alternative Mobile: </div>
                                        </Col>
                                        <Col className='p-0'>{contactPersonDetails === undefined || contactPersonDetails.altMobile === undefined ? ' ' : contactPersonDetails.altMobile}</Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Address: </div>
                                        </Col>
                                        <Col className='p-0'>
                                            <p className="m-0 p-0">{contactPersonDetails === undefined || contactPersonDetails.address1 === undefined ? ' ' : contactPersonDetails.address1}</p>
                                            <p className="m-0 p-0">{contactPersonDetails === undefined || contactPersonDetails.address2 === undefined ? ' ' : contactPersonDetails.address2}</p>
                                            <p className="m-0 p-0">
                                                {
                                                    `${contactPersonDetails === undefined || contactPersonDetails.city === undefined ? ' ' : `${contactPersonDetails.city}, `} 
                                                    ${contactPersonDetails === undefined || contactPersonDetails.state === undefined ? ' ' : `${contactPersonDetails.state}, `} 
                                                    ${contactPersonDetails === undefined || contactPersonDetails.country === undefined ? ' ' : `${contactPersonDetails.country}, `} 
                                                    ${contactPersonDetails === undefined || contactPersonDetails.pinCode === undefined ? ' ' : `${contactPersonDetails.pinCode} `}`
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }
                    </Row>
                    <Row className={`h-100 rounded mx-2 mb-5`}
                        style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%" }}
                    >
                        <div className='m-0 p-0'>
                            <div className='m-0 mb-3 p-0'>
                                <Row className='w-100 m-0 p-0'>
                                    {
                                        summary.map((data, index) => (
                                            <Col className='w-25 bg-white mx-2 border border-white px-2 py-2 rounded summaryCard' key={index} style={{ boxShadow: '0px 0px 10px #c8c9ca' }}>
                                                <Row className='w-100 m-0 p-0'>
                                                    <Col sm={8}>
                                                        <div className='fs-3 text-dark'>{currency === '' ? "" : "₹"} {(data.amount).toFixed(2)}</div>
                                                        <div className='text-secondary fs-5' style={{ textTransform: "uppercase" }}>{data.title}</div>
                                                    </Col>
                                                    <Col sm={4} className='text-end'>
                                                        <span className='px-3 py-3 m-0 rounded rounded-circle text-primary fs-2 text-center'>
                                                            {data.logo}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className='w-100 m-0 mt-3 p-0'>
                                                    <Col sm={12}>
                                                        <span
                                                            className={`m-0 py-1 px-3 text-white rounded fw-semibold`}
                                                            style={{ background: data.title === "Due" ? '#FFB802' : data.title === "Invoices" ? 'blue' : 'white' }}
                                                        >{data.count}</span>
                                                        <span className='m-0 ms-2 p-0 text-secondary' style={{ fontSize: "0.8rem" }}>{data.desc}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                            <Row className='m-0 mt-3 p-0 rounded bg-white'>
                                <Card.Header className="bg-primary" style={{ width: "100%" }}>
                                    <Row className='w-100'>
                                        <Col sm={11} style={{ cursor: "pointer" }}>
                                            <h4 className='text-white'>
                                                {
                                                    showInvoices ? 'Taxes' : (showTaxes || showProducts || !showProducts) && 'Invoices'
                                                }
                                            </h4>
                                        </Col>
                                        {
                                            showInvoices ? (
                                                <Col sm={1} className='text-end'>
                                                    {
                                                        !showTaxes ? (
                                                            <BsChevronDown className='fs-4 fw-bold text-white' onClick={() => handleShowTaxes()} style={{ cursor: "pointer" }} />
                                                        ) : showTaxes && (
                                                            <BsChevronUp className='fs-4 fw-bold text-white' onClick={() => setShowTaxes(false)} style={{ cursor: "pointer" }} />
                                                        )
                                                    }
                                                </Col>
                                            ) : (showTaxes || showProducts || !showProducts) && (
                                                <Col sm={1} className='text-end'>
                                                    {
                                                        !showInvoices ? (
                                                            <BsChevronDown className='fs-4 fw-bold text-white' onClick={() => handleShowInvoices()} style={{ cursor: "pointer" }} />
                                                        ) : showInvoices && (
                                                            <BsChevronUp className='fs-4 fw-bold text-white' onClick={() => setShowInvoices(false)} style={{ cursor: "pointer" }} />
                                                        )
                                                    }
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Card.Header>
                            </Row>
                            <Row className='m-0 mt-3 p-0 rounded bg-white'>
                                <Card.Header className="bg-primary" style={{ width: "100%" }}>
                                    <Row className='w-100'>
                                        <Col sm={11} style={{ cursor: "pointer" }}>
                                            <h4 className='text-white'>
                                                {
                                                    (showTaxes || showInvoices) ? 'Products' : (showProducts || !showProducts) && 'Taxes'
                                                }
                                            </h4>
                                        </Col>
                                        {
                                            (showTaxes || showInvoices) ? (
                                                <Col sm={1} className='text-end'>
                                                    {
                                                        !showProducts ? (
                                                            <BsChevronDown className='fs-4 fw-bold text-white' onClick={() => handleShowProducts()} style={{ cursor: "pointer" }} />
                                                        ) : showProducts && (
                                                            <BsChevronUp className='fs-4 fw-bold text-white' onClick={() => setShowProducts(false)} style={{ cursor: "pointer" }} />
                                                        )
                                                    }
                                                </Col>
                                            ) : (showProducts || !showProducts) && (
                                                <Col sm={1} className='text-end'>
                                                    {
                                                        !showTaxes ? (
                                                            <BsChevronDown className='fs-4 fw-bold text-white' onClick={() => handleShowTaxes()} style={{ cursor: "pointer" }} />
                                                        ) : showTaxes && (
                                                            <BsChevronUp className='fs-4 fw-bold text-white' onClick={() => setShowTaxes(false)} style={{ cursor: "pointer" }} />
                                                        )
                                                    }
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Card.Header>
                            </Row>

                            {
                                showInvoices ? (
                                    <Row className='m-0 mt-3 p-0 rounded bg-white'>
                                        <Card.Header className="bg-primary" style={{ width: "100%" }}>
                                            <Row className="w-100">
                                                <Col sm={11} style={{ cursor: "pointer" }} onClick={() => handleShowInvoices()}>
                                                    <h4 className=" text-white">Invoices</h4>
                                                </Col>
                                                <Col sm={1} className="text-end">
                                                    {showInvoices === false ? (
                                                        <BsChevronDown className="fs-4 fw-bold text-white" onClick={() => handleShowInvoices()} style={{ cursor: "pointer" }} />
                                                    ) : (
                                                        <BsChevronUp className="fs-4 fw-bold text-white" onClick={() => setShowInvoices(false)} style={{ cursor: "pointer" }} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        {
                                            showInvoices && (
                                                <div>
                                                    <Row sm={12} className="bg-white text-primary pt-2 mb-3 text-center fs-4">
                                                        {mainfilters.map((data, index) => (
                                                            <Col
                                                                onClick={() => handleFilter(data)}
                                                                className={`${data === selected
                                                                    ? "border-primary text-primary"
                                                                    : "border-white text-dark"
                                                                    }`}
                                                                style={{ cursor: "pointer", borderBottom: "3px solid" }}
                                                                key={index}
                                                            >
                                                                {data}
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    <hr />
                                                    <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
                                                        <table className="" style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                                                                    {/* <th className="fw-bold" style={{ fontSize: "0.9rem" }}></th> */}
                                                                    <th className="fw-bold w-5" style={{ fontSize: "1rem" }}>Invoice No.</th>
                                                                    <th className="fw-bold w-25" style={{ fontSize: "1rem" }}>Firm Name</th>
                                                                    <th className="fw-bold w-25" style={{ fontSize: "1rem" }}>Client Name</th>
                                                                    <th className="fw-bold w-25" style={{ fontSize: "1rem" }}>Status</th>
                                                                    <th className="fw-bold w-20 text-end" style={{ fontSize: "1rem" }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    firmInvoices
                                                                        .slice(pagination.start, pagination.end)
                                                                        .map((data, index) => (
                                                                            (
                                                                                <tr key={index}>
                                                                                    <td className="w-5 py-3" style={{ width: "12%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                        <span className="ms-0 text-dark">{data.invoiceNumber}</span>
                                                                                    </td>
                                                                                    <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                        {`${data.firm === undefined || data.firm.name === undefined ? "" : data.firm.name}`}
                                                                                    </td>
                                                                                    {
                                                                                        data.client.clientFirmName ? (
                                                                                            <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                                {`${data.client === undefined || data.client.clientFirmName === undefined ? "" : data.client.clientFirmName} `}
                                                                                            </td>
                                                                                        ) : (
                                                                                            <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                                {`${data.client === undefined || data.client.firstName === undefined ? "" : data.client.firstName} `}
                                                                                                {`${data.client === undefined || data.client.lastName === undefined ? "" : data.client.lastName}`}
                                                                                            </td>
                                                                                        )
                                                                                    }
                                                                                    <td className="pt-3" style={{ borderBottom: "1px solid rgb(227 227 227)" }}>
                                                                                        <p className="text-white rounded-pill w-50 text-center"
                                                                                            style={styleHandle(data)}
                                                                                        >
                                                                                            {data.status === undefined ? "" : data.status}
                                                                                        </p>
                                                                                    </td>
                                                                                    <td className="" style={{ borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                        <div className="flex text-end list-user-action">
                                                                                            <Tooltip title="View" key="v">
                                                                                                <Link to={`/${orgName}/dashboard/invoice/invoice-details/${data._id}`}>
                                                                                                    <FiEye className="text-primary" style={{ fontSize: "1rem", cursor: "pointer" }} />
                                                                                                </Link>
                                                                                            </Tooltip>
                                                                                            <Tooltip title="Delete" key="delete">
                                                                                                <Link to={`#`}>
                                                                                                    <MdDeleteOutline onClick={() => handleDeleteInvoice(data._id)} className="text-danger mx-2" style={{ fontSize: "1rem", cursor: "pointer", }} />
                                                                                                </Link>
                                                                                            </Tooltip>
                                                                                            {
                                                                                                selected != "Draft" && selected != "Deleted" ? (
                                                                                                    <Tooltip title="Share">
                                                                                                        <Link to="#" onMouseOver={() => setInvoiceId(data._id)}>
                                                                                                            <RWebShare data={{ text: "Share Invoice", url: path, title: "invoice", }}>
                                                                                                                <BsFillShareFill className='text-primary'
                                                                                                                    style={{ fontSize: "0.9rem", cursor: "pointer" }}
                                                                                                                />
                                                                                                            </RWebShare>
                                                                                                        </Link>
                                                                                                    </Tooltip>
                                                                                                ) : null
                                                                                            }
                                                                                            {
                                                                                                selected === "Deleted" && (
                                                                                                    <Tooltip title="Restore Invoice" key="restore">
                                                                                                        <Link to={`#`}>
                                                                                                            <IoReloadOutline onClick={() => handleRestoreInvoice(data._id)} className="text-warning" style={{ fontSize: "1rem", cursor: "pointer", }} />
                                                                                                        </Link>
                                                                                                    </Tooltip>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {
                                                            firmInvoices.length === 0 && (
                                                                <div className='mt-5 fs-4 text-center text-secondary'>
                                                                    No Invoices To Show
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="my-5">
                                                        <Pagination
                                                            showPerPage={showPerPage}
                                                            onPaginationChange={onPaginationChange}
                                                            total={firmTaxes.length}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Row>
                                ) : showTaxes ? (
                                    <Row className='m-0 mt-3 rounded bg-white'>
                                        <Card.Header className="bg-primary" style={{ width: "100%" }}>
                                            <Row className="w-100">
                                                <Col sm={11} style={{ cursor: "pointer" }} onClick={() => handleShowTaxes()}>
                                                    <h4 className=" text-white">Taxes</h4>
                                                </Col>
                                                <Col sm={1} className="text-end">
                                                    {showTaxes === false ? (
                                                        <BsChevronDown className="fs-4 fw-bold text-white" onClick={() => handleShowTaxes()} style={{ cursor: "pointer" }} />
                                                    ) : (
                                                        <BsChevronUp className="fs-4 fw-bold text-white" onClick={() => setShowTaxes(false)} style={{ cursor: "pointer" }} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        {
                                            showTaxes && (
                                                <div style={{ minHeight: "50vh" }}>
                                                    <hr />
                                                    <div className="table-responsive mt-2" style={{ minHeight: "30vh" }}>
                                                        <table className="" style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                                                                    <th className="fw-bold w-5" style={{ fontSize: "0.9rem" }}>S.No.</th>
                                                                    <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Tax Name</th>
                                                                    <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Rate(%)</th>
                                                                    {/* <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>{`Tax Collected (${currency})`}</th> */}
                                                                    <th className="fw-bold w-25 text-center" style={{ fontSize: "0.9rem" }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    firmTaxes
                                                                        .slice(pagination.start, pagination.end)
                                                                        .map((data, index) => (
                                                                            (
                                                                                <tr key={index}>
                                                                                    <td className="w-5 py-3" style={{ width: "12%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                        <Tooltip title="Invoice Details">
                                                                                            <Link to={`#`}>
                                                                                                <span className="ms-0 text-dark">{index + 1}</span>
                                                                                            </Link>
                                                                                        </Tooltip>
                                                                                    </td>
                                                                                    <td className="py-3" style={{ borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                        {Object.keys(data)}
                                                                                    </td>
                                                                                    <td className="py-3" style={{ borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                        {Object.values(data)}
                                                                                    </td>
                                                                                    {/* <td className="py-3" style={{ borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                {Object.values(data)}
                                                                            </td> */}
                                                                                    <td className="" style={{ borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                        <div className="flex text-center list-user-action">
                                                                                            <Tooltip title="Edit" key="edit">
                                                                                                <Link to={`#`}>
                                                                                                    <HiOutlinePencilAlt className="text-success" style={{ fontSize: "1rem", cursor: "pointer" }} />
                                                                                                </Link>
                                                                                            </Tooltip>
                                                                                            <Tooltip title="Delete" key="delete">
                                                                                                <Link to={`#`}>
                                                                                                    <MdDeleteOutline className="text-danger mx-2" style={{ fontSize: "1rem", cursor: "pointer", }} />
                                                                                                </Link>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {
                                                            firmTaxes.length === 0 && (
                                                                <div className='mt-5 fs-4 text-center text-secondary'>
                                                                    No Taxes To Show
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="my-5">
                                                        <Pagination
                                                            showPerPage={showPerPage}
                                                            onPaginationChange={onPaginationChange}
                                                            total={firmTaxes.length}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Row>
                                ) : (
                                    <Row className='m-0 mt-3 p-0 rounded bg-white'>
                                        <Card.Header className="bg-primary" style={{ width: "100%" }}>
                                            <Row className="w-100">
                                                <Col sm={11} style={{ cursor: "pointer" }} onClick={() => handleShowProducts()}>
                                                    <h4 className=" text-white">Products</h4>
                                                </Col>
                                                <Col sm={1} className="text-end">
                                                    {showProducts === false ? (
                                                        <BsChevronDown className="fs-4 fw-bold text-white" onClick={() => handleShowProducts()} style={{ cursor: "pointer" }} />
                                                    ) : (
                                                        <BsChevronUp className="fs-4 fw-bold text-white" onClick={() => setShowProducts(false)} style={{ cursor: "pointer" }} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        {
                                            showProducts && (
                                                <div>
                                                    <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
                                                        <table className="w-100 mt-2" style={{ fontSize: "0.75rem" }}>
                                                            <thead className="bg-primary p-2 fs-5 text-white w-100" style={{ borderBottom: "1px solid #c8c9ca", borderRadius: "10px 0 10px 0" }}>
                                                                <th className="ps-2" style={{ width: "5%", borderRadius: "10px 0 0 0" }}>S.No.</th>
                                                                <th className="ps-2" style={{ width: "40%" }}>Item Name</th>
                                                                <th className='text-center' style={{ width: "10%" }}>HSN</th>
                                                                <th className='text-center' style={{ width: "10%" }}>SAC</th>
                                                                {/* <th className='text-center' style={{ width: "7%" }}>SKU</th> */}
                                                                <th className='text-Start ps-2' style={{ width: "15%" }}>Price</th>
                                                                <th className='text-center' style={{ width: "10%" }}>Tax</th>
                                                                {/* <th className="text-Start ps-2" style={{ width: "15%", borderRadius: "0px 10px 0 0" }}>Amount</th> */}
                                                                <th className="pe-2 text-end" style={{ borderRadius: "0 10px 0 0" }}>Actions</th>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    allProducts
                                                                        .slice(pagination.start, pagination.end)
                                                                        .map((data, index) => (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <td className="ps-2" style={{ width: "5%", }}>{index + 1}</td>
                                                                                    <td className="ps-2" style={{ width: "40%", }}>{data.name}</td>
                                                                                    <td className='text-center' style={{ width: "10%" }}>{data.hsn}</td>
                                                                                    <td className='text-center' style={{ width: "10%" }}>{data.sac}</td>
                                                                                    <td className='text-start ps-2' style={{ width: "5%" }}>{data.price}</td>
                                                                                    <td className='text-center' style={{ width: "7%" }}>{data.tax}</td>
                                                                                    <td>
                                                                                        <div className="flex text-end list-user-action">

                                                                                            <Tooltip title="Edit" key="edit">
                                                                                                <Link to='#'>
                                                                                                    <HiOutlinePencilAlt onClick={() => handleShowUpdateProduct(data._id)} className="text-success me-2" style={{ fontSize: "1rem", cursor: "pointer" }} />
                                                                                                </Link>
                                                                                            </Tooltip>

                                                                                            <Tooltip title="View" key="view">
                                                                                                <Link to='#'>
                                                                                                    <FiEye onClick={() => handleShowProduct(data._id)} className="text-primary" style={{ fontSize: "1rem", cursor: "pointer" }} />
                                                                                                </Link>
                                                                                            </Tooltip>

                                                                                            <Tooltip title='Delete' key="delete">
                                                                                                <Link to={`#`}>
                                                                                                    <MdDeleteOutline onClick={() => handleShowDelete(data._id)} className="text-danger mx-2" style={{ fontSize: "1rem", cursor: "pointer", }} />
                                                                                                </Link>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    </td>
                                                                                    {/* <td className='text-center' className="text-end pe-2">{data.quantity * data.unitPrice}</td> */}
                                                                                </tr>
                                                                                <tr className="" style={{ borderBottom: "1px solid #c8c9ca" }} key={index}>
                                                                                    <td syle={{ width: "5%" }}></td>
                                                                                    <td className="ps-2 pb-2 pe-4 w-25" style={{ width: "200px", maxWidth: "200px" }}>
                                                                                        {data.desc}
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {
                                                            allProducts.length === 0 && (
                                                                <div className='mt-5 fs-4 text-center text-secondary'>
                                                                    No Products To Show
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="my-5">
                                                        <Pagination
                                                            showPerPage={showPerPage}
                                                            onPaginationChange={onPaginationChange}
                                                            total={allProducts.length}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Row>
                                )
                            }
                        </div>
                    </Row>
                    <ActivityBar options={firmOptions} allRecentActivities={allRecentActivities} />
                </div>
                <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
                    <Modal.Body className="my-2">
                        <Row className="mb-2 ms-1">
                            <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Firm</Col>
                            <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">You want to delete the firm ?</Col>
                        </Row>
                        <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                            <div
                                className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                                onClick={() => handleDelete(singleFirm._id)}
                                style={{ borderRadius: "4px", cursor: "pointer" }}
                            >
                                Yes
                            </div>
                            <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                                onClick={() => setShowDelete(false)}
                                style={{ borderRadius: "5px", cursor: "pointer" }}
                            >
                                No
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal centered show={showDeleteProduct} onHide={() => setShowDeleteProduct(false)}>
                    <Modal.Body className="my-2">
                        <Row className="mb-2 ms-1">
                            <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Product</Col>
                            <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">You want to delete the product ?</Col>
                        </Row>
                        <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                            <div
                                className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                                onClick={() => handleDeleteProduct()}
                                // onClick={() => handleDelete(productId)}
                                style={{ borderRadius: "4px", cursor: "pointer" }}
                            >
                                Yes
                            </div>
                            <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                                onClick={() => setShowDeleteProduct(false)}
                                style={{ borderRadius: "5px", cursor: "pointer" }}
                            >
                                No
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <CreateProducts getProducts={getProducts} allFirms={allFirms} showCreateProducts={showCreateProducts} setShowCreateProducts={setShowCreateProducts} firmTaxes={firmTaxes} firmId={id} getAllRecentActivities={getAllRecentActivities} />
                <ProductDetails getProducts={getProducts} showProductDetails={showProductDetails} setShowProductDetails={setShowProductDetails} firmId={firmId}
                    showUpdateProduct={showUpdateProduct} setShowUpdateProduct={setShowUpdateProduct} firmTaxes={firmTaxes} productId={productId} />
                <DeleteInvoice showDelete={showDeleteInvoice} setShowDelete={setShowDeleteInvoice} id={deleteId} selected={selected} getAllInvoices={getFirmInvoices} setDetails={setFirmInvoices} />
                <AddNewTaxes showNewTaxes={showNewTax} setShowNewTaxes={setShowNewTax} allTaxes={allTaxes} />
                {/* <About title="About Client" showAbout={showAbout} setShowAbout={setShowAbout} phone={arr.phone} email={arr.email} name={`${arr.firstName} ${arr.lastName}`} address={clientInfo} /> */}
            </div>
            {loader}
        </div>
    )
}

export default AboutFirm;
