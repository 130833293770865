import React, { useState, useContext } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
// import {Store} from 'react-icons/'
import { MdSell, MdSpaceDashboard } from "react-icons/md";
import {
  FaUser,
  FaFileInvoice,
  FaStoreAlt,
  FaInvision,
  FaMoneyBillAlt,
  FaShoppingCart,
} from "react-icons/fa";
import { HiOutlineLogout, HiReceiptTax } from 'react-icons/hi';
import { GoChecklist } from "react-icons/go";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

// import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "@material-ui/core";
// import {DashboardIcon} from '@mui/icons-material';
// import {PeopleIcon} from '@mui/icons-material/People';
// import {StoreIcon} from '@mui/icons-material/Store';
// import {DescriptionIcon} from '@mui/icons-material/Description';
import {
  PeopleAlt,
  Description,
  Dashboard,
  Store,
  Leaderboard,
  AccountCircle,
  Schema,
  Settings,
  LocationSearching,
  VerifiedUserOutlined,
  CorporateFare,
} from "@mui/icons-material";
// import { toast } from "react-hot-toast";
import { ErrorToast } from "../../../../views/dashboard/components/toast";
// import { ToastContainer } from "react-toastify";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  let location = useLocation();
  const orgID = localStorage.getItem('orgID');
  const userId = localStorage.getItem('userId');
  const orgName = localStorage.getItem("orgName");

  const history = useHistory();

  const handleLogOut = () => {
    // setTimeout(() => {
    ErrorToast("You have been logged out ! ! ! !");
    // }, 500);
    setTimeout(() => {
      localStorage.clear();
      history.push('/');
    }, 1000)
  };

  const sideNavItems = [
    {
      title: "Dashboard",
      classPath: `dashboard`,
      path: `dashboard`,
      icon: <svg width="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.4"
          d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
          fill="currentColor"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
          fill="currentColor"
        ></path>
      </svg>
    },
    {
      title: "Invoices",
      classPath: `dashboard/invoice/`,
      path: `dashboard/invoice/invoice-list`,
      icon: <Description className="fs-4" />
    },
    {
      title: "Firms",
      classPath: `dashboard/firm/`,
      path: `dashboard/firm/firm-list`,
      icon: <Store width="15" className="fs-4" />
    },
    {
      title: "Clients",
      classPath: `dashboard/client/`,
      path: `dashboard/client/client-list`,
      icon: <PeopleAlt className="fs-4" />
    },
    {
      title: "Leads",
      classPath: `dashboard/lead/`,
      path: `dashboard/lead/lead-list`,
      icon: <Leaderboard className="fs-4" />
    },
    {
      title: "Tax",
      classPath: `dashboard/tax/`,
      path: `dashboard/tax/add-tax`,
      icon: <HiReceiptTax className="fs-4" />
    },
    {
      title: "Vendors",
      classPath: `dashboard/vendor/`,
      path: `dashboard/vendor/all-vendors`,
      icon: <MdSell className="fs-4" />
    },
    {
      title: "Purchase",
      classPath: `dashboard/purchase/`,
      path: `dashboard/purchase/all-purchase`,
      icon: <FaShoppingCart className="fs-4" />
    },
    {
      title: "Users",
      classPath: `user`,
      path: `dashboard/user/all-users`,
      icon: <AccountCircle className="fs-4" />
    },
  ];

  const accordionItems = [
    {
      title: "Settings",
      eventKey: "settings",
      icon: <Settings className="fs-4" />,
      collapse: [
        {
          title: 'Organization',
          path: `settings/organization/update-org/${orgID}`,
          icon: <CorporateFare className="fs-4" />
        },
        {
          title: 'Roles & permissions',
          path: `settings/roles/roles`,
          icon: <CorporateFare className="fs-4" />
        }
      ]
    },
  ];

  return (
    <>
      <div className="m-0 p-0 d-flex justify-content-between flex-column"
        style={{ height: "87vh", overflowY: "scroll", overflowX: "hidden" }}
      >
        <Accordion as="ul" className="w-100 navbar-nav iq-main-menu">
          {
            sideNavItems.map((data, index) => (
              <li className="rounded nav-item" key={index}>
                <Link
                  className={`${location.pathname === `/${orgName}/${data.classPath}` ? "active" : ""} nav-link rounded`}
                  aria-current="page" to={`/${orgName}/${data.path}`}
                >
                  <i className="icon">{data.icon}</i>
                  <span className="item-name">{data.title}</span>
                </Link>
              </li>
            ))
          }

          {
            accordionItems.map((data, index) => (
              <Accordion.Item as="li" eventKey={data.eventKey} bsPrefix="nav-item" key={index}>
                <CustomToggle eventKey={data.eventKey} onClick={(activeKey) => setActiveMenu(activeKey)}>
                  <i className="icon">{data.icon}</i>
                  <span className="item-name">{data.title}</span>
                  <i className="right-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </i>
                </CustomToggle>
                <Accordion.Collapse eventKey={data.eventKey}>
                  <ul className="sub-nav">
                    {
                      data.collapse.map((item, i) => (
                        <li className="nav-item" key={i}>
                          <Link
                            className={`${location.pathname === `/${orgName}/dashboard/${item.path}` ? "active" : ""} nav-link rounded`}
                            to={`/${orgName}/dashboard/${item.path}`}
                          >
                            <i className="icons">{item.icon}</i>
                            <span className="item-name"> {item.title} </span>
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </Accordion.Collapse>
              </Accordion.Item>
            ))
          }
        </Accordion>
      </div>
    </>
  );
};

export default VerticalNav;
