import axios from "axios";
import { API } from "../../../../backend";

export const signInUser = async (form) => {
    const url = `${API}/auth/signin`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios(config);
    return response;
};

export const updateUser = async (form, id) => {
    const url = `${API}/auth/updateUser/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios(config);
    return response;
};

