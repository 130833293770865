import axios from "axios";
import { API } from "../../../../backend"

const orgId = localStorage.getItem('orgID');

export const adduser = async (data) => {
    let url = `${API}/auth/signup`;
    let config = {
        method: "POST",
        url: url,
        data: data,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };
    try {
        const res = await axios(config);
        if (res.status === 201) {
            console.log("User added succssfully");
            return res.data;
        }
        else {
            console.log("Something went wrong");
        }
    }
    catch (err) {
        return err.message
    }
}


export const createUser = async (data) => {
    const url = `${API}/auth/signup`;
    const config = {
        method: "POST",
        url: url,
        data: data,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios(config);
    return response;
}

export const updateUser = async (form, id) => {
    let url = `${API}/auth/updateUser/${id}`;
    let config = {
        method: "PATCH",
        url: url,
        body: JSON.stringify(form),
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };
    try {
        const res = await axios(config);
        if (res.status === 201) {
            console.log("User added succssfully");
            return res.data;
        }
        else {
            console.log("Something went wrong");
        }
    }
    catch (err) {
        return err.message
    }
}

export const userById = async (setSingleUser, id) => {
    await axios.get(`${API}/auth/${id}`, {
        headers: {
            "Contetn-Type": "application/json",
        }
    })
        .then((response) => setSingleUser(response.data.data))
        .catch((err) => console.log(err.message))
}


export const getUsersByDept = async (department) => {
    const url = `${API}/auth/getUsersByDept`;
    const config = {
        method: 'POST',
        url: url,
        data: {
            orgId: orgId,
            department: department
        },
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const response = await axios(config);
    return response;
}

export const inviteUser = async (form) => {
    const url = `${API}/auth/invitation`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "Contetn-Type": "application/json",
        },
    };

    const response = await axios(config);
    return response;
}


export const getUserById = async (id) => {
    const url = `${API}/auth/getUser/${id}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json"
        }
    };

    const response = await axios(config);
    return response;
}

export const updateUserProfileImage = async (formData, id) => {
    const url = `${API}/auth/updateProfilephoto/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: formData,
        headers: {
            "Content-Type": "application/json"
        }
    };

    const response = await axios(config);
    return response;
}

export const getAllUsers = async () => {
    const url = `${API}/auth/getAllusers/${orgId}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            'Content-Type': "application/json",
        },
    }
    const response = await axios(config);
    return response;
}