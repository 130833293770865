import zIndex from "@mui/material/styles/zIndex";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useBootstrapBreakpoints } from "react-bootstrap/esm/ThemeProvider";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import "./activity.css";

const Activity = ({ title, showActivity, setShowActivity }) => {
  const activities = [
    {
      id: 1,
      name: "Activity 1",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 2,
      name: "Activity 2",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 3,
      name: "Activity 3",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 4,
      name: "Activity 4",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 5,
      name: "Activity 5",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 6,
      name: "Activity 6",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 7,
      name: "Activity 8",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 8,
      name: "Activity 8",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 9,
      name: "Activity 9",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 10,
      name: "Activity 10",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 11,
      name: "Activity 11",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 12,
      name: "Activity 12",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 13,
      name: "Activity 13",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
    {
      id: 14,
      name: "Activity 14",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias, rerum!",
    },
  ];

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 970;

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  })

  return (
    <div className="m-0 p-0" style={{position: 'relative'}}>
      <Col sm={12} className="mb-3 bg-white rounded"
        style={{ position: "sticky", top: 0, boxShadow: "3px 3px 10px #00000030", width: screenWidth < breakPoint && "75%", display: screenWidth < breakPoint && "none"}}
      >
        <Row className="p-3">
          <Col sm={9} onClick={() => setShowActivity(!showActivity)}>
            <h5 className="text-primary" style={{ fontSize: "1", fontWeight: "550" }}>{title}</h5>
          </Col>
          <Col sm={1}>
            {showActivity === false ? (
              <BsChevronDown className="fs-4 fw-bold" style={{ cursor: "pointer" }} onClick={() => setShowActivity(true)}/>
            ) : (
              <BsChevronUp className="fs-4 fw-bold" style={{ cursor: "pointer" }} onClick={() => setShowActivity(false)}/>
            )}
          </Col>
        </Row>
      </Col>
      {activities.map(
        (data, index) =>
          showActivity === true && (
            <>
              <Col sm={12} className={`${screenWidth >= breakPoint ? 'bg-white' : 'bg-soft-primary'} mb-2 rounded text-dark px-2`}
                style={{ width: screenWidth >= breakPoint ? "100%" : "75%", padding: screenWidth >= breakPoint ? "auto" : "1rem", boxShadow: "3px 3px 10px #00000030"}}
                key={data.id}
              >
                <Row className="py-2">
                  <h5 className="text-primary" style={{ fontWeight: "550" }}>{data.name}</h5>
                </Row>
                <Row>
                  <div className="mb-2">{data.desc}</div>
                </Row>
              </Col>
              <div
                style={{ marginTop: "-2.5%", marginLeft: screenWidth >= breakPoint ? "50%" : "40%", borderLeft: "3px solid rgb(250 65 65)", height: "25px", display: activities.length - 1 === index && "none"}}
              ></div>
            </>
          )
      )}
    </div>
  );
};

export default Activity;
