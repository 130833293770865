import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import SettingsNav from "./Settignsnav/settignsNav";
import VerticalNav from "./vertical-nav";
import cubicleweb from '../../../../assets/images/Cubicleweb2.png';
import cubicleFullLogo from '../../../../assets/images/cubicleFullLogo.png';

const SidebarSmall = () => {
  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  const screenSize = window.innerWidth;

  const orgName = localStorage.getItem('orgName');

  return (
    <>
      <aside className="sidebar sidebar-mini sidebar-default sidebar-hover">
        <div className="sidebar-header d-flex align-items-center justify-content-start">
          <Link className="navbar-brand m-0 p-0">
            <img src={cubicleweb} alt="logo" width={55} height={55} className="main-logo m-0 p-0" />
            <img src={cubicleFullLogo} alt="logo" width={120} height={45} className="logo-title m-0 mt-2 p-0" />
          </Link>
          {screenSize < 1200 && (
            <div
              data-toggle="sidebar"
              data-active="true"
              onClick={minisidebar}
            >
              <i className="icon">
                <AiOutlineArrowLeft className="fs-2 sidebar-toggle text-white" />
              </i>
            </div>
          )}
        </div>
        <div
          className="pt-0 sidebar-body data-scrollbar"
          data-scroll="1"
          id="my-scrollbar"
        >
          {/* sidebar-list class to be added after replace css */}
          <div className="ms-2 sidebar-list navbar-collapse" id="sidebar">
            <VerticalNav />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </>
  );
};

export default SidebarSmall;
