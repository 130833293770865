import axios from "axios";
import { config } from "react-transition-group";
import { API } from "../../../../backend";

const getAuthToken = async () => {
  // console.log(process.env.API_TOKEN, "process.env.API_TOKEN");
  const res = await axios.get(
    "https://www.universal-tutorial.com/api/getaccesstoken",
    {
      mode: "cors",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "access-control-allow-origin": "*",
        "api-token":
          "U1ZTNCkp7PvuRY38O7WQwv5zoZHuqYQc8CfATo4v8CpE8y3M2ZcxCfSDG6o3XY_jwg0",
        "user-email": "adityaditya711@gmail.com",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );

  return res.data.auth_token;
};
export const getCountries = async () => {
  const token = await getAuthToken();
  // console.log(token, "token");

  const config = {
    method: "get",
    url: "https://www.universal-tutorial.com/api/countries",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios(config);

  //console.log(res.data, "res.data");
  return res.data;
};

export const getState = async (country) => {
  const token = await getAuthToken();

  const config = {
    method: "get",
    mode: "cors",
    url: `https://www.universal-tutorial.com/api/states/${country}`,
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios(config);

  // console.log(res.data, "res.data states");

  return res.data;
};

export const getCity = async (state) => {
  const token = await getAuthToken();

  const config = {
    method: "get",
    url: `https://www.universal-tutorial.com/api/cities/${state}`,
    mode: "cors",
    headers: {
      Accept: "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios(config);

  // console.log(res.data, "res.data cities");

  return res.data;
};

export const updateOrganization = async (data, id) => {
  let url = `https://crm-backend-xb27.onrender.com/api/org/update/${id}`;
  const config = {
    method: "patch",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios(config);
    if(res.status === 201){
      return res.data;
    }
  } catch (error) {
    return error.message;
  }
};
