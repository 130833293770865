import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { addLeadActivity } from "../../../Hook/leadHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../../../components/toast";

const NoteForm = ({ leadId, leadActivitiesByType }) => {
  const [form, setForm] = useState({});
  const [note, setNote] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);

  const orgId = localStorage.getItem('orgID');

  const addActivity = async (e) => {
    e.preventDefault();
    setDisabledBtn(true);
    addLeadActivity(form).then((response) => {
      if (response.status === 201) {
        SuccessToast('Activity Added');
        setNote('');
        setDisabledBtn(false);
        leadActivitiesByType('Note');
      } else {
        ErrorToast("Unable to add activity");
        setDisabledBtn(false);
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong')
      setDisabledBtn(false);
    })
  };

  const handleChange = (e) => {
    setNote(e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      type: "Note",
      leadId: leadId,
      orgId: orgId
    });
  };

  return (
    <>
      <Row>
        <form className="my-3" onSubmit={addActivity} style={{ width: "98%" }}>
          <Col sm={12} className="mt-2">
            <textarea rows="5" placeholder="Add a note" className="w-100 py-1 border border-primary rounded" id="leadFormInput" name="desc"
              value={note} onChange={handleChange} required
            ></textarea>
          </Col>
          <Col sm={12} className="mt-2 d-flex justify-content-end align-items-end">
            <div className="m-0 p-0" style={{ cursor: disabledBtn ? 'not-allowed' : 'pointer' }}>
              <Button type="submit" variant={disabledBtn ? 'secondary' : 'primary'} className="ms-0 text-white fs-5 px-4" disabled={disabledBtn ? true : false} style={{ padding: '3px' }}>Add Note</Button>
            </div>
          </Col>
        </form>
      </Row>
    </>
  );
};

export default NoteForm;
