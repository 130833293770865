import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import LeadSubheader from "../../../../components/partials/dashboard/HeaderStyle/lead-subheader";
import LeadActivities from "./leadActivities";
import { getActivitiesByType, getAllActivities, getLeadById, updateLead } from "../Hook/leadHooks";
import Loader from "../../../../components/Loader";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ActivityBar from "../../../../components/partials/components/activitybar";
import { MdClear, MdDeleteOutline } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import ActivityForm from "./lead-main/lead-forms/activity-form";
import NoteForm from "./lead-main/lead-forms/note-form";
import EmailForm from "./lead-main/lead-forms/email-form";
import AttachForm from "./lead-main/lead-forms/attachment-form";
import ReminderForm from "./lead-main/lead-forms/reminder-form";
import { getOrgDetails } from "../Hook/orgHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { HiOutlinePencilAlt } from "react-icons/hi";

const LeadDetails = () => {
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [status, setStatus] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showDrop, setShowDrop] = useState(false);
  const [showStagesDropdown, setShowStagesDropdown] = useState(false);
  const [load, setLoad] = useState(true);
  const [selectForm, setSelectForm] = useState("activity");
  const [allActivities, setAllActivities] = useState([]);
  const [type, setType] = useState('All');

  const [showUpdateStage, setShowUpdateStage] = useState(false);
  const [showChangePipeline, setShowChangePipeline] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [assignedTo, setAssignedTo] = useState("");
  const [isOpenAssigneeList, setIsOpenAssigneeList] = useState(false);
  const [transferForm, setTransferForm] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  const [activeStep, setActiveStep] = useState(1);
  const [allPipelines, setAllPipelines] = useState([]);
  const [allStages, setAllStages] = useState([]);
  const [pipeline, setPipeline] = useState('');
  const [showPipelineDropdown, setShowPipelineDropdown] = useState(false);

  const breakPoint = 970;
  const history = useHistory();
  const orgId = localStorage.getItem('orgID');

  const allAssignees = [
    {
      id: 1,
      name: 'Name 1',
    },
    {
      id: 2,
      name: 'Name 2',
    },
    {
      id: 3,
      name: 'Name 3',
    },
    {
      id: 4,
      name: 'Name 4',
    },
    {
      id: 5,
      name: 'Name 5',
    },
  ];

  // const allPipelines = ['Pipeline 1', 'Pipeline 2', 'Pipeline 3', 'Pipeline 4', 'Pipeline 5'];

  const orgName = localStorage.getItem("orgName");

  useEffect(() => {
    getLeadById(id).then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setLoad(false);
          setDetails(response.data.data);
          setStatus(response.data.data.status);
          setPipeline(response.data.data.pipeline === undefined || response.data.data.pipeline.department === undefined ? '' : response.data.data.pipeline.department)
        }, 500);
      }
    }).catch((err) => err?.response?.data?.message && WarningToast(err?.response?.data?.message));
  }, [id]);

  useEffect(() => {
    getAllActivities(id).then((response) => {
      if (response.status === 200) {
        setAllActivities(response.data.data);
      }
    }).catch((err) => {
      err?.response?.data?.message && WarningToast(err?.response?.data?.message);
      setAllActivities([]);
    })
  }, [id]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getLeadDetails = () => {
    getLeadById(id).then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setLoad(false);
          setDetails(response.data.data);
          setStatus(response.data.data.status);
          setPipeline(response.data.data.pipeline === undefined || response.data.data.pipeline.department === undefined ? '' : response.data.data.pipeline.department)
        }, 500);
      }
    }).catch((err) => err?.response?.data?.message && WarningToast(err?.response?.data?.message));
  };

  const leadDetails = [
    {
      title: 'Title',
      about: details === undefined || details.title === undefined ? '' : details.title,
    },
    // {
    //   title: 'Assigned To',
    //   about: details === undefined || details.pipeline === undefined ? '' : details.pipeline.assignTo,
    // },
    {
      title: 'Closure Date',
      about: details === undefined || details.closureDate === undefined ? '' : details.closureDate,
    },
    {
      title: "Created Date",
      about: details === undefined || details.createdDate === undefined ? '' : details.createdDate
    },
    {
      title: "Stage",
      about: details === undefined || details.stage === undefined ? '' : details.stage
    },
    {
      title: "Estimated Worth",
      about: details === undefined || details.estimatedWorth === undefined ? '' : details.estimatedWorth
    }
  ];

  const clientDetails = [
    {
      title: "Name",
      about: `${details === undefined || details.clientFName === undefined ? '' : details.clientFName} ${details === undefined || details.clientLName === undefined ? '' : details.clientLName}`
    },
    {
      title: "Email",
      about: details === undefined || details.clientEmail === undefined ? '' : details.clientEmail
    },
    {
      title: "Phone",
      about: details === undefined || details.clientPhone === undefined ? '' : details.clientPhone
    }
  ];

  const orgDetails = [
    {
      title: "Name",
      about: details === undefined || details.orgDetails === undefined || details.orgDetails.orgName === undefined ? '' : details.orgDetails.orgName
    },
    {
      title: "Email",
      about: details === undefined || details.orgDetails === undefined || details.orgDetails.orgEmail === undefined ? '' : details.orgDetails.orgEmail
    },
    {
      title: "Phone",
      about: details === undefined || details.orgDetails === undefined || details.orgDetails.orgPhone === undefined ? '' : details.orgDetails.orgPhone
    },
  ];


  useEffect(() => {
    getOrgDetails().then((response) => {
      if (response.status === 200) {
        const allData = response.data.data;

        const testStatges = allData.orgLeadStages;
        let testStagesVar = [];
        testStagesVar = testStatges.filter((data, index) => data.length > 0);
        setAllStages(...allStages, testStagesVar);

        const testPipelines = allData.orgDept;
        let testPipelinesVar = [];
        testPipelinesVar = testPipelines.filter((data, index) => data.length > 0);
        setAllPipelines(...allPipelines, testPipelinesVar);

        // setAllStatuses(response.data.data.orgLeadStatus);
      }
    })
  }, []);

  const getLeadActivites = () => {
    getAllActivities(id).then((response) => {
      if (response.status === 200) {
        setAllActivities(response.data.data);
      }
    }).catch((err) => {
      err?.response?.data?.message && WarningToast(err?.response?.data?.message);
      setAllActivities([]);
    })
  };

  const leadActivitiesByType = (type) => {
    setType(type);
    getActivitiesByType(type, id).then((response) => {
      if (response.status === 200) {
        setAllActivities(response.data.data);
        if (type === 'All') {
          getLeadActivites();
        }
      }
    }).catch((err) => err?.response?.data?.message && WarningToast(err?.response?.data?.message));
  };

  const updateStatus = async (updatedStatus) => {
    const form = { status: updatedStatus };
    updateLead(id, form).then((response) => {
      if (response.status === 200) {
        SuccessToast('Lead status updated successfully');
        getLeadDetails();
      } else {
        ErrorToast('Something went wrong');
      }
    }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'))
  };

  const updateStage = (newStage) => {
    if (newStage === details.stage) {
      ErrorToast("Stage Already Been Intialized");
    } else {
      const form = { orgId: orgId, stage: newStage }
      updateLead(id, form).then((response) => {
        if (response.status === 200) {
          SuccessToast('Stage updated successfully');
          getLeadDetails();
        } else {
          ErrorToast('Unable to update stage');
        }
      }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'))
    }
  };

  const changePipeline = (newPipeline) => {
    if (newPipeline === pipeline) {
      ErrorToast('Pipeline already been initialized');
    } else {
      const form = {
        orgId: orgId,
        pipeline: { department: newPipeline }
      }
      updateLead(id, form).then((response) => {
        if (response.status === 200) {
          SuccessToast('Pipeline updated successfully');
          setShowPipelineDropdown(false);
          getLeadDetails();
        } else {
          ErrorToast('Unable to update pipeline');
        }
      }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'))
    }
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleInputChangeAssignee = (e) => {
    setAssignedTo(e.target.value);

    setIsOpenAssigneeList(true);
    setTransferForm({
      ...transferForm,
      assignTo: e.target.value
    })
  };

  const handleOptionClickAssignee = (i) => {
    setAssignedTo(i);
    setTransferForm({
      ...transferForm,
      assignTo: i
    })
    setIsOpenAssigneeList(false);
  };

  const filteredAssigneeOptions = allAssignees.filter((option) =>
    (option.name).toLowerCase().includes(assignedTo.toLowerCase())
  );

  const submitTransferLead = (e) => {
    e.preventDefault();
    const form = { pipeline: { assignTo: assignedTo } };
    updateLead(id, form).then((response) => {
      if (response.status === 200) {
        SuccessToast('Lead transferred successfully');
        setAssignedTo('');
        setIsOpenAssigneeList(false);
        setShowTransfer(false);
        getLeadDetails();
      } else {
        ErrorToast('Unable to transfer lead');
      }
    }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'))
  };

  const handleDeleteLead = () => {
    const form = { delete: true };
    updateLead(id, form).then((response) => {
      if (response.status === 200) {
        SuccessToast('Lead Deleted Successfully');
        setShowDelete(false);
        setTimeout(() => {
          history.push(`/${orgName}/dashboard/lead/lead-list`);
        }, 1000);
      } else {
        ErrorToast('Unable to delete the lead');
      }
    }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'))
  }

  const leadOptions = () => {
    return (
      <div style={{ fontSize: "0.8rem" }} onClick={() => showDrop && setShowDrop(false)}>
        <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={12}>Update Status: </Col>
          <Col>
            <div className="ms-2" style={{ overflow: "hidden" }}>
              <div className="row">
                <Col sm={2}>
                  <div
                    className="text-center text-dark rounded-circle"
                    style={{ width: "1.5rem", height: "1.5rem", background: status === "Won" ? "#10813B" : "#fff", border: '1px solid #10813B', borderRadius: '5px', cursor: status === "Won" ? "not-allowed" : "pointer", }}
                    onClick={() => status !== "Won" ? updateStatus("Won") : ErrorToast("Status Already been initialized")}
                  >
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    className="text-center text-dark rounded-circle"
                    style={{ width: "1.5rem", height: "1.5rem", background: status === "Hold" ? "#FFE600" : "#fff", border: '1px solid #FFE600', cursor: status === "Hold" ? "not-allowed" : "pointer", }}
                    onClick={() => status !== "Hold" ? updateStatus("Hold") : ErrorToast("Status Already been initialized")}
                  >
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    className="text-center text-dark rounded-circle"
                    style={{ width: "1.5rem", height: "1.5rem", background: status === "Lost" ? "#A70200" : "#fff", border: '1px solid #A70200', cursor: status === "Lost" ? "not-allowed" : "pointer", }}
                    onClick={() => status !== "Lost" ? updateStatus("Lost") : ErrorToast("Status Already been initialized")}
                  >
                  </div>
                </Col>
              </div>
              <div className="row">
                <Col sm={2}>
                  <div className="fw-semibold" style={{ fontSize: "0.7rem" }}>Won</div>
                </Col>
                <Col sm={2}>
                  <div className="fw-semibold" style={{ fontSize: "0.7rem" }}> Hold</div>
                </Col>
                <Col sm={2}>
                  <div className="fw-semibold" style={{ fontSize: "0.7rem" }}> Lost</div>
                </Col>
              </div>
            </div >
          </Col >
        </Row >

        <Link to={`/${orgName}/dashboard/lead/update-lead/${id}`}>
          <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
            <Col sm={9}>Update Lead</Col>
            <Col sm={1} className="text-end">
              <Tooltip title="Update Lead">
                <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                  <HiOutlinePencilAlt className="fs-3" style={{ fontSize: "1rem", cursor: "pointer" }} />
                </Link>
              </Tooltip>
            </Col>
          </Row>
        </Link>

        {/* <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" onClick={() => setShowTransfer(true)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={9}>Transfer Lead</Col>
          <Col sm={1} className="text-end">
            <Tooltip title="Transfer Lead">
              <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                <BiTransfer className="fs-3" style={{ fontSize: "1rem", cursor: "pointer" }} />
              </Link>
            </Tooltip>
          </Col>
        </Row> */}

        <Row className="mx-3 my-2">
          <Col sm={12} className="bg-primary text-white ps-4 m-0 p-0 py-2" onClick={() => setShowUpdateStage(!showUpdateStage)} style={{ cursor: "pointer", borderRadius: "5px" }}>
            <div className="m-0 p-0 row">
              <Col sm={9} className="px-0">Update Stage</Col>
              <Col sm={1}>
                {
                  showUpdateStage ? (
                    <BsChevronUp className="text-white fs-4" onClick={() => setShowUpdateStage(true)} />
                  ) : (
                    <BsChevronDown className="text-white fs-4" onClick={() => setShowUpdateStage(false)} />
                  )
                }
              </Col>
            </div>
          </Col>
          {
            showUpdateStage ? (
              <Col className="m-0 p-0" style={{ position: "relative" }}>
                <div className="mt-2 p-2 fs-6 text-dark border border-primary rounded" onClick={() => setShowStagesDropdown(true)} style={{ cursor: "pointer" }}>
                  <Row className="ps-3 pe-4">
                    <Col sm={9}>{details.stage}</Col>
                    <Col sm={2} className="text-end m-0 p-0 text-dark">
                      {
                        showStagesDropdown ? (
                          <BsChevronUp className="fs-5 text-end text-dark" style={{ cursor: "pointer" }} />
                        ) : (
                          <BsChevronDown className="fs-5 text-end text-dark" style={{ cursor: "pointer" }} />
                        )
                      }
                    </Col>
                  </Row>
                </div>
                {
                  showStagesDropdown ? (
                    <ul className="mt-1 py-2 px-0 bg-white rounded" style={{ zIndex: 9, width: "90%", position: "absolute", listStyle: 'none', border: "1px solid #c8c9ca", boxShadow: '0px 0px 10px #c8c9ca' }}>
                      {
                        allStages.map((data, index) => (
                          <li key={index} className={`${data === details.stage ? 'bg-primary text-white' : null} py-1 px-2 fs-6`} onClick={() => updateStage(data)}
                            style={{ cursor: data === details.stage ? 'not-allowed' : 'pointer', display: data === "" && 'none' }}
                          >
                            {data}
                          </li>
                        ))
                      }
                    </ul>
                  ) : null
                }
              </Col>
            ) : null
          }
        </Row>

        <Row className="mx-3 my-2">
          <Col sm={12} className="bg-primary text-white ps-4 m-0 p-0 py-2" onClick={() => setShowChangePipeline(!showChangePipeline)} style={{ cursor: "pointer", borderRadius: "5px" }}>
            <div className="m-0 p-0 row">
              <Col sm={9} className="px-0">Change Pipeline</Col>
              <Col sm={1}>
                {
                  showChangePipeline ? (
                    <BsChevronUp className="text-white fs-4" onClick={() => setShowChangePipeline(true)} />
                  ) : (
                    <BsChevronDown className="text-white fs-4" onClick={() => setShowChangePipeline(false)} />
                  )
                }
              </Col>
            </div>
          </Col>
          {
            showChangePipeline ? (
              <Col className="m-0 p-0" style={{ position: "relative" }}>
                <div className="mt-2 p-2 fs-6 text-dark border border-primary rounded" onClick={() => setShowPipelineDropdown(true)} style={{ cursor: "pointer" }}>
                  <Row className="ps-3 pe-4">
                    <Col sm={9}>{pipeline === "" ? 'Select Pipeline' : pipeline}</Col>
                    <Col sm={2} className="text-end m-0 p-0 text-dark">
                      {
                        showPipelineDropdown ? (
                          <BsChevronUp className="fs-5 text-end text-dark" style={{ cursor: "pointer" }} />
                        ) : (
                          <BsChevronDown className="fs-5 text-end text-dark" style={{ cursor: "pointer" }} />
                        )
                      }
                    </Col>
                  </Row>
                </div>
                {
                  showPipelineDropdown ? (
                    <ul className="mt-1 py-2 px-0 bg-white rounded" style={{ zIndex: 9, width: "90%", position: "absolute", listStyle: 'none', border: "1px solid #c8c9ca", boxShadow: '0px 0px 10px #c8c9ca' }}>
                      {
                        allPipelines.map((data, index) => (
                          <li key={index} className={`${data === pipeline ? 'bg-primary text-white' : ''} py-1 px-2 fs-6`} onClick={() => changePipeline(data)}
                            style={{ cursor: data === pipeline ? 'not-allowed' : 'pointer' }}>
                            {data}
                          </li>
                        ))
                      }
                    </ul>
                  ) : null
                }
              </Col>
            ) : null
          }
        </Row>

        <Row className="mx-3 my-2">
          <Col sm={12} className="bg-danger text-white ps-4 m-0 p-0 py-2" onClick={() => setShowDelete(!showDelete)} style={{ cursor: "pointer", borderRadius: "5px" }}>
            <div className="m-0 p-0 row">
              <Col sm={9} className="px-0">Delete Lead</Col>
              <Col sm={1}>
                <MdDeleteOutline className="text-white fs-4" style={{ cursor: "pointer" }} />
              </Col>
            </div>
          </Col>
        </Row>

        <Modal centered size="md" show={showTransfer} onHide={() => setShowTransfer(false)}>
          <Modal.Header className="bg-primary">
            <Col sm={11}>
              <h4 className="text-white">Transfer Lead</h4>
            </Col>
            <Col sm={1} className="text-end">
              <MdClear className="fs-4 text-white" style={{ cursor: "pointer" }} onClick={() => setShowTransfer(false)} />
            </Col>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={submitTransferLead}>
              <span className="m-0 p-0 fw-semibold">Transfer Lead To:</span>
              <div style={{ zIndex: 999, position: "relative" }}>
                <Form.Group style={{ position: 'relative' }}>
                  <input type="text" id="autocomplete-state" className="mt-1 p-2 w-100 border border-primary" value={assignedTo} name="assignTo" placeholder="Select Assignee"
                    onChange={handleInputChangeAssignee} onClick={() => setIsOpenAssigneeList(true)} autoComplete='off' style={{ borderRadius: '5px' }} required
                  />
                  {isOpenAssigneeList && (
                    <>
                      <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                        {
                          allAssignees.length > 0 ? (
                            filteredAssigneeOptions.length > 0 ? (
                              filteredAssigneeOptions.map((option, i) => (
                                <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickAssignee(option.name)}>
                                  {option.name}
                                </div>
                              ))
                            ) : (
                              <div className="mt-2">No match found</div>
                            )
                          ) : (
                            <div className="mt-2">Loading . . . . </div>
                          )
                        }
                      </div>
                    </>
                  )}
                </Form.Group>
              </div>
              <Row className="pt-2">
                <Col sm={3}>
                  <button type="submit" className="m-0 p-0 border border-none bg-white">
                    <span
                      className="m-0 py-1 px-3 rounded bg-primary text-white"
                      style={{ cursor: "pointer" }}
                    >
                      Assign
                    </span>
                  </button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

        <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Body className="my-2">
            <Row className="mb-2 ms-1">
              <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Lead</Col>
              <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">
                You want to delete lead ?
              </Col>
            </Row>
            <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
              <div
                className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                onClick={() => handleDeleteLead()}
                style={{ borderRadius: "4px", cursor: "pointer" }}
              >
                Yes
              </div>
              <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                onClick={() => setShowDelete(false)}
                style={{ borderRadius: "5px", cursor: "pointer" }}
              >
                No
              </div>
            </div>
          </Modal.Body>
        </Modal >
      </div >
    );
  };

  return (
    <div onClick={() => (showDrop && setShowDrop(false)) || (isOpenAssigneeList && setIsOpenAssigneeList(false)) ||
      (showStagesDropdown && setShowStagesDropdown(false)) || (showPipelineDropdown && setShowPipelineDropdown(false))}>
      <LeadSubheader
        allStages={allStages} stage={details.stage} title={details.title} status={details.status} updateStatus={updateStatus}
        updateStage={updateStage} setActiveStep={setActiveStep} activeStep={activeStep} />
      {
        load ? (<Loader />) : (
          <div className="d-flex text-dark"
            style={{ marginTop: screenWidth >= 1200 ? "-2%" : "-3%", flexDirection: "row" }}>
            <Row sm={3} className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
              <Col sm={12} className="rounded bg-white">
                <Row className="py-2">
                  <h5 className="text-primary" style={{ fontWeight: "550" }}>About Lead</h5>
                </Row>
                {
                  leadDetails.map((data, index) => (
                    <Row className="py-1" key={index}>
                      <Col md={5} sm={12}>
                        <div style={{ fontWeight: "550" }}>{data.title}: </div>
                      </Col>
                      <Col className="m-0 p-0 text-start">{data.about}</Col>
                    </Row>
                  ))
                }
              </Col>

              <Col sm={12} className="mt-2 rounded bg-white">
                <Row className="py-2">
                  <h5 className="text-primary" style={{ fontWeight: "550" }}>About Client</h5>
                </Row>
                {
                  clientDetails.map((data, index) => (
                    <Row className="py-1" key={index}>
                      <Col md={3} sm={12}>
                        <div style={{ fontWeight: "550" }}>{data.title}: </div>
                      </Col>
                      <Col>{data.about}</Col>
                    </Row>
                  ))
                }
              </Col>

              <Col sm={12} className="mt-2 rounded bg-white">
                <Row className="py-2">
                  <h5 className="text-primary" style={{ fontWeight: "550" }}>About Oeganization</h5>
                </Row>
                {
                  orgDetails.map((data, index) => (
                    <Row className="py-1" key={index}>
                      <Col md={3} sm={12}>
                        <div style={{ fontWeight: "550" }}>{data.title}: </div>
                      </Col>
                      <Col>{data.about}</Col>
                    </Row>
                  ))
                }
              </Col>
            </Row>

            <Row sm={7}
              className={`h-100 mx-2 ps-3 mb-5`}
              style={{
                minWidth: screenWidth >= breakPoint ? "50%" : "50%",
                width: "70%",
                maxWidth: "80%",
              }}>
              <div className="bg-white rounded px-5 py-2"
              >
                <Row className="text-primary fw-bold" style={{ fontSize: "1rem" }}>
                  Description
                </Row>
                <Row className="pt-2" style={{ cursor: "pointer" }}>{details === undefined || details.description === undefined ? " " : details.description}</Row>
              </div>
              <div className="m-0 p-0" style={{ maxWidht: "75%", widows: "75%", minHeight: "400px", overflowX: "hidden" }}>
                <div className="bg-white rounded mt-2"
                  style={{ overflowX: "hidden" }}
                >
                  <div className="" style={{ minHeight: "25vh" }}>
                    <Row className="text-primary fw-bold" style={{ fontSize: "1rem" }}>
                      <Row className="border-primary" style={{ borderBottom: "2px solid red" }}>
                        <Col
                          id="nav-item"
                          className={`${selectForm === "activity" ? "text-white bg-primary" : "text-primary bg-white"}`}
                          onClick={() => setSelectForm("activity")}
                          style={{ borderRadius: "10px 0px 0px 0px", }}
                        >
                          Add Activity
                        </Col>
                        <Col
                          id="nav-item"
                          onClick={() => setSelectForm("note")}
                          className={`${selectForm === "note" ? "text-white bg-primary" : "text-primary bg-white"}`}
                        >
                          Take Note
                        </Col>
                        <Col
                          id="nav-item"
                          onClick={() => setSelectForm("email")}
                          className={`${selectForm === "email" ? "text-white bg-primary" : "text-primary bg-white"}`}
                        >
                          Send Email
                        </Col>
                        <Col
                          id="nav-item"
                          onClick={() => setSelectForm("attach")}
                          className={`${selectForm === "attach" ? "text-white bg-primary" : "text-primary bg-white"}`}
                        >
                          Attachment
                        </Col>
                        <Col
                          id="nav-item"
                          onClick={() => setSelectForm("reminder")}
                          className={`${selectForm === "reminder" ? "text-white bg-primary" : "text-primary bg-white"}`}
                        >
                          Set Reminder
                        </Col>
                      </Row>
                      <div className="mx-2">
                        {selectForm === "activity" && <ActivityForm getLeadActivites={getLeadActivites} leadActivitiesByType={leadActivitiesByType} leadId={id} />}
                        {selectForm === "note" && <NoteForm getLeadActivites={getLeadActivites} leadActivitiesByType={leadActivitiesByType} leadId={id} />}
                        {selectForm === "email" && <EmailForm getLeadActivites={getLeadActivites} leadActivitiesByType={leadActivitiesByType} leadId={id} />}
                        {selectForm === "attach" && <AttachForm getLeadActivites={getLeadActivites} leadActivitiesByType={leadActivitiesByType} leadId={id} />}
                        {selectForm === "reminder" && <ReminderForm getLeadActivites={getLeadActivites} leadActivitiesByType={leadActivitiesByType} leadId={id} />}
                      </div>
                    </Row>
                  </div>
                </div>
                <div className="mt-5">
                  <LeadActivities allActivities={allActivities} setAllActivities={setAllActivities} leadActivitiesByType={leadActivitiesByType} type={type} leadId={details._id} />
                </div>
              </div>
            </Row>
            <ActivityBar options={leadOptions} lead={true} />
          </div>
        )
      }
    </div>
  );
};

export default LeadDetails;
