import React from 'react';
import shapes1 from "../../../../assets/images/shapes/01.png";
import shapes2 from "../../../../assets/images/shapes/02.png";
import shapes3 from "../../../../assets/images/shapes/03.png";
import shapes4 from "../../../../assets/images/shapes/04.png";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import CustomToggle from '../../../../components/dropdowns';
import { Link } from 'react-router-dom';
import { MdClear } from 'react-icons/md';

const AllNotifications = ({ showAllNotifs, setShowAllNotifs, setNotifsViewed}) => {

    const dummyNotifs = [
        {
            id: 1,
            img: shapes1,
            title: "Emma Watson Bni",
            description: "95 MB",
            time: "Just Now"
        },
        {
            id: 2,
            img: shapes2,
            title: "New customer is join",
            description: "Cyst Bni",
            time: "5 days ago"
        },
        {
            id: 3,
            img: shapes3,
            title: "Two customer is left",
            description: "Cyst Bni",
            time: "2 days ago"
        },
        {
            id: 4,
            img: shapes4,
            title: "New Mail from Fenny",
            description: "Cyst Bni",
            time: "3 days ago"
        },
        {
            id: 1,
            img: shapes1,
            title: "Emma Watson Bni",
            description: "95 MB",
            time: "Just Now"
        },
        {
            id: 2,
            img: shapes2,
            title: "New customer is join",
            description: "Cyst Bni",
            time: "5 days ago"
        },
        {
            id: 3,
            img: shapes3,
            title: "Two customer is left",
            description: "Cyst Bni",
            time: "2 days ago"
        },
        {
            id: 4,
            img: shapes4,
            title: "New Mail from Fenny",
            description: "Cyst Bni",
            time: "3 days ago"
        },
        {
            id: 1,
            img: shapes1,
            title: "Emma Watson Bni",
            description: "95 MB",
            time: "Just Now"
        },
        {
            id: 2,
            img: shapes2,
            title: "New customer is join",
            description: "Cyst Bni",
            time: "5 days ago"
        },
        {
            id: 3,
            img: shapes3,
            title: "Two customer is left",
            description: "Cyst Bni",
            time: "2 days ago"
        },
        {
            id: 4,
            img: shapes4,
            title: "New Mail from Fenny",
            description: "Cyst Bni",
            time: "3 days ago"
        },
    ];

    const handleViewed = () => {
        setShowAllNotifs(false);
        setNotifsViewed(true);
    }

    return (
        <div>
            <Modal centered size="md" show={showAllNotifs} onHide={() => handleViewed()}>
                <Modal.Header className='bg-primary'>
                    <Col sm={11} className="text-white">
                        <h4 className="text-white">All Notifications</h4>
                    </Col>
                    <Col sm={1} className="text-end text-white">
                        <MdClear className="fs-4" onClick={() => handleViewed()} style={{ cursor: "pointer" }} />
                    </Col>
                </Modal.Header>
                <Modal.Body className='pb-0' style={{maxHeight: "80vh", overflowY: "scroll"}}>
                    {/* <div className='m-0'> */}
                        {
                            dummyNotifs.map((notif, index) => (
                                <Row className='py-3' style={{ fontSize: '0.8rem', borderBottom: "1px solid #c8c9ca" }} key={index}>
                                    <Col sm={2}>
                                        <img src={notif.img} className="p-1 avatar-40 rounded-pill bg-soft-primary" />
                                    </Col>
                                    <Col sm={7}>
                                        <div className='w-100 m-0 p-0'>{notif.title}</div>
                                        <div className='w-100 m-0 p-0'>{notif.description}</div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className='w-100 m-0 p-0'>
                                            <span></span>
                                        </div>
                                        <div className='w-100 m-0 p-0' style={{ fontSize: "0.7rem" }}>{notif.time}</div>
                                    </Col>
                                </Row>
                            ))
                        }
                    {/* </div> */}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AllNotifications
