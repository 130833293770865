import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { MdClear } from "react-icons/md";
import { useParams } from "react-router-dom";
import { recordPayment } from "../Hook/invoiceHooks";
import { toast } from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { recordPurchasePayment } from "../Hook/purchaseHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { addRecentActivity } from "../Hook/recentActivityHooks";

const RecordPayment = ({ getInvoiceData, data, view, setView, invoiceNumber, getAllRecentActivities }) => {
  const [form, setForm] = useState([]);
  const [status, setStatus] = useState("Pending");
  const [adjustDue, setAdjustDue] = useState("Other");
  const [amountPaidpayment, setamountPaidpayment] = useState(0);
  const [grandTotal, setGrandTotal] = useState(data.total);

  const [invoicePayment, setInvoicePayment] = useState(true);

  let total = grandTotal > (grandTotal - data.roundOff) ? (grandTotal - data.roundOff) : (grandTotal + data.roundOff)
  // let total = 0;

  const datetimeString = new Date();
  const dateObject = new Date(datetimeString);

  const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
  const formattedTime = dateObject.toLocaleTimeString();

  useEffect(() => {
    location.pathname.includes("invoice") ? setInvoicePayment(true) : setInvoicePayment(false);
  }, []);

  useEffect(() => {
    const round = Math.round(data.total);
    setGrandTotal(round);
  });

  const { id } = useParams();
  // const navigate = useNavigate();
  const orgName = localStorage.getItem("orgName");
  const location = useLocation();

  const newDate = new Date().toLocaleString();
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      amountPaidpayment: adjustDue === "Full" ? JSON.stringify(Math.round(data.total - data.amountPaid)) : amountPaidpayment,
      status: data.total - data.amountPaid > form.amountPaidpayment ? "Partial Paid" : "Paid",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (location.pathname.includes('invoice')) {
      recordPayment(form, id).then((response) => {
        if (response.status === 201) {
          SuccessToast("Payment Recorded");
          const activityForm = {
            module: ['invoice'],
            entityId: [id],
            activity: 'Payment Recorded',
            activityDesc: `Payment of ${data.currency} ${amountPaidpayment} recorded`,
            createdDate: formattedDate,
            createdTime: formattedTime
          };

          addRecentActivity(activityForm).then((response) => response.status === 201 & getAllRecentActivities());

          setView(false);
          getInvoiceData();
        }
      }).catch((err) => {
        err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
      });
    } else {
      recordPurchasePayment(id, form).then((response) => {
        if (response.status === 201) {

          const activityForm = {
            module: ['invoice'],
            entityId: [id],
            activity: 'Payment Recorded',
            activityDesc: `Payment of ${data.currency} ${amountPaidpayment} recorded`,
            createdDate: formattedDate,
            createdTime: formattedTime
          };

          addRecentActivity(activityForm);

          SuccessToast("Payment Recordded Successfully");
          setView(false);
          getInvoiceData();
        }
      }).catch((err) => {
        err?.response?.data?.messgae ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
      })
    }
  }

  return (
    <Modal centered size="lg" show={view} onHide={() => setView(!view)} style={{ color: "#000" }}>
      <Modal.Header className="bg-primary text-white">
        <Col sm={8}>
          <h4 className="text-white">Record Payment</h4>
        </Col>
        <Col sm={4} className="text-end">
          <MdClear className="fs-3" style={{ cursor: "pointer" }} onClick={() => setView(false)} />
        </Col>
      </Modal.Header>
      <Modal.Body className="mx-3">
        <Row>
          <Col sm={6}>
            <Row className="my-2">
              <Col sm={4} className="fs-5 text-start" style={{ fontWeight: "550" }}> {invoicePayment ? 'Invoice No:' : 'Purchase No.'} </Col>
              <Col sm={8} className="fs-5 text-start"> {invoiceNumber} </Col>
            </Row>
            <Row className="my-2">
              <Col sm={4} className="fs-5 text-start" style={{ fontWeight: "550" }}> Amount Paid: </Col>
              <Col sm={8} className="fs-5 text-start"> {data.currency} {data.amountPaid} </Col>
            </Row>
            <Row className="mt-2 mb-4 text-danger">
              <Col sm={4} className="fs-5 text-start text-danger" style={{ fontWeight: "550" }}> Due Amount: </Col>
              {/* {
                adjustDue === "Adjust" ? (
                  <Col sm={8} className="fs-5 text-start text-danger"> {data.currency} {Math.round(data.total - data.amountPaid)} </Col>
                ) : (
                  <Col sm={8} className="fs-5 text-start text-danger"> {data.currency} {data.total - data.amountPaid} </Col>
                )
              } */}
              {/* {
                data.dueAmount === 0 ? (
                  <Col sm={8} className="fs-5 text-start"> {data.currency} {grandTotal - data.amountPaid} </Col>
                ) : (
                  <Col sm={8} className="fs-5 text-start"> {data.currency} {data.dueAmount} </Col>
                )
              } */}
              <Col sm={8} className="fs-5 text-start"> {data.currency} {data.dueAmount} </Col>
            </Row>

            {/* )
            } */}
          </Col>
          <Col sm={6}>
            <Row className="mt-2 mb-2">
              <Col sm={4} className="fs-5 text-start" style={{ fontWeight: "550" }}> Total Amount: </Col>
              <Col sm={8} className="fs-5 text-start"> {data.currency}
                {total}
                {/* {data.roundOff === undefined ? grandTotal : grandTotal - data.roundOff} */}
              </Col>
            </Row>
            {/* {
              !data.roundOff && ( */}
            <Row className="mb-2">
              <Col sm={4} className="fs-5 text-start" style={{ fontWeight: "550" }}> Roundoff: </Col>
              <Col sm={8} className="fs-5 text-start"> {data.currency} {data === undefined || data.roundOff === undefined ? "" : data.roundOff} </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={4} className="fs-5 text-start text-primary" style={{ fontWeight: "550" }}> Grand Total: </Col>
              <Col sm={8} className="fs-5 text-start text-primary"> {data.currency} {grandTotal} </Col>
            </Row>
          </Col>
        </Row>

        <form onSubmit={handleSubmit}>
          <Row className="mt-1">
            <Col sm={3}>
              <Form.Group>
                <Form.Label className="fs-5">Adjust Due</Form.Label>
                <Form.Select onChange={(e) => setAdjustDue(e.target.value)} className="border border-primary bg-primary text-white">
                  <option className="bg-white text-dark">Select Option</option>
                  <option className="bg-white text-dark">Full</option>
                  <option className="bg-white text-dark">Custom</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-2">
            {/* <Col sm={6}>
            </Col> */}
            <Col sm={6}>
              <Form.Group>
                <Form.Label className="fs-5">Amount Paid</Form.Label>
                {
                  adjustDue === "Full" ? (
                    <Form.Control type="number" value={adjustDue === "Full" && Math.round(data.total - data.amountPaid)} className="text-dark border border-primary" name="amountPaidpayment" placeholder="Amount paid" onInput={(e) => setamountPaidpayment(e.target.value)} required />
                  ) : (
                    <Form.Control type="number" className="text-dark border border-primary" name="amountPaidpayment" placeholder="Amount paid" onChange={(e) => setamountPaidpayment(e.target.value)} required />
                  )
                }
                {/* <Form.Control type="number" step="any" className="text-dark border border-primary" name="amountPaidpayment" placeholder="Amount paid" onChange={handleChange} required /> */}
              </Form.Group>
            </Col>
            {/* <Col sm={6}>
              <Form.Group>
                <Form.Label className="fs-5">Amount Paid</Form.Label>
                <Form.Control type="number" step={!allow ? "any" : false} className="text-dark border border-primary" name="amountPaidpayment" placeholder="Amount paid" onChange={handleChange} required />
              </Form.Group>
            </Col> */}
            <Col sm={6}>
              <Form.Group>
                <Form.Label className="fs-5">Date Paid</Form.Label>
                <Form.Control type="date" name="datePaid" className="text-dark border border-primary" onChange={handleChange} required />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group>
            <Form.Label className="fs-5">Payment Method</Form.Label>
            <Form.Select className="text-dark border border-primary" name="paymentMethod" onChange={handleChange} required>
              <option>Select Payment Method</option>
              <option>Bank Transfer</option>
              <option>Cash</option>
              <option>Cheque</option>
              <option>Credit Card</option>
              <option>Debit Card</option>
            </Form.Select>
          </Form.Group>

          {form.paymentMethod === "Cheque" && (
            <Form.Group className="mt-2">
              <Form.Label className="fs-5">Check No.</Form.Label>
              <Form.Control type="text" name="chequeNo" placeholder="Cheque No." className="text-dark border border-primary" onChange={handleChange} />
            </Form.Group>
          )}

          <Form.Group
            className="mt-2"
            style={{
              display:
                (form.paymentMethod === "Cash" ||
                  form.paymentMethod === "Cheque") &&
                "none",
            }}
          >
            <Form.Label className="fs-5">Transaction ID</Form.Label>
            <Form.Control type="text" name="transId" placeholder="Transaction ID" className="text-dark border border-primary" onChange={handleChange} />
          </Form.Group>

          <Form.Group className="my-2">
            <Form.Label className="fs-5">Note</Form.Label>
            <Form.Control type="text" placeholder="Enter note" rows="6" as="textarea" name="notes" className="text-dark border border-primary"
              onChange={handleChange}
            />
          </Form.Group>
          <Modal.Footer>
            <Button type="submit" onMouseOver={handleChange} onMouseEnter={handleChange}>Save</Button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default RecordPayment;
