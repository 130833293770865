import React, { useEffect, useState } from "react";
import { Row, Col, Image, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import auth1 from "../../../assets/images/auth/01.png";
import cubicleweb from '../../../assets/images/cubicleweb.png';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { signInUser } from "../app/Hook/authHooks";
import { ErrorToast, SuccessToast } from "../components/toast";
// import useFullPageLoader from "../app/Hook/useFullPageLoader";
// import { toast } from "react-hot-toast";
// import './signin.css';
import './signin.css';
import { cubicle2, cubicleFull } from "../../../assets/images";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getOrgById, getOrgDetails } from "../app/Hook/orgHooks";

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const SignIn = () => {
  const [form, setForm] = useState([]);
  const [inputType, setInputType] = useState("password");
  const [disable, setDisable] = useState(false);
  const orgName = localStorage.getItem('orgName');
  const [org, setOrg] = useState('');
  const { id } = useParams();

  // const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [orgId, setOrgId] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (id) {
      localStorage.setItem('orgID', id);
      getOrgById(id).then((response) => {
        console.log("response", response.data.data);
        if (response.status === 200) {
          setOrg(response?.data?.data?.orgName);
        } else {
          setOrg('');
        }
      }).catch(() => {
        setOrg('');
      })
    }
  }, [id]);

  useEffect(() => {
    if (id === undefined) {
      orgName && history.push(`/${orgName}/dashboard`)
    }
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const logIn = (e) => {
    e.preventDefault();
    // showLoader();
    setDisable(true);
    signInUser(form).then((response) => {
      if (response.status === 200) {
        SuccessToast("LoggedIn successfully");

        localStorage.setItem("orgID", response.data.data.orgID);
        localStorage.setItem("orgName", response.data.data.orgName);
        localStorage.setItem("orgEmail", response.data.data.orgEmail);
        localStorage.setItem('userId', response.data.data.id);
        localStorage.setItem('user_token', response.data.token);
        setOrgId(localStorage.getItem("orgID", JSON.stringify(response.data.data.orgID)));

        // console.log("data", response.data);

        localStorage.setItem("userRole", JSON.stringify(response.data.data.role));

        const userRole = JSON.parse(localStorage.getItem("userRole"));
        const orgName = localStorage.getItem("orgName");

        if (id === undefined) {
          if (userRole) {
            setTimeout(() => {
              history.push(`/${orgName}/dashboard`);
            }, 2000);
          } else {
            setTimeout(() => {
              history.push(`/${orgName}/dashboard`);
            }, 2000);
          }
          setTimeout(() => {
            history.push(`/${orgName}/dashboard`);
          }, 2000);
        } else {
          localStorage.setItem('orgID', id);
          localStorage.setItem('orgName', org);
          setTimeout(() => {
            history.push(`/acceptInvitation`);
          }, 2000);
        }

        setTimeout(() => {
          // hideLoader();
        }, 2000);

      } else {
        ErrorToast("Invalid Credentials");
        setDisable(false)
        // hideLoader();
      }
    })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        ErrorToast(err?.response?.data?.message)
        // ErrorToast("Something went wrong");
        setDisable(false)
        // setLoad(false);
        // hideLoader();
      });
  }

  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          {/* <Col md="6" className="d-flex justify-content-center align-items-center p-0 mt-n1 vh-100 overflow-hidden" style={{ position: 'relative' }}>
            <img src={cubicleweb} alt="logo" />
          </Col> */}
          {/* <Col md={6} className="justify-content-center align-items-center p-0 mt-n1 vh-100 overflow-hidden">
            <img src={cubicle2} />
          </Col> */}
          <Col md={6} className="d-flex justify-content-center align-items-center p-0 mt-n1 vh-100 overflow-hidden" >
            <div className="row ">
              <div className="col-xl-12 col-lg-8 pt-5">
                <div className="d-flex justfy-content-center align-items-center orbit" style={{ marginLeft: "30vw" }}>
                  <div className="scrollme start-or wow zoomIn" data-wow-delay=".2s">
                    <div className=" animateme" data-when="exit" data-from="0" data-to=".9" data-scale="0" data-opacity="0">
                      <div className='banner-orbit-main-container '>
                        <div className="main-orbit">
                          {/* <span className="sm-circle"></span> */}
                          <img src={cubicle2} alt="logo" style={{ width: "30rem", height: "30rem" }} />
                          {/* <span className="mid-circle"></span> */}
                          {/* <span className="outer-circle"></span> */}
                          <div className="outer_text scrollme clock-spin">
                            <span className="scrollme clock-spin">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale="0" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span></span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="6" className="d-flex justify-content-center align-items-center p-0 vh-100" style={{ position: "relative" }}>
            <Image src={auth1} className="Image-fuild gradient-main" alt="images" />
            <Row className="justify-content-center" style={{ position: "absolute" }}>
              <Col md="10">
                <Card>
                  <Card.Body>
                    <h1 className="text-center fw-bold" style={{ fontSize: "3rem" }}>Cubicle</h1>
                  </Card.Body>
                </Card>
                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                  <Card.Body>
                    <div className="navbar-brand d-flex justify-content-center align-items-center mb-3">
                    </div>
                    <h2 className="mb-2 text-center">Sign In</h2>
                    <p className="text-center">Login to stay connected.</p>
                    <form onSubmit={logIn} method="POST">
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control type="email" className="border border-primary" id="email" aria-describedby="email" name="email"
                              onChange={handleChange} placeholder="Enter email" required
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="12" style={{ position: "relative" }}>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <Form.Control type={inputType} className="border border-primary" id="password" aria-describedby="password" name="password"
                              onChange={handleChange} placeholder="Enter password" required
                            />
                          </Form.Group>
                          {
                            inputType === "password" ? (
                              <AiFillEyeInvisible className="fs-4 text-primary" onClick={() => setInputType("text")} style={{ display: !form.password && "none", cursor: "pointer", position: "absolute", top: 35, right: 30 }} />
                            ) : (
                              <AiFillEye className="fs-4 text-primary" onClick={() => setInputType("password")} style={{ cursor: "pointer", position: "absolute", top: 35, right: 30 }} />
                            )
                          }
                        </Col>
                        {/* <Col
                              lg="12"
                              className="d-flex my-3 justify-content-between"
                      login-content      >
                              <Form.Check className="form-check mb-3">
                                            <Form.Check.Input type="checkbox"  id="customCheck1"/>
                                            <Form.Check.Label htmlFor="customCheck1">Remember Me</Form.Check.Label>
                                        </Form.Check>
                              <Link to="/auth/forgot">Forgot Password?</Link>
                            </Col> */}
                      </Row>
                      <div className="d-flex justify-content-center" style={{ cursor: disable === true ? 'not-allowed' : 'pointer' }}>
                        <Button disabled={disable === true} type="submit" variant="btn btn-primary">Sign In</Button>
                      </div>
                      {/* <p className="text-center my-3">or sign in with other accounts?</p>
                                  <div className="d-flex justify-content-center">
                                      <ListGroup as="ul" className="list-group-horizontal list-group-flush">
                                        <ListGroup.Item as="li"  className="border-0 pb-0">
                                            <Link to="#"><Image src={facebook} alt="fb"/></Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li"  className="border-0 pb-0">
                                            <Link to="#"><Image src={google} alt="gm"/></Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li"  className="border-0 pb-0">
                                            <Link to="#"><Image src={instagram} alt="im"/></Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li"  className="border-0 pb-0">
                                            <Link to="#"><Image src={linkedin} alt="li"/></Link>
                                        </ListGroup.Item>
                                      </ListGroup>
                                  </div> */}
                      <p className="mt-3 text-center">
                        Don’t have an account?{" "}
                        <Link to="/signup" className="text-underline">
                          Click here to sign up.
                        </Link>
                      </p>
                    </form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      {/* {loader} */}
    </>
  );
};

export default SignIn;
