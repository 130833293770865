import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { addLeadActivity } from "../../../Hook/leadHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../../../components/toast";


const ActivityForm = ({ leadId, leadActivitiesByType }) => {
  const [form, setForm] = useState({});
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);

  const orgId = localStorage.getItem("orgID");

  const addActivity = async (e) => {
    e.preventDefault();
    setDisabledBtn(true);
    addLeadActivity(form).then((response) => {
      if (response.status === 201) {
        SuccessToast('Activity Added');
        leadActivitiesByType('All');
        setTitle('');
        setType('');
        setDescription('');
        setDisabledBtn(false);
      } else {
        ErrorToast("Unabel to add activity");
        setDisabledBtn(false);
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong')
      setDisabledBtn(false);
    })
  };

  const handleChange = (e) => {
    (e.target.name === 'title') ? setTitle(e.target.value) : (e.target.name === 'type') ? setType(e.target.value) : setDescription(e.target.value)

    setForm({
      ...form,
      [e.target.name]: e.target.value,
      leadId: leadId,
      orgId: orgId
    });
  };

  return (
    <Row style={{ fontSize: "0.8rem" }}>
      <form className="my-3" onSubmit={addActivity} style={{ width: "98%" }}>
        <Col sm={12} className="mt-2">
          <input type="text" placeholder="Activity title" className="w-100 py-1 px-2 border border-primary rounded" name="title"
            value={title} onChange={handleChange} required
          />
        </Col>
        <Col sm={12} className="mt-2">
          <select className="w-100 py-1 px-2 border border-primary rounded text-secondary" name="type"
            value={type} onChange={handleChange} required
            style={{ fontSize: "0.8rem" }}
          >
            <option className=" border border-primary text-dark" value="" style={{ fontWeight: "500" }}>Select Activity Type</option>
            <option>Call</option>
            <option>Meeting</option>
            <option>Task</option>
          </select>
        </Col>
        <Col sm={12} className="mt-2">
          <textarea rows="5" placeholder="Activity Description" className="w-100 border border-primary py-1 px-2 rounded" name="desc"
            value={description} onChange={handleChange}
          ></textarea>
        </Col>
        <Col sm={12} className="mt-2 d-flex justify-content-end align-items-end">
          <div className="m-0 p-0" style={{ cursor: disabledBtn ? 'not-allowed' : 'pointer' }}>
            <Button type="submit" variant={disabledBtn ? 'secondary' : 'primary'} className="ms-0 text-white fs-5 px-4" disabled={disabledBtn ? true : false} style={{ padding: '3px' }}>Add Activity</Button>
          </div>
        </Col>
      </form>
    </Row>
  );
};

export default ActivityForm;
