import React, { useEffect, useRef, useState } from "react";
import topHeader from "../../../../../assets/images/dashboard/top-header.png";
import { Row, Col, Container, Form, InputGroup, Button } from "react-bootstrap";
import Card from "../../../../../components/Card";
// import Badge from "../../../uikit/badge";
import { fontWeight } from "@mui/system";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { getUserById, updateUserProfileImage } from "../../Hook/userHooks";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FiUpload } from 'react-icons/fi';
import { ErrorToast, SuccessToast, WarningToast } from "../../../components/toast";
import { updateUser } from "../../Hook/authHooks";
// import { getUserById } from "../../Hook/userHooks";

const UserProfile = () => {
  const [modulePermissions, setModulePermissions] = useState([]);
  const [form, setForm] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [checkboxes, setCheckboxes] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [selectedRole, setSelectedRole] = useState('User');
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [showUpdatImage, setShowUpdateImage] = useState(false);
  const [showCustomRoleField, setShowCustomRoleField] = useState(false);
  const [disableImgUploadBtn, setDisableImageUploadBtn] = useState(false);
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(false);
  const [rolePermissions, setRolePermissions] = useState(
    [
      {
        module: "invoice",
        action: [
          { title: 'Create', value: '' },
          { title: 'Read', value: '' },
          { title: 'Update', value: '' },
          { title: 'Delete', value: '' }
        ]
      },
      {
        module: "firm",
        action: [
          { title: 'Create', value: '' },
          { title: 'Read', value: '' },
          { title: 'Update', value: '' },
          { title: 'Delete', value: '' }
        ]
      },
      {
        module: "client",
        action: [
          { title: 'Create', value: '' },
          { title: 'Read', value: '' },
          { title: 'Update', value: '' },
          { title: 'Delete', value: '' }
        ]
      },
      {
        module: "lead",
        action: [
          { title: 'Create', value: '' },
          { title: 'Read', value: '' },
          { title: 'Update', value: '' },
          { title: 'Delete', value: '' }
        ]
      },
      {
        module: "vendor",
        action: [
          { title: 'Create', value: '' },
          { title: 'Read', value: '' },
          { title: 'Update', value: '' },
          { title: 'Delete', value: '' }
        ]
      },
      {
        module: "purchase",
        action: [
          { title: 'Create', value: '' },
          { title: 'Read', value: '' },
          { title: 'Update', value: '' },
          { title: 'Delete', value: '' }
        ]
      },
      {
        module: "tax",
        action: [
          { title: 'Create', value: '' },
          { title: 'Read', value: '' },
          { title: 'Update', value: '' },
          { title: 'Delete', value: '' }
        ]
      },
      {
        module: "procduct",
        action: [
          { title: 'Create', value: '' },
          { title: 'Read', value: '' },
          { title: 'Update', value: '' },
          { title: 'Delete', value: '' }
        ]
      },
    ]
  );

  const [permissions, setPermissions] = useState([]);
  const [customRolePermissions, setCustomRolePermissions] = useState([]);

  const hiddenImageField = useRef(null);
  const { id } = useParams();
  const location = useHistory();
  const orgName = localStorage.getItem('orgName');

  const formData = new FormData();

  // const initialUrl = 'https://thumbs.dreamstime.com/b/default-avatar-profile-vector-user-profile-default-avatar-profile-vector-user-profile-profile-179376714.jpg';
  const initialUrl = 'https://i.pinimg.com/280x280_RS/2e/45/66/2e4566fd829bcf9eb11ccdb5f252b02f.jpg';

  const projects = [
    {
      id: 1,
      title: "Project 1",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, vel.",
    },
    {
      id: 2,
      title: "Project 2",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, vel.",
    },
    {
      id: 3,
      title: "Project 3",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, vel.",
    },
    {
      id: 4,
      title: "Project 4",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, vel.",
    },
    {
      id: 5,
      title: "Project 5",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, vel.",
    },
  ];

  const skills = [
    { id: 1, name: "HTML", },
    { id: 2, name: "CSS", },
    { id: 3, name: "Javascript", },
    { id: 4, name: "ReactJS", },
    { id: 5, name: "NodeJS", },
    { id: 6, name: "MongoDB", },
  ];

  const allRoles = [
    { role: 'User', value: 'User' },
    { role: 'Admin', value: 'Admin' },
    // { role: 'Sub Admin', value: 'subAdmin' },
    // { role: 'Purchase', value: 'Purchase' },
    { role: 'Custom', value: 'Custom' },
  ];

  const allPipelines = [
    { role: 'Management', value: 'Management' },
    { role: 'Marketing', value: 'Marketing' },
    { role: 'Development', value: 'Development' },
  ];

  const userbasicDetails = [
    { tilte: 'Email', desc: userDetails?.email },
    { tilte: 'Mobile', desc: userDetails?.phone },
    { tilte: 'Email', desc: userDetails?.role }
  ];

  const allPermissions = [
    {
      module: "invoice",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "firm",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "client",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "lead",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "vendor",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "purchase",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "tax",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "procduct",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
  ];

  // const modulePermissions = [
  //   {
  //     module: 'invoice',
  //     action: ['Read', 'Update', 'Delete']
  //   },
  //   {
  //     module: 'vendor',
  //     action: ['Create']
  //   }
  // ];

  const getUserDetails = () => {
    getUserById(id).then((response) => {
      if (response.status === 200) {
        console.log("response", response.data.data);
        const permissions = response?.data?.data?.permissions === undefined ? [] : response?.data?.data?.permissions;
        response?.data?.data?.role === 'Custom' && setShowCustomRoleField(true)

        setModulePermissions(permissions);
        setUserDetails(response.data.data);
        setSelectedRole(response.data.data.role);
        setSelectedImage(response?.data?.data?.profilePhoto?.length > 0 ? response?.data?.data?.profilePhoto : initialUrl);
      } else setUserDetails({})
    }).catch(() => setUserDetails({}));
  };

  // console.log("module permi");

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    handleChangeRole(selectedRole)
  }, []);

  useEffect(() => {
    setForm({
      ...form,
      permissions: modulePermissions
    })
  }, [modulePermissions]);

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleImageChange = (e) => {
    setShowUpdateImage(true);
    setSelectedFile(e.target.files[0]);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleChangeRole = (role) => {
    setSelectedRole(role);
    setShowCustomRoleField(false);
    setForm({
      ...form,
      role: role
    })
    if (role === "User") {
      setRolePermissions(
        [
          {
            module: "invoice",
            action: [
              { title: 'Create', value: '' },
              { title: 'Read', value: '' },
              { title: 'Update', value: '' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "firm",
            action: [
              { title: 'Create', value: '' },
              { title: 'Read', value: '' },
              { title: 'Update', value: '' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "client",
            action: [
              { title: 'Create', value: '' },
              { title: 'Read', value: '' },
              { title: 'Update', value: '' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "lead",
            action: [
              { title: 'Create', value: '' },
              { title: 'Read', value: '' },
              { title: 'Update', value: '' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "vendor",
            action: [
              { title: 'Create', value: '' },
              { title: 'Read', value: '' },
              { title: 'Update', value: '' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "purchase",
            action: [
              { title: 'Create', value: '' },
              { title: 'Read', value: '' },
              { title: 'Update', value: '' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "tax",
            action: [
              { title: 'Create', value: '' },
              { title: 'Read', value: '' },
              { title: 'Update', value: '' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "procduct",
            action: [
              { title: 'Create', value: '' },
              { title: 'Read', value: '' },
              { title: 'Update', value: '' },
              { title: 'Delete', value: '' }
            ]
          },
        ]
      );
      setPermissions([]);
    } else if (role === 'Admin') {
      setRolePermissions(
        [
          {
            module: "invoice",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: 'Delete' }
            ]
          },
          {
            module: "firm",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: 'Delete' }
            ]
          },
          {
            module: "client",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: 'Delete' }
            ]
          },
          {
            module: "lead",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: 'Delete' }
            ]
          },
          {
            module: "vendor",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: 'Delete' }
            ]
          },
          {
            module: "purchase",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: 'Delete' }
            ]
          },
          {
            module: "tax",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: 'Delete' }
            ]
          },
          {
            module: "procduct",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: 'Delete' }
            ]
          },
        ]
      );
      setPermissions([
        {
          module: "firm",
          action: ["Create", "Read", "Update", "Delete"]
        },
        {
          module: "client",
          action: ["Create", "Read", "Update", "Delete"]
        },
        {
          module: "tax",
          action: ["Create", "Read", "Update", "Delete"]
        },
        {
          module: "invoice",
          action: ["Create", "Read", "Update", "Delete"]
        },
        {
          module: "lead",
          action: ["Create", "Read", "Update", "Delete"]
        },
        {
          module: "purchase",
          action: ["Create", "Read", "Update", "Delete"]
        },
        {
          module: "product",
          action: ["Create", "Read", "Update", "Delete"]
        }
      ]);
    } else if (role === 'subAdmin') {
      setRolePermissions(
        [
          {
            module: "invoice",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "firm",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "client",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "lead",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "vendor",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "purchase",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "tax",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: '' }
            ]
          },
          {
            module: "procduct",
            action: [
              { title: 'Create', value: 'Create' },
              { title: 'Read', value: 'Read' },
              { title: 'Update', value: 'Update' },
              { title: 'Delete', value: '' }
            ]
          },
        ]
      );
      setPermissions([
        {
          module: "firm",
          action: ["Create", "Read", "Update"]
        },
        {
          module: "client",
          action: ["Create", "Read", "Update"]
        },
        {
          module: "tax",
          action: ["Create", "Read", "Update"]
        },
        {
          module: "invoice",
          action: ["Create", "Read", "Update"]
        },
        {
          module: "lead",
          action: ["Create", "Read", "Update"]
        },
        {
          module: "purchase",
          action: ["Create", "Read", "Update"]
        },
        {
          module: "product",
          action: ["Create", "Read", "Update"]
        }
      ]);
    } else if (role === 'Custom') {
      setShowCustomRoleField(true);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'phone') {
      if (e.target.value.length <= 10) {
        e.target.value = e.target.value.slice(0, 10);
        setForm({
          ...form,
          phone: e.target.value
        })
      } else {
        e.target.value = e.target.value.slice(0, 10);
        ErrorToast("Mobile No. length can't be more than 10");
      }
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  };

  const test = [];

  const handleCustomRolePermissions = (moduleName, permission) => {
    const existingModuleIndex = modulePermissions.findIndex(
      (module) => module.module === moduleName
    );

    if (existingModuleIndex !== -1) {
      const updatedModules = [...modulePermissions];
      const permissions = updatedModules[existingModuleIndex].action;

      if (permissions.includes(permission)) {
        const updatedPermissions = permissions.filter(
          (p) => p !== permission
        );
        if (updatedPermissions.length === 0) {
          updatedModules.splice(existingModuleIndex, 1);
        } else {
          updatedModules[existingModuleIndex].action = updatedPermissions;
        }
      } else {
        permissions.push(permission);
      }
      setModulePermissions(updatedModules);
    } else {
      test.push({
        module: moduleName,
        action: [permission]
      })
      setModulePermissions((prevModules) => [
        ...prevModules,
        {
          module: moduleName,
          action: [permission],
        },
      ]);
    }
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    setDisableUpdateBtn(true);
    updateUser(form, id).then((response) => {
      if (response.status === 200) {
        setDisableUpdateBtn(false);
        SuccessToast('User updated successfully');
        location.push(`/${orgName}/dashboard/user/all-users`);
      } else {
        ErrorToast("Unable to update user");
        setDisableUpdateBtn(false);
      }
    }).catch(() => {
      ErrorToast("Something went wrong");
      setDisableUpdateBtn(false);
    })
  };

  const handleUpdateProfileImage = () => {
    setDisableImageUploadBtn(true);
    formData.append('profilePhoto', selectedFile);
    updateUserProfileImage(formData, id).then((response) => {
      if (response.status === 201) {
        setDisableImageUploadBtn(false);
        SuccessToast("Profile image updated successfully");
        getUserDetails();
        setSelectedFile('');
        setSelectedImage('');
        setShowUpdateImage(false);
      } else {
        ErrorToast("Unable to update profile image");
        setSelectedFile('');
        setSelectedImage('');
        setShowUpdateImage(false);
        setDisableImageUploadBtn(false);
      }
    }).catch(() => {
      ErrorToast("Something went wrong");
      setSelectedFile('');
      setSelectedImage('');
      setShowUpdateImage(false);
      setDisableImageUploadBtn(false);
    })
  };

  const cancelUpdateProfileImage = () => {
    setSelectedFile('');
    setSelectedImage(userDetails?.profilePhoto?.length > 0 ? userDetails?.profilePhoto : initialUrl);
    setShowImageUploader(false);
    setShowUpdateImage(false);
  };

  const GenerateCheckboxes = () => {

    const newCheckboxes = allPermissions.map((permission) => {
      const rolePermission = rolePermissions.find((rp) => rp.module === permission.module);

      return (
        <Row key={permission.module}>
          <Col sm={1}>
            <Form.Label className="my-1 fw-semibold" style={{ fontSize: "0.8rem" }}>{permission.module}:</Form.Label>
          </Col>
          <Col sm>
            <Row>
              {
                rolePermission.action.map((data, index) => (
                  <Col sm={2} className="pt-2" key={index}>
                    <div className="w-50 d-flex justify-content-around align-item-center cursor-pointer">
                      <input type="checkbox"
                        checked={selectedRole !== 'Custom' && data.value === permission.action[index].value}
                        className="p-1" value={data.value}
                      />
                      <Form.Label className="pt-2" style={{ fontSize: "0.8rem" }}>{data.title}</Form.Label>
                    </div>
                  </Col>
                ))
              }
            </Row>
          </Col>
        </Row>
      );
    });

    setCheckboxes(newCheckboxes);
  };

  useEffect(() => {
    GenerateCheckboxes();
  }, [selectedRole, userDetails, showCustomRoleField]);

  return (
    <>
      <h2 className="my-5 mx-3 bg-secondary text-white p-2" style={{ width: "98%" }}>Update User</h2>
      {/* <SubHeader title="Update User" /> */}
      <Row
        className="mx-2 mb-3"
        style={{ marginTop: screenWidth >= 1200 ? "-1%" : "-3%" }}
      >
        <Col sm={3} className="px-2">
          <div className="bg-white p-3 rounded">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <div className="rounded-circle relative cursor-pointer" onMouseEnter={() => setShowImageUploader(true)} onMouseLeave={() => setShowImageUploader(false)}>
                  <img src={selectedImage.length === 0 ? initialUrl : selectedImage} alt="" className="rounded-circle" style={{ width: "5.5rem", height: "5.5rem" }} />
                  {
                    showImageUploader ? (
                      <div className="rounded-circle d-flex justify-content-center align-items-center" onClick={() => hiddenImageField.current.click()} style={{ position: "absolute", top: '21%', background: "#00000030", width: "5.5rem", height: "5.5rem" }}>
                        <FiUpload className="text-white primary text-center" style={{ fontSize: "2rem" }} />
                      </div>
                    ) : null
                  }
                </div>
                <input type="file" className="d-none" onChange={handleImageChange} ref={hiddenImageField} />
              </div>
              {
                showUpdatImage ? (
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <div className="mt-3 w-50 d-flex justify-content-center align-items-center">
                      {/* <FiUpload className=" cursor-pointer text-success fs-2" />
                      <MdCancel className="cursor-pointer text-danger fs-2" onClick={() => setSelectedImage('')} /> */}
                      <span className="px-3 py-1 rounded-5 cursor-pointer bg-success border border-success text-white" onClick={handleUpdateProfileImage}>Upload</span>
                      <span className="px-3 py-1 rounded-5 cursor-pointer bg-white border border-danger" onClick={() => cancelUpdateProfileImage()}>Cancel</span>
                    </div>
                  </div>
                ) : null
              }
              <h3 className="pt-2">{userDetails?.firstName} {userDetails?.lastName}</h3>
            </div>
            <div className="mt-2">
              {
                userbasicDetails.map((data, index) => (
                  <Row key={index}>
                    <Col sm={2} style={{ fontWeight: 550 }}>{data?.tilte}: </Col>
                    <Col sm>{data?.desc}</Col>
                  </Row>
                ))
              }
            </div>
          </div>
        </Col>
        <Col sm={9} className="px-2">
          <div className="bg-white p-3 rounded">
            <Form onSubmit={handleUpdateProfile}>
              <h4>Edit Info</h4>
              <div>
                <h5 className="p-2 bg-soft-primary text-dark mt-3">Personal Info</h5>
                <div className="row mt-2">
                  <Form.Group className="col-sm-6 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>First Name</Form.Label>
                    <Form.Control type="text" name="firstName" defaultValue={userDetails?.firstName} onChange={handleChange} placeholder="Enter First Name" autoComplete="off" style={{ border: "1px solid #00000030" }} />
                  </Form.Group>

                  <Form.Group className="col-sm-6 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Last Name</Form.Label>
                    <Form.Control type="text" name="lastName" defaultValue={userDetails?.lastName} onChange={handleChange} placeholder="Enter Last Name" autoComplete="off" style={{ border: "1px solid #00000030" }} />
                  </Form.Group>

                  <Form.Group className="col-sm-6 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Email</Form.Label>
                    <Form.Control type="email" name="email" defaultValue={userDetails?.email} onChange={handleChange} placeholder="Enter Email" autoComplete="off" style={{ border: "1px solid #00000030" }} />
                  </Form.Group>

                  <Form.Group className="col-sm-6 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Mobile</Form.Label>
                    <Form.Control type="number" name="phone" defaultValue={userDetails?.phone} onChange={handleChange} placeholder="Enter Mobile No." autoComplete="off" style={{ border: "1px solid #00000030" }} />
                  </Form.Group>

                  <Form.Group className="col-sm-6 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Pipeline</Form.Label>
                    <Form.Select name="department" onChange={handleChange} style={{ fontSize: "0.8rem", border: '1px solid #00000030', borderRadius: "5px" }}>
                      {/* <option defaultValue={userDetails?.department}>{userDetails?.role}</option> */}
                      {
                        allPipelines.map((data, index) => (
                          <option key={index} value={data.value}>{data.role}</option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              <div className="mt-5">
                <h5 className="p-2 bg-soft-primary text-dark mt-3">Role & Permissions</h5>
                <div className="row mt-2">
                  <Form.Group className="col-sm-3 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Role</Form.Label>
                    <Form.Select onChange={(e) => handleChangeRole(e.target.value)} style={{ fontSize: "0.8rem", border: '1px solid #00000030', borderRadius: "5px" }}>
                      {/* <option defaultValue={userDetails?.value}>{userDetails?.role}</option> */}
                      <option defaultValue={userDetails?.value}>{userDetails?.role}</option>
                      {
                        allRoles.map((data, index) => (
                          <option key={index} value={data.value}>{data.role === userDetails?.value ? userDetails?.value : data.role}</option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>
                  {
                    showCustomRoleField ? (
                      <Form.Group className="col-sm-3 ms-2 pe-2 pt-2">
                        <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Custom Role</Form.Label>
                        <Form.Control type="text" name="subRole" defaultValue={userDetails?.subRole} onChange={(e) => setForm({ ...form, subRole: e.target.value })} placeholder="Enter Role" autoComplete="off" style={{ border: "1px solid #00000030" }} />
                      </Form.Group>
                    ) : null
                  }
                  {
                    showCustomRoleField === true ? (
                      <div className="">
                        <Form.Label className="my-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Permissions</Form.Label>
                        {
                          allPermissions.map((data, i) => (
                            <Row key={i}>
                              <Col sm={1}>
                                <Form.Label className="my-1 fw-semibold" style={{ fontSize: "0.8rem" }}>{data.module}:</Form.Label>
                              </Col>
                              <Col sm>
                                <Row>
                                  {
                                    data.action.map((per, index) => (
                                      <Col sm={2} className="pt-2" key={index}>
                                        <div className="w-50 d-flex justify-content-around align-item-center cursor-pointer">
                                          {
                                            console.log("index", i)
                                          }
                                          <input type="checkbox"
                                            className="p-1"
                                            value={per.value}
                                            onChange={() => handleCustomRolePermissions(data.module, per.value)}
                                            checked={modulePermissions.some(
                                              (module) =>
                                                module.module === data.module &&
                                                module.action.includes(per.value)
                                            )}
                                          />
                                          <Form.Label
                                            onClick={() => handleCustomRolePermissions(data.module, per.value)}
                                            className="pt-2 cursor-pointer" style={{ fontSize: "0.8rem" }}>{per.title}</Form.Label>
                                        </div>
                                      </Col>
                                    ))
                                  }
                                </Row>
                              </Col>
                            </Row>
                          ))
                        }
                      </div>
                    ) : (
                      <div className="">
                        <Form.Label className="my-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Permissions</Form.Label>
                        {checkboxes}
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <Button type="submit cursor-pointer w-5" disabled={disableUpdateBtn}>Update</Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserProfile;
