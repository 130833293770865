import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
// import Stepmain from "./stepbar/stepmain";
import '../../../styles/stepProgressBar.css'

import { bindActionCreators } from "redux";

//img
import topHeader from "../../../../assets/images/dashboard/top-header.png";
import topHeader1 from "../../../../assets/images/dashboard/top-header1.png";
import topHeader2 from "../../../../assets/images/dashboard/top-header2.png";
import topHeader3 from "../../../../assets/images/dashboard/top-header3.png";
import topHeader4 from "../../../../assets/images/dashboard/top-header4.png";
import topHeader5 from "../../../../assets/images/dashboard/top-header5.png";

// store
import {
  NavbarstyleAction,
  getDirMode,
  SchemeDirAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../../../store/setting/setting";
import { connect } from "react-redux";
import { propTypes } from "react-bootstrap/esm/Image";
import { toast } from "react-hot-toast";
import { TiTick } from "react-icons/ti";
import { BsCheckLg, BsWindowSidebar } from "react-icons/bs";

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
    },
    dispatch
  ),
});

const LeadSubheader = (props) => {
  //   const screenWidth = window.innerWidth;
  // const [activeStep, setActiveStep] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 1200;

  // navbarstylemode
  useEffect(() => {
    const navbarstyleMode = sessionStorage.getItem("Navbarstyle-mode");
    props.NavbarstyleAction(navbarstyleMode);
  });

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // const testVar = props.allStages.filter((data, index) => data.title === props.stage);
    props.allStages.map((data, index) => (data === props.stage) && props.setActiveStep(index + 1))
  }, [props.stage]);

  const calculateProgress = () => {
    return ((props.activeStep - 1) / (props.allStages.length - 1)) * 100;
  };

  const handleStageClick = (title, id) => {
    props.updateStage(title);
    props.setActiveStep(id);
  };

  return (
    <>
      <div className="iq-navbar-header" style={{ maxHeight: "215px" }}>
        <Container
          fluid
          className=" iq-container"
          style={{ position: "absolute", width: "96.5vw", minWidth: "96.5vw", height: "20px", marginTop: screenWidth >= 1200 ? "-3%" : "-3%" }}
        >
          <Row className="me-3">
            <Col sm={2} md={6}>
              <div className="d-flex justify-content-between flex-wrap">
                <div>
                  <h1>{props.title}</h1>
                </div>
              </div>
            </Col>
          </Row>
          <div className="m-0 p-0 mt-3 me-3">
            <div className="step-progress-bar">
              <div className="progressBar" style={{ width: `${calculateProgress()}%` }}></div>
              <div className="steps m-0 p-0">
                {props.allStages.map((step, index) => (
                  <div className="m-0 p-0 row" key={index}>
                    <Col sm={12} className="m-0 p-0 text-center">
                      <div
                        className={`step ${index + 1 <= props.activeStep ? 'active' : ''}`}
                        onClick={() => handleStageClick(step, index)}
                        style={{ cursor: props.stage === step ? "not-allowed" : "pointer" }}
                      >
                        {step != "" ? index + 1 : null}
                      </div>
                    </Col>
                  </div>
                ))}
              </div>
            </div>
            <div className="step-progress-bar">
              <div className="steps m-0 p-0">
                {props.allStages.map((step, index) => (
                  <div className="m-0 p-0 row" key={index}>
                    <Col sm={12} className="m-0 p-0 text-center">
                      <div
                        className='stageName'
                        style={{ display: step === "" && 'none' }}
                      >
                        {step}
                      </div>
                    </Col>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="step-progress-bar">
              <div className="steps2">
                {props.allStages.map((step, index) => (
                  <div key={index} className="stepName">
                    {step.title}
                  </div>
                ))}
              </div>
            </div> */}
          </div>
          {/* <Row className="mx-0 p-0 my-3 w-100">
            {props.allStages.map((data) => (
              <>
                <Col sm className="m-0 p-0 w-100">
                  <div>{data.title}</div>
                  <div
                    id="stage" 
                    className="bg-white rounded-circle fw-bold text-dark"
                    onClick={() => props.updateStage(data.title)}
                    style={{ cursor: props.stage === data.title ? "not-allowed" : "pointer" }}
                  >
                    {props.stage === data.title ? (
                      <BsCheckLg style={{ fontSize: "1rem", color: "green" }} />
                    ) : (
                      data.id
                    )}
                  </div>
                </Col>
              </>
            ))}
          </Row> */}
        </Container>
        <div>
          <img src={topHeader} alt="header" className="theme-color-default-img" style={{ height: "180px", minWidth: "96.5vw", maxWidth: "96.5vw" }} />
          <img src={topHeader1} alt="header" className=" theme-color-purple-img" style={{ height: "180px", minWidth: "96.5vw", maxWidth: "96.5vw" }} />
          <img src={topHeader2} alt="header" className="theme-color-blue-img" style={{ height: "180px", minWidth: "96.5vw", maxWidth: "96.5vw" }} />
          <img src={topHeader3} alt="header" className="theme-color-green-img" style={{ height: "180px", minWidth: "96.5vw", maxWidth: "96.5vw" }} />
          <img src={topHeader4} alt="header" className="theme-color-yellow-img" style={{ height: "180px", minWidth: "96.5vw", maxWidth: "96.5vw" }} />
          <img src={topHeader5} alt="header" className="theme-color-pink-img" style={{ height: "180px", minWidth: "96.5vw", maxWidth: "96.5vw" }} />
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadSubheader);
