import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { Link } from "react-router-dom";
import Pagination from "../pagination/pagination";
import { API } from "../../../../backend";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FiEye } from "react-icons/fi";
import { MdDeleteOutline, MdOutlineCancel, MdOutlineRefresh, MdOutlineRotateLeft } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import "./lead.css";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import ActivityBar from "../../../../components/partials/components/activitybar";
import { bulkAddLead, bulkDeleteLeads, getLeadById, getLeadListByOrg, getOrgLeadByStatus, searchLead, updateLead } from "../Hook/leadHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";

const Newleadlist = () => {
  const [newLeadList, setNewLeadList] = useState([]);
  const [arr, setArr] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All");
  const [leadIndex, setLeadIndex] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectStatus, setSelectStatus] = useState(false);

  const showPerPage = 10;
  const [searchByName, setSearchByName] = useState(false);
  const [searchById, setSearchById] = useState(false);
  const [searchByEmail, setSearchByEmail] = useState(false);
  const [searchByCountry, setSearchByCountry] = useState(false);
  const [searchByCity, setSearchByCity] = useState(false);

  const [showAddOptions, setShowAddOptions] = useState(false);

  const [toggle, setToggle] = useState(false);
  const [searchId, setSearchId] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [seacrhCountry, setSearchCountry] = useState('');
  const [actions, setActions] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSingleDelete, setShowSingleDelete] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [checkedSelectAll, setCheckedSelectAll] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 970;

  const excelInputref = useRef(null);

  const [selectedLead, setSelectedLead] = useState('');
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const orgName = localStorage.getItem("orgName");
  const orgId = localStorage.getItem('orgID');

  const leadDetails = [
    {
      title: 'Title',
      about: arr === undefined || arr.title === undefined ? '' : arr.title,
    },
    // {
    //   title: 'Assigned To',
    //   about: arr === undefined || arr.pipeline === undefined ? '' : arr.pipeline.assignTo,
    // },
    {
      title: 'Closure Date',
      about: arr === undefined || arr.closureDate === undefined ? '' : arr.closureDate,
    },
    {
      title: "Created Date",
      about: arr === undefined || arr.createdDate === undefined ? '' : arr.createdDate
    },
    {
      title: "Stage",
      about: arr === undefined || arr.stage === undefined ? '' : arr.stage
    },
    {
      title: "Estimated Worth",
      about: arr === undefined || arr.estimatedWorth === undefined ? '' : arr.estimatedWorth
    }
  ];

  const clientDetails = [
    {
      title: "Name",
      about: `${arr === undefined || arr.clientFName === undefined ? '' : arr.clientFName} ${arr === undefined || arr.clientLName === undefined ? '' : arr.clientLName}`
    },
    {
      title: "Email",
      about: arr === undefined || arr.clientEmail === undefined ? '' : arr.clientEmail
    },
    {
      title: "Phone",
      about: arr === undefined || arr.clientPhone === undefined ? '' : arr.clientPhone
    }
  ];

  const orgDetails = [
    {
      title: "Name",
      about: arr === undefined || arr.orgDetails === undefined || arr.orgDetails.orgName === undefined ? '' : arr.orgDetails.orgName
    },
    {
      title: "Email",
      about: arr === undefined || arr.orgDetails === undefined || arr.orgDetails.orgEmail === undefined ? '' : arr.orgDetails.orgEmail
    },
    {
      title: "Phone",
      about: arr === undefined || arr.orgDetails === undefined || arr.orgDetails.orgPhone === undefined ? '' : arr.orgDetails.orgPhone
    },
  ];

  const objectKeyMapping = {
    "Title": 'title',
    'Firm Name': 'firmName',
    'Status': 'status',
    "Stage": 'stage',
    'Currency': 'currency',
    'Estimated Worth': 'estimatedWorth',
    'Created Date': 'createdDate',
    'Closure Date': 'closureDate',
    'Description': 'description',
    'Client First Name': 'clientFName',
    'Client Last Name': 'clientLName',
    'Client Email': 'clientEmail',
    'Client Phone': 'clientPhone',
    'Time Zone': 'timezone',
  };

  const clientAddressKeyMapping = {
    'Client Street 1': 'lineOne',
    'Client Street 2': 'lineTwo',
    'Client City': 'city',
    'Client State': 'state',
    'Client Country': 'country',
    'Client Pincode / Zipcode': 'code',
  };

  const orgKeyMapping = {
    'Organization Name': "orgName",
    'Organization Email': 'orgEmail',
    'Organization Phone': "orgPhone"
  };

  const orgAddressKeyMapping = {
    'Organization Street 1': 'orgLineOne',
    'Organization Street 2': 'orgLineTwo',
    'Organization City': 'orgCity',
    'Organization State': 'orgState',
    'Organization Country': 'orgCountry',
    'Organization Pincode': 'orgCode',
  };

  const pipelineKeyMapping = {
    'Pipeline': 'department',
    "Assign To": 'assignTo'
  };


  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const handleDeleteLead = () => {
    const form = { delete: true };
    updateLead(selectedLead, form).then((response) => {
      if (response.status === 200) {
        SuccessToast("Lead deleted successfully");
        getLeadByStatus(filterStatus);
        setShowSingleDelete(false);
        setShowDelete(false);
      }
      else ErrorToast("Unable to delete lead");
    }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'))
  };

  const handleBulkDelete = () => {
    bulkDeleteLeads(selectedValues).then((response) => {
      if (response.status === 200) {
        SuccessToast("Leads Deleted Succesfully");
        getLeadByStatus(filterStatus);
        setShowDelete(false);
      } else {
        ErrorToast("Unable to delete leads");
      }
    }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Unable to delete leads"))
  };

  const handleOpenExcelInputField = () => {
    excelInputref.current.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const keys = sheetData[0];

      const formattedData = sheetData.slice(1).map((row) => {
        let obj = {
          clientAddress: {},
          orgDetails: {
            orgAddress: {}
          },
          orgId: orgId
        };
        keys.forEach((columnName, index) => {
          const objKey = objectKeyMapping[columnName] || columnName;
          const keyName = clientAddressKeyMapping[columnName] || columnName;
          const orgAddrKey = orgAddressKeyMapping[columnName] || columnName;
          const orgKey = orgKeyMapping[columnName] || columnName
          const pipelineKey = pipelineKeyMapping[columnName] || columnName
          if (objKey === 'title' || objKey === 'firmName' || objKey === 'stage' || objKey === 'currency' || objKey === 'estimatedWorth' || objKey === 'createdDate' || objKey === 'closureDate' || objKey === 'description' || objKey === 'clientFName' || objKey === 'clientLName' || objKey === 'clientEmail' || objKey === 'clientPhone' || objKey === 'timezone' || objKey === 'status') {
            row[index] && (obj[objKey] = row[index]);

          } else if (keyName === 'lineOne' || keyName === 'lineTwo' || keyName === 'city' || keyName === 'state' || keyName === 'country' || keyName === 'code') {
            obj['clientAddress'] = obj['clientAddress'] || {};
            row[index] && (obj['clientAddress'][keyName] = row[index]);

          } else if (orgAddrKey === 'orgLineOne' || orgAddrKey === 'orgLineTwo' || orgAddrKey === 'orgCity' || orgAddrKey === 'orgState' || orgAddrKey === 'orgCountry' || orgAddrKey === 'orgCode') {
            obj['orgDetails']['orgAddress'] = obj['orgDetails']['orgAddress'] || {};
            row[index] && (obj['orgDetails']['orgAddress'][orgAddrKey] = row[index]);

          } else if (orgKey === 'orgName' || orgKey === 'orgEmail' || orgKey === 'orgPhone') {
            obj['orgDetails'] = obj['orgDetails'] || {};
            row[index] && (obj['orgDetails'][orgKey] = row[index]);

          } else if (pipelineKey === 'department' || pipelineKey === 'assignTo') {
            obj['pipeline'] = obj['pipeline'] || {};
            row[index] && (obj['pipeline'][orgAddrKey] = row[index]);
          }
        });
        return obj;
      });
      bulkAddLead(formattedData).then((response) => {
        if (response.status === 200) {
          SuccessToast("Leads added successfully");
          getLeadByStatus("All");
          setShowAddOptions(false);
        } else {
          ErrorToast("Unable to add leads");
        }
      }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'))
    };

    reader.readAsBinaryString(file);
  };

  const submitSearchId = (e) => {
    e.preventDefault();
    if (searchId !== null) {
      if (searchId.length > 0) {
        if (toggle === false) {
          searchLead(searchId).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data) => data.clientFName === searchName || data.clientEmail === searchEmail);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        } else {
          const testVar = newLeadList.filter((data) => data.clientEmail === searchEmail);
          setNewLeadList(testVar);
        }
      }
      else {
        if (searchEmail !== null) {
          searchLead(searchEmail).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data) => data.clientFName === searchName || data.clientEmail === searchEmail);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        }
        else if (searchName !== null) {
          searchLead(searchName).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data) => data.clientFName === searchName || data.clientEmail === searchEmail);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        }
        if (searchId.length === 0) {
          setSearchId(null)
          if (searchName === null && searchEmail === null) {
            getLeadByStatus('All');
            setToggle(false);
          }
        }
      }
    }
    // if (search.length === 0) {
    //   searchLead(search).then((response) => {
    //     if (response.status === 200) {
    // setAllLeads(response.data.data);
    //       setNewLeadList(response.data.data);
    // setSearch('');

    //     } else if (response.status === 404) {
    //       ErrorToast("No data found");
    //     }
    //   })
    // } else {
    //   const testVar = newLeadList.filter((data, index) => data.randomLeadId === search || data.clientFName === search || data.clientLName === search || data.clientEmail === search);
    //   console.log('test var', testVar);
    //   setNewLeadList(testVar);
    // }
  };

  const submitSearchName = (e) => {
    e.preventDefault();
    if (searchName !== null) {
      if (searchName.length > 0) {
        if (toggle === false) {
          searchLead(searchName).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.randomLeadId === searchId || data.clientEmail === searchEmail || data.clientAddress.city || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        } else {
          const testVar = newLeadList.filter((data, index) => data.clientEmail === searchEmail);
          setNewLeadList(testVar);
        }
      }
      else {
        if (searchEmail != null) {
          searchLead(searchEmail).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.randomLeadId === searchId || data.clientEmail === searchEmail || data.clientAddress.city || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        }
        else if (searchId !== null) {
          searchLead(searchId).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.randomLeadId === searchId || data.clientEmail === searchEmail || data.clientAddress.city || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        }
        else if (searchCity !== null) {
          searchLead(searchCity).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientAddress.city || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        }
        else if (seacrhCountry !== null) {
          searchLead(seacrhCountry).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientAddress.city || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        }
        if (searchName.length === 0) {
          setSearchName(null)
          if (searchEmail === null && searchId === null && searchName.length === 0) {
            setToggle(true);
            getLeadByStatus('All');
          }
        }
      }
    }
  };

  const submitSearchEmail = (e) => {
    e.preventDefault();
    if (searchEmail !== null) {
      if (searchEmail.length > 0) {
        if (toggle === false) {
          searchLead(searchEmail).then((response) => {
            if (response.status === 200) {
              setToggle(true)
              // setAllLeads(response.data.data);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientAddress.city || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        } else {
          const testVar = newLeadList.filter((data, index) => data.clientEmail === searchEmail);
          setNewLeadList(testVar);
        }
      }
      else {
        if (searchName !== null) {
          searchLead(searchName).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientAddress.city || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        }
        else if (searchId !== null) {
          searchLead(searchId).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientAddress.city || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        }
        else if (searchCity !== null) {
          searchLead(searchCity).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientAddress.city || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        }
        else if (seacrhCountry !== null) {
          searchLead(seacrhCountry).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientAddress.city || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
              // setSearch('');
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        }
        if (searchEmail.length === 0) {
          setSearchEmail(null);
          if (searchName === null && searchId === null && searchCity === null && seacrhCountry === null) {
            setToggle(false);
            getLeadByStatus('All');
          }
        }
      }
    }
  };

  const submitSearchCity = (e) => {
    e.preventDefault();
    if (searchCity !== null) {
      if (searchCity.length > 0) {
        if (toggle === false) {
          searchLead(searchCity).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientEmail === searchEmail);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        } else {
          const testVar = newLeadList.filter((data, index) => data.clientAddress.city === searchCity);
          setNewLeadList(testVar);
        }
      }
      else {
        if (searchName !== null) {
          searchLead(searchName).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true)
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientEmail === searchEmail || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          })
        }
        else if (searchId !== null) {
          searchLead(searchId).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientEmail === searchEmail || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          })
        }
        else if (searchEmail !== null) {
          searchLead(searchEmail).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.randomLeadId === searchEmail || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          })
        }
        else if (seacrhCountry !== null) {
          searchLead(seacrhCountry).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.randomLeadId === searchEmail || data.clientAddress.country);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          })
        }
        if (searchCity.length === 0) {
          setSearchCity(null)
          if (searchName === null && searchId === null && searchEmail === null && seacrhCountry) {
            setToggle(false);
            getLeadByStatus('All');
          }
        }
      }
    }
  };

  const submitSearchCountry = (e) => {
    e.preventDefault();
    if (seacrhCountry !== null) {
      if (seacrhCountry.length > 0) {
        if (toggle === false) {
          searchLead(seacrhCountry).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientEmail === searchEmail || data.clientAddress.city === searchCity);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          });
        } else {
          const testVar = newLeadList.filter((data, index) => data.clientAddress.country === seacrhCountry);
          setNewLeadList(testVar);
        }
      }
      else {
        if (searchName !== null) {
          searchLead(searchName).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true)
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientEmail === searchEmail || data.clientAddress.city === searchCity);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          })
        }
        else if (searchId !== null) {
          searchLead(searchId).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.clientEmail === searchEmail || data.clientAddress.city === searchCity);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          })
        }
        else if (searchEmail !== null) {
          searchLead(searchEmail).then((response) => {
            if (response.status === 200) {
              // setAllLeads(response.data.data);
              setToggle(true);
              const testVar = response.data.data.filter((data, index) => data.clientFName === searchName || data.randomLeadId === searchId || data.randomLeadId === searchEmail || data.clientAddress.city === searchCity);
              if (testVar.length === 0) {
                setNewLeadList(response.data.data);
              }
              else {
                setNewLeadList(testVar);
              }
            } else if (response.status === 404) {
              ErrorToast("No data found");
            }
          })
        }
        if (searchCity.length === 0) {
          setSearchCity(null)
          if (searchName === null && searchId === null && searchEmail === null && searchCity === null) {
            setToggle(false);
            getLeadByStatus('All');
          }
        }
      }
    }
  };

  const handleResetFilters = () => {
    getLeadByStatus('All');

    setSearchName(null);
    setSearchEmail(null);
    setSearchId(null);
    setSearchCity(null);
    setSearchCountry(null);

    setSearchById(false);
    setSearchByName(false);
    setSearchByEmail(false);
    setSearchByCity(false);
    setSearchByCountry(false);
  };

  const getLeadDetails = (data) => {
    getLeadById(data).then((response) => {
      if (response.status === 200) setArr(response.data.data);
      else setArr({});
    }).catch((err) => err?.response?.data?.message && WarningToast(err?.response?.data?.message));
  };

  const getLeadByStatus = (status) => {
    if (status === "Deleted") {
      getLeadListByOrg(status).then((response) => {
        if (response.status === 200) {
          // setAllLeads(response.data.data);
          setNewLeadList(response.data.data);
          getLeadDetails(response.data.data[0]._id);
          setCheckedSelectAll(true)
        }
      }).catch((err) => err?.response?.data?.message && WarningToast(err?.response?.data?.message));
    } else {
      if (status === "All") {
        getLeadListByOrg(status).then((response) => {
          if (response.status === 200) {
            // setStatus(response.data.data[0]._id);
            // setAllLeads(response.data.data);
            setNewLeadList(response.data.data);
            getLeadDetails(response.data.data[0]._id);
            setCheckedSelectAll(true)
          }
        }).catch((err) => err?.response?.data?.message && WarningToast(err?.response?.data?.message));
      } else {
        getOrgLeadByStatus(status).then((response) => {
          if (response.status === 200) {
            // setAllLeads(response.data.data);
            setNewLeadList(response.data.data);
            getLeadDetails(response.data.data[0]._id);
            setCheckedSelectAll(false);
          }
        }).catch((err) => err?.response?.data?.message && WarningToast(err?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    const status = "All";
    getLeadByStatus(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStatus = async (id, updatedStatus) => {
    const form = { status: updatedStatus }
    updateLead(id, form).then((response) => {
      if (response.status === 200) {
        SuccessToast('Lead status updated successfully');
        getLeadByStatus('All');
        setSelectStatus(false);
      } else {
        ErrorToast('Unable to update status');
      }
    }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'))
  };

  const handleSelect = (name) => {
    setFilterStatus(name);
    getLeadByStatus(name);
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleSearch = (e) => {
  //   setSearch(e.target.value);
  //   if (e.target.value.length === 0) {
  //     const testVar = allLeads.filter((data, index) => data.clientFName != e.target.value || data.clientEmail != e.target.value || data.randomLeadId != search);
  //     setNewLeadList(testVar);
  //   }
  // };

  const handleCheckboxChange = (newValues) => {
    setSelectedValues(newValues);
  };

  const handleSelectAll = () => {
    const allItems = newLeadList.map((option, index) => option._id);
    if (selectAll) {
      setSelectedValues([]);
    } else {
      setSelectedValues(allItems);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckChange = (value) => {
    const currentIndex = selectedValues.indexOf(value);
    const newValues = [...selectedValues];
    if (currentIndex === -1) {
      newValues.push(value);
    } else {
      newValues.splice(currentIndex, 1);
    }
    handleCheckboxChange(newValues);
  }

  const handleView = (e) => {
    getLeadDetails(e);
  };

  const leadOptions = () => {
    return (
      <>
        <div className="m-0 p-0" style={{ position: "relative" }}>
          <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2"
            onClick={() => setShowAddOptions(!showAddOptions)}
            style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
            <Col sm={10}>
              Add Lead
            </Col>
            <Col sm={1} className="text-end">
              {
                showAddOptions ? (
                  <BsChevronUp className="fs-4 text-dark" style={{ cursor: "pointer" }} />
                ) : (
                  <BsChevronDown className="fs-4 text-dark" style={{ cursor: "pointer" }} />
                )
              }
            </Col>
          </Row>
          {
            showAddOptions ? (
              <ul className="m-0 ms-3 p-0 bg-white rounded"
                style={{ width: "91%", zIndex: 9, listStyle: "none", position: "absolute", boxShadow: "0px 0px 10px #c8c9ca" }}
              >
                <Link to={`/${orgName}/dashboard/lead/add-lead`}>
                  <li className='py-2 px-3 text-dark' style={{ cursor: "pointer" }}>Add Lead</li>
                </Link>
                <input type="file" ref={excelInputref} onChange={handleFileUpload} className="d-none" />
                <Link to="#" onClick={handleOpenExcelInputField}>
                  <li className='py-2 px-3 text-dark' style={{ cursor: "pointer" }}>Import leads from excel</li>
                </Link>
              </ul>
            ) : null
          }
        </div>
        {
          (searchId !== null || searchName !== null || searchCity !== null || seacrhCountry !== null) ? (
            <Row onClick={() => handleResetFilters()} className="bg-primary mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{ cursor: "pointer", borderRadius: "5px" }}>
              <Col sm={9}>Reset Filters</Col>
              <Col sm={1}>
                <Tooltip title="Reset">
                  <Link to="#">
                    <MdOutlineRotateLeft className="text-white fs-4" />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          ) : null
        }
        {
          selectedValues.length > 0 && (
            <Row className="bg-danger mx-3 py-2 ps-2 pe-3 my-2 text-white" onClick={() => setShowDelete(true)} style={{ cursor: "pointer", borderRadius: "5px" }}>
              <Col sm={12}>Delete</Col>
            </Row>
          )
        }
      </>
    )
  };

  const handleChecked = (e, id) => {
    handleView(id);
    handleCheckChange(id);
    setSelectedLead(id)
  };

  const deleteSingleLead = (data) => {
    setShowSingleDelete(true);
    setSelectedLead(data)
  };

  const restoreDeletedLead = (data) => {
    const form = { delete: false }
    updateLead(data, form).then((response) => {
      if (response.status === 200) {
        SuccessToast("Lead restored");
        getLeadByStatus(filterStatus);
      } else {
        ErrorToast('Unable to restore lead');
      }
    }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'))
  };

  const styleHandle = (data) => {
    if (data.status === "Hold") {
      return {
        backgroundColor: "#FFB802",
        textAlign: "center",
        fontSize: "90%",
      };
    } else if (data.status === "Won") {
      return {
        backgroundColor: "#10813B",
        textAlign: "center",
        fontSize: "90%",
      };
    } else if (data.status === "Lost") {
      return {
        backgroundColor: "#A70200",
        textAlign: "center",
        fontSize: "90%",
      };
    }
  };

  const mainfilters = ['All', 'Hold', 'Lost', 'Deleted'];

  const allStatuses = [
    {
      id: 0,
      name: 'Won'
    },
    {
      id: 1,
      name: 'Hold'
    },
    {
      id: 2,
      name: 'Lost'
    }
  ]

  const handleShowOptions = () => {
    actions && setActions(false);
    selectStatus && setSelectStatus(false);
  };


  const handleSelectedStatus = (index) => {
    setLeadIndex(index)
    setSelectStatus(!selectStatus);
  }

  return (
    <div onClick={handleShowOptions}>
      <SubHeader title="Lead List"
      // btnTitle="Add Lead"
      // btnLink={`/${orgName}/dashboard/lead/add-lead`}
      />
      <div>
        <div className="d-flex text-dark"
          style={{ marginTop: screenWidth >= 1200 ? "-2%" : "-3%", flexDirection: "row" }}
        >
          <Row sm={3} className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
            <Col sm={12} className="rounded bg-white">
              <Row className="py-2">
                <h5 className="text-primary" style={{ fontWeight: "550" }}>About Lead</h5>
              </Row>
              {
                leadDetails.map((data, index) => (
                  <Row className="py-1" key={index}>
                    <Col md={5} sm={12}>
                      <div style={{ fontWeight: "550" }}>{data.title}: </div>
                    </Col>
                    <Col className="m-0 p-0 text-start">{data.about}</Col>
                  </Row>
                ))
              }
            </Col>

            <Col sm={12} className="mt-2 rounded bg-white">
              <Row className="py-2">
                <h5 className="text-primary" style={{ fontWeight: "550" }}>About Client</h5>
              </Row>
              {
                clientDetails.map((data, index) => (
                  <Row className="py-1" key={index}>
                    <Col md={3} sm={12}>
                      <div style={{ fontWeight: "550" }}>{data.title}: </div>
                    </Col>
                    <Col>{data.about}</Col>
                  </Row>
                ))
              }
            </Col>

            <Col sm={12} className="mt-2 rounded bg-white">
              <Row className="py-2">
                <h5 className="text-primary" style={{ fontWeight: "550" }}>About Oeganization</h5>
              </Row>
              {
                orgDetails.map((data, index) => (
                  <Row className="py-1" key={index}>
                    <Col md={3} sm={12}>
                      <div style={{ fontWeight: "550" }}>{data.title}: </div>
                    </Col>
                    <Col>{data.about}</Col>
                  </Row>
                ))
              }
            </Col>
          </Row>

          <Row sm={7} className={`h-100 mx-2 ps-3 mb-5`}
            style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%", }}
          >
            <Row sm={12} className="bg-white rounded pt-2 mb-3 text-center fs-4">
              {mainfilters.map((data, index) => (
                <Col
                  onClick={() => handleSelect(data)} key={index}
                  className={`${data === filterStatus ? "border-primary text-primary" : "border-white text-dark"}`}
                  style={{ cursor: "pointer", borderBottom: "3px solid" }}
                >
                  {data}
                </Col>
              ))}
            </Row>
            <Row className="bg-white rounded">
              <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
                <table className="table-responsive" style={{ width: "100%" }}>
                  <thead>
                    <tr className="h-20 text-primary fw-bold" style={{ borderBottom: "1px solid #a09fa3" }}>
                      <th className="fw-bold" style={{ fontSize: "0.8rem" }}>
                        <input type="checkbox" id="id" className="ms-0 " name="selectAll" value="selectAll"
                          checked={checkedSelectAll && selectedValues.length === newLeadList.length}
                          onChange={handleSelectAll}
                          style={{ margin: "0.5rem", cursor: "pointer" }}
                        />
                      </th>
                      <th className="fw-bold" style={{ fontSize: "0.8rem", width: "10%", paddingLeft: "1rem" }}>
                        {searchById === false ? (
                          <>
                            Id
                            {/* <BsSearch id="ldeadSeaerchIcon" style={{ fontSize: "0.6rem", cursor: "pointer" }} onClick={() => setSearchById(!searchById)} /> */}
                          </>
                        ) : (
                          <>
                            <form onSubmit={submitSearchId} method="POST">
                              <input type="text" id="leadSearchById" placeholder="ID" className="form-control" onChange={(e) => setSearchId(e.target.value)} />
                              <MdOutlineCancel onClick={() => setSearchById(!searchById)} style={{ fontSize: "1rem", cursor: "pointer" }} />
                            </form>
                          </>
                        )}
                      </th>
                      <th
                        className="fw-bold"
                        style={{ fontSize: "0.8rem", width: "20%" }}
                      >
                        {searchByName === false ? (
                          <>
                            <div style={{ width: "70%", display: "inline", marginRight: "10%" }}>Name</div>
                            {/* <BsSearch id="ldeadSeaerchIcon" style={{ fontSize: "0.6rem", cursor: "pointer" }} onClick={() => setSearchByName(!searchByName)} /> */}
                          </>
                        ) : (
                          <>
                            <>
                              <form onSubmit={submitSearchName} method="POST">
                                <input type="search" name="clientFName" onChange={(e) => setSearchName(e.target.value)} placeholder="Name" className="testInput" />
                                <MdOutlineCancel
                                  style={{ fontSize: "1rem", cursor: "pointer" }}
                                  onClick={() => setSearchByName(!searchByName)}
                                />
                              </form>
                              {/* <input type="search" name="clientLName" placeholder="Name" className="testInput" onChange={handleSearch} />
                                <MdOutlineCancel
                                  style={{ fontSize: "1rem", cursor: "pointer" }}
                                  onClick={() => setSearchByName(!searchByName)}
                                /> */}
                            </>
                          </>
                        )}
                      </th>
                      <th
                        className="fw-bold"
                        style={{ fontSize: "0.8rem", textAlign: "center" }}
                      >
                        {searchByEmail === false ? (
                          <>
                            Email
                            {/* <BsSearch id="ldeadSeaerchIcon" style={{ fontSize: "0.6rem", cursor: "pointer" }} onClick={() => setSearchByEmail(!searchByEmail)} /> */}
                          </>
                        ) : (
                          <>
                            <>
                              <form onSubmit={submitSearchEmail} method="POST">
                                <input type="search" className="testInput" name="clientEmail" onChange={(e) => setSearchEmail(e.target.value)} placeholder="Email" />
                                <MdOutlineCancel
                                  style={{ fontSize: "1rem", cursor: "pointer" }}
                                  onClick={() => setSearchByEmail(!searchByEmail)}
                                />
                              </form>
                            </>
                          </>
                        )}
                      </th>
                      <th className="fw-bold" style={{ fontSize: "0.8rem", width: "15%", textAlign: "center" }}>
                        {searchByCity === false ? (
                          <>
                            City
                            {/* <BsSearch id="ldeadSeaerchIcon" style={{ fontSize: "0.6rem", cursor: "pointer" }} onClick={() => setSearchByCity(!searchByCity)} /> */}
                          </>
                        ) : (
                          <form onSubmit={submitSearchCity} method="POST">
                            <input type="search" name="clientCity" placeholder="City" className="testInput" onChange={(e) => setSearchCity(e.target.value)} />
                            <MdOutlineCancel style={{ fontSize: "1rem", cursor: "pointer" }} onClick={() => setSearchByCity(!searchByCity)} />
                          </form>
                        )}
                      </th>
                      <th className="fw-bold" style={{ fontSize: "0.8rem", marginLeft: "3rem", textAlign: "center" }}>
                        {searchByCountry === false ? (
                          <>
                            Country
                            {/* <BsSearch id="ldeadSeaerchIcon" style={{ fontSize: "0.6rem", cursor: "pointer" }} onClick={() => setSearchByCountry(!searchByCountry)}
                            /> */}
                          </>
                        ) : (
                          <>
                            <form onSubmit={submitSearchCountry} method="POST">
                              <input type="search" name="clientCountry" placeholder="Country" onChange={(e) => setSearchCountry(e.target.value)} className="testInput" />
                              <MdOutlineCancel style={{ fontSize: "1rem", cursor: "pointer" }}
                                onClick={() => setSearchByCountry(!searchByCountry)}
                              />
                            </form>
                          </>
                        )}
                      </th>
                      <th className="fw-bold" style={{ fontSize: "0.8rem", marginLeft: "3rem", textAlign: "center" }}>Status</th>
                      <th className="fw-bold" style={{ fontSize: "0.8rem", marginLeft: "3rem", textAlign: "center" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newLeadList
                      .slice(pagination.start, pagination.end)
                      .map((data, index) => (
                        <tr
                          key={index}
                          style={{
                            borderBottom: "1px solid gray",
                          }}
                        >
                          <td className="py-2">
                            <input type="checkbox" name="lead" style={{ cursor: "pointer" }}
                              onChange={(e) => handleChecked(e, data._id)}
                              checked={selectedValues.includes(data._id)}
                            // defaultChecked={newLeadList[0]}
                            />
                          </td>
                          <td className="text-left">
                            <span>{data.randomLeadId === undefined ? '' : data.randomLeadId}</span>
                          </td>
                          <td className="text-start">{data.clientFName} {data.clientLName}</td>
                          <td className="text-center">{data.clientEmail}</td>
                          <td className="text-center">{data.clientAddress === undefined || data.clientAddress.city === undefined ? '' : data.clientAddress.city}</td>
                          <td className="text-center">{data.clientAddress === undefined || data.clientAddress.country === undefined ? '' : data.clientAddress.country}</td>
                          <td className="text-center pt-3" onClick={() => handleSelectedStatus(index)} style={{ display: "relative", cursor: "pointer" }}>
                            <p style={styleHandle(data)} className="text-white rounded-pill text-center">{data.status}</p>
                            {
                              (selectStatus === true && (leadIndex === index)) && (
                                <ul className="bg-white text-dark rounded m-0 p-0"
                                  style={{ width: "10%", listStyle: "none", position: "absolute", borderLeft: "1px solid #c8c9ca", borderTop: "1px solid #c8c9ca", boxShadow: "5px 5px 10px #c8c9ca" }}
                                >
                                  {
                                    allStatuses.map((curr, ind) => (
                                      <li
                                        key={ind}
                                        className={`${data.status === curr.name ? 'bg-primary text-white' : 'text-primary'} py-2`}
                                        value={curr.name} onClick={(e) => updateStatus(data._id, curr.name)} style={{ borderBottom: "1px solid #c8c9ca", cursor: "pointer" }}
                                      >
                                        {curr.name}
                                      </li>
                                    ))
                                  }
                                </ul>
                              )
                            }
                          </td>
                          <td className="text-center">
                            <Tooltip title="View" key="view">
                              <Link to={`/${orgName}/dashboard/lead/lead-details/${data._id}`}>
                                <FiEye id="viewLead" className="text-primary" style={{ fontSize: "1rem", cursor: "pointer" }} />
                              </Link>
                            </Tooltip>
                            {
                              !data.delete ? (
                                <Tooltip title="Delete" key="delete">
                                  <Link to='#' className="mx-2">
                                    <MdDeleteOutline onClick={() => deleteSingleLead(data._id)} className="text-danger" style={{ fontSize: "1rem", cursor: "pointer" }} />
                                  </Link>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Restore Invoice" key="delete" className="">
                                  <Link to={`#`} className="mx-2">
                                    <MdOutlineRefresh onClick={() => restoreDeletedLead(data._id)} className="text-success" style={{ fontSize: "1.2rem", cursor: "pointer", }} />
                                  </Link>
                                </Tooltip>
                              )
                            }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {
                  newLeadList.length === 0 && (
                    <div className='mt-5 fs-4 text-center text-secondary'>
                      No Leads To Show
                    </div>
                  )
                }
              </div>
              <div className="my-5">
                <Pagination
                  showPerPage={showPerPage}
                  onPaginationChange={onPaginationChange}
                  total={newLeadList.length}
                />
              </div>
            </Row>
          </Row>
          <ActivityBar options={leadOptions} />
        </div>
      </div>

      <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Body className="my-2">
          <Row className="mb-2 ms-1">
            <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Lead</Col>
            <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">
              You want to delete all the selected leads ?
            </Col>
          </Row>
          <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
            <div
              className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
              onClick={() => handleBulkDelete()}
              style={{ borderRadius: "4px", cursor: "pointer" }}
            >
              Yes
            </div>
            <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
              onClick={() => setShowDelete(false)}
              style={{ borderRadius: "5px", cursor: "pointer" }}
            >
              No
            </div>
          </div>
        </Modal.Body>
      </Modal >

      <Modal centered show={showSingleDelete} onHide={() => setShowSingleDelete(false)}>
        <Modal.Body className="my-2">
          <Row className="mb-2 ms-1">
            <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Lead</Col>
            <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">
              You want to delete the lead ?
            </Col>
          </Row>
          <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
            <div
              className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
              onClick={() => handleDeleteLead()}
              style={{ borderRadius: "4px", cursor: "pointer" }}
            >
              Yes
            </div>
            <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
              onClick={() => setShowSingleDelete(false)}
              style={{ borderRadius: "5px", cursor: "pointer" }}
            >
              No
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </div>
  );
};

export default Newleadlist;