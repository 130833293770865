import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { addLeadActivity } from "../../../Hook/leadHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../../../components/toast";

const EmailForm = ({ leadId, leadActivitiesByType }) => {
  const [form, setForm] = useState({});
  const [email, setEmail] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);

  const orgId = localStorage.getItem('orgID');

  const addActivity = async (e) => {
    e.preventDefault();
    setDisabledBtn(true);
    addLeadActivity(form).then((response) => {
      if (response.status === 201) {
        SuccessToast('Activity Added');
        leadActivitiesByType('Email');
        setEmail('');
        setDisabledBtn(false);
      } else {
        ErrorToast("Unable to add activity");
        setDisabledBtn(false);
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong')
      setDisabledBtn(false);
    })
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      type: "Email",
      leadId: leadId,
      orgId: orgId
    });
  };

  return (
    <Row>
      <form className="my-3" onSubmit={addActivity} style={{ width: "98%" }}>
        <Col sm={12} className="mt-2">
          <textarea rows="5" placeholder="Send an email" className="w-100 py-1 border border-primary rounded" id="leadFormInput" name="desc"
            value={email} onChange={handleChange} required
          ></textarea>
        </Col>
        <Col sm={12} className="d-flex justify-content-end aling-items-end">
          <div className="m-0 p-0" style={{ cursor: disabledBtn ? 'not-allowed' : 'pointer' }}>
            <Button type="submit" variant={disabledBtn ? 'secondary' : 'primary'} className="ms-0 text-white fs-5 px-4" disabled={disabledBtn ? true : false} style={{ padding: '3px' }}>Send Email</Button>
          </div>
        </Col>
      </form>
    </Row>
  );
};

export default EmailForm;
