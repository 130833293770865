import axios from "axios";
import { API } from "../../../../backend"

const baseUrl = `${API}/activities`

export const addRecentActivity = async (form) => {
    const url = `${baseUrl}/add`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios(config);
    return response;
};

export const getRecentActivities = async (module) => {
    const url = `${baseUrl}/${module}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios(config);
    return response;
};

export const getEntityRecentActivities = async (module, id) => {
    const url = `${baseUrl}/${module}/${id}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios(config);
    return response;
};