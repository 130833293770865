import React, { useEffect, useState, useRef } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Row, Col, Table, Container, Button, Form, Modal } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import RecordPayment from "./record-payment";
import { RWebShare } from "react-web-share";
import ReactToPrint from "react-to-print";
import Card from "../../../../components/Card";
import { BsPrinterFill, BsFillShareFill } from "react-icons/bs";
import { FiRefreshCw } from 'react-icons/fi';
import { RiEdit2Fill } from "react-icons/ri";
import "./invoice.css";
import ActivityBar from "../../../../components/partials/components/activitybar";
import Preview from "./preview";
import { cancelInvoice, draftToInvoice, getInvoiceById, restoreCancelledInvoice, updateInvoice } from "../Hook/invoiceHooks";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { MdBlock, MdContentCopy } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { GoNote } from 'react-icons/go';
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import SetReminderModal from "./setReminder-modal";
import useFullPageLoader from "../Hook/useFullPageLoader";
import { ToastContainer } from "react-toastify";
import { addRecentActivity, getEntityRecentActivities } from "../Hook/recentActivityHooks";

const InvoiceDetails = () => {
  const { id } = useParams();
  const editId = id;
  const [data, setData] = useState([]);
  const [allRecentActivities, setAllRecentActivities] = useState([]);
  const [firmDetails, setFirmDetails] = useState({});
  const [firmAdd, setFirmAdd] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [clientAdd, setClientAdd] = useState({});
  const [items, setItems] = useState([]);
  const [terms, setTerms] = useState([]);
  const [view, setView] = useState(false);
  const [load, setLoad] = useState(true);
  const [showCancel, setShowCancel] = useState(false);
  const [hideStatus, setHideStatus] = useState(false);
  const [showReminders, setShowReminders] = useState(false);
  const init = []
  const [addedtax, setAddedTax] = useState(init);
  const [currentInvoiceNumber, setCurrentInvoiceNumber] = useState('');
  const [selectedFirmId, setSelectedFirmId] = useState('');
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 1200;

  const componentRef = useRef();
  const orgName = localStorage.getItem('orgName');
  const orgId = localStorage.getItem('orgID');


  const datetimeString = new Date();
  const dateObject = new Date(datetimeString);

  const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
  const formattedTime = dateObject.toLocaleTimeString();

  const getInvoiceData = () => {
    getInvoiceById(id).then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setLoad(false);
          // setSelectedFirmId(response.data.data[0].firm.firmID);
          setData(response.data.data[0]);
          setFirmDetails(response.data.data[0].firm);
          setSelectedFirmId(response.data.data[0].firm.firmID);
          setFirmAdd(response.data.data[0].firm.address);
          setClientDetails(response.data.data[0].client);
          setClientAdd(response.data.data[0].client.address);
          setAddedTax(response.data.data[0].tax);
          setItems(response.data.data[0].items);
          setTerms(response.data.data[0].termsNcondition);
          setCurrentInvoiceNumber(response.data.data[0].invoiceNumber);
        }, 2000);
      } else {
        setData({});
      }
    }).catch(() => {
      setData({})
    })
  };

  useEffect(() => {
    getInvoiceData();
  }, []);

  const getAllRecentActivities = () => {
    getEntityRecentActivities("invoice", id).then((response) => {
      response.status === 200 ? setAllRecentActivities(response?.data?.data) : setAllRecentActivities([]);
    }).catch(() => setAllRecentActivities([]));
  };

  useEffect(() => {
    getAllRecentActivities();
  }, []);

  const currentDate = new Date();
  let givenDate;

  useEffect(() => {
    givenDate = new Date(data.dueDate);
  }, [data]);

  const updateStatus = () => {
    if ((data.status === "Pending") && currentDate > givenDate) {
      const form = {
        orgId: orgId,
        status: "Overdue"
      };

      updateInvoice(editId, form).then((response) => {
        if (response.status === 200) {
          getInvoiceData();
        } else {
          ErrorToast("Unable to update status")
        }
      }).catch((err) => {
        err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
      })
    }
  }

  useEffect(() => {
    updateStatus();
  }, [data]);

  useEffect(() => {
    currentInvoiceNumber.length === 0 ? showLoader() : hideLoader()
  }, [currentInvoiceNumber]);

  const convertDraft = () => {
    draftToInvoice(id).then((response) => {
      if (response.status === 201) {
        SuccessToast("Draft converted to Invoice");
        getInvoiceData();
      }
      else {
        ErrorToast("Unable to convert");
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
    })
  };

  const handleCancel = () => {
    cancelInvoice(id).then((response) => {
      if (response.status === 200) {

        const activityForm = {
          module: ['invoice', 'firm', 'client'],
          entityId: [id, data?.firm?.firmID, data?.client?.client_id],
          activity: 'Invoice Cancelled',
          activityDesc: `Invoice ${data?.invoiceNumber} cancelled`,
          createdDate: formattedDate,
          createdTime: formattedTime
        };

        addRecentActivity(activityForm).then((resp) => resp.status === 201 && getAllRecentActivities());

        SuccessToast("Invoice Cancelled");
        getInvoiceData();
      } else {
        ErrorToast("Unable to Cancel Invoice")
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
    });
    setShowCancel(false);
  }

  const handleRestoreInvoice = () => {
    restoreCancelledInvoice(id).then((response) => {
      if (response.status === 200) {

        const activityForm = {
          module: ['invoice', 'firm', 'client'],
          entityId: [id, data?.firm?.firmID, data?.client?.client_id],
          activity: "Invoice Restored",
          activityDesc: `Invoice ${data?.invoiceNumber} restored`,
          createdDate: formattedDate,
          createdTime: formattedTime
        };

        addRecentActivity(activityForm).then((resp) => resp.status === 201 && getAllRecentActivities());

        SuccessToast("Invoice Restored");
        getInvoiceData();
      }
      else {
        ErrorToast("Unable to Restore Invoice");
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
    });
  };

  const handleCopyInvoice = () => {
    localStorage.setItem('CopiedInvoiceId', id);
    SuccessToast("Invoice Copied")
  };

  const format = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const path = `${window.location.protocol}//${window.location.hostname}/invoice/${orgId}/${id}`;
  // https://crm.cubicleweb.com/invoice/64b787367d0202eb67abf4c6

  const invoiceOptions = () => {
    return (
      <>
        {
          !data.draft && !data.cancel && (
            <RWebShare data={{ text: "", url: path, title: "invoice", }}>
              <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{ cursor: "pointer" }}>
                <Col sm={9}>Share Invoice</Col>
                <Col sm={1}>
                  <Tooltip title="Share">
                    <Link to="#" className="py-1 px-3 text-white roundedme-2" style={{ cursor: "pointer" }}>
                      <BsFillShareFill style={{ fontSize: "0.8rem", cursor: "pointer" }} />
                    </Link>
                  </Tooltip>
                </Col>
              </Row>
            </RWebShare>
          )
        }

        {/* {
          !data.draft && !data.cancel && (
            <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" onClick={() => setShowShare(true)} style={{ cursor: "pointer" }}>
              <Col sm={9}>Share Invoice</Col>
              <Col sm={1}>
                <Tooltip title="Share">
                  <Link to="#" className="py-1 px-3 text-white roundedme-2" style={{ cursor: "pointer" }}>
                    <BsFillShareFill style={{ fontSize: "0.8rem", cursor: "pointer" }} />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )
        } */}

        <ReactToPrint
          trigger={() => (
            <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{ cursor: "pointer" }}>
              <Col sm={9}>Print Invoice</Col>
              <Col sm={1} className="text-end">
                <Tooltip title="Print">
                  <Link to="#" className="py-1 px-3 text-white rounded me-2">
                    <BsPrinterFill style={{ fontSize: "1rem", cursor: "pointer" }} />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )}
          content={() => componentRef.current}
        />

        {
          !data.draft && !data.cancel && (
            <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" onClick={() => setView(true)} style={{ cursor: "pointer" }}>
              <Col sm={9}>Record Payment</Col>
              <Col sm={1} className="text-end">
                <Tooltip title="Record Payment">
                  <Link to="#" className="py-1 px-3 rounded text-white me-2">
                    <RiEdit2Fill style={{ fontSize: "1rem", cursor: "pointer" }} />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )
        }

        {
          data.draft && (
            <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" onClick={convertDraft} style={{ cursor: "pointer" }}>
              <Col sm={9}>Convert Draft to Invoice</Col>
              <Col sm={1} className="text-end">
                <Tooltip title="Convert Invoice">
                  <Link to="#" className="py-1 px-3 rounded text-white me-2">
                    <FiRefreshCw style={{ fontSize: "1rem", cursor: "pointer" }} />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )
        }

        {
          (data.status === "Draft" || data.status === "Pending" || data.status === "Overdue") && (
            <Link to={`/${orgName}/dashboard/invoice/edit/${id}`}>
              <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{ cursor: "pointer" }}>
                <Col sm={9}>{data.draft ? 'Edit Draft' : 'Edit Invoice'}</Col>
                <Col sm={1} className="text-end">
                  <Tooltip title={data.draft ? 'Edit Draft' : 'Edit Invoice'}>
                    <Link to={'#'} className="py-1 px-3 rounded text-white me-2">
                      <HiOutlinePencilAlt style={{ fontSize: "1rem", cursor: "pointer" }} />
                    </Link>
                  </Tooltip>
                </Col>
              </Row>
            </Link>
          )
        }

        {
          !data.draft && (
            <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" onClick={() => setHideStatus(!hideStatus)} style={{ cursor: "pointer" }}>
              <Col sm={9}>{hideStatus ? 'Show Status' : 'Hide Status'}</Col>
              <Col sm={1} className="text-end">
                <Tooltip title="Convert Invoice">
                  <Link to="#" className="py-1 px-3 rounded text-white me-2">
                    {
                      hideStatus ? (
                        <AiFillEye style={{ fontSize: "1rem", cursor: "pointer" }} />
                      ) : (
                        <AiFillEyeInvisible style={{ fontSize: "1rem", cursor: "pointer" }} />
                      )
                      // <AiFillEyeInvisible />
                    }
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )
        }

        {
          data.cancel && (
            <Row className="bg-success rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" onClick={() => handleRestoreInvoice()} style={{ cursor: "pointer" }}>
              <Col sm={9}>Restore Invoice</Col>
              <Col sm={1} className="text-end">
                <Tooltip title="Restore Invoice">
                  <Link to="#" className="py-1 px-3 rounded text-white me-2">
                    <FiRefreshCw style={{ fontSize: "1rem", cursor: "pointer" }} />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )
        }

        <Link to={`/${orgName}/dashboard/invoice/copiedInvoice`}>
          <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" onClick={() => handleCopyInvoice()} style={{ cursor: "pointer" }}>
            <Col sm={9}>Copy Invoice</Col>
            <Col sm={1} className="text-end">
              <Tooltip title="Restore Invoice">
                <Link to="#" className="py-1 px-3 rounded text-white me-2">
                  <MdContentCopy style={{ fontSize: "1rem", cursor: "pointer" }} />
                </Link>
              </Tooltip>
            </Col>
          </Row>
        </Link>


        <Link to={`#`} onClick={() => setShowReminders(true)}>
          <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{ cursor: "pointer" }}>
            <Col sm={9}>Reminders</Col>
            <Col sm={1} className="text-end">
              <Tooltip title="Reminders">
                <Link to="#" className="py-1 px-3 rounded text-white me-2">
                  <GoNote style={{ fontSize: "1rem", cursor: "pointer" }} />
                </Link>
              </Tooltip>
            </Col>
          </Row>
        </Link>

        {
          ((data.status === "Pending" || data.status === 'Overdue') && data.draft === false) && (
            <Row className="bg-danger rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" onClick={() => setShowCancel(true)} style={{ cursor: "pointer" }}>
              <Col sm={9}>Cancel Invoice</Col>
              <Col sm={1} className="text-end">
                <Tooltip title="Record Payment">
                  <Link to="#" className="py-1 px-3 rounded text-white me-2">
                    <MdBlock style={{ fontSize: "1rem", cursor: "pointer" }} />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )
        }

        {view && (
          <RecordPayment getInvoiceData={getInvoiceData} data={data} view={view} setView={setView} invoiceNumber={data.invoiceNumber} total={data.total} getAllRecentActivities={getAllRecentActivities}
          />
        )}
      </>
    );
  };

  return (
    <>
      <ToastContainer />
      <SubHeader title="Invoice Details" />
      <div>
        <div className="d-flex text-dark" style={{ marginTop: screenWidth >= breakPoint ? "-1%" : "-3%", flexDirection: "row", }}>
          <Row className="mx-3 mb-5" style={{ width: screenWidth >= breakPoint ? "70%" : "90%" }}>
            <div className="rounded px-5 bg-white">
              <Card.Body>
                <div className="p-3 bg-white px-5" style={{ boxShadow: "0px 0px 10px 2px #E8E8E8" }}>
                  <div className="m-0 p-0 px-3" ref={componentRef}>
                    {/* <iframe src={`${window.location.protocol}/${window.location.hostname}/invoice/${id}`} style={{height: "100vh", width: "100vw"}} frameborder="0"></iframe> */}
                    <Preview
                      addedtax={addedtax} form={data} firmDetails={firmDetails} firmAdd={firmAdd} clientDetails={clientDetails}
                      clientAdd={clientAdd} items={items} terms={terms} currency="$" hideStatus={hideStatus} />
                  </div>
                </div>
              </Card.Body>
            </div>
          </Row>
          <ActivityBar options={invoiceOptions} editRequest="editRequest" allRecentActivities={allRecentActivities} />
        </div>
        <Modal centered show={showCancel} onHide={() => setShowCancel(false)}>
          <Modal.Body className="my-2">
            <Row className="mb-2 ms-1">
              <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Cancel Invoice</Col>
              <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">Do you want to cancel the invoice ?</Col>
            </Row>
            <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
              <div
                className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                style={{ borderRadius: "4px", cursor: "pointer" }} onClick={handleCancel}
              >
                Yes
              </div>
              <div className="bg-white text-primary py-1 px-5 border border-primary text-center" style={{ borderRadius: "5px", cursor: "pointer" }} onClick={() => setShowCancel(false)}>
                No
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <SetReminderModal showAddReminder={showReminders} setShowAddReminder={setShowReminders} getInvoiceData={getInvoiceData} />
      </div>
      {loader}
    </>
  );
};

export default InvoiceDetails;
