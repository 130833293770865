import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import Pagination from "../../../app/pagination/pagination";
import { API } from "../../../../../backend";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { getAllUsers } from "../../Hook/userHooks";
import ActivityBar from "../../../../../components/partials/components/activitybar";
import { HiOutlinePencilAlt } from "react-icons/hi";

const AllUsers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [singleData, setSingleData] = useState([]);
  const [viewOptions, setViewOptions] = useState(false);
  const [show, setShow] = useState(false);
  const showPerPage = 10;
  const [pagination, setPagination] = useState({ start: 0, end: showPerPage });

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 970;

  const orgName = localStorage.getItem('orgName');

  useEffect(() => {
    getAllUsers().then((response) => {
      if (response.status === 200) {
        setAllUsers(response.data.data);
      } else {
        setAllUsers([]);
      }
    }).catch(() => {
      setAllUsers([]);
    })
  }, []);

  const singlUserData = (e) => {
    setSingleData(e);
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleClose = () => {
    setShow(false);
  };

  const onPaginationChange = (start, end) => setPagination({ start: start, end: end });

  const handleSelect = (e) => singlUserData(e);

  const userOptions = () => {
    return (
      <></>
    )
  };

  const userDetails = [
    {
      title: 'Name',
      desc: `${singleData.firstName != undefined ? singleData.firstName : ''} ${singleData.lastName != undefined ? singleData.lastName : ''}`
    },
    {
      title: 'Phone',
      desc: singleData?.phone
    },
    {
      title: 'Email',
      desc: singleData?.email
    },
    {
      title: 'Department',
      desc: singleData?.department
    },
    {
      title: 'Role',
      desc: singleData?.role === 'Custom' ? singleData?.subRole : singleData?.role
    }
  ];

  return (
    <div onClick={() => viewOptions === true && setViewOptions(false)}>
      <SubHeader title="All Users"
      // btnLink={`/${orgName}/dashboard/user/add-user`} btnTitle="Add User"
      />
      <div className="d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-1%" : "-3%", flexDirection: "row" }}>
        <Row className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%" }}>
          <Col sm={12} className="rounded bg-white">
            <Row className="py-2">
              <h5 className="text-primary" style={{ fontWeight: "550" }}>About</h5>
            </Row>
            {
              userDetails.map((data, index) => (
                <Row className="py-1" key={index}>
                  <Col md={4} sm={12}>
                    <div style={{ fontWeight: "550" }}>{data?.title}: </div>
                  </Col>
                  <Col>{data?.desc}</Col>
                </Row>
              ))
            }
          </Col>
        </Row>

        <Row className={`h-100 bg-white rounded mx-2 mb-5`}
          style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%" }}
        >
          <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
            <table className="table-responsive" style={{ width: "100%" }}>
              <thead className="fs-5 text-primary">
                <tr>
                  <th></th>
                  <th className="ps-3">Name</th>
                  <th>Email</th>
                  <th>Phone No.</th>
                  <th>Department</th>
                  <th>Role</th>
                  <th className="w-5 cursor-pointer">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allUsers
                  .slice(pagination.start, pagination.end)
                  .map((data, index) => (
                    <tr style={{ borderBottom: "1px solid rgb(227 227 227)" }} key={index}>
                      <td className="py-3">
                        <input type="radio" name="selectUser" value={data} onChange={() => handleSelect(data)} defaultChecked={index === 0} style={{ cursor: "pointer" }} />
                      </td>
                      <td>
                        <span className="text-dark">{`${data?.firstName} ${data?.lastName != undefined ? data?.lastName : ''}`}</span>
                      </td>
                      <td style={{ textAlign: "left" }}>{data?.email}</td>
                      <td>{data?.phone}</td>
                      <td>{data?.department}</td>
                      <td>{data?.role === 'Custom' ? data?.subRole : data?.role}</td>
                      <td>
                        <div className="flex align-items-left">
                          <Tooltip title="Update" key="Profile">
                            <Link to={`/${orgName}/dashboard/user/profile/${data?._id}`} className="mx-2">
                              <HiOutlinePencilAlt id="editUser" className="text-success" style={{ fontSize: "0.9rem", cursor: "pointer" }} />
                            </Link>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="my-5">
            <Pagination showPerPage={showPerPage} onPaginationChange={onPaginationChange} total={allUsers.length} />
          </div>
        </Row>
        <ActivityBar options={userOptions} list={true} />

        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} closeButton>
          <Modal.Header closeButton>
            <Modal.Title className="text-primary">Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>Do you want to delete the user ? </span>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default AllUsers;
