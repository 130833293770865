import React, { useEffect, useState } from "react";
import topHeader from "../../../../../assets/images/dashboard/top-header.png";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { FiEdit } from "react-icons/fi";
import Activity from "../../../../../components/partials/dashboard/MainStyle/Activity";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import Pagination from "../../../app/pagination/pagination";
import { API } from "../../../../../backend";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
import axios from "axios";
// import Badges from "../../../uikit/badge";

const AllEomployees = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [selectUser, setSelectUseer] = useState([]);
  const dummyData = [
    {
      id: 1,
      name: "Manish",
      email: "manish@gmail.com",
      phone: "1234567899",
      address: "123, Mansarovar, jaipur",
      department: "Development",
      role: "Developer",
      color: "bg-primary",
    },

    {
      id: 2,
      name: "testuser 1",
      email: "testuser1@gmail.com",
      phone: "9876543211",
      address: "123, shipra path, mansaorvar",
      department: "Marketing",
      role: "Digital Marketer",
      color: "bg-primary",
    },

    {
      id: 3,
      name: "user",
      email: "user@gmail.com",
      phone: "6753463465",
      address: "65, Bhakrota, jaipur",
      department: "Development",
      role: "Jr. Developer",
      color: "bg-primary",
    },

    {
      id: 4,
      name: "test User 2",
      email: "testuser2@gmail.com",
      phone: "6735092357",
      address: "45, Swarn Path, Mansarovar",
      department: "Development",
      role: "Sr. Developer",
      color: "bg-primary",
    },

    {
      id: 5,
      name: "Test User 3",
      email: "testuser3@gmail.com",
      phone: "7853476346",
      address: "6, Mansarovar, jaipur",
      department: "Development",
      role: "Developer",
      color: "bg-primary",
    },

    {
      id: 6,
      name: "Test User 4",
      email: "testuser4@gmail.com",
      phone: "7634523973",
      address: "87, Mansarovar, jaipur",
      department: "Marketing",
      role: "Digital Marketer",
      color: "bg-primary",
    },
  ];
//   const [singleData, setSingleData] = useState(dummyData[0]);
  const [singleData, setSingleData] = useState(allUsers);
  const [viewOptions, setViewOptions] = useState(false);
  const [show, setShow] = useState(false);
  const [showPerPage, setShowPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const [dummyState, setDummyState] = useState([dummyData]);
  const [showActivity, setShowActivity] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 970;

  console.log("first user", allUsers[0]);

  // const getAllUsers = async () => {
  //   await fetch(`${API}/auth`, {
  //     method: "GET",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Access-Control-Allo-Orign": "*",
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       console.log("All users");
  //       if (response.status === 201) {
  //         console.log("if block all users");
  //         setAllUsers(response.data);
  //       } else {
  //         console.log("Something went wrong");
  //       }
  //     })
  //     .catch((err) => console.log(err.message));
  // };

  const getAllUsers = async () => {
    await axios.get(`${API}/auth`)
    .then((response) => setAllUsers(response.data.data))
    .cathc((err) => console.log(err.message))
  }

  console.log("all users", allUsers)

  const getSingleUser = async (data) => {
    await fetch(`${API}/${data}`, {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allo-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          console.log("Single user data");
        } else console.log("Something went wrong");
      })
      .catch((err) => console.log(err.message));
  };

  const singlUserData = (e) => {
    setDummyState(e);
    // setSingleUserData(e);
    setSingleData(e);
  };

  console.log("Checked data", dummyState);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleClose = () => {
    setShow(false);
  };

  const onPaginationChange = (start, end) => {
    setPagination({
      start: start,
      end: end,
    });
  };

  // const handleSelect = (e) => {
  //   if (e.checked) {
  //     selectUser.push({
  //       ...dummyData,
  //       [e.target.name]: e.target.value,
  //     });
  //     console.log(e.target.value, "Selected");
  //   } else {
  //     selectUser.pop({
  //       ...dummyData,
  //       [e.target.name]: e.target.value,
  //     });
  //     console.log(e.target.value, "Deselected");
  //   }
  // };

  const handleSelect = (e) => {
    singlUserData(e);
    // setSingleData(e)
  };

  return (
    <div
      onClick={() => {
        viewOptions === true && setViewOptions(false);
      }}
    >
      <SubHeader
        title="All Employees"
        btnTitle="Edit Employee Details"
      />
      <div
        className="d-flex text-dark"
        style={{ marginTop: screenWidth >= 1200 ? "-1%" : "-3%", flexDirection: "row" }}
        // onClick={() => setViewOptions(false)}
      >
        <Row
          sm={3}
          className="w-25 rounded mx-2"
          style={{ height: "100%", marginRight: "10%" }}
        >
          <Col sm={12} className="rounded bg-white">
            <Row className="py-2">
              <h5 className="text-primary" style={{ fontWeight: "550" }}>
                About
              </h5>
            </Row>
            <Row className="py-1">
              <Col md={4} sm={12}>
                <div style={{ fontWeight: "550" }}>Name: </div>
              </Col>
              <Col>{`${singleData.firstName} ${singleData.lastName != undefined ? singleData.lastName : ''}`}</Col>
            </Row>
            <Row className="py-1">
              <Col md={4} sm={12}>
                <div style={{ fontWeight: "550" }}>Phone: </div>
              </Col>
              <Col>{singleData.phone}</Col>
            </Row>
            <Row className="py-1">
              <Col md={4} sm={12}>
                <div style={{ fontWeight: "550" }}>Email: </div>
              </Col>
              <Col>{singleData.email}</Col>
            </Row>
            <Row className="py-1">
              <Col md={4} sm={12}>
                <div style={{ fontWeight: "550" }}>Department: </div>
              </Col>
              <Col>{singleData.department}</Col>
            </Row>
            <Row className="py-1">
              <Col md={4} sm={12}>
                <div style={{ fontWeight: "550" }}>Role:</div>
              </Col>
              <Col>{singleData.role}</Col>
            </Row>
          </Col>
        </Row>

        <Row
          sm={7}
          className={`h-100 bg-white rounded mx-2 mb-5`}
          style={{
            minWidth: screenWidth >= breakPoint ? "50%" : "50%",
            width: "70%",
            maxWidth: "80%",
          }}
        >
          <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
            <table className="table-responsive" style={{ width: "100%" }}>
              <thead className="fs-5 pb-2 text-primary">
                <tr>
                  <th></th>
                  <th className="ps-3">Name</th>
                  <th>Email</th>
                  <th>Phone No.</th>
                  <th>Department</th>
                  <th>Role</th>
                  <th
                    className="w-5"
                    style={{ cursor: "pointer" }}
                    // onClick={() => setViewOptions(!viewOptions)}
                  >
                    Actions
                    {/* {viewOptions === true && (
                      <div
                        className="bg-primary rounded text-white px-2 py-1"
                        style={{
                          position: "absolute",
                          zIndex: "999",
                          marginLeft: "-2%",
                        }}
                      >
                        <div onClick={() => setShow(true)}>Delete</div>
                        <div>View</div>
                        <div>Edit</div>
                      </div>
                    )} */}
                  </th>
                </tr>
              </thead>
              <tbody className="border-primary" style={{borderTop: "2px solid"}}>
                {allUsers
                  .slice(pagination.start, pagination.end)
                  .map((data, index) => (
                    <tr
                      style={{
                        borderBottom: "1px solid rgb(227 227 227)",
                      }}
                      key={index}
                    >
                      <td className="py-3">
                        <input
                          type="radio"
                          name="selectUser"
                          value={data}
                          onChange={() => handleSelect(data)}
                          defaultChecked={index === 0}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                      <td>
                        <Tooltip title="User Profile">
                          <Link to="/dashboard/app/user/user-profile">
                            <span className="text-dark">{`${data.firstName} ${data.lastName != undefined ? data.lastName : ''}`}</span>
                          </Link>
                        </Tooltip>
                      </td>
                      <td style={{ textAlign: "left" }}>{data.email}</td>
                      <td>{data.phone}</td>
                      <td>{data.department}</td>
                      <td>{data.role}</td>
                      <td>
                        <div className="flex align-items-left">
                          <Tooltip title="Edit Details" key="Profile">
                            <Link
                              to={`/dashboard/app/employees/edit-employee/${data._id}`}
                              className="mx-2"
                            >
                              <FiEdit
                                id="viewLead"
                                className="text-success"
                                style={{
                                  fontSize: "0.9rem",
                                  cursor: "pointer",
                                }}
                              />
                            </Link>
                          </Tooltip>
                        </div>
                        {/* <div className="bd-example">
                          <Badges pill bg="primary">test</Badges>
                        </div> */}
                        {/* <span className={`badge ${data.color}`}>Test</span> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="my-5">
            <Pagination
              showPerPage={showPerPage}
              onPaginationChange={onPaginationChange}
              total={dummyData.length}
            />
          </div>
        </Row>

        {screenWidth >= breakPoint ? (
          <Row
            sm={2}
            className="w-25 mx-2 mb-5 rounded"
            style={{
              height: "100%",
              maxHeight: "90vh",
              overflowY: "scroll",
              scrollBehavior: "smooth",
            }}
          >
            <Activity title="Recent Activities" />
          </Row>
        ) : (
          <Row
            sm={1}
            className="mx-2 mb-5"
            style={{ height: "100%", width: "2rem" }}
          >
            <div className="activity-bar">
              <Tooltip title="Activities" key="activities">
                <Link to="#">
                  <BsFillArrowLeftCircleFill
                    className="text-white fs-2"
                    onClick={() => setShowActivity(!showActivity)}
                    style={{ cursor: "pointer" }}
                  />
                </Link>
              </Tooltip>
            </div>
            <nav
              className={
                showActivity ? "activity-menu active" : "activity-menu"
              }
            >
              <div
                className="activity-menu-items"
                // onClick={() => setShowActivity(!showActivity)}
              >
                <div className="activitybar-toggle h-1">
                  <Tooltip title="Close Activities" key="clsActivity">
                    <Link to="#">
                      <BsFillArrowRightCircleFill
                        className="text-primary fs-2"
                        onClick={() => setShowActivity(!showActivity)}
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </Tooltip>
                </div>
                <div style={{ width: "50%" }}>
                  <Activity
                    title="Recent Activities"
                    className="activity-text mx-3"
                    style={{ background: "#000" }}
                  />
                </div>
              </div>
            </nav>
          </Row>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          closeButton
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-primary">Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>Do you want to delete the user ? </span>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default AllEomployees;
