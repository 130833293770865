import React, { useState, useEffect } from "react";
import { Col, Row, Modal, Form } from "react-bootstrap";
import { API } from "../../../../backend";
import { Link } from "react-router-dom";
import Pagination from "../pagination/pagination";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { IoRadioButtonOn } from "react-icons/io5";
import { FiEye } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { Tooltip } from "@material-ui/core";
import About from "../client/about";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { deleteFirm, getAllFirmsList, getFirmById } from "../Hook/firmHooks";
import ActivityBar from "../../../../components/partials/components/activitybar";
import { getAllTaxes } from "../Hook/taxHooks";
import { CgProfile } from "react-icons/cg";
import { toast } from "react-hot-toast";
import CreateProducts from "../products/create-products";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { addRecentActivity, getRecentActivities } from "../Hook/recentActivityHooks";

const FirmList = () => {

  const [details, setDetails] = useState([]);
  const [allRecentActivities, setAllRecetActivities] = useState([]);
  const [singleFirm, setSingleFirm] = useState([]);
  const [firmDetails, setFirmDetails] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [firmAdd, setFirmAdd] = useState({});
  const [firmTaxes, setFirmTaxes] = useState([]);
  const [showCreateProducts, setShowCreateProducts] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [firmid, setFirmId] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showCreateOptions, setShowCreateOptions] = useState(false);
  const breakPoint = 970;
  const orgId = localStorage.getItem("orgID");
  const orgName = localStorage.getItem("orgName");

  const [showPerPage, setShowPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });


  const datetimeString = new Date();
  const dateObject = new Date(datetimeString);

  const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
  const formattedTime = dateObject.toLocaleTimeString();

  const getDetails = async () => {

    getAllFirmsList().then((response) => {
      if (response.status === 200) {
        setDetails(response.data.data);
        getData(response.data.data[0]._id);
      } else {
        setDetails([]);
      }
    }).catch(() => {
      setDetails([]);
    })
  };

  useEffect(() => {
    getDetails();
  }, []);

  const getAllRecentActivities = () => {
    getRecentActivities("firm").then((response) => {
      response.status === 200 ? setAllRecetActivities(response?.data?.data) : setAllRecetActivities([]);
    }).catch(() => setAllRecetActivities([]));
  };

  useEffect(() => {
    getAllRecentActivities();
  }, []);

  const getFirmTaxes = (id) => {
    getAllTaxes(id).then((response) => {
      if (response.status === 200) {
        response.data.data.length > 0 ? setFirmTaxes(response.data.data[0].taxRates) : setFirmTaxes([]);
      }
      else {
        setFirmTaxes([]);
      }
    }).catch(() => {
      setFirmTaxes([]);
    });
  };

  const getData = async (id) => {
    getFirmById(id).then((response) => {
      if (response.status === 200) {
        setSingleFirm(response.data.data[0]);
        setFirmAdd(response.data.data[0].add);
        setFirmDetails({
          name: (response.data.data[0].registeredFirmName === undefined || response.data.data[0].registeredFirmName === '') ? response.data.data[0].name : response.data.data[0].registeredFirmName,
          phone: response.data.data[0].phone,
          taxId: response.data.data[0].gst_no,
          email: response.data.data[0].email,
          address: response.data.data[0].add,
          logo: response.data.data[0].logo,
          firmID: response.data.data[0]._id
        });
      } else {
        setSingleFirm({});
        setFirmAdd({});
        setFirmDetails({});
      }
    }).catch(() => {
      setSingleFirm({});
      setFirmAdd({});
      setFirmDetails({});
    });

    getFirmTaxes();
  };

  const handleDeleteClient = (e) => {
    setFirmId(e);
    setShowDelete(true);
  };

  // const getSingleFirmDetails = (firmId) => {
  //   getFirmById(firmId).then((response) => {

  //   });
  // };

  const [firmName, setFirmName] = useState('');

  useEffect(() => {
    getFirmById(firmid).then((response) => {
      (response.status === 200) ? response?.data?.data[0]?.registeredFirmName ? setFirmName(response?.data?.data[0]?.registeredFirmName) : setFirmName(response?.data?.data[0]?.name) : setFirmName('');
    }).catch(() => setFirmName(''));
  }, [firmid]);

  // let firmName = '';
  const handleDelete = () => {

    deleteFirm(firmid, getDetails, setShowDelete).then((response) => {
      if (response.status === 200) {

        const activityForm = {
          module: ["firm"],
          activity: "Firm Deleted",
          activityDesc: `Firm ${firmName} Deleted Successfully`,
          createdDate: formattedDate,
          createdTime: formattedTime
        };
        addRecentActivity(activityForm).then((response) => response.status === 201 && getAllRecentActivities());

        SuccessToast("Firm deleted");
        setShowDelete(false);
        getDetails();
      }
      else {
        ErrorToast("Unable to delete the firm");
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
      setShowDelete(false);
    });
  }

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const handleRadio = (e) => {
    getData(e);
  };

  const handleShowAbout = (firmId) => {
    setShowAbout(true);
    handleRadio(firmId);
  }


  const firmOptions = () => {
    return (
      <div className="m-0 p-0" style={{ position: "relative" }}>
        <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2"
          onClick={() => setShowCreateOptions(!showCreateOptions)}
          style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={10}>
            Create New
          </Col>
          <Col sm={1} className="text-end">
            {
              showCreateOptions ? (
                <BsChevronUp className="fs-4 text-dark" style={{ cursor: "pointer" }} />
              ) : (
                <BsChevronDown className="fs-4 text-dark" style={{ cursor: "pointer" }} />
              )
            }
          </Col>
        </Row>
        {
          showCreateOptions ? (
            <ul className="m-0 ms-3 p-0 bg-white rounded"
              style={{ width: "91%", zIndex: 9, listStyle: "none", position: "absolute", boxShadow: "0px 0px 10px #c8c9ca" }}
            >
              <Link to={`/${orgName}/dashboard/firm/add-firm`}>
                <li className='py-2 px-3 text-dark' style={{ cursor: "pointer" }}>Create Firm</li>
              </Link>
              <Link t0="#">
                <li className='py-2 px-3 text-dark' onClick={() => setShowCreateProducts(true)} style={{ cursor: "pointer" }}>Create New Product</li>
              </Link>
            </ul>
          ) : null
        }
      </div>
    )
  }

  return (
    <>
      <SubHeader title="Firms" />
      <div className="">
        <div className="d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-2%" : "-3%", flexDirection: "row", }}>
          <Row className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
            <Col sm={12} className="bg-white rounded">
              <Row className="py-2">
                <h5 className="text-primary" style={{ fontWeight: "550" }}> About </h5>
              </Row>
              <Row>
                <Col sm={12} style={{ textAlign: "center", height: "100%" }}>
                  {
                    singleFirm.logo ? (
                      <img
                        src={singleFirm.logo} alt="firm" height="70rem" width="70rem" style={{ borderRadius: "50%" }} />
                    ) : (
                      <CgProfile className="text-secondary" style={{ height: "25%", width: "25%" }} />
                    )
                  }
                </Col>
                <Col className="my-2 text-dark text-center" style={{ fontWeight: "550", fontSize: "1rem", textTransform: "uppercase" }}>
                  {singleFirm === undefined || singleFirm.name === undefined ? '' : singleFirm.name}
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="mt-2 rounded bg-white">
              <Row className="py-2">
                <h5 className="text-primary" style={{ fontWeight: "550" }}>Contact</h5>
              </Row>
              <Row className="py-1">
                <Col md={3} sm={12}>
                  <div style={{ fontWeight: "550" }}>Phone: </div>
                </Col>
                <Col>{singleFirm.phone}</Col>
              </Row>
              <Row className="py-1">
                <Col md={3} sm={12}>
                  <div style={{ fontWeight: "550" }}>Email: </div>
                </Col>
                <Col>{singleFirm.email}</Col>
              </Row>
              <Row className="py-1">
                <Col md={3} sm={12}>
                  <div style={{ fontWeight: "550" }}>Address: </div>
                </Col>
                <Col sm>
                  <p className="m-0 p-0">{firmAdd === undefined || firmAdd.address1 === undefined ? '' : `${firmAdd.address1}, `}</p>
                  <p className="m-0 p-0">{firmAdd === undefined || firmAdd.address2 === undefined ? '' : `${firmAdd.address2}, `}</p>
                  <p className="m-0 p-0">
                    {
                      `${firmAdd === undefined || firmAdd.city === undefined ? '' : `${firmAdd.city}, `} 
                      ${firmAdd === undefined || firmAdd.state === undefined ? '' : `${firmAdd.state}, `} 
                      ${firmAdd === undefined || firmAdd.country === undefined ? '' : `${firmAdd.country}, `} 
                      ${firmAdd === undefined || firmAdd.pinCode === undefined ? '' : `${firmAdd.pinCode}, `}`
                    }
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row sm={7} className={`h-100 bg-white rounded mx-2 mb-5`} style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%", }}>
            <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
              <table className="" style={{ width: "100%" }}>
                <thead>
                  <tr className="h-20 text-primary fw-bold" style={{ borderBottom: "1px solid #a09fa3" }}>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}>
                      <IoRadioButtonOn style={{ color: "blue" }} />
                    </th>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Name</th>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Email</th>
                    <th className="fw-bold text-end" style={{ fontSize: "0.9rem" }}>Phone</th>
                    <th className="fw-bold text-end" style={{ fontSize: "0.9rem" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {details
                    .slice(pagination.start, pagination.end)
                    .map((data, index) => (
                      <tr style={{ borderBottom: "1px solid rgb(227 227 227)" }} key={index}>
                        <td className="py-3" style={{ height: "30px", borderBottom: "1px solid rgb(227 227 227)", }}>
                          <Form.Check.Input type="radio" name="radiobtn" className="" value={data._id} onChange={() => handleRadio(data._id)} defaultChecked={index === 0} style={{ cursor: "pointer" }} />
                        </td>
                        <td className="py-3" style={{ width: "30%", height: "30px" }}
                          onClick={() => getDetails(data._id)}
                        >
                          <span className="ms-0 text-dark">{data.name}</span>
                        </td>
                        <td className="w-5 py-3" style={{ paddingRight: "10px", width: "10%", }}>{data.email}</td>
                        <td className="w-5 py-3 text-end">{data.phone}</td>
                        <td className="py-3 text-end">
                          <div className="flex align-items-end list-user-action">
                            {/* <Tooltip title="Edit" key="edit">
                              <Link to={`/${orgName}/dashboard/firm/edit-firm/${data._id}`}>
                                <HiOutlinePencilAlt className="text-success" style={{ fontSize: "0.9rem", cursor: "pointer", }} />
                              </Link>
                            </Tooltip> */}
                            <Tooltip title="View" key="view">
                              <Link to={`/${orgName}/dashboard/firm/about-firm/${data._id}`}>
                                <FiEye id="viewLead" className="text-primary mx-2" style={{ fontSize: "1.1rem", cursor: "pointer", }} />
                              </Link>
                            </Tooltip>
                            <Tooltip title="Delete" key="view">
                              <Link to='#'>
                                <MdDeleteOutline id="delete" className="text-danger" onClick={() => handleDeleteClient(data._id)} style={{ fontSize: "1.1rem", cursor: "pointer", }} />
                              </Link>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {
                details.length === 0 && (
                  <div className='mt-5 fs-4 text-center text-secondary'>
                    No Firms To Show
                  </div>
                )
              }
            </div>
            <div className="my-5">
              <Pagination showPerPage={showPerPage} onPaginationChange={onPaginationChange} total={details.length} />
            </div>
          </Row>
          <ActivityBar options={firmOptions} allRecentActivities={allRecentActivities} />
        </div>
        <CreateProducts allFirms={details} showCreateProducts={showCreateProducts} setShowCreateProducts={setShowCreateProducts} firmTaxes={firmTaxes} firmId={firmid} />
        <About taxes={firmTaxes} title="About Firm" name={singleFirm.name} phone={singleFirm.phone} email={singleFirm.email} address={firmAdd} showAbout={showAbout} setShowAbout={setShowAbout} />
        <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Body className="my-2">
            <Row className="mb-2 ms-1">
              <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Firm</Col>
              <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">You want to delete the firm ?</Col>
            </Row>
            <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
              <div
                className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                onClick={() => handleDelete()}
                style={{ borderRadius: "4px", cursor: "pointer" }}
              >
                Yes
              </div>
              <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                onClick={() => setShowDelete(false)}
                style={{ borderRadius: "5px", cursor: "pointer" }}
              >
                No
              </div>
            </div>
          </Modal.Body>
        </Modal >
      </div>
    </>
  );
};

export default FirmList;
