import axios from "axios";
import { API } from "../../../../backend";
import { ErrorToast, SuccessToast } from "../../components/toast";

const orgId = localStorage.getItem('orgID');
const base = `${API}/purchase`;


export const addPurchase = async (form) => {
    const url = `${base}/create`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            'Content-Type': "application/json",
        }
    }
    const response = await axios(config);
    return response;
};

export const updatePurchase = async (id, form) => {
    const url = `${base}/updatepurchase/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
        }
    }

    const response = await axios(config);
    return response;
}

export const getAllPurchase = async () => {
    const url = `${base}/all/${orgId}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
        }
    }
    const response = await axios(config);
    return response;
};

export const getPurchaseById = async (id) => {
    const url = `${base}/singlePurchase/${orgId}/${id}`;
    const config = {
        url: url,
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios(config);
    return response;
}

export const getPurchaseByVendor = async (id) => {
    const url = `${base}/getPurchasebyVender/${id}`;
    const config = {
        url: url,
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios(config);
    return response;
}


export const deletePurchase = async (id) => {
    const url = `${base}/delete/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        headers: {
            "Content-Type": "application/json",
        }
    }
    const response = await axios(config);
    return response;
}

export const getVendorById = async (id) => {
    const url = `${base}/getVendorById/${id}`;
    const config = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': "application/json"
        }
    }
    const response = await axios(config);
    return response;
}

export const getSharablePurchase = async (id, data) => {
    const url = `${base}/getSinglepurchase`;
    const config = {
        method: "POST",
        url: url,
        data: { id: id },
        headers: {
            "Content-Type": "application/json"
        }
    }

    const response = await axios(config);
    return response
}

// Record payment
export const recordPurchasePayment = async (id, data) => {
    const url = `${base}/payment/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: data,
        headers: {
            "Content-Type": "application/json"
        }
    }

    const response = await axios(config);
    return response
}



export const softDeletePurchase = async (id, getAllInvoices) => {
    // const orgid = localStorage.getItem("orgID");
    const url = `${base}/softDeletepurchase/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json",
        }
    }
    try {
        const response = await axios(config);
        if (response.status === 200) {
            getAllInvoices();
            SuccessToast("Purchase Deleted Successfully");
        }
        else {
            ErrorToast("Unable to delete the Purchase");
        }
    }
    catch (err) {
    }
}

export const hardDeletePurchase = async (id, getAllInvoices) => {
    // const orgid = localStorage.getItem("orgID");
    const url = `${base}/deletepurchase/${id}`;
    const config = {
        method: "DELETE",
        url: url,
        // mode: 'no-cors',
        headers: {
            "Content-Type": "application/json",
        }
    }
    try {
        const response = await axios(config);
        if (response.status === 200) {
            getAllInvoices();
            SuccessToast("Purchase Deleted Permanently");
        }
        else {
            ErrorToast("Unable to delete the purchase");
        }
    }
    catch (err) {
    }
}
