import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import QRCode from "qrcode.react";
import { useParams } from 'react-router-dom';

const Preview = ({ addedtax, form, firmDetails, firmAdd, clientDetails, clientAdd, items, terms, hideStatus }) => {

  // const tax = (10 / 100) * form.subTotal;
  const [taxObj, setTaxObj] = useState({});
  const [taxName, setTaxName] = useState("");
  const [taxRates, setTaxRates] = useState(0);
  const [showHSNColumn, setShowHSNColumn] = useState(false);
  const [showSACColumn, setShowSACColumn] = useState(false);
  const [showDiscountColumn, setShowDiscountColumn] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const testVar = addedtax.reduce((prev, curr) => prev + parseFloat(Object.values(curr)), 0);
    setTaxRates(testVar);
  }, [addedtax]);

  const subTotal = form.subTotal;
  let discountedPrice = items.reduce((prev, curr) => prev +
    parseFloat(
      parseFloat(parseFloat(
        form.incluTax === true
          ? (curr.quantity * curr.unitPrice)
          : (parseFloat(curr.quantity * curr.unitPrice) + (parseFloat(curr.quantity * curr.unitPrice) * ((curr.taxRate ? parseFloat(curr.taxRate) : 0) / 100))).toFixed(2)
      )
        - (parseFloat(
          form.incluTax === true
            ? (curr.quantity * curr.unitPrice)
            : (parseFloat(curr.quantity * curr.unitPrice) + (parseFloat(curr.quantity * curr.unitPrice) * ((curr.taxRate ? parseFloat(curr.taxRate) : 0) / 100))).toFixed(2)
        ) * ((curr.discount ? parseFloat(curr.discount) : 0) / 100)))
    ), 0);


  // let discountedPrice = items.reduce((prev, curr) => prev + parseFloat(parseFloat(curr.amount) - (parseFloat(curr.amount) * ((curr.discount ? parseFloat(curr.discount) : 0) / 100))), 0)
  let basePrice = form.incluTax === true ? (discountedPrice) / (1 + (taxRates / 100)) : (discountedPrice);

  // const taxTotal = addedtax.reduce((acc, tax) => parseFloat((acc + (basePrice * Object.values(tax)) / 100).toString().match(/^-?\d+(?:\.\d{0,2})?/)), 0);
  const taxTotal = addedtax.reduce((acc, tax) => parseFloat((acc + (basePrice * Object.values(tax)) / 100).toString().match(/^-?\d+(?:\.\d{0,2})?/)), 0);

  let tax = form.incluTax === true ? subTotal * (taxRates / 100) : subTotal * (taxRates / 100);

  let total = form.total;
  let roundoff = 0;
  let roundOperation = '';
  let grandTotal = 0;

  let itemstaxTotal = 0;
  if (form.incluTax === true) {
    itemstaxTotal = items.reduce((prev, curr) => prev + (parseFloat(curr.taxRate) / 100) * parseFloat((parseFloat(curr.quantity) * parseFloat(curr.unitPrice)) / (1 + (parseFloat(curr.taxRate) / 100))), 0);
    total = basePrice + itemstaxTotal + taxTotal + (basePrice - discountedPrice);
    grandTotal = Math.round(basePrice + itemstaxTotal + taxTotal + (basePrice - discountedPrice));
    roundoff = grandTotal > total
      ? grandTotal - parseFloat(total.toFixed(2))
      : parseFloat(total.toFixed(2)) - grandTotal
    roundOperation = grandTotal >= parseFloat(total.toFixed(2)) ? '' : '-';
  } else {
    itemstaxTotal = items.reduce((prev, curr) => prev + ((((curr.quantity * curr.unitPrice) * (curr.taxRate ? parseFloat(curr.taxRate) : 0)) / 100)), 0);
    total = discountedPrice + taxTotal + itemstaxTotal + (basePrice - discountedPrice);
    grandTotal = Math.round(basePrice + itemstaxTotal + taxTotal + (basePrice - discountedPrice));
    roundoff = grandTotal > total
      ? grandTotal - parseFloat(total.toFixed(2))
      : parseFloat(total.toFixed(2)) - grandTotal
    roundOperation = grandTotal >= parseFloat(total.toFixed(2)) ? '' : '-';
  }


  const HSNKeyExists = (key) => {
    const testVar = items.filter((data) => (data.hsn != undefined) && ((data.hsn).length > 0));
    testVar.length > 0 ? setShowHSNColumn(true) : setShowHSNColumn(false);
  };

  useEffect(() => {
    HSNKeyExists();
  });

  const SACKeyExists = (key) => {
    const testVar = items.filter((data) => (data.sac != undefined) && (data.sac).length > 0);
    testVar.length > 0 ? setShowSACColumn(true) : setShowSACColumn(false);
  }

  useEffect(() => {
    SACKeyExists();
  });

  const DiscountKeyExists = (key) => {
    const testVar = items.filter((data) => data.discount);
    testVar.length > 0 ? setShowDiscountColumn(true) : setShowDiscountColumn(false);
  }

  useEffect(() => {
    DiscountKeyExists();
  })

  useEffect(() => {
    setTaxObj(form.tax);
  }, []);


  useEffect(() => {
    setTimeout(() => {
      setTaxName(Object.keys(taxObj));
    }, 1000)
  }, [taxName]);

  const url = "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/business-logo-design-template-78655edda18bc1196ab28760f1535baa_screen.jpg?ts=1617645324";

  const format = (dateString) => {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const path = `${window.location.protocol}//${window.location.hostname}/invoice/${id}/`;
  // const path = 'https://crm.cubicleweb.com/invoice/64b787367d0202eb67abf4c6';
  return (
    <>
      <div className="pt-5 pb-0 m-0">
        <div className="d-flex justify-content-between align-items-between mx-2 mb-2 pb-2" style={{ borderBottom: "1px solid #c8c9ca" }}>
          <div className="m-0 p-0">
            {/* <img src={firmDetails?.logo ? firmDetails?.logo : ""} alt="logo" style={{ width: "20%", height: "95%", display: firmDetails.logo === undefined && "none" }} /> */}
          </div>
          <div className="d-flex justify-content-start align-items-start m-0 p-0 text-end">
            <QRCode value={path} style={{ width: "100px", height: "100px", marginRight: 50 }} />
            <div className='d-flex justify-content-center align-items-center flex-column'>
              <h1 className='fw-semibold text-primary'>Invoice</h1>
              <div className='fs-5 w-100' style={{ display: !form.invoiceNumber && 'none' }}>{form.invoiceNumber}</div>
              {
                !hideStatus ? (
                  <div className='fs-3 fw-semibold w-100' style={{ letterSpacing: "0.02rem", display: !form.status && 'none', color: form.status === "Pending" ? '#FFB802' : form.status === 'Paid' ? 'green' : form.status === "Partial Paid" ? 'blue' : form.status === "Overdue" ? '#ff0000' : '#374550' }}>{form.status}</div>
                ) : null
              }
            </div>
          </div>
        </div>
        <Row className="mx-2 pb-2">
          <Col sm={6} className="w-50 p-0">
            <div className="my-2">
              <Row className="mx-1">
                <span className="m-0 p-0 fw-semibold" style={{ fontSize: "0.8rem" }}>From </span>
              </Row>
            </div>
            <div className="my-2">
              <Row className="mx-1">
                <span className="m-0 p-0 fs-5">{firmDetails === undefined || firmDetails.name === undefined ? '' : firmDetails.name}</span>
                <span className="m-0 p-0">{firmDetails === undefined || firmDetails.email === undefined ? '' : firmDetails.email}</span>
                <Row className='mt-2 mx-0 p-0'>
                  {/* <Col sm={1} xs={1} className="m-0 p-0">GST: </Col> */}
                  <Col sm={12} className="m-0 p-0 text-start" style={{ textTransform: "uppercase" }}>GST: {firmDetails === undefined || firmDetails.taxId === undefined ? '' : firmDetails.taxId}</Col>
                </Row>
                <p className="m-0 p-0 mt-2">{firmDetails === undefined || firmAdd === undefined || firmAdd.address1 === undefined ? '' : firmAdd.address1}</p>
                <p className="m-0 p-0">{firmAdd === undefined || firmAdd.address2 === undefined ? '' : firmAdd.address2}</p>
                <p className="m-0 p-0">
                  {
                    `${firmAdd === undefined || firmAdd.city === undefined ? '' : `${firmAdd.city}, `} 
                  ${firmAdd === undefined || firmAdd.state === undefined ? '' : `${firmAdd.state}, `} 
                  ${firmAdd === undefined || firmAdd.country === undefined ? '' : `${firmAdd.country}, `} 
                  ${firmAdd === undefined || firmAdd.pinCode === undefined ? '' : `${firmAdd.pinCode} `}`
                  }
                </p>
              </Row>
            </div>
          </Col>
          <Col sm={6} className="w-50 p-0 text-end">
            <div className="my-2">
              <Row className="mx-1">
                <span className="m-0 p-0 fw-semibold" style={{ fontSize: "0.8rem" }}>To </span>
              </Row>
            </div>
            <div className="my-2">
              <Row className="mx-1">
                {
                  clientDetails.clientFirmName ? (
                    <span className="m-0 p-0 fs-5">{`${clientDetails.clientFirmName === undefined ? '' : clientDetails.clientFirmName}`}</span>
                  ) : (
                    <span className="m-0 p-0 fs-5">{`${clientDetails.firstName === undefined ? '' : clientDetails.firstName} ${clientDetails.lastName === undefined ? '' : clientDetails.lastName}`}</span>
                  )
                }
                <span className="m-0 p-0">{clientDetails.email === undefined ? '' : clientDetails.email} </span>
                <Row className='mt-2 mx-0 p-0'>
                  {/* <Col sm={8} xs={8} className='m-0 p-0'></Col>
                <Col sm={1} xs={1} className="m-0 p-0 text-end"> </Col> */}
                  <Col sm={12} xs={12} className="m-0 p-0 ps-1 text-end" style={{ textTransform: "uppercase" }}>GST: {firmDetails === undefined || clientDetails.taxId === undefined ? '' : clientDetails.taxId}</Col>
                </Row>
                <p className="m-0 p-0 mt-2">{clientAdd === undefined || clientAdd.address1 === undefined ? '' : clientAdd.address1}</p>
                <p className="m-0 p-0">{clientAdd === undefined || clientAdd.address2 === undefined ? '' : clientAdd.address2}</p>
                <p className="m-0 p-0">
                  {
                    `${clientAdd === undefined || clientAdd.city === undefined ? '' : `${clientAdd.city}, `} 
                  ${clientAdd === undefined || clientAdd.state === undefined ? '' : `${clientAdd.state}, `} 
                  ${clientAdd === undefined || clientAdd.country === undefined ? '' : `${clientAdd.country}, `} 
                  ${clientAdd === undefined || clientAdd.pinCode === undefined ? '' : `${clientAdd.pinCode} `}`
                  }
                </p>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="mx-2 my-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Row className="mx-1 py-2">
            <Col className="w-50 text-start"><span className="m-0 p-0 fw-semibold ">Invoice Date: </span>{format(form.invoiceDate)}</Col>
            <Col className="w-50  text-end"><span className="m-0 p-0 fw-semibold ">Due Date: </span>{format(form.dueDate)}</Col>
          </Row>
        </div>
        <div className="mx-2 py-3">
          <span className="m-0 p-0 fw-semibold" style={{ fontSize: "0.8rem" }}>Item Details</span>
          <table className="w-100 mt-2">
            <thead className="bg-primary p-2 fs-5 text-white w-100" style={{ borderBottom: "1px solid #c8c9ca", borderRadius: "10px 0 10px 0" }}>
              <th className="ps-2" style={{ width: "40%", borderRadius: "10px 0 0 0" }}>Item</th>
              {
                showHSNColumn ? (
                  <th className='text-center' style={{ width: "10%" }}>HSN</th>
                ) : null
              }

              {
                showSACColumn ? (
                  <th className='text-center' style={{ width: "10%" }}>SAC</th>
                ) : null
              }

              <th className='text-center' style={{ width: "7%" }}>Qty</th>
              <th className='text-Start ps-2' style={{ width: "5%" }}>Price</th>
              {
                itemstaxTotal > 0 ? (
                  <th className='text-center' style={{ width: "7%" }}>Tax</th>
                ) : null
              }
              {
                showDiscountColumn ? (
                  <th className='text-center' style={{ width: "10%" }}>Discount(%)</th>
                ) : null
              }
              <th className="text-Start ps-2" style={{ width: "15%", borderRadius: "0px 10px 0 0" }}>Amount</th>
            </thead>
            <tbody>
              {items.map((data, index) => (
                <>
                  <tr key={index}>
                    <td className="ps-2" style={{ width: "40%", }}>{data === undefined || data.itemName === undefined ? "" : data.itemName}</td>
                    {
                      showHSNColumn ? (
                        <td className='text-center' style={{ width: "10%" }}>{data === undefined || data.hsn === undefined ? "" : data.hsn}</td>
                      ) : null
                    }

                    {
                      showSACColumn ? (
                        <td className='text-center' style={{ width: "10%" }}>{data === undefined || data.sac === undefined ? "" : data.sac}</td>
                      ) : null
                    }
                    <td className='text-center' style={{ width: "7%" }}>
                      {data === undefined || data.quantity === undefined ? "" : `${data.quantity >= 10 ? data.quantity : '0' + data.quantity}`}
                    </td>
                    {/* <td className='text-start ps-2' style={{ width: "5%" }}>{data === undefined || data.unitPrice === undefined ? "" : data.unitPrice}</td> */}
                    <td className='text-start ps-2' style={{ width: "5%" }}>
                      {
                        form.incluTax === true
                          ? (((parseFloat(data.quantity * data.unitPrice) / (1 + (parseFloat(data.taxRate) / 100))).toFixed(2)) / (data.quantity)).toFixed(2)
                          : parseFloat(data.unitPrice).toFixed(2)
                      }
                    </td>

                    {
                      itemstaxTotal > 0 ? (
                        <td className='text-center' style={{ width: "7%" }}>{!data.taxRate || data.taxRate === '0' ? '' : data.taxRate}</td>
                      ) : null
                    }
                    {
                      showDiscountColumn ? (
                        <td className='text-center' style={{ width: "10%" }}>{data === undefined || data.discount === undefined ? 0 : data.discount}</td>
                      ) : null
                    }
                    {/* <td className='text-center' className="text-end pe-2">{data.quantity * data.unitPrice}</td> */}
                    <td className='text-start ps-2' style={{ width: "15%" }}>
                      {
                        parseFloat(
                          form.incluTax === true
                            ? (data.quantity * data.unitPrice)
                            : (parseFloat(data.quantity * data.unitPrice) + (parseFloat(data.quantity * data.unitPrice) * ((data.taxRate ? parseFloat(data.taxRate) : 0) / 100))).toFixed(2)
                        ) - (
                          parseFloat(
                            form.incluTax === true
                              ? (data.quantity * data.unitPrice)
                              : (parseFloat(data.quantity * data.unitPrice) + (parseFloat(data.quantity * data.unitPrice) * ((data.taxRate ? parseFloat(data.taxRate) : 0) / 100))).toFixed(2)
                          ) * ((data.discount ? parseFloat(data.discount) : 0) / 100)
                        )
                      }
                      {/* {
                        form.incluTax === true
                          ? (((parseFloat(data.quantity * data.unitPrice) / (1 + (parseFloat(data.taxRate) / 100))).toFixed(2)) / (data.quantity)).toFixed(2)
                          : parseFloat(data.unitPrice).toFixed(2)
                      } */}
                      {/* {parseFloat((data.quantity * data.unitPrice)) + ((parseFloat((data.quantity * data.unitPrice)) * parseFloat(data.taxRate)) / 100)} */}
                    </td>
                  </tr>
                  <tr key={index} style={{ borderBottom: "1px solid #c8c9ca" }}>
                    <td className="ps-2 pb-2 pe-4 w-25" style={{ width: "200px", maxWidth: "200px" }}>
                      {data.desc}
                    </td>
                    <td></td>
                    {
                      showHSNColumn ? (
                        <td></td>
                      ) : null
                    }
                    {
                      showSACColumn ? (
                        <td></td>
                      ) : null
                    }
                    <td></td>
                    {
                      itemstaxTotal > 0 ? (
                        <td></td>
                      ) : null
                    }
                    {
                      showDiscountColumn ? (
                        <td></td>
                      ) : null
                    }
                    <td></td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mx-2 pb-3" style={{ borderBottom: "1px solid #c8c9ca" }}>
          <Row>
            <Col sm={12} className="text-end pe-4">
              <Row>
                <Col sm={12} md={4} className=" fw-semibold" style={{ width: "80%" }}>Subtotal</Col>
                <Col sm={12} md={8} className="" style={{ width: "20%" }}>{form.currency}
                  {form.subTotal}
                  {/* {discountedPrice} */}
                </Col>
              </Row>
              {
                showDiscountColumn ? (
                  <Row className='mt-2'>
                    <Col sm={12} md={4} className=" fw-semibold" style={{ width: "80%" }}>Discount</Col>
                    <Col sm={12} md={8} className="" style={{ width: "20%" }}>
                      {form.currency}
                      {(form.subTotal - discountedPrice).toFixed(2)}
                    </Col>
                  </Row>
                ) : null
              }
              {
                itemstaxTotal > 0 && (
                  <Row className='mt-2'>
                    <Col sm={12} md={4} className=" fw-semibold" style={{ width: "80%" }}>Tax</Col>
                    <Col sm={12} md={8} className="" style={{ width: "20%" }}>
                      {form.currency}
                      {parseFloat(itemstaxTotal).toFixed(2)}
                      {/* {includeTax
                            ? parseFloat((total - addedtax.reduce((prev, curr) => prev + (total - (subTotal / (1 + ((Object.values(curr) / 100))))), 0)))
                            : subTotal} */}
                      {/* {subTotal.toFixed(2)} */}
                    </Col>
                  </Row>
                )
              }
              {/* {
                addedtax.map((data, index) => (
                  (
                    <Row className="my-2" key={index}>
                      <Col sm={12} md={4} className=" fw-semibold" style={{ width: "80%" }}>{Object.keys(data)}</Col>
                      <Col sm={12} md={8} className=" text-end" style={{ width: "20%" }}>{form.currency}
                        {((Object.values(data) / 100) * form.subTotal).toFixed(2)}
                      </Col>
                    </Row>
                  )
                ))
              } */}

              {
                addedtax.map((data, index) => (
                  (
                    <Row className="my-2" key={index}>
                      <Col sm={12} md={4} className=" fw-semibold" style={{ width: "80%" }}>{Object.keys(data)}</Col>
                      <Col sm={12} md={8} className="" style={{ width: "20%" }}>{form.currency}
                        {/* {form.incluTax ? (total - (subTotal / (1 + ((Object.values(data) / 100))))).toFixed(2) :
                          ((subTotal * (Object.values(data))) / 100)} */}
                        {/* {form.incluTax ? parseFloat((total - (subTotal / (1 + ((Object.values(data) / 100))))).toString().match(/^-?\d+(?:\.\d{0,2})?/)) :
                          (parseFloat((subTotal * (Object.values(data))) / 100))} */}
                        {
                          parseFloat(Object.values(data) / 100 * basePrice).toFixed(2)
                        }
                        {/* {form.includeTax ? parseFloat((total - addedtax.reduce((prev, curr) => prev + (total - (subTotal / (1 + ((Object.values(curr) / 100))))), 0)).toFixed(2)) : subTotal.toFixed(2)} */}
                      </Col>
                    </Row>
                  )
                ))
              }
              {/* <Row className="">
                <Col sm={12} md={4} className=" fw-semibold" style={{ width: "80%" }}>Roundoff</Col>
                <Col sm={12} md={8} className="" style={{ width: "20%" }}>{form.currency} {(form.roundOff ? (parseFloat(form.roundOff).toString().match(/^-?\d+(?:\.\d{0,2})?/)) : parseFloat(0))}</Col>
              </Row> */}
              <Row className="mt-2">
                <Col sm={12} md={4} className=" fw-semibold" style={{ width: "80%" }}>Amount Paid</Col>
                <Col sm={12} md={8} className="" style={{ width: "20%" }}>{form.currency} {(form.amountPaid ? (parseFloat(form.amountPaid).toString().match(/^-?\d+(?:\.\d{0,2})?/)) : parseFloat(0))}</Col>
              </Row>
              <Row className="text-primary mt-2">
                <Col sm={12} md={4} className=" fw-semibold" style={{ width: "80%" }}>Total Due</Col>
                <Col sm={12} md={8} className="" style={{ width: "20%" }}>
                  {form.currency}
                  {form.dueAmount ? parseFloat(form.dueAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/) : parseFloat(0)}
                </Col>

                {/* {
                    form.dueAmount === 0 ? (
                      <Col sm={12} md={8} className="" style={{ width: "20%" }}>{form.currency} {form.dueAmount}</Col>
                    ) : (
                      <Col sm={12} md={8} className="" style={{ width: "20%" }}>{form.currency} {form.dueAmount}</Col>
                    )
                  } */}
              </Row>
            </Col>
          </Row>
        </div>
        <div className="mx-2 py-3" style={{ borderBottom: "1px solid #c8c9ca" }}>
          <Row className="mx-2 pb-2" style={{ borderBottom: "1px solid #c8c9ca", display: form.remark === undefined && 'none' }}>
            <span className="m-0 p-0 fw-semibold">Remark: </span>
            <div className="m-0 p-0">{form.remark}</div>
          </Row>
          <Row className="m-2 pb-2" style={{ borderBottom: "1px solid #c8c9ca", display: form.notes === undefined && 'none' }}>
            <span className="m-0 p-0 fw-semibold">Note: </span>
            <div className="m-0 p-0">{form.notes}</div>
          </Row>
          {
            form.termsNcondition === undefined ? (
              <>
              </>
            ) : (
              <Row className="mx-2 pb-3" style={{ display: form.termsNcondition.length === 0 && "none" }}>
                <span className="m-0 p-0 fw-semibold">Terms & Conditions:</span>
                <ul className="mx-0">
                  {
                    terms.map((term, i) => (
                      <li className="my-1 px-2" key={i}> {term}</li>
                    ))
                  }
                </ul>
              </Row>
            )
          }
          <Row className="mt-5 mx-2 mb-0 text-secondary text-center" style={{ bottom: 0 }}>
            <div className="m-0 p-0 my-2">This is a computer generated invoice and does not require any physical signature.</div>
          </Row>
        </div>
      </div>
      {/* </iframe> */}
    </>
  )
}

export default Preview