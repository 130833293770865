import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClear } from "react-icons/md";

const Activitymodal = ({ show, setShow, details, allComments }) => {

  console.log("allcomments", allComments);

  return (
    <div>
      <Modal centered size="lg" show={show} onHide={() => setShow(false)} style={{ fontSize: "0.8rem" }}>
        <Modal.Header className="bg-primary">
          <Col sm={11}>
            <h4 className="text-white">Activity Details</h4>
          </Col>
          <Col sm={1} onClick={() => setShow(false)} className="text-end">
            <MdClear className="fs-4 text-white" style={{ cursor: "pointer" }} />
          </Col>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col sm={2} className="text-primary" style={{ fontWeight: 500 }}>Activity Type:</Col>
              <Col sm>{((details === undefined) || (details.type === undefined)) ? '' : details.type}</Col>
            </Row>{
              ((details === undefined) || (details.title === undefined) || (details.title === '')) ? null : (
                <Row className="my-1">
                  <Col sm={2} style={{ fontWeight: 500 }}>Activity Title:</Col>
                  <Col sm>{((details === undefined) || (details.title === undefined)) ? '' : details.title}</Col>
                </Row>
              )
            }
            <Row>
              <Col sm={2} style={{ fontWeight: 500 }}>Description:</Col>
              <Col sm>{((details === undefined) || (details.desc === undefined)) ? '' : details.desc}</Col>
            </Row>
          </div>
          <hr />
          <div>
            <Row>
              <Col sm={12} className="text-secondary">All comments</Col>
              <Col sm={12} style={{ maxHeight: "20rem", overflowY: "scroll", scrollBehavior: "smooth", }}>
                {
                  allComments.length > 0 ? (
                    allComments.map((data, index) => (
                      <div className="py-2 mx-2 row" style={{ borderBottom: allComments.length > 1 && "1px solid #d1d1d187" }} key={index}>
                        <Col sm={1}>
                          <img src="https://tse4.mm.bing.net/th?id=OIP.urs9CLtRNkSZtaP2K1uOIAHaHa&pid=Api&P=0" alt="" width="30px" height="30px" className="rounded-circle" />
                        </Col>
                        <Col sm={10}>
                          <Row>
                            <div className="text-secondary fs-6">
                              {data === undefined || data.userID === undefined || data.userID.firstName === undefined ? '' : data.userID.firstName}
                            </div>
                            <div className="text-dark">{data === undefined || data.comment === undefined ? '' : data.comment}</div>
                          </Row>
                        </Col>
                      </div>
                    ))
                  ) : (
                    <div className="my-2 pb-2 text-secondary text-center" style={{ borderBottom: "1px solid #c8c9ca" }}>
                      No comments yet
                    </div>
                  )
                }
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Activitymodal;
