import React, { useEffect, useState } from 'react'
import SubHeader from '../../../../components/partials/dashboard/HeaderStyle/sub-header';
import { FiEye, FiFilter } from 'react-icons/fi';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Col, Modal, Row } from 'react-bootstrap';
import { HiOutlinePencilAlt, HiOutlineReceiptTax } from 'react-icons/hi'
import { FiCheckSquare } from 'react-icons/fi';
import { FaRegClock } from 'react-icons/fa';
import { CgProfile } from 'react-icons/cg';
import ActivityBar from "../../../../components/partials/components/activitybar"; import Card from '../../../../components/Card';
import { BsChevronDown, BsChevronUp, BsFillShareFill } from 'react-icons/bs';
import { MdAccountTree, MdDeleteOutline, MdMessage } from 'react-icons/md';
import { Tooltip } from '@material-ui/core';
import { deleteClient, getClientById } from '../Hook/clientHooks';
import axios from 'axios';
import { API } from '../../../../backend';
import { getInvoicesByClient, restoreDeletedInvoice } from '../Hook/invoiceHooks';
import { Description, Warning } from '@mui/icons-material';
import { RWebShare } from 'react-web-share';
import DeleteInvoice from '../invoice/delete-invoice';
import Pagination from '../pagination/pagination';
import { IoReloadOutline } from 'react-icons/io5';
import { ErrorToast, SuccessToast, WarningToast } from '../../components/toast';
import { addRecentActivity, getEntityRecentActivities } from '../Hook/recentActivityHooks';
// import ModalContainer from '../../components/Modal/modal';

const AboutClient = () => {
    const [singleClient, setSingleClient] = useState([]);
    const [allRecentActivities, setAllRecetActivities] = useState([]);
    const [clientAdd, setClientAdd] = useState([]);
    const [clientDetails, setClientDetails] = useState([]);
    const [contactPerson, setContactPerson] = useState([]);
    const [collectedTax, setCollectedTax] = useState(0);

    const datetimeString = new Date();
    const dateObject = new Date(datetimeString);

    const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
    const formattedTime = dateObject.toLocaleTimeString();


    const initalData = Object.freeze({
        amountPaid: 0,
        client: {
            address: {
                address1: "",
                address2: "",
                city: "",
                country: "",
                state: "",
            },
        },
        currency: "",
        delete: false,
        draft: false,
        dueAmount: 0,
        dueDate: "",
        firm: {
            address: {
                address1: "",
                address2: "",
                city: "",
                country: "",
                state: "",
            }
        },
        invoiceDate: "",
        items: [
            {
                amount: 0,
                desc: "",
                itemName: "",
                quantity: 0,
                unitPrice: 0,
            }
        ],
        notes: "",
        orgId: "",
        payment: [
            {
                amountPaidpayment: 0,
                datePaid: "",
                notes: "",
                paymentMethod: "",
            }
        ],
        remark: "",
        status: "",
        subTotal: "",
        tax: [{}],
        termsNcondition: [""],
        total: 0
    })
    const [details, setDetails] = useState([initalData]);
    const [selected, setSelected] = useState("All");

    const [clientInvoices, setClientInvoices] = useState([]);

    const [currency, setCurrency] = useState('');
    const [paidAmount, setPaidAmount] = useState(0);
    const [pendingAmount, setPendingAmount] = useState(0);

    const [paidCount, setPaidCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);

    const [showInvoice, setShowInvoice] = useState(true);
    const [showProjects, setShowProjects] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [showDeleteInvoice, setShowDeleteInvoice] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [showShare, setShowShare] = useState(false);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const showPerPage = 10;
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const breakPoint = 970;
    const { id } = useParams();

    const orgId = localStorage.getItem("orgID");
    const orgName = localStorage.getItem("orgName");

    const history = useHistory();

    const clientObj = {
        clientId: id,
        orgId: orgId
    }

    const [newArray, setNewArray] = useState([]);


    const getAllRecentActivities = () => {
        getEntityRecentActivities("firm", id).then((response) => {
            response.status === 200 ? setAllRecetActivities(response?.data?.data) : setAllRecetActivities([]);
        }).catch(() => setAllRecetActivities([]));
    };

    useEffect(() => {
        getAllRecentActivities();
    }, []);

    const invoices = () => {
        getInvoicesByClient(clientObj).then((response) => {
            if (response.status === 200) {
                setNewArray(response.data.data);
            } else {
                setNewArray([]);
            }
        }).catch((err) => {
            console.log(err.message);
        })
    }

    useEffect(() => {
        invoices();
    }, [])

    const getClientInvoices = async () => {
        getInvoicesByClient(clientObj).then((response) => {
            if (response.status === 200) {
                const mainArray = response.data.data;
                const allInvoices = mainArray.filter(data => data.delete != true);
                setClientInvoices(allInvoices)
                if (selected === "All") {
                    setClientInvoices(allInvoices);
                } else if (selected === "Pending") {
                    setClientInvoices(allInvoices.filter(data => data.status === "Pending"));
                } else if (selected === "Paid") {
                    setClientInvoices(allInvoices.filter(data => data.status === "Paid"));
                } else if (selected === "Partial Paid") {
                    setClientInvoices(allInvoices.filter(data => data.status === "Partial Paid"));
                } else if (selected === "Overdue") {
                    setClientInvoices(allInvoices.filter(data => data.status === "Overdue"));
                } else if (selected === "Disputed") {
                    setClientInvoices(allInvoices.filter(data => data.disputed !== undefined));
                } else if (selected === "Deleted") {
                    const deleted = response.data.data;
                    setClientInvoices(deleted.filter(data => data.delete === true));
                } else if (selected === "Canceled") {
                    const canceled = response.data.data;
                    setClientInvoices(canceled.filter(data => data.cancel === true));
                } else if (selected === "Draft") {
                    const drafts = response.data.data;
                    setClientInvoices(drafts.filter(data => data.draft === true));
                }
            } else {
                setNewArray([]);
            }
        }).catch((err) => {
            console.log(err.message);
        });
    }

    useEffect(() => {
        getClientInvoices();
    }, [selected])

    useEffect(() => {
        getClientById(id).then((response) => {
            if (response.status === 200) {
                setSingleClient(response.data.data);
                setClientAdd(response.data.data.add)
                setContactPerson(response.data.data.contectPerson)
                setClientDetails({
                    firstName: response.data.data.firstName,
                    lastName: response.data.data.lastName,
                    phone: response.data.data.phone,
                    email: response.data.data.email,
                    address: response.data.data.add,
                    clientFirmName: response.data.data.clientFirmName,
                })
                getClientInvoices();
            } else {
                setSingleClient({});
                setClientAdd({});
                setContactPerson({});
                setClientDetails({});
            }
        }).catch((err) => {
            err?.response?.data?.message && WarningToast(err?.response?.data?.message);
            setSingleClient({});
            setClientAdd({});
            setContactPerson({});
            setClientDetails({});
        })
    }, []);

    const handleFilter = (name) => {
        setSelected(name);
    };

    useEffect(() => {
        getClientInvoices();
    }, [details, selected]);

    const getSummary = () => {
        setCurrency(clientInvoices[0] ? clientInvoices[0].currency : null);
        const subtotal = clientInvoices.reduce((prev, curr) => prev + curr.subTotal, 0);
        const total = clientInvoices.reduce((prev, curr) => prev + curr.total, 0);
        setCollectedTax(total - subtotal);

        const testPartialPaidArray = clientInvoices.filter((data) => data.status === "Partial Paid")
        const testPaidArray = clientInvoices.filter((data) => data.status === "Paid");
        const testPendingArray = clientInvoices.filter((data) => data.status === "Pending" || data.status === 'Overdue');

        const paidSubtotal = testPaidArray.reduce((prev, curr) => prev + (curr.subTotal * ((!curr.curConvert || parseFloat(curr.curConvert) === 0) ? 1 : curr.curConvert)), 0);
        const partialSubtotal = testPartialPaidArray.reduce((prev, curr) => prev + (curr.subTotal * ((!curr.curConvert || parseFloat(curr.curConvert) === 0) ? 1 : curr.curConvert)), 0);

        const paidTotal = testPaidArray.reduce((prev, curr) => prev + (curr.total * ((!curr.curConvert || parseFloat(curr.curConvert) === 0) ? 1 : curr.curConvert)), 0);
        const partialTotal = testPartialPaidArray.reduce((prev, curr) => prev + (curr.total * ((!curr.curConvert || parseFloat(curr.curConvert) === 0) ? 1 : curr.curConvert)), 0);

        const totalpaid = testPaidArray.reduce((prev, curr) => prev + (curr.total * (parseFloat(curr.curConvert > 0) ? parseFloat(curr.curConvert) : 1)), 0);
        const totalPartial = testPartialPaidArray.reduce((prev, curr) => prev + (curr.dueAmount * (parseFloat(curr.curConvert > 0) ? parseFloat(curr.curConvert) : 1)), 0)

        setCollectedTax((paidTotal + partialTotal) - (paidSubtotal + partialSubtotal));

        setPaidAmount(totalpaid + totalPartial);
        setPendingAmount(testPendingArray.reduce((prev, curr) => prev + curr.total, 0));

        setPaidCount(testPaidArray.length);
        setPendingCount(testPendingArray.length);
    }

    useEffect(() => {
        getSummary();
    }, [newArray]);

    const handleDelete = (singleClientId) => {
        deleteClient(singleClientId).then((response) => {
            if (response.status === 200) {

                const clientName = singleClient?.clientFirmName ? singleClient?.clientFirmName : `${singleClient?.firstName} ${singleClient?.lastName}`;

                const activityForm = {
                    module: ['client'],
                    activity: 'Client Deleted',
                    activityDesc: `Client ${clientName} Deleted`,
                    createdDate: formattedDate,
                    createdTime: formattedTime
                };
                addRecentActivity(activityForm);

                SuccessToast("Client deleted");
                setShowDelete(false);
                history.push(`/${orgName}/dashboard/client/client-list`);
            }
            else {
                ErrorToast("Unable to delete the client");
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
            setShowDelete(false);
        })
    };

    const handleShowInvoice = () => {
        setShowProjects(false);
        setShowInvoice(!showInvoice);
    }

    // const handleShowProjects = () => {
    //     setShowInvoice(false);
    //     setShowProjects(!showProjects);
    // }

    const handleDeleteInvoice = (invoId) => {
        setDeleteId(invoId);
        setShowDeleteInvoice(true);
    }

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };

    const filters = ['All', 'Pending', 'Partial Paid', 'Overdue', 'Draft', 'Deleted', 'Canceled', 'Disputed']

    const mainFilters = ['All', 'Pending', 'Paid', 'Overdue']

    const handleRestoreInvoice = (invoiceId) => {
        restoreDeletedInvoice(invoiceId).then((response) => {
            if (response.status === 200) {
                invoices();
                SuccessToast("Invoice Restored SuccessFully");
            }
            else {
                ErrorToast("Unable to restore the invoice");
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Somethign went wrong');
        });
    }

    const clientOptions = () => {
        return (
            <div style={{ position: "relative", zIndex: 1 }}>
                <div className="mx-3 text-dark fs-5">Filters</div>
                <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setShowFilter(!showFilter)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>

                    <Col sm>
                        {
                            selected === "All" ? 'All' : `${selected}`
                        }
                    </Col>
                    <Col sm={1}>
                        <Tooltip title="filter">
                            <Link to="#">
                                <FiFilter className="text-dark fs-4" />
                            </Link>
                        </Tooltip>
                    </Col>
                </Row>
                {
                    showFilter && (
                        <ul className="m-0 ms-3 p-0 bg-white rounded" style={{ width: "91%", zIndex: 9, listStyle: "none", position: "absolute", boxShadow: "0px 0px 10px #c8c9ca" }}>
                            {filters.map((data, index) => (
                                <li
                                    onClick={() => handleFilter(data)}
                                    className={`${data === selected
                                        ? " bg-primary border-primary text-white"
                                        : "border-white text-dark"
                                        } py-2 px-3 rounded`}
                                    style={{ cursor: "pointer", borderBottom: "3px solid" }}
                                    key={index}
                                >
                                    {data}
                                </li>
                            ))}
                        </ul>
                    )
                }
                <Link to={`/${orgName}/dashboard/client/add-invoice/${singleClient._id}`}>
                    <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                        <Col sm={9}>Create Invoice</Col>
                        <Col sm={1} className="text-end">
                            <Tooltip title="Create invoice">
                                <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                                    <Description style={{ fontSize: "1rem", cursor: "pointer" }} />
                                </Link>
                            </Tooltip>
                        </Col>
                    </Row>
                </Link>

                <Link to={`/${orgName}/dashboard/client/update-client/${id}`}>
                    <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                        <Col sm={9}>Edit Client</Col>
                        <Col sm={1} className="text-end">
                            <Tooltip title="Edit Client">
                                <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                                    <HiOutlinePencilAlt style={{ fontSize: "1rem", cursor: "pointer" }} />
                                </Link>
                            </Tooltip>
                        </Col>
                    </Row>
                </Link>

                <Row className="bg-soft-secondary text-secondary mx-3 py-2 ps-2 pe-3 my-2" style={{ cursor: "not-allowed", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={9}>Create Project</Col>
                    <Col sm={1} className="text-end">
                        {/* <Tooltip title="Create Project"> */}
                        <Link to="#" className="py-1 px-3 rounded  text-secondary me-2" style={{ cursor: "not-allowed" }}>
                            <MdAccountTree style={{ fontSize: "1rem", cursor: "not-allowed" }} />
                        </Link>
                        {/* </Tooltip> */}
                    </Col>
                </Row>

                <Row className="bg-soft-secondary text-secondary mx-3 py-2 ps-2 pe-3 my-2" style={{ cursor: "not-allowed", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={9}>Send Reminder</Col>
                    <Col sm={1} className="text-end">
                        {/* <Tooltip title="Create Project"> */}
                        <Link to="#" className="py-1 px-3 rounded  text-secondary me-2" style={{ cursor: "not-allowed" }}>
                            <MdMessage style={{ fontSize: "1rem", cursor: "not-allowed" }} />
                        </Link>
                        {/* </Tooltip> */}
                    </Col>
                </Row>

                <Row className="text-white bg-danger mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setShowDelete(true)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={12}>Delete Client</Col>
                </Row>
            </div>
        )
    }

    const styleHandle = (data) => {
        if (data.status === "Pending") {
            return {
                backgroundColor: "#FFB802",
                textAlign: "center",
                fontSize: "90%",
            };
        } else if (data.status === "Paid") {
            return {
                backgroundColor: "green",
                textAlign: "center",
                fontSize: "90%",
            };
        } else if (data.status === "Partial Paid") {
            return {
                backgroundColor: "blue",
                textAlign: "center",
                fontSize: "90%",
            };
        } else if (data.status === "Overdue") {
            return {
                backgroundColor: "#ff0000",
                textAlign: "center",
                fontSize: "90%",
            };
        } else if (data.status === "Canceled" || data.status === "Cancel") {
            return {
                backgroundColor: "#a9083d",
                textAlign: "center",
                fontSize: "90%",
            };
        } else if (data.status === "Draft") {
            return {
                backgroundColor: "#374550",
                textAlign: "center",
                fontSize: "90%",
            };
        }
    };

    const path = `${window.location.protocol}//${window.location.hostname}/invoice/${orgId}/${invoiceId}`;

    const summary = [
        {
            id: 1,
            amount: collectedTax,
            title: "TAX COLLECTED",
            logo: <HiOutlineReceiptTax />,
            count: '',
            desc: ""
        },
        {
            id: 2,
            amount: paidAmount,
            title: "PAID",
            logo: <FiCheckSquare />,
            count: paidCount,
            desc: "Paid invoices"
        },
        {
            id: 3,
            amount: pendingAmount,
            title: "PENDING",
            logo: <FaRegClock />,
            count: pendingCount,
            desc: "Pending invoices"
        },
    ]
    return (
        <div onClick={() => showFilter && setShowFilter(false)}>
            <SubHeader title={
                singleClient.clientFirmName ? `${singleClient.clientFirmName}` : `${clientDetails.firstName} ${clientDetails.lastName}`
            } />
            <div>
                <div className="d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-1%" : "-3%", flexDirection: "row" }}>
                    <Row sm={3} className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
                        <Col sm={12} className="bg-white rounded">
                            <Row className="py-2">
                                <h5 className="text-primary" style={{ fontWeight: "550" }}> About </h5>
                            </Row>
                            <Row>
                                <Col sm={12} style={{ textAlign: "center", height: "100%" }}>
                                    {/* <img
                                        src="https://tse4.mm.bing.net/th?id=OIP.Ix6XjMbuCvoq3EQNgJoyEQHaFj&pid=Api&P=0" alt="client" height="70rem" width="70rem" style={{ borderRadius: "50%" }}
                                    /> */}
                                    <CgProfile className="text-secondary" style={{ height: "25%", width: "25%" }} />
                                </Col>
                                {
                                    singleClient.clientFirmName ? (
                                        <Col className="my-2 text-dark text-center" style={{ fontWeight: "550", fontSize: "1rem", textTransform: "uppercase" }}>
                                            {singleClient === undefined || singleClient.clientFirmName === undefined ? '' : singleClient.clientFirmName}
                                        </Col>
                                    ) : (
                                        <Col className="my-2 text-dark text-center" style={{ fontWeight: "550", fontSize: "1rem", textTransform: "uppercase" }}>
                                            {singleClient === undefined || singleClient.firstName === undefined ? '' : singleClient.firstName} {singleClient === undefined || clientDetails.lastName === undefined ? '' : clientDetails.lastName}
                                        </Col>
                                    )
                                }
                            </Row>
                            <Row className="py-1">
                                <Col md={3} sm={12}>
                                    <div style={{ fontWeight: "550" }}>Website: </div>
                                </Col>
                                <Col>{singleClient === undefined || singleClient.website === undefined ? '' : singleClient.website}</Col>
                            </Row>
                            <Row className="py-1">
                                <Col md={3} sm={12}>
                                    <div style={{ fontWeight: "550" }}>GST: </div>
                                </Col>
                                <Col>{singleClient === undefined || singleClient.taxId === undefined ? '' : singleClient.taxId}</Col>
                            </Row>
                            <Row className="py-1">
                                <Col md={3} sm={12}>
                                    <div style={{ fontWeight: "550" }}>Phone: </div>
                                </Col>
                                <Col>{singleClient === undefined || singleClient.phone === undefined ? '' : singleClient.phone}</Col>
                            </Row>
                            <Row className="py-1">
                                <Col md={3} sm={12}>
                                    <div style={{ fontWeight: "550" }}>Email: </div>
                                </Col>
                                <Col>{singleClient === undefined || singleClient.email === undefined ? '' : singleClient.email}</Col>
                            </Row>
                            <Row className="py-1">
                                <Col md={3} sm={12}>
                                    <div style={{ fontWeight: "550" }}>Address: </div>
                                </Col>
                                <Col md={8} sm={12}>
                                    <div>{clientAdd === undefined || clientAdd.address1 === undefined ? '' : clientAdd.address1}</div>
                                    <div>{clientAdd === undefined || clientAdd.address2 === undefined ? '' : clientAdd.address2}</div>
                                    <span className='m-0 p-0'>{clientAdd === undefined || clientAdd.country === undefined ? '' : `${clientAdd.country}, `}</span>
                                    <span className='m-0 p-0'>{clientAdd === undefined || clientAdd.state === undefined ? '' : `${clientAdd.state}, `}</span>
                                    <span className='m-0 p-0'>{clientAdd === undefined || clientAdd.city === undefined ? '' : `${clientAdd.city}, `}</span>
                                    <span className='m-0 p-0'>{clientAdd === undefined || clientAdd.pinCode === undefined ? '' : `${clientAdd.pinCode} `}</span>
                                </Col>
                            </Row>
                        </Col>
                        {
                            contactPerson && (
                                <Col sm={12} className="bg-white rounded my-2">
                                    <Row className="py-2">
                                        <h5 className="text-primary" style={{ fontWeight: "550" }}> Contact Person </h5>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Name: </div>
                                        </Col>
                                        <Col className='p-0'>{contactPerson === undefined || contactPerson.name === undefined ? '' : contactPerson.name}</Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Phone:</div>
                                        </Col>
                                        <Col className='p-0'>{contactPerson === undefined || contactPerson.phone === undefined ? '' : contactPerson.phone}</Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Alternative Phone: </div>
                                        </Col>
                                        <Col className='p-0'>{contactPerson === undefined || contactPerson.altPhone === undefined ? '' : contactPerson.altPhone}</Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Mobile: </div>
                                        </Col>
                                        <Col className='p-0'>{contactPerson === undefined || contactPerson.mobile === undefined ? '' : contactPerson.mobile}</Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Alternative Mobile: </div>
                                        </Col>
                                        <Col className='p-0'>{contactPerson === undefined || contactPerson.altMobile === undefined ? '' : contactPerson.altMobile}</Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md={5} sm={12}>
                                            <div style={{ fontWeight: "550" }}>Address: </div>
                                        </Col>
                                        {/* <Col className='p-0'>123, Shipra path, masarovar, <br /> Jaipur, Rajasthan, India, <br /> 303030</Col> */}
                                        <Col className='p-0'>
                                            <p className="m-0 p-0">{contactPerson === undefined || contactPerson.address1 === undefined ? '' : contactPerson.address1}</p>
                                            <p className="m-0 p-0">{contactPerson === undefined || contactPerson.address2 === undefined ? '' : contactPerson.address2}</p>
                                            <p className="m-0 p-0">
                                                {
                                                    `${contactPerson === undefined || contactPerson.city === undefined ? '' : `${contactPerson.city}, `} 
                                            ${contactPerson === undefined || contactPerson.state === undefined ? '' : `${contactPerson.state}, `} 
                                            ${contactPerson === undefined || contactPerson.country === undefined ? '' : `${contactPerson.country}, `} 
                                            ${contactPerson === undefined || contactPerson.pinCode === undefined ? '' : `${contactPerson.pinCode} `}`
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }
                    </Row>
                    <Row
                        sm={7}
                        className={`h-100 rounded mx-2 mb-5`}
                        style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%" }}
                    >
                        <div className='m-0 p-0'>
                            <div className='m-0 p-0 mb-3'>
                                <Row className='w-100 m-0 p-0'>
                                    {
                                        summary.map((data, index) => (
                                            <Col className='w-25 bg-white m-0 mx-2 border border-white px-2 py-2 rounded summaryCard' key={index} style={{ boxShadow: "0px 0px 10px #c8c9ca" }}>
                                                <Row className='w-100 m-0 p-0'>
                                                    <Col sm={8}>
                                                        <div className='fs-3'>{currency === '' ? "" : "₹"} {data.amount.toFixed(2)}</div>
                                                        <div className='text-secondary fs-5'>{data.title}</div>
                                                    </Col>
                                                    <Col sm={4} className='text-end'>
                                                        <span className='px-3 py-3 m-0 rounded rounded-circle text-primary fs-2 text-center'>
                                                            {data.logo}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className='w-100 m-0 mt-3 p-0'>
                                                    <Col sm={12}>
                                                        <span
                                                            className={`m-0 py-1 px-3 text-white rounded fw-semibold`}
                                                            style={{ background: data.title === "PAID" ? 'green' : data.title === "PENDING" ? '#FFB802' : '#fff' }}
                                                        >{data.count}</span>
                                                        <span className='m-0 ms-2 p-0 text-secondary' style={{ fontSize: "0.8rem" }}>{data.desc}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>

                            <Row className='m-0 p-0 rounded bg-white'>
                                <Card.Header className="bg-primary" style={{ width: "100%" }}>
                                    <Row className='bg-primary w-100'>
                                        <Col sm={11} style={{ cursor: "pointer" }} onClick={() => handleShowInvoice()}>
                                            <h4 className=" text-white">Invoices</h4>
                                        </Col>
                                        <Col sm={1} className="text-end">
                                            {showInvoice === false ? (
                                                <BsChevronDown className="fs-4 fw-bold text-white" onClick={() => handleShowInvoice()} style={{ cursor: "pointer" }} />
                                            ) : (
                                                <BsChevronUp className="fs-4 fw-bold text-white" onClick={() => setShowInvoice(false)} style={{ cursor: "pointer" }} />
                                            )}
                                        </Col>
                                    </Row>
                                </Card.Header>
                                {
                                    showInvoice && (
                                        <div>
                                            {/* <hr /> */}
                                            <Row sm={12} className="bg-white text-primary my-3 pt-2 mb-3 text-center fs-4">
                                                {mainFilters.map((data, index) => (
                                                    <Col
                                                        onClick={() => handleFilter(data)}
                                                        className={`${data === selected
                                                            ? "border-primary text-primary"
                                                            : "border-white text-dark"
                                                            }`}
                                                        style={{ cursor: "pointer", borderBottom: "3px solid" }}
                                                        key={index}
                                                    >
                                                        {data}
                                                    </Col>
                                                ))}
                                            </Row>
                                            {/* <hr /> */}
                                            <div className="table-responsive mt-2" style={{ minHeight: "40vh" }}>
                                                <table className="" style={{ width: "100%" }}>
                                                    <thead>
                                                        <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                                                            {/* <th className="fw-bold" style={{ fontSize: "0.9rem" }}></th> */}
                                                            <th className="fw-bold w-5" style={{ fontSize: "0.9rem" }}>Invoice No.</th>
                                                            <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Firm Name</th>
                                                            <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Client Name</th>
                                                            <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Status</th>
                                                            <th className="fw-bold w-20 text-end" style={{ fontSize: "0.9rem" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {clientInvoices
                                                            .slice(pagination.start, pagination.end)
                                                            .map((data, index) => (
                                                                <tr key={index}>
                                                                    <td className="w-5 py-3" style={{ width: "12%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                        <span className="ms-0 text-dark">{data.invoiceNumber}</span>
                                                                    </td>
                                                                    <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                        {`${data.firm === undefined || data.firm.name === undefined ? "" : data.firm.name}`}
                                                                    </td>
                                                                    {
                                                                        data.client.clientFirmName ? (
                                                                            <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                {`${data.client === undefined || data.client.clientFirmName === undefined ? "" : data.client.clientFirmName} `}
                                                                            </td>
                                                                        ) : (
                                                                            <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                                {`${data.client === undefined || data.client.firstName === undefined ? "" : data.client.firstName} `}
                                                                                {`${data.client === undefined || data.client.lastName === undefined ? "" : data.client.lastName}`}
                                                                            </td>
                                                                        )
                                                                    }
                                                                    <td className="pt-3" style={{ borderBottom: "1px solid rgb(227 227 227)" }}>
                                                                        <p className="text-white rounded-pill w-50 text-center"
                                                                            style={styleHandle(data)}
                                                                        >
                                                                            {data.status === undefined ? "" : data.status}
                                                                        </p>
                                                                    </td>
                                                                    <td className="text-end" style={{ borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                        <div className="flex text-end list-user-action">
                                                                            <Tooltip title="View" key="view">
                                                                                <Link to={`/${orgName}/dashboard/invoice/invoice-details/${data._id}`}>
                                                                                    <FiEye className="text-primary mx-2" style={{ fontSize: "1rem", cursor: "pointer" }} />
                                                                                </Link>
                                                                            </Tooltip>
                                                                            <Tooltip title={selected === "Deleted" ? "Delete Permanently" : "Delete"} key="delete">
                                                                                <Link to={`#`}>
                                                                                    <MdDeleteOutline onClick={() => handleDeleteInvoice(data._id)} className="text-danger mx-2" style={{ fontSize: "1rem", cursor: "pointer", }} />
                                                                                </Link>
                                                                            </Tooltip>
                                                                            {
                                                                                selected != "Draft" && selected != "Deleted" ? (
                                                                                    <Tooltip title="Share">
                                                                                        <Link to="#" onMouseOver={() => setInvoiceId(data._id)} >
                                                                                            <RWebShare data={{ text: "Share Invoice", url: path, title: "invoice", }}>
                                                                                                <BsFillShareFill className='text-primary'
                                                                                                    style={{ fontSize: "0.9rem", cursor: "pointer" }}
                                                                                                />
                                                                                            </RWebShare>
                                                                                        </Link>
                                                                                    </Tooltip>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                selected === "Deleted" && (
                                                                                    <Tooltip title="Restore Invoice" key="restore">
                                                                                        <Link to={`#`}>
                                                                                            <IoReloadOutline onClick={() => handleRestoreInvoice(data._id)} className="text-warning" style={{ fontSize: "1rem", cursor: "pointer", }} />
                                                                                        </Link>
                                                                                    </Tooltip>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {
                                                    clientInvoices.length === 0 && (
                                                        <div className='mt-5 fs-4 text-center text-secondary'>
                                                            No Invoices To Show
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="my-5">
                                                <Pagination
                                                    showPerPage={showPerPage}
                                                    onPaginationChange={onPaginationChange}
                                                    total={clientInvoices.length}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </Row>

                            {/* <Row className='m-0 mt-3 p-0 rounded bg-white'>
                                <Card.Header className="bg-primary" style={{ width: "100%" }}>
                                    <Row className="w-100">
                                        <Col sm={11} onClick={() => handleShowProjects()} style={{ cursor: "pointer" }}>
                                            <h4 className=" text-white">Projects</h4>
                                        </Col>
                                        <Col sm={1} className="text-end">
                                            {showProjects === false ? (
                                                <BsChevronDown className="fs-4 fw-bold text-white" onClick={() => handleShowProjects()} style={{ cursor: "pointer" }} />
                                            ) : (
                                                <BsChevronUp className="fs-4 fw-bold text-white" onClick={() => setShowProjects(false)} style={{ cursor: "pointer" }} />
                                            )}
                                        </Col>
                                    </Row>
                                </Card.Header>
                                {
                                    showProjects && (
                                        <div>
                                            <div
                                                className="table-responsive mt-2"
                                                style={{ minHeight: "50vh" }}
                                            >
                                                <table className="" style={{ width: "100%" }}>
                                                    <thead>
                                                        <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                                                            <th className="fw-bold" style={{ fontSize: "0.9rem" }}>S.No.</th>
                                                            <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Projetc Name</th>
                                                            <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Worth</th>
                                                            <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Status</th>
                                                            <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-5 py-3" style={{ width: "12%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                <Tooltip title="Invoice Details">
                                                                    <Link to={`#`}>
                                                                        <span className="ms-0 text-dark">1</span>
                                                                    </Link>
                                                                </Tooltip>
                                                            </td>
                                                            <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                1
                                                            </td>
                                                            <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                Project 1
                                                            </td>
                                                            <td className="pt-3" style={{ borderBottom: "1px solid rgb(227 227 227)" }}>
                                                                <p className="text-dark rounded-pill w-50 text-center"
                                                                >On Going</p>
                                                            </td>
                                                            <td className="" style={{ borderBottom: "1px solid rgb(227 227 227)", }}>
                                                                <div className="flex text-start list-user-action">
                                                                    <Tooltip title="View" key="view">
                                                                        <Link to={`#`}>
                                                                            <FiEye className="text-primary" style={{ fontSize: "1rem", cursor: "pointer" }} />
                                                                        </Link>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete" key="view">
                                                                        <Link to={`#`}>
                                                                            <MdDeleteOutline className="text-danger mx-2" style={{ fontSize: "1rem", cursor: "pointer", }} />
                                                                        </Link>
                                                                    </Tooltip>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )
                                }
                            </Row> */}
                        </div>
                    </Row>
                    <ActivityBar options={clientOptions} allRecentActivities={allRecentActivities} />
                </div>
                <Modal show={showDelete} onHide={() => setShowDelete(false)} style={{ marginTop: "20%" }}>
                    <Modal.Body className="my-2">
                        <Row className="mb-2 ms-1">
                            <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Client</Col>
                            <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">You want to delete the client ?</Col>
                        </Row>
                        <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                            <div
                                className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                                onClick={() => handleDelete(singleClient._id)}
                                style={{ borderRadius: "4px", cursor: "pointer" }}
                            >
                                Yes
                            </div>
                            <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                                onClick={() => setShowDelete(false)}
                                style={{ borderRadius: "5px", cursor: "pointer" }}
                            >
                                No
                            </div>
                        </div>
                    </Modal.Body>
                </Modal >

                <DeleteInvoice showDelete={showDeleteInvoice} setShowDelete={setShowDeleteInvoice} id={deleteId} selected={selected} getAllInvoices={getClientInvoices} setDetails={setClientInvoices} />
            </div >
        </div >
    )
}

export default AboutClient;

