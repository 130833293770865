import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { MdClear } from 'react-icons/md'
import { updateActivity, updateAttachment } from '../../Hook/leadHooks';
import { useParams } from 'react-router-dom';
import { ErrorToast, SuccessToast, WarningToast } from '../../../components/toast';

const UpdateActivity = ({ show, setShow, activityId, getAllActivities, activityDetails }) => {

    const [form, setForm] = useState({});
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [attachmentImage, setAttachmentImage] = useState('');
    const [image, setImage] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);

    const formData = new FormData();
    const { id } = useParams();
    const orgId = localStorage.getItem('orgID');

    useEffect(() => {
        setType(activityDetails.type === undefined ? '' : activityDetails.type);
        setDescription(activityDetails.desc === undefined ? '' : activityDetails.desc);
        setTitle(activityDetails.title === undefined ? '' : activityDetails.title);
        setImage(activityDetails.image === undefined ? '' : activityDetails.image);
        setAttachmentImage(activityDetails.image === undefined ? '' : activityDetails.image);
    }, [activityDetails.type, activityDetails.desc, activityDetails.title, activityDetails.image]);

    const handleAttachmentImage = (e) => {
        setAttachmentImage(e.target.files[0]);
        setImage(URL.createObjectURL(e.target.files[0]));
    };

    const handleChange = (e) => {
        (e.target.name === 'type') ? setType(e.target.value) : (e.target.name === "title") ? setTitle(e.target.value) : (e.target.name === "desc") && setDescription(e.target.value);

        setForm({
            ...form,
            [e.target.name]: e.target.value,
            leadId: id,
            orgId: orgId
        });
    };

    const handleUpdateActivity = (e) => {
        e.preventDefault();
        setDisableBtn(true);
        setImage !== undefined && handleUpdateAttachment();
        updateActivity(activityId, form).then((response) => {
            if (response.status === 200) {
                SuccessToast('Activity updated successfully');
                getAllActivities(type);
                setShow(false);
                setDisableBtn(false);
            } else {
                ErrorToast('Unable to update activity');
            }
        }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'));
    }

    const handleUpdateAttachment = () => {
        formData.append('image', attachmentImage);
        updateAttachment(activityId, formData).then((response) => {
            if (response.status === 200) {
                SuccessToast("Activity Updated Successfully");
            } else {
                ErrorToast('Unable to update actiivty');
            }
        }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'));
    }

    return (
        <>
            <Modal centered size='lg' show={show} onHide={() => setShow(false)}>
                <Modal.Header className="bg-primary text-white">
                    <Col sm={8}>
                        <h4 className="text-white">Update Lead Activity</h4>
                    </Col>
                    <Col sm={4} className="text-end">
                        <MdClear className="fs-3" style={{ cursor: "pointer" }} onClick={() => setShow(false)} />
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleUpdateActivity}>
                        <Row className='mb-3'>
                            <Col sm={12} md={6}>
                                <Form.Group className='w-100'>
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control name="type" className='border border-primary' value={type} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            {
                                title.length > 0 ? (
                                    <Col sm={12} md={6}>
                                        <Form.Group className='w-100'>
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control name="title" className='border border-primary' value={title} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                ) : null
                            }
                            {
                                (type === "Attachment") && (image !== undefined) > 0 ? (
                                    <>
                                        <Col sm={6} className='mt-2'>
                                            <Form.Label>Image</Form.Label>
                                            <Form.Group>
                                                <Form.Control type="file" className='border border-primary' onChange={handleAttachmentImage} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6} className='mt-2'>
                                            <img src={image} alt="attachment" style={{ width: "80px", height: "80px" }} />
                                        </Col>
                                    </>
                                ) : null
                            }
                            <Col sm={12} md={12} className='mt-2'>
                                <Form.Group className='w-100'>
                                    <Form.Label>Description</Form.Label>
                                    <textarea name="desc" className='border border-primary w-100 p-2 rounded' cols={8} rows={3} value={description} onChange={handleChange} ></textarea>
                                </Form.Group>
                            </Col>
                        </Row>
                        <span onClick={handleUpdateActivity} className={`${disableBtn ? 'bg-secondary' : 'bg-primary'} text-white mx-0 rounded py-2 px-3`}
                            style={{ cursor: disableBtn ? 'not-allowed' : 'pointer', width: "5rem" }}
                        >
                            Update
                        </span>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UpdateActivity
