import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import topHeader from "../../../../../assets/images/dashboard/top-header.png";
import { Row, Col, Container, Modal, Form, Button } from "react-bootstrap";
import Card from "../../../../../components/Card";
import { API } from "../../../../../backend";
import { HiOutlinePencilAlt } from "react-icons/hi";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
import cubicleweb2 from '../../../../../assets/images/Cubicleweb2.png';
import cubicleweb from '../../../../../assets/images/cubicleweb.png';
import { MdClear } from "react-icons/md";
import { ErrorToast, SuccessToast } from "../../../components/toast";
import { AiOutlineSend } from 'react-icons/ai';
import { inviteUser } from "../../Hook/userHooks";

const OrganizationProfile = () => {
  const intialData = Object.freeze({});
  const [form, setForm] = useState({});
  const [orgData, setOrgData] = useState([intialData]);
  const [departments, setDepartments] = useState([]);
  const [stages, setStages] = useState([]);
  const [status, setStatus] = useState([]);
  const { id } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showInvitationForm, setShowInvitationForm] = useState(false);
  const [inviteHovered, setInviteHoevered] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const orgName = localStorage.getItem('orgName');
  const orgId = localStorage.getItem('orgID');
  const orgEmail = localStorage.getItem('orgEmail');

  const getSingleOrg = async (e) => {
    await fetch(`${API}/org/getData/${id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setOrgData(response.data);
          setDepartments(response.data.orgDept);
          setStages(response.data.orgLeadStages);
          setStatus(response.data.orgLeadStatus);
        } else {
        }
      })
  };

  useEffect(() => {
    getSingleOrg();
  }, []);

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleChangeInvitation = (e) => {
    setForm({
      ...form,
      to: e.target.value,
      from: orgEmail,
      userName: orgName,
      // link: `http://localhost:3000/login/${orgId}`
      link: `https://crm.cubicleweb.com/login/${orgId}`
    });
  };

  const sendInvitation = (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    inviteUser(form).then((response) => {
      if (response.status === 201) {
        setShowInvitationForm(false);
        SuccessToast("Invitation sent");
        setBtnDisabled(false);
      } else {
        setBtnDisabled(false);
        ErrorToast("Unbale to send invitation");
      }
    }).catch(() => {
      setBtnDisabled(false);
      ErrorToast("Unbale to send invitation");
    })
  };

  return (
    <>
      <SubHeader title="Organization Profile" />
      <div className="mb-3 d-flex justify-content-center align-items-center" style={{ marginTop: "-1.5%", zIndex: 999 }}>
        <Row className="mx-2 px-3 py-2 rounded bg-white w-100">
          <Col sm={9} className="d-flex" style={{ flexDirection: "row" }}>
            <Col sm={1} style={{ marginTop: "-30px" }}>
              <img
                // src={
                //   orgData.orgLogo === ""
                //     ? "https://tse1.mm.bing.net/th?id=OIP.HpdsRtDI3kGbdkXAoYNdfAHaHo&pid=Api&P=0"
                //     : orgData.orgLogo
                // }
                // src={orgData[0].orgLogo}
                src={cubicleweb}
                // src="https://static.vecteezy.com/system/resources/previews/002/318/271/original/user-profile-icon-free-vector.jpg"
                alt="profile"
                width="70px"
                height="70px"
                className="rounded-circle bg-white"
                style={{ border: "4px solid #fff" }}
              />
            </Col>
            <Col sm={3} className="ms-3">
              <div
                className="text-dark"
                style={{ fontSize: "1rem", fontWeight: "550" }}
              >
                {orgData === undefined || orgData.orgName === undefined ? "" : orgData.orgName}
              </div>
            </Col>
          </Col>
          <Col sm={3} className="d-flex justify-content-end align-items-end">
            <div
              className={`d-flex justify-content-between align-items-center w-50 border border-primary ${inviteHovered ? 'bg-primary' : 'bg-white'} px-3 py-2 cursor-pointer`}
              onMouseOver={() => setInviteHoevered(true)}
              onMouseOut={() => setInviteHoevered(false)}
              onClick={() => setShowInvitationForm(true)}
              style={{ borderRadius: "5px" }}>
              <h5 className={`${inviteHovered ? 'text-white' : 'text-primary'} cursor-pointer`}>Send Invitation</h5>
              <AiOutlineSend className={`${inviteHovered ? 'text-white' : 'text-primary'} fs-3 ms-3`} />
            </div>
          </Col>
          {/* <Col sm={1}>
              <Tooltip title="Edit Organization">
                <Link to={`/${orgName}/dashboard/settings/organization/update-org/${id}`}>
                  <HiOutlinePencilAlt
                    className="text-primary fs-4"
                    style={{ cursor: "pointer" }}
                  />
                </Link>
              </Tooltip>
            </Col> */}
        </Row>
      </div>
      <div className="mt-2 d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-2%" : "-3%", flexDirection: "row" }}>
        <div className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
          <Col sm={12} className="rounded bg-white px-3 py-3" style={{ overflowX: "hidden" }}>
            <h5 className="py-2 text-primary" style={{ fontWeight: "550" }}>About</h5>
            <Row className="py-1">
              <Col md={3} sm={12}>
                <div style={{ fontWeight: "550" }}>Name: </div>
              </Col>
              <Col>{orgData?.orgName}</Col>
            </Row>
            <Row className="py-1">
              <Col md={3} sm={12}>
                <div style={{ fontWeight: "550" }}>Email: </div>
              </Col>
              <Col>{orgData?.orgEmail}</Col>
            </Row>
            <Row className="py-1">
              <Col md={3} sm={12}>
                <div style={{ fontWeight: "550" }}>Phone: </div>
              </Col>
              <Col md={9} sm={12}>{orgData?.orgPhone}</Col>
            </Row>
          </Col>
        </div>

        <div className={`h-100 rounded mx-2 mb-5`} style={{ minWidth: "50%", width: "75%", maxWidth: "80%" }}>
          <Col>
            <div className="bg-white mb-2 px-3 rounded text-dark" style={{ minHeight: "3rem" }}>
              <Card.Header className="bg-white" style={{ width: "100%" }}>
                <div sm={10} className="">
                  <h4 className=" text-primary fw-bold">Organization Profile</h4>
                </div>
              </Card.Header>
              <div className="d-flex justify-content-center align-items-center">
                {/* <Row
                    className="pb-3 px-4 d-flex"
                    style={{ flexDirection: "column", alignItems: "center" }}
                  > */}
                <img
                  // src={orgData[0].orgLogo}
                  src={cubicleweb2}
                  // src="https://static.vecteezy.com/system/resources/previews/002/318/271/original/user-profile-icon-free-vector.jpg"
                  // src="https://images.pexels.com/photos/1337386/pexels-photo-1337386.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  // src={
                  //   orgData.orgLogo === ""
                  //     ? orgData.orgLogo
                  //     : "https://tse1.mm.bing.net/th?id=OIP.HpdsRtDI3kGbdkXAoYNdfAHaHo&pid=Api&P=0"
                  // }
                  // className="rounded-circle"
                  alt="profilePic"
                  style={{
                    width: "20rem",
                    height: "20rem",
                  }}
                />
              </div>
              {/* </Row> */}
              <div className="text-center my-2">
                <span className="fs-3" style={{ fontWeight: "550" }}>
                  {orgData === undefined || orgData.orgName === undefined ? "" : orgData.orgName}
                </span>
                {/* <span className="text-secondary">- Web Developer</span> */}
              </div>
            </div>
            <Row>
              {/* <Col sm={12}>
                  <div
                    className="bg-white mb-2 py-3 px-3 rounded text-dark"
                    style={{ minHeight: "3rem" }}
                  >
                    <Card.Header className="bg-white" style={{ width: "100%" }}>
                      <div sm={10} className="">
                        <h4 className=" text-primary fw-bold">Pipeline</h4>
                      </div>
                    </Card.Header>
                    <div
                      style={{
                        maxHeight: "200px",
                        overflowY: "scroll",
                        scrollBehavior: "smooth",
                        scrollbarColor: "red",
                      }}
                    >
                      <ul style={{ listStyleType: "none" }}>
                        {departments.map((depts, index) => (
                          <li
                            key={index}
                            className="my-2"
                            style={{
                              cursor: "pointer",
                              fontSize: "0.8rem",
                              fontWeight: "550",
                            }}
                          >
                            {depts}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Col> */}

              {/* <Col sm={6}>
                <div
                  className="bg-white mb-2 py-3 px-3 rounded text-dark"
                  style={{ minHeight: "3rem" }}
                >
                  <Card.Header className="bg-white" style={{ width: "100%" }}>
                    <div sm={10} className="">
                      <h4 className=" text-primary fw-bold">Departments</h4>
                    </div>
                  </Card.Header>
                  <div className="mx-4">
                    <div>
                      <h4>Departments</h4>
                      <div>
                        <ul>
                          {departments.map((dept) => (
                            <li>{dept.orgDept}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Col>
        </div>
      </div>
      <Modal size="md" centered show={showInvitationForm} onHide={() => setShowInvitationForm(false)}>
        <Modal.Header className="bg-primary">
          <Col sm={11} className="text-white">
            <h4 className="text-white">Send Invitation</h4>
          </Col>
          <Col sm={1} className="text-end text-white">
            <MdClear className="fs-4" onClick={() => setShowInvitationForm(false)} style={{ cursor: "pointer" }} />
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={sendInvitation}>
            <div className="d-flex justify-contetn-between align-items-center">
              <Form.Control type="input" className="border border-primary me-2 w-75" name="email" onChange={handleChangeInvitation} placeholder="Enter email" required />
              <Button className="bg-primary text-white p-2 w-25 cursor-pointer" type="submit" disabled={btnDisabled} style={{ borderRadius: "5px" }}>Send</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrganizationProfile;
