import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Card, Button } from 'react-bootstrap'
import { Toaster } from 'react-hot-toast';
import { createOrg } from '../Hook/orgHooks'
import { ErrorToast, SuccessToast } from '../../components/toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getCity, getCountries, getState } from '../Hook/CustomHooks';

const OrgResigter = () => {

    const [form, setForm] = useState([]);

    const allPipelines = [];
    const allStages = [];
    const allStatus = [];

    const validated = false;

    // const [allPipelines, setAllPipelines] = useState([]);
    // const [allStages, setAllStages] = useState([]);
    // const [allStatus, setAllStatus] = useState([]);

    const [adminEmail, setAdminEmail] = useState('');
    // const [validated, setValidated] = useState(false);

    const initialOrgAddress = Object.freeze({
        orgLineOne: '',
        orgLineTwo: '',
        orgCountry: "",
        orgState: "",
        orgDistrict: "",
    });

    const [Orgcountries, setOrgCountries] = useState([]);
    const [orgStates, setOrgStates] = useState([]);
    const [orgCities, setOrgCities] = useState([]);

    const [orgAddress, setOrgAddress] = useState(initialOrgAddress);
    const [orgCountry, setOrgCountry] = useState('');
    const [isOpenOrgCountry, setIsOpenOrgCountry] = useState(false);
    const [orgState, setOrgState] = useState('');
    const [isOpenOrgState, setIsOpenOrgState] = useState(false);
    const [orgCity, setOrgCity] = useState('');
    const [isOpenOrgCity, setIsOpenOrgCity] = useState(false);

    const location = useHistory();

    useEffect(() => {
        setAdminEmail(localStorage.getItem("AdminEmail"))
    }, []);

    useEffect(() => {
        getCountries().then((res) => {
            setOrgCountries(res);
        });
    }, []);

    useEffect(() => {
        if (orgAddress.orgCountry) {
            getState(orgAddress.orgCountry).then((res) => {
                setOrgStates(res);
            });
        }
    }, [orgAddress.orgCountry]);

    useEffect(() => {
        if (orgAddress.orgState) {
            getCity(orgAddress.orgState).then((res) => {
                setOrgCities(res);
            });
        }
    }, [orgAddress.orgState]);

    const handleInputChangeOrgCountry = (e) => {
        setOrgCountry(e.target.value);

        setIsOpenOrgCountry(true);
        setOrgAddress({
            ...orgAddress,
            orgCountry: e.target.value
        })
    };

    const handleOptionClickOrgCountry = (i) => {
        setOrgCountry(i)
        setOrgAddress({
            ...orgAddress,
            orgCountry: i
        })
        setIsOpenOrgCountry(false);
    };

    const handleInputChangeOrgState = (e) => {
        setOrgState(e.target.value);

        setIsOpenOrgState(true);
        setOrgAddress({
            ...orgAddress,
            orgState: e.target.value
        })
    };

    const handleOptionClickOrgState = (i) => {
        setOrgState(i)
        setOrgAddress({
            ...orgAddress,
            orgState: i
        })
        setIsOpenOrgState(false);
    };

    const handleInputChangeOrgCity = (e) => {
        setOrgCity(e.target.value);

        setIsOpenOrgCity(true);
        setOrgAddress({
            ...orgAddress,
            orgCity: e.target.value
        })
    };

    const handleOptionClickOrgCity = (i) => {
        setOrgCity(i)
        setOrgAddress({
            ...orgAddress,
            orgDistrict: i
        })
        setIsOpenOrgCity(false);
    };

    const filteredOrgCountryOptions = Orgcountries.filter((option) =>
        (option.country_name).toLowerCase().includes(orgCountry.toLowerCase())
    );

    const filteredStateOptions = orgStates.filter((option) =>
        (option.state_name).toLowerCase().includes(orgState.toLowerCase())
    );

    const filteredOrgCityOptions = orgCities.filter((option) =>
        (option.city_name).toLowerCase().includes(orgCity.toLowerCase())
    );

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
            role: 'Admin',
            permissions: [
                {
                    module: "firm",
                    action: ["Create", "Read", "Update", "Delete"]
                },
                {
                    module: "client",
                    action: ["Create", "Read", "Update", "Delete"]
                },
                {
                    module: "tax",
                    action: ["Create", "Read", "Update", "Delete"]
                },
                {
                    module: "invoice",
                    action: ["Create", "Read", "Update", "Delete"]
                },
                {
                    module: "lead",
                    action: ["Create", "Read", "Update", "Delete"]
                },
                {
                    module: "purchase",
                    action: ["Create", "Read", "Update", "Delete"]
                },
                {
                    module: "product",
                    action: ["Create", "Read", "Update", "Delete"]
                }
            ],
            orgEmail: adminEmail,
            orgDept: allPipelines,
            orgLeadStages: allStages,
            orgLeadStatus: allStatus
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        createOrg(form).then((response) => {
            if (response.status === 200) {
                SuccessToast('Organization created successfully');
                location.push('/');
            } else ErrorToast('Unable to create organization');
        }).catch(() => ErrorToast("Something went wrong"));
    };

    return (
        <div className='px-5 py-3' style={{ height: '100vh', width: "100vw", backgroundSize: 'cover' }}>
            <Toaster />
            <Row className=''>
                <div className='d-flex justify-content-center align-items-center'>
                    <h1 className='text-center w-25 text-primary bg-white p-3 rounded'>Register Organization</h1>
                </div>
                {/* <h1 class>Add Organization</h1> */}
                <Col className="text-dark m-3 d-flex justify-content-center align-items-center w-100">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="w-100 bg-white rounded" style={{ minWidth: "75vw" }}>
                            {
                                <Card className="mb-0">
                                    <Card.Header>
                                        <div className="header-title">
                                            <h4 className="card-title text-primary fw-bold">About organization</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className="">
                                            <Col className="mt-3" sm={12} md={6}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="title">Name *</Form.Label>
                                                    <Form.Control type="text" className="border border-primary" value={form.orgName} placeholder="Organization name" name="orgName" onChange={handleChange} required />
                                                    <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mt-3" sm={6}>
                                                <Form.Label htmlFor="title">Organization Logo</Form.Label>
                                                <Form.Control
                                                    type="file" onChange={handleChange}
                                                    className="border border-primary"
                                                    placeholder="Organization Logo"
                                                    name="orgLogo"
                                                />
                                            </Col>
                                            <Col sm={6} className='mt-3'>
                                                <img
                                                    // src="https://images.pexels.com/photos/1337386/pexels-photo-1337386.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                                                    src={""}
                                                    alt="Logo" width="80px" height="80px" className="rounded-circle mt-2"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="">
                                            <Col className="mt-3" sm={12} md={6}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="clientFName">Email *</Form.Label>
                                                    <Form.Control type="text" name="clientFName" value={adminEmail} placeholder="Enter email" className="border border-primary" onChange={handleChange} readOnly required />
                                                    <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mt-3" sm={12} md={6}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="clientLName">Phone no.</Form.Label><br />
                                                    <input type="text" name="orgPhone" value={form.orgPhone} className="p-2 border border-primary w-100" onChange={handleChange}
                                                        placeholder="Enter phone no." style={{ borderRadius: "5px" }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className="mt-3" sm={12} md={6}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="address1">Address line 1
                                                    </Form.Label>
                                                    <Form.Control type="email" name="address1" placeholder="Address line 1" className="border border-primary" onChange={handleChange} />
                                                    <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col className="mt-3" sm={12} md={6}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="address1">Address line 2
                                                    </Form.Label>
                                                    <Form.Control type="email" name="address1" placeholder="Address line 2" className="border border-primary" onChange={handleChange} />
                                                    <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mt-3" sm={12} md={6}>
                                                <Form.Group style={{ position: 'relative' }}>
                                                    <Form.Label htmlFor="clientCountry">Select Country</Form.Label>
                                                    <input type="text" id="autocomplete-state" className="p-2 w-100 border border-primary" value={orgCountry} name="clientCountry" placeholder="Select Country"
                                                        onChange={handleInputChangeOrgCountry} onClick={() => setIsOpenOrgCountry(true)} autoComplete='off' style={{ borderRadius: '5px' }}
                                                    />
                                                    {isOpenOrgCountry && (
                                                        <>
                                                            <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                {
                                                                    Orgcountries.length > 0 ? (
                                                                        filteredOrgCountryOptions.length > 0 ? (
                                                                            filteredOrgCountryOptions.map((option, i) => (
                                                                                <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickOrgCountry(option.country_name)}>
                                                                                    {option.country_name}
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <div className="mt-2">No match found</div>
                                                                        )
                                                                    ) : (
                                                                        <div className="mt-2">Loading . . . . </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            <Col className="mt-3" sm={12} md={6}>
                                                <Form.Group style={{ position: 'relative' }}>
                                                    <Form.Label htmlFor="clientState">Select State</Form.Label>
                                                    <input type="text" id="autocomplete-state" className="w-100 p-2 border border-primary" value={orgState} name="clientState" placeholder="Select State"
                                                        onChange={handleInputChangeOrgState} onClick={() => setIsOpenOrgState(true)} autoComplete='off' style={{ borderRadius: "5px" }}
                                                    />
                                                    {isOpenOrgState && (
                                                        <>
                                                            <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                {
                                                                    orgStates.length > 0 ? (
                                                                        filteredStateOptions.length > 0 ? (
                                                                            filteredStateOptions.map((option, i) => (
                                                                                <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickOrgState(option.state_name)}>
                                                                                    {option.state_name}
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <div className="mt-2">No match found</div>
                                                                        )
                                                                    ) : (
                                                                        <div className="mt-2">Select country first </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            <Col className="mt-3" sm={12} md={6}>
                                                <Form.Group style={{ position: 'relative' }}>
                                                    <Form.Label htmlFor="clientCity">Select City</Form.Label>
                                                    <input type="text" id="autocomplete-state" className="w-100 p-2 border border-primary" value={orgCity} name="clientCity" placeholder="Select City"
                                                        onChange={handleInputChangeOrgCity} onClick={() => setIsOpenOrgCity(true)} autoComplete='off' style={{ borderRadius: "5px" }}
                                                    />
                                                    {isOpenOrgCity && (
                                                        <>
                                                            <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                {
                                                                    orgCities.length > 0 ? (
                                                                        filteredOrgCityOptions.length > 0 ? (
                                                                            filteredOrgCityOptions.map((option, i) => (
                                                                                <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickOrgCity(option.city_name)}>
                                                                                    {option.city_name}
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <div className="mt-2">No match found</div>
                                                                        )
                                                                    ) : (
                                                                        <div className="mt-2">Select state first </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            <Col className="mt-3" sm={12} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Pincode / Zipcode</Form.Label>
                                                    <input type="number" name="code" onChange={handleChange} className="border border-primary p-2 w-100" placeholder="Pincode / Zipcode" style={{ borderRadius: '5px' }} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                        <div className='mt-5 d-flex justify-content-end align-items-end w-100 '>
                                            <Button type='submit' className='w-5 mx-3'>Register</Button>
                                        </div>
                                    </Card.Footer>
                                </Card>

                            }

                            {/* <div className="pt-5 d-flex justify-content-center align-items-center bg-white rounded p-2">
                                {
                                    formStep === 1 ? (
                                        <>
                                            <Button type='button' className='w-5 mx-3' onClick={() => setFormStep(formStep + 1)}>Next</Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button type='button' className='w-5 mx-3' onClick={() => setFormStep(formStep - 1)}>Previous</Button>
                                            <Button type='submit' className='w-5 mx-3'>Register</Button>
                                        </>
                                    )
                                }
                            </div> */}
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default OrgResigter;
